import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { apiService } from 'src/app/_services/app.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import _ from 'lodash';

export interface DialogData {
    data: any;
}

@Component({
    selector: 'app-comma-filter',
    templateUrl: './comma-filter.component.html',
    styleUrls: ['./comma-filter.component.css']
})
export class CommaFilterComponent implements OnInit {
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    headerDropDown = [];
    headerName: any;
    previousPayload = {};
    processedPreviousPayload = {};
    pageNumber = 0;
    startDate;
    endDate;
    auditYear;
    country;
    tableName;
    tableColumns;
    selectedValue: string;
    selectedFormat: string;
    selectedStatus: string;
    processedData = {
        result: [
            {
                name: '',
                value: '',
                flag: 1
            }
        ]
    };
    showInput = false;
    showLoader = false;
    enableSubmitButton = false;
    numberArrya = [
        'FIXED_AMOUNT_AGREEMENT',
        'Calculated',
        'TOTAL_DUE',
        'AMT_DEDUCTED',
        'AMOUNT_DUE',
        'UFAMT',
        'STORE_NBR',
        'OTHER_DEDUCTION',
        'FIXED_AMT',
        'YEAR_VOLUME',
        'VOLUME',
        'EARNED',
        'AMOUNT_RECEIVED',
        'DEBITED_AMOUNT',
        'AMOUNT',
        'AMOUNT_FOR_THE_PERIOD',
        'AMT_FOR_THE_PERIOD',
        'AMOUNT_DEDUCTED',
        'AMOUNT_DUE',
        'TOTAL_DEDUCTION',
        'PENDING_DUE',
        'IVA_DUE',
        'Accrual_AMT',
        'TOTAL_PENDING',
        'TOTAL_SALES',
        'STORE',
        'VEN_NUM',
        'SEQ',
        'AUDIT_YEAR',
        'DEPARTMENT',
        'TIER',
        'DPT_CAT',
        'SAP_NUMBER',
        'ALLOW_EARNED',
        'AMTDEDUCTED',
        'COOP_DEDUCTED',
        'AMTDUE',
        'IEPS',
        'IVA',
        'IEPS_PERCENT',
        'IVA_PERCENT',
        'SHIP_CHK',
        'TOTAL_SHIPS',
        'APP_CLM_NBR',
        'VERSION',
        'RECORD_ID'
    ];
    formats = [];
    statuses = [];
    constructor(public dialogRef: MatDialogRef<CommaFilterComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _snackBar: MatSnackBar, public translate: TranslateService, private apiservice: apiService) {}

    ngOnInit(): void {
        console.log(this.data)
        this.processedData = {
            result: [
                {
                    name: '',
                    value: '',
                    flag: 1
                }
            ]
        };
        this.previousPayload = this.data.previousPayload;
        this.tableColumns = JSON.parse(JSON.stringify(this.data.columnDets));
        this.tableColumns.forEach((val, i) => {
            if (val.field == 'AUDIT_YEAR') {
                this.tableColumns.splice(i, 1);
            }
        });
        this.tableName = this.data.id;
        this.auditYear = this.data.auditYear;
        this.country = this.data.country;
        if (this.previousPayload !== {}) {
            this.processPreviousPayload(this.previousPayload['result'], this.tableColumns);
        }
        if (this.tableName != 'Advertising' && this.tableName != 'Low Price' && this.tableName != 'Shortages') {
            this.getStatusandFormats();
        }
        
    }

    getStatusandFormats() {
        this.apiservice.getmxformatslist(this.tableName).subscribe(
            (response) => {
                this.formats = response;
            },
            (error) => {
                this.showLoader = false;
                this.openSnackBar('There was an error getting the formats ' + error, 'Ok');
            }
        );

        this.apiservice.getMXDatadropdowns(this.tableName).subscribe(
            (response) => {
                for (var i = 0; i < response.length; i++) {
                    if (response[i].Lookup_Values == 'STATUS') {
                        this.statuses.push(response[i].Lookup_Value_Meaning);
                    }
                }
            },
            (error) => {
                this.showLoader = false;
                this.openSnackBar('There was an error getting the status' + error, 'Ok');
            }
        );
    }

    processPreviousPayload(data, tableData) {
        this.processedPreviousPayload = {};
        if (data !== undefined && data.length > 0) {
            data.forEach((dat) => {
                tableData.forEach((val) => {
                    if (dat.name === val.field) {
                        this.processedPreviousPayload[val.headerName] = dat.value;
                    }
                });
            });
        }
    }

    changeFilterKey(key) {
        this.showInput = true;
        this.headerName = key.value;
        this.processedData = {
            result: [
                {
                    name: '',
                    value: '',
                    flag: 1
                }
            ]
        };
        this.startDate = '';
        this.endDate = '';
        this.enableSubmitButton = false;
    }

    dropDownSelect($event) {
        this.processedData.result[0]['value'] = '';
        this.processedData.result[0]['value'] = $event.map((str) => `'${str}'`).join(',');
        if (this.processedData.result[0]['value'] !== '' && this.processedData.result[0]['value'] !== null && this.processedData.result[0]['value'] !== undefined) {
            this.enableSubmitButton = true;
        } else {
            this.enableSubmitButton = false;
        }
    }

    inputChange($event) {
        this.processedData.result[0]['value'] = '';
        let singleSpacedString = $event.replace(/\s\s+/g, ' ').trim();
        let strArray = singleSpacedString.split(' ');
        this.processedData.result[0]['value'] = strArray.map((str) => `'${str}'`).join(',');
        if (this.processedData.result[0]['value'] !== '' && this.processedData.result[0]['value'] !== null && this.processedData.result[0]['value'] !== undefined) this.enableSubmitButton = true;
    }

    dateChange(dte, type) {
        this.processedData.result[0]['value'] = '';
        this.enableSubmitButton = false;
        if (type == 'start') this.startDate = dte;
        if (type == 'end') this.endDate = dte;
        this.dateValidations();
    }

    radioChange($event) {
        this.processedData.result[0]['flag'] = Number($event.value);
    }

    dateValidations() {
        if (this.startDate !== '' && this.startDate !== undefined && this.startDate !== null && this.endDate !== '' && this.endDate !== undefined && this.endDate !== null) {
            if (this.startDate >= this.endDate) {
                alert('End Date should be greater then Start Date');
            } else {
                let dateArray = [];
                dateArray.push(this.startDate);
                dateArray.push(this.endDate);
                this.processedData.result[0]['value'] = dateArray.join(',');
                this.enableSubmitButton = true;
            }
        }
    }

    getTextValue() {
        const getAry = this.headerName.headerName.split(' ');
        const header_text = _.find(getAry, (v) => {
            return v.toLowerCase() === 'date';
        });
        //  const findNumber = _.find(this.numberArrya, (v) => v === this.headerName.field);
        if (
            header_text ||
            this.headerName.field == 'START_DATE' ||
            this.headerName.field === 'END_DATE' ||
            this.headerName.field === 'GO_DATE' ||
            this.headerName.field === 'INV_DATE' ||
            this.headerName.field === 'CLEAR_DATE' ||
            this.headerName.field == 'MIN_SALES' ||
            this.headerName.field === 'MAX_SALES' ||
            this.headerName.field == 'Valid_From' ||
            this.headerName.field == 'Valid_To' ||
            this.headerName.field == 'OPEN_DATE' ||
            this.headerName.field == 'POSTING_DATE' ||
            this.headerName.field == 'INITIAL_DATE' ||
            this.headerName.field == 'FINAL_DATE' ||
            this.headerName.field == 'DATE_AUDITED' ||
            this.headerName.field == 'CREATION_DATE' ||
            this.headerName.field == 'DATE_AUDITED' ||
            this.headerName.field == 'COMPLETE_DATE' ||
            this.headerName.field == 'Complete_DT' ||
            this.headerName.field == 'EFFECTIVE_DATE'
        ) {
            return 'date';
        } else if (this.headerName.field.toUpperCase() == 'STATUS' || this.headerName.field.toUpperCase() == 'STATUSCODE') {
            return 'STATUS';
        } else if (this.headerName.field.toUpperCase() == 'FORMAT') {
            return 'FORMAT';
        } else {
            return 'text';
        }
    }

    onSubmit() {
        this.processedData.result[0]['name'] = this.headerName.field;
        if (Object.keys(this.previousPayload).length !== 0) {
            this.processedData.result = [...this.previousPayload['result'], ...this.processedData.result];
        }
        this.showLoader = true;
        let key = 'AUDIT_YEAR';
        let order = 0;
        this.apiservice.qrPageFiltersMX(this.country, this.processedData, this.tableName, this.auditYear, this.pageNumber, key, order).subscribe(
            (response) => {
                this.showLoader = false;
                this.dialogRef.close({
                    data: response,
                    payload: this.processedData,
                    from: 'submit'
                });
            },
            (error) => {
                this.showLoader = false;
                this.openSnackBar('There was an error ' + error, 'Ok');
                this.dialogRef.close({
                    data: [],
                    payload: this.processedData,
                    from: 'submit'
                });
            }
        );
    }

    onCancel() {
        this.dialogRef.close({
            data: [],
            payload: this.processedData,
            from: 'cancel'
        });
    }

    openSnackBar(message: string, action: string) {
        //  duration: 3000
        // this._snackBar.open(message, action);
        this._snackBar.open(message, action, {
            duration: 3000,
            // horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
        });
    }
}
