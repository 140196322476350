export class EditDetails {
    ACCOUNT_DEPARTMENT: any;
    AGREEMENT_NUMBER: any;
    ALLOW_TYPE: any;
    amountReceived: any;
    appClaimNo: any;
    AUDITOR_ID: any;
    auditYr: any;
    CATEGORY: any;
    COMMENTS: any;
    DATE_AUDITED: number;
    DEAL_AGREEMENT_NUM: any;
    debitedAMount: any;
    earned: any;
    END_DATE: any;
    FIXED_AMOUNT_AGREEMENT: number;
    formatType: any;
    LEGACY_CATEGORY: any;
    PERCENTAGE: number;
    PRIVATE_LABEL: any;
    QR_STATUS: any;
    recordID: any;
    RELEASE_NUM: number;
    RUT: any;
    START_DATE: any;
    SUPPLIER_NAME: any;
    SUPPLIER_NUM: any;
    volumn: number;
    yearvolumn: any;
    RECORD_ID: number;
    AMOUNT_RECEIVED: number;
    APP_CLAIM_NUMBER: any;
    AUDIT_YEAR: number;
    CHANGE_USER: any;
    DEBITED_AMOUNT: number;
    EARNED: number;
    FORMAT_TYPE: any;
    VOLUME: number;
    YEAR_VOLUME: number;
    VERSION: number;
    CREATED_BY: any;
    CREATED_DATE: any;
    LAST_UPDATED_BY: any;
    Include: any;
    DEPT: any;
    MIN_SALES: any;
    MAX_SALES: any;
    TOTAL_SALES: any;
    STORE_NBR: any;
    TYPE: any;
    OPEN_DATE: any;
    FORMAT: any;
    FIXED_AMT: any;
    UFAMT: any;
    TOTAL_DUE: any;
    AMT_DEDUCTED: any;
    OTHER_DEDUCTION: any;
    AMOUNT_DUE: any;
    COMPLETED_DATE: any;
    CITY: any;
    AREA: any;
    ADDRESS: any;
    code: any;
    data: any;
    claimDate: any;
    fileType: any;
    response: string;
}
