import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssignHistoryComponent } from './assign-history/assign-history.component';
import { AssignComponent } from './assign/assign.component';
import { CreateClaimComponent } from './create-claim/create-claim.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataComponent } from './data/data.component';
import { ManagePRComponent } from './manage-pr/manage-pr.component';
import { ReportsComponent } from './reports/reports.component';
import { AuthGuard } from '../_helpers';
import { Role } from '../_models';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [OktaAuthGuard]
    },
    {
        path: 'dashboard/:ID',
        component: DashboardComponent,
        data: {
            title: 'dashboard'
        },
        canActivate: [AuthGuard, OktaAuthGuard]
    },

    {
        path: 'assign/:CL/:ID',
        component: AssignComponent,
        canActivate: [AuthGuard, OktaAuthGuard],
        data: {
            roles: [Role.Manager, Role.ApplicationAdmin, Role.QAAdmin, Role.SystemUser, Role.Admin],
            breadcrumb: 'Assign PR',
            title: 'Assign PR'
        }
    },
    {
        path: 'managePR/:CL/:ID',
        component: ManagePRComponent,
        canActivate: [AuthGuard, OktaAuthGuard],
        data: {
            roles: [Role.Manager, Role.Auditor, Role.ApplicationAdmin, Role.QAAdmin, Role.SystemUser, Role.Admin],
            breadcrumb: 'Manage PR',
            title: 'Manage PR'
        }
    },

    {
        path: 'data/:CL/:ID',
        component: DataComponent,
        canActivate: [AuthGuard, OktaAuthGuard],
        data: {
            roles: [Role.Manager, Role.Auditor, Role.ApplicationAdmin, Role.QAAdmin, Role.SystemUser, Role.Admin],
            breadcrumb: 'Data',
            title: 'Data'
        }
    },
    {
        path: 'reports/:CL/:ID',
        component: ReportsComponent,
        canActivate: [AuthGuard, OktaAuthGuard],
        data: {
            roles: [Role.Manager, Role.Auditor, Role.ApplicationAdmin, Role.QAAdmin, Role.SystemUser, Role.Admin],
            breadcrumb: 'Reports',
            title: 'Reports'
        }
    },

    {
        path: 'assign-history/:CL/:ID/:recordID',
        component: AssignHistoryComponent,
        data: {
            title: 'AssignHistory',
            roles: [Role.Manager, Role.Auditor, Role.ApplicationAdmin, Role.QAAdmin, Role.SystemUser, Role.Admin],
            breadcrumb: 'Check-History'
        },
        canActivate: [AuthGuard, OktaAuthGuard]
    },
    {
        path: 'create-claim/:CL/:ID/:recordID',
        component: CreateClaimComponent,
        data: {
            title: 'create-claim',
            roles: [Role.Manager, Role.Auditor, Role.ApplicationAdmin, Role.QAAdmin, Role.SystemUser, Role.Admin],
            breadcrumb: 'Create-Claim'
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'create-claim/:CL',
        component: CreateClaimComponent,
        data: {
            title: 'create-claim',
            roles: [Role.Manager, Role.Auditor, Role.ApplicationAdmin, Role.QAAdmin, Role.SystemUser, Role.Admin],
            breadcrumb: 'Create-Claim'
        },
        canActivate: [AuthGuard, OktaAuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule {}
