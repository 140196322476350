import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
import { apiService } from 'src/app/_services/app.service';
import { EditDetails } from '../../../_models/editDetails';
import _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface DialogData {
  requestId: any;
}

@Component({
  selector: 'app-add-row-data',
  templateUrl: './add-row-data.component.html',
  styleUrls: ['./add-row-data.component.css']
})
export class AddRowDataComponent implements OnInit {
  editData: any;
  pageID: any;
  ccCode: any;
  allowTypeDropDown: any;
  AllDropdowns: any;
  rowDataObject: any;
  rowData = [];
  addForm: FormGroup;
  auditorList = [];
  singleAuditYear;
  showLoader = false;
  username: any;

  auditYearList = [];
  formatList = [];
  MXDEP = [];
  numberArrya = ['VEN_NUM', 'SAP_NUMBER', 'DPT_CAT', 'SUPERCTR_AMT', 'SUPERAMA_AMT', 'BODEGA_AMT', 'MI_BODEGA_AMT',
    'BAE_AMT', 'SAMS_AMT', 'IEPS_PCT', 'IVA_PCT', 'SC_PCT', 'BOD_PCT', 'SPRAMA_PCT', 'MI_BOD_PCT', 'BAE_PCT',
    'SAMS_PCT', 'ID'];

  currentDateTime;
  changeInputValue: Subject<any> = new Subject<any>();

  public responsedata: any = [];
  constructor(private datePipe: DatePipe, public dialogRef: MatDialogRef<AddRowDataComponent>, @Inject(MAT_DIALOG_DATA) public datas: DialogData, public datepipe: DatePipe, public router: Router, public activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private apiservice: apiService) {
    this.changeInputValue.pipe(debounceTime(2000)).subscribe((obj) => {
      this.changeInModal(obj);
    });
    this.currentDateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  editPage: EditDetails;
  showForm = false;
  @Output()
  createFileValue(objectFrom) {

    this.addForm = null;
    this.rowData = [];
    this.showForm = false;
    this.rowData = this.generatedColumn([objectFrom]);

    let formObject: any = {};


    return formObject;
  }


  currentValueObject;
  ngOnInit() {
    //Audit year dropdown values
    let result = [{ AUDIT_YEAR: 2018 }, { AUDIT_YEAR: 2019 }, { AUDIT_YEAR: 2020 }, { AUDIT_YEAR: 2021 }, { AUDIT_YEAR: 2022 }, { AUDIT_YEAR: 2023 },
       { AUDIT_YEAR: 2024 }, { AUDIT_YEAR: 2025 }];
    this.auditYearList = _.map(result, (v) => {
      return {
        AUDITYEAR: v.AUDIT_YEAR
      };
    });
    this.auditYearList.sort().reverse();

    console.log("datas ", this.datas);

    const prompt = JSON.parse(localStorage.getItem('userinfo'));
    this.username = prompt.username;


    this.editData = this.datas.requestId.data;

    this.pageID = this.datas.requestId.id;
    this.ccCode = this.datas.requestId.countryCode;
    this.getAllDropdownValues(this.ccCode, this.pageID);
    this.formatDropDownValue()


    this.rowDataObject = this.editData[0];
    localStorage.setItem('initial_value', JSON.stringify(this.editData[0]));



    this.rowData = this.generatedColumn(this.editData);

    const objectFrom = {};
    _.each(this.rowData, (v) => {
      const getData = v.replaceAll('_', ' ');
      const getAry = getData.split(' ');
      const findDate = _.find(getAry, (v) => {
        return v.toLowerCase() === 'date';
      });
      const findNumber = _.find(this.numberArrya, (item) => item === v);
      if (findNumber) {
        const valueNum = this.convertStringToNumberValue(this.rowDataObject[v]);
        objectFrom[v] = [valueNum];
      }

      else {
        objectFrom[v] = [
          {
            value: this.rowDataObject[v],
            disabled: this.getEditableColumn(v)
          }
        ];
      }
    });
    this.addForm = this.formBuilder.group(objectFrom);
    this.currentValueObject = JSON.parse(localStorage.getItem('initial_value'));
    if (this.pageID == 'PPA_NEW_STORE_INVENTORY') {
      this.addForm.get('SOURCE').setValue("Auditor Added");
      this.addForm.get('INS_ROW').setValue("Y");
    } else if (this.pageID != 'PPA_VOLUME_ALLOWANCE_DETAIL') {
      this.addForm.get('INCLUDE').setValue("Y");
    }
  }



  getEditableColumn(data) {
    let vColumn;

    vColumn = this.generatedColumn([this.editData[0]]);
    const isFind = _.find(vColumn, (item) => item === data);
    if (isFind) {
      return false;
    } else {
      return true;
    }
  }
  valueEdited = false;

  changeModalData(obj) {
    if (obj == 'VEN_NUM') {
      this.changeInputValue.next(obj);
    }
  }

  changeInModal(obj) {
    const data = obj;
    if (obj == 'VEN_NUM') {
      const fromData = this.addForm.getRawValue();
      console.log("fromData", fromData.VEN_NUM)
      this.getvendorNumber(fromData.VEN_NUM)

    }
  }

  getvendorNumber(vendornumber) {
    this.showLoader = true
    this.apiservice.getvendornameinData(vendornumber).subscribe((response) => {
      this.showLoader = false
      console.log("response", response)
      this.addForm.get('SAP_NUMBER').setValue(response[0].SAP_NUMBER);
      this.addForm.get('VENDOR_NAME').setValue(response[0].VENDOR_NAME);

    });
  }
  generatedColumn(data) {
    let column = [];
    data.map((object) => {
      Object.keys(object).map((key) => {
        if (key != 'ID') {
          column.push(key);
        }
      });
    });
    return column;
  }



  dataArray = ['AUDIT_YEAR', 'DPT_CAT', 'LastUpdatedBy', 'LastUpdatedDate', 'ID', 'SOURCE', 'FORMAT', 'INCLUDE', 'INS_ROW'];

  checkIsPresentAny(v) {
    let findIndex;
    findIndex = this.dataArray.indexOf(v);
    if (findIndex === -1) {
      return true;
    } else {
      return false;
    }
  }

  getTextValue(data) {
    const getData = data.replaceAll('_', ' ');
    const getAry = getData.split(' ');
    const findDate = _.find(getAry, (v) => {
      return v.toLowerCase() === 'date';
    });
    const findNumber = _.find(this.numberArrya, (v) => v === data);
    if (findDate || data == 'EFF_DATE' || data === 'EXPIRED_DATE' || data == 'GO_DATE' || data == 'START_DATE' || data == 'END_DATE') {
      return 'date';
    } else if (findNumber) {
      return 'number';
    } else {
      return 'text';
    }
  }

  getAllDropdownValues(CLCodee, pageID) {
    this.apiservice.getClaimsDropdowns(CLCodee, pageID).subscribe((response) => {
      this.AllDropdowns = response;
      for (var i = 0; i < this.AllDropdowns.length; i++) {
        if (this.AllDropdowns[i].Lookup_Code == 'ACCOUNT_DEPARTMENT') {
          this.MXDEP.push(this.AllDropdowns[i].Lookup_Values);
        }
      }
    });
  }




  convertStringToNumberValue(data) {
    if (data != '' && data != null && !isNaN(data)) {
      if (typeof data == 'number' ) {
        // check if it is integer
        if (Number.isInteger(data)) {
          return data;
        } else {
          return data;
        }
      } else {
        const v = parseFloat(data.replace(/,/g, ''));
        return Number(v);
      }
    } else {
      return 0;
    }
  }


  formatDropDownValue() {
    this.showLoader = true;
    this.apiservice.getFormatsForDWH('PPA_NEW_STORE_LIST_M').subscribe((response) => {
      this.showLoader = false;
      this.formatList = response;
    });
  }


  onSubmit() {
  //  this.showLoader = true
    let c: any = {};
    let payload: any = {};
    c = this.addForm.getRawValue();
    console.log("c", c)
    if (this.ccCode === 'MX') {
      if (this.pageID === 'PPA_NEW_STORE_INVENTORY') {
        for (let x in c) {
          let check = c[x];
          if (x == 'SUPERCTR_AMT' || x == 'SUPERAMA_AMT' || x == 'BODEGA_AMT' || x == 'MI_BODEGA_AMT'
            || x == 'BAE_AMT' || x == 'SAMS_AMT' || x == 'VEN_NUM' || x == 'SAP_NUMBER' || x == 'DPT_CAT'
            || x == 'SC_PCT' || x == 'SPRAMA_PCT' || x == 'BOD_PCT' || x == 'MI_BOD_PCT' || x == 'BAE_PCT'
            || x == 'SAMS_PCT' || x == 'IVA_PCT' || x == 'IEPS_PCT' || x == 'AUDIT_YEAR') {
            payload[x] = parseFloat(check);
          }
          else if (x == 'EFF_DATE' || x == 'EXPIRED_DATE') {
            if (check == 0) {
              payload[x] = null;
            }
            else {
              payload[x] = check
            }
          }
          else {
            payload[x] = check
          }

        }
      }
      else if (this.pageID === 'PPA_NEW_STORE_LIST_M') {
        for (let x in c) {
          let check = c[x];
          if (x == 'STORE' || x == 'AUDIT_YEAR') {
            payload[x] = parseInt(check);
          }
          else if (x == 'GO_DATE') {
            if (check == 0) {
              payload[x] = null;
            }
            else {
              payload[x] = check
            }
          }
          else {
            payload[x] = check
          }

        }
      }
      else if (this.pageID === 'PPA_VOLUME_ALLOWANCE_DETAIL') {
        c['INCLUDE'] = 'Y';
        c['RECORD_ID'] = '';
        for (let x in c) {
          let check = c[x];
          if (x == 'AUDIT_YEAR') {
            payload[x] = parseInt(check);
          }
          else if (x == 'START_DATE' || x == 'END_DATE') {
            if (check == 0) {
              payload[x] = null;
            }
            else {
              payload[x] = check
            }
          }
          else {
            payload[x] = check
          }

        }
      }

    }
    payload.LastUpdatedBy = this.username;
    payload.LastUpdatedDate = null;

    console.log(payload);
    this.apiservice.addNewRowData(payload, this.pageID, this.ccCode).subscribe((response) => {
      console.log("response", response.response)
      if (response.response == 'OK') {
        this.dialogRef.close();
        this.showLoader = false
        alert('data added sucessfully');
        this.router.navigate(['data/' + this.ccCode + '/' + this.pageID]);
        this.Refresh();
      }
      else {
        alert('Cannot add data, not a valid combination');
        this.showLoader = false
      }

    });
  }

  Refresh() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['./data/' + this.ccCode + '/' + this.pageID], { relativeTo: this.activatedRoute });
  }


}
