import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, RowSelectedEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';
import { AuditServiceService } from 'src/app/_services/audit-service.service';
import { apiService } from 'src/app/_services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AssignAuditorComponent } from '../popup/assign-auditor/assign-auditor.component';
import { MatDialog } from '@angular/material/dialog';
import { AddRowComponent } from '../popup/add-row/add-row.component';
import { BulkUPloadComponent } from '../popup/bulk-upload/bulk-upload.component';
import { PivotTableComponent } from '../popup/pivot-table/pivot-table.component';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import _ from 'lodash';
import { DataFiltersComponent } from '../popup/data-filters/data-filters.component';
import { MatSnackBar } from '@angular/material/snack-bar';

// import { workbook } from 'exceljs'
import * as fs from 'file-saver';
import * as moment from 'moment';
import { FilterBulkUploadComponent } from '../popup/filter-bulk-upload/filter-bulk-upload.component';
import { AddRowDataComponent } from '../popup/add-row-data/add-row-data.component';
import { EditRowDataComponent } from '../popup/edit-row-data/edit-row-data.component';

@Component({
    selector: 'app-data',
    templateUrl: './data.component.html',
    styleUrls: ['./data.component.css']
})
export class DataComponent implements OnInit {
    showTable = true;
    auditTableType: any;
    auditTableData: any;
    auditTypeID: any;
    ID: any;
    auditDropDown: any;
    selecteddata: any;
    clCode: any;
    keyName: string = 'AuditYear ';
    keyOrder: number = 0;
    public rowSelection = 'multiple';
    public rowSelection1 = 'multiple';
    private gridApi!: GridApi;
    private gridApi1!: GridApi;
    dummyData: any;
    dataDropDown: any;
    dataDropDownValues = [];
    DataDropValue: any;
    columnDefs;
    rowData = [];
    rowData1 = [];
    columnDefs1 = [];
    payloadForCellEdit = [];
    pageNumber = -1;
    nextDisabled = false;
    inputDisabled = false;
    prvDisabled = true;
    showLoader = false;
    filterPagination = false;
    showPivot = false;
    numberOfPage = 0;
    totalNumber = 0;
    totalNumberFilter = 0;
    TotalRecords: any;
    finalData = [];
    latestYear = new Date().getFullYear()-1;
    auditYear = `${this.latestYear}`;
    auditYearList = [];
    monthValue = '01';
    columnName = [];
    hideFilter = 'inline-block';
    normalExport = 'block';
    APIExport = 'none';
    APIPivotExport = 'none'
    filterbulkUpdateBtn = 'none';
    addRow = 'none';
    showEditRow = 'none'
    APIExportNormal = 'inline-block';
    selectedRows = 'none';
    filterPayload: any;
    countOfFilterRecords: any;
    btnCount = [];
    btnCountFilter: any;
    filterPageNumer: any;
    exportBtnList = [];
    totalNumberCountGrid: any;
    columnNameandKey = [];

    country = '';
    showArray = [
        'PPA_NEW_STORE_INVENTORY',
        'PPA_SCAN_REBATE_DEDUCTIONS',
        'PPA_CL_COOPS_POS_T',
        'PPA_MX_POS_SAMS',
        'PPA_MX_POS_WM',
        'Item_Master_Sams',
        'Item_Master_WM',
        'PPA_NEW_STORE_DEDUCTIONS',
        'PPA_NEW_STORE_LIST_M',
        'PPA_STORE_SHIPS_DWH',
        'PPA_VOLUME_ALLOWANCE_DETAIL',
        'PPA_VOLUME_ALLOWANCE_DEDUCTION',
        'PPA_PO_SAMS',
        'PPA_PO_WM',
        'PPA_UNDEDUCTED_COOPS_DWH',
        'PPA_FBL5N',
        'PPA_DSD_DWH',
        'PPA_FBL5N_ADVERTISING',
        'PPA_FILL_RATE_DWH',
        'PPA_FBL5N_FillRate',
        'PPA_ADVERTISING_DEDUCTIONS',
        'PPA_MSI_DWH',
        'PPA_MSI_DEDUCTIONS',
        'PPA_EDI_DWH',
        'PPA_GMIL_DWH',
        'PPA_AP_HISTORY',
        'PPA_POS_ECOMMERCE',
        'PPA_MARKDOWN_DETAIL',
        'PPA_MARKDOWN_PRICE_HISTORY',
    ];

    public defaultColDef: ColDef = {
        initialWidth: 100,
        sortable: true,
        resizable: true,
        editable: true
    };
    flag = '';
    country_cd = '';
    gridOptions = {
        rowData: null,
        animateRows: true,
        sortingOrder: ['desc', 'asc', null]
    };
    advanceFilterPostBulkUpdateObj = {};
    isAdvanceFilter = false;
    public sortingOrder: ('asc' | 'desc' | null)[] = ['desc', 'asc', null];

    perchesData = ['Rut', 'DeptNbr', 'CategoryNbr', 'ItemNbr', 'PurchaseDate', 'VendorNbr'];

    pointOfSaleData = ['SalesDate', 'format', 'ItemNbr', 'Rut', 'DeptNbr', 'VendorNbr', 'SalesCaseQty', 'SalesUnitQty', 'SalesCostAmt', 'YearMM'];

    // MXsortingList = ['SalesDate', 'format', 'ItemNbr', 'Rut', 'DeptNbr', 'VendorNbr', 'SalesCaseQty', 'SalesUnitQty',
    //   'SalesCostAmt', 'YearMM']

    isShowMonthOption = false;
    isShowYearOPtion = true;
    bulkUpdateBtn = 'block';

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private apiservice: apiService, private http: HttpClient, private auditService: AuditServiceService, public activatedRoute: ActivatedRoute, private _snackBar: MatSnackBar, public dialog: MatDialog, public router: Router, public translate: TranslateService) {
        this.auditTypeID = this.activatedRoute.snapshot.params['ID'];
        this.auditYear = sessionStorage.getItem('auditYear') === null ? `${this.latestYear}`: sessionStorage.getItem('auditYear');
        if (this.auditYear == 'All' && this.auditTypeID != 'PPA_CL_NEW_STORE_LIST_M') {
            this.auditYear = `${this.latestYear}`;
        }
    }

    afterGuiAttached(params) {
        params.hidePopup();
    }

    ngOnInit() {
        this.country_cd = localStorage.getItem('country');
        this.countryfields();
        this.SetLang();
        this.clCode = this.country_cd;
        this.SetLang();
        this.DataDropValue = this.activatedRoute.snapshot.params['ID'];
        this.getAllAuditYear(this.DataDropValue);
        this.dataDropDownValue(this.clCode);
        this.showMonthOption(this.DataDropValue);
        this.showYearOption(this.DataDropValue);
        this.ShowAddRowOption(this.DataDropValue);
        this.showEditRowOption(this.DataDropValue);
        this.hideBulkupdateBtn(this.DataDropValue);
        this.loadInitialData();
        this.filterNameConditions(this.DataDropValue);
    }

    ShowAddRowOption(value) {
        //  debugger
        const showArray = ['PPA_NEW_STORE_INVENTORY', 'PPA_NEW_STORE_LIST_M'];

        if (this.clCode != undefined) {
            const findData = showArray.indexOf(value);
            if (findData > -1) {
                this.addRow = 'inline-block';
            } else {
                this.addRow = 'none';
            }
        }
    }

    showEditRowOption(value) {
        const showArray = ['PPA_VOLUME_ALLOWANCE_DETAIL'];
        if (this.clCode != undefined) {
            const findData = showArray.indexOf(value);
            if (findData > -1) {
                this.showEditRow = 'inline-block';
            } else {
                this.showEditRow = 'none';
            }
        }
    }

    showMonthOption(value) {
        // const showArrya = this.showArray;
        const showArray = ['PPA_SCAN_REBATE_DEDUCTIONS', 'PPA_CL_COOPS_POS_T'];

        if (this.clCode != undefined) {
            const findData = showArray.indexOf(value);
            this.isShowMonthOption = findData > -1 ? true : false;
        }
    }

    showYearOption(value) {
        console.log("showYearOption ", value)
        // const showArrya = this.showArray;
        const showArray = [];

        if (this.clCode != undefined) {
            const findData = showArray.indexOf(value);
            this.isShowYearOPtion = findData > -1 ? false : true;
        }
    }

    countryfields() {
        if (this.country_cd === 'CL') {
            this.country = 'Chile';
            this.flag = '../assets/img/chile.png';
        } else if (this.country_cd === 'MX') {
            this.country = 'Mexico';
            this.flag = '../assets/img/mexico.png';
        } else {
            this.country = '';
            this.flag = '';
        }
    }

    SetLang() {
        let lang = localStorage.getItem('lang');
        if (lang === null) {
            this.translate.setDefaultLang('es');
        } else {
            this.translate.use(lang);
        }
    }

    hideBulkupdateBtn(tableNAme) {
        if (this.clCode === 'MX') {
            if (tableNAme == 'Item_Master_Sams' || tableNAme == 'Item_Master_WM' || tableNAme == 'PPA_AP_HISTORY' || tableNAme == 'PPA_POS_ECOMMERCE' || tableNAme == 'PPA_MARKDOWN_DETAIL') {
                this.bulkUpdateBtn = 'none';
                this.filterbulkUpdateBtn = 'none';
            } else {
                this.bulkUpdateBtn = 'block';
                this.filterbulkUpdateBtn = 'block';
            }
        }
    }

    homeIcon() {
        // const url = this.router.serializeUrl(this.router.createUrlTree(['dashboard/' + '/' + this.clCode]));
        // window.open(url, '_blank');
        this.router.navigate(['dashboard/' + '/' + this.clCode]);
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    }

    updateYearAndAuditType() {
        this.hideBulkupdateBtn(this.DataDropValue);
        const rowData = [];
        this.exportBtnList = [];
        this.TotalRecords = [];
        // this.apiservice.getAuditTableDataByYear(this.DataDropValue, this.auditYear, this.country_cd, this.pageNumber).subscribe((result: any) => {
        //   if (result.data != undefined && result.data.length < 100) {
        //     this.nextDisabled = true;
        //   }
        //   _.each(result.data, (v) => {
        //     delete v.PipeLineRunID
        //     delete v.LoadStartTime
        //     delete v.LoadEndTime
        //     delete v.HDR_ZUKRI;
        //     delete v.HDR_EBELN;
        //     delete v.HDR_NETWR;
        //     delete v.HDR_WAERS;
        //     delete v.HDR_IPTYP;
        //     delete v.ITM_FILE_DATE;
        //     delete v.ZZVAR_WT_IND
        //     delete v.VSUM
        //     delete v.VDRSUM
        //     // delete v.YEAR_MONTH;
        //     rowData.push(this.addingCommas(v));
        //   });
        //   this.rowData1 = rowData;
        //   this.columnDefs1 = this.generateColumns(this.rowData1);
        //   this.gridApi1.hideOverlay();
        //   this.gridApi1.setRowData(this.rowData);
        //   this.showLoader = false;
        // })
    }

    commaSerrateArray = [
        'AmountPeriod',
        'TotalAllwncAmt',
        'Unit',
        'Caj',
        'Dir',
        'Dev',
        'BaseGlas',
        'MermaGlas',
        'DifBase',
        'SalesCaseQty',
        'SalesUnitQty',
        'SalesCostAmt',
        'AmountEntered',
        'AmountPosted',
        'CstAmt',
        'AmountInML',
        'TotAmt',
        'SaleBaseCost',
        'InvAmt',
        'SalesCostAmt',
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
        'TotBase',
        'Trf',
        'LOCAL_AMOUNT',
        'VNPK_QTY',
        'WHPK_QTY',
        'VNPK_COST_AMT',
        'WHPK_SELL_AMT',
        'WEEKLY_STR_COUNT',
        'WEEKLY_SALES_AMT',
        'WEEKLY_SALES_QTY',
        'DAILY_SALES_AMT',
        'DAILY_SALES_QTY',
        'AVG_SALE_PRICE',
        'POS_COST',
        'GROSS_AVG_SHIP_COST',
        'GROSS_AVG_SHIP_RET',
        'GROSS_SHIP_QTY',
        'NET_SHIP_QTY',
        'STORE_RC_QTY',
        'STORE_VC_QTY',
        'GROSS_SHIP_COST',
        'VND_PK_QTY',
        'WHPK_ORD',
        'VNPKS_ORD',
        'B_RCVD_QTY',
        'E_RCVD_QTY',
        'CNT_RCVRS',
        'PURCHASES',
        'REG_VNPK_CST',
        'NET_VNPK_CST',
        'UNITS_RCVD',
        'UNIT_RETAIL',
        'TOTAL_COST',
        'TOTAL_WHPK_ORD',
        'TOTAL_VNPK_ORD',
        'VNPK_WGT',
        'WGT_RECV',
        'SHORT_QTY',
        'DAMAGE_QTY',
        'WHPK_COST',
        'UNIT_COST',
        'UNITS_ORD',
        'WHPK_COST',
        'MENGE',
        'NETPR',
        'KZWI1',
        'ACAMT',
        'ZZPO_VNDR_PCK_QTY',
        'ZZALLOCAMT',
        'ZZARESIAMT',
        'HDR_NETWR',
        'AVG_UNIT_SALES_COST',
        'AVG_UNIT_SALES_SELL',
        'DAILY_SALES_AMT',
        'DAILY_SALES_QTY',
        'SHIP_COST',
        'SELL_PRICE',
        'SHIP_QTY',
        'NET_SHIP',
        'PURCHASES',
        'ENTERED_AMT',
        'AMS_PURCHASE',
        'PO_PURCHASES',
        'ALLOW_EARNED',
        'AMS_QTY',
        'SUPERCTR_AMT',
        'SUPERAMA_AMT',
        'BODEGA_AMT',
        'MI_BODEGA_AMT',
        'BAE_AMT',
        'SAMS_AMT',
        'LOCAL_AMOUNT_WITHOUT_TAX',
        'USD_AMOUNT',
        'QTY_SHORT',
        'RETAIL_AMT_SHORT',
        'MARGIN_LOST',
        'MU_PCT',
        'COST_AMT',
        'DSD_PURCHASE_AMT',
        'INVOICE_COST',
        'RETAIL_AMT',
        'STORE_SALE_AMT',
        'TOTAL_COST_AMT',
        'TOTAL_RETAIL_AMT',
        'TOTAL_SALE_AMT',
        'WHPK_SELL',
        'UNIT_COST',
        'DEAL_AMOUNT',
        'DAILY_SALES_AMT2',
        'VNPK_COST_AMT',
        'WHPK_SELL_AMT',
        'TOTAL_RETAIL_AMT_SHORT',
        'TOTAL_MARGIN_LOST',
        'TOTAL_EARNED',
        'DEDUCTION_CIVA',
        'DEDUCTION',
        'CASES_RECV',
        'HIST_ON_HAND',
        "INV_QTY",
        "PACK_QTY",
        "QTY_RCVD",
        "VDRPK_QTY",
        "EQUIV_EACH_INV",
        "EQUIVEACHRCVD",
        "QTY_SHORT",
        "SHORT_DUE",
        "IEPS_SHORT_DUE",
        "IVA_SHORT_DUE",
        "INV_EACH_COST",
        "PO_EACH_COST",
        "LOW_EACH_COST",
        "INV_EXTD_COST",
        "WM_PO_COST",
        "RCVD_COST",
        "COST_PAID",
        "SBCOST",
        "INV_COST_DUE",
        "IEPSCOSTDUE",
        "IVA_COST_DUE",
        "INV_TOTAL_AMT",
        "PO_TOT_AMT",
        "RCVD_TOT_AMT",
        "TOT_COST_PAID_AMT",
        "AP_TOT_AMT",
        "INV_UNIT_PRICE",
        "VNDRPK_COST",
        "ALW_AMT1",
        "IEPS_AMT",
        "TOT_INV_COST",
        "CALC_PO_TOT_COST",
        "TOT_RCVD_COST",
        "TOT_COST_PAID",
        "UNIT_COST",
        "INV_QTY",
        "PACK_QTY",
        "NET_COST",
        "UNIT_PRICE",
        "EXT_COST",
        "ORD_QTY",
        "ALW_AMT1",
        "TTL_PRETAX_INV_AMT",
        "TTL_INV_AMT",
        "TTL_TAX_AMT",
        "INV_TAX",
        "INV_AMT_AFT_TAX",
        "POS_SALES",
        "POS_QTY",
        'POS_QTY',
        'POS_SALES',
        'IMPACT_MU/MD',
        'AGRT_AMT',
        'DIFF',
        'VAT',
        'IEPS',
        'Unit Allowance',
        'STD_UNIT_RETAIL',
        'ACT_AVG_RETAIL',
        'STD_UNIT_COST'

    ];

    onGridReady(params) {
        this.gridApi = params.api; // To access the grids API
        this.gridApi.hideOverlay();
    }

    setKeyNameForMX() {
        let key;
        if (this.DataDropValue === 'PPA_SCAN_REBATE_DEDUCTIONS') {
            key = 'YEAR_MONTH';
        } else if (
            this.DataDropValue === 'PPA_MX_POS_WM' ||
            this.DataDropValue === 'PPA_MX_POS_SAMS' ||
            this.DataDropValue === 'PPA_NEW_STORE_INVENTORY' ||
            this.DataDropValue === 'PPA_NEW_STORE_DEDUCTIONS' ||
            this.DataDropValue === 'PPA_NEW_STORE_LIST_M' ||
            this.DataDropValue === 'PPA_STORE_SHIPS_DWH' ||
            this.DataDropValue === 'PPA_VOLUME_ALLOWANCE_DETAIL' ||
            this.DataDropValue === 'PPA_VOLUME_ALLOWANCE_DEDUCTION' ||
            this.DataDropValue === 'PPA_PO_SAMS' ||
            this.DataDropValue === 'PPA_PO_WM' ||
            this.DataDropValue === 'PPA_FBL5N' ||
            this.DataDropValue === 'PPA_FBL5N_ADVERTISING' ||
            this.DataDropValue === 'PPA_UNDEDUCTED_COOPS_DWH' ||
            this.DataDropValue === 'PPA_DSD_DWH' ||
            this.DataDropValue === 'PPA_FILL_RATE_DWH' ||
            this.DataDropValue == 'PPA_FBL5N_FillRate' ||
            this.DataDropValue == 'PPA_ADVERTISING_DEDUCTIONS' ||
            this.DataDropValue == 'PPA_MSI_DWH' ||
            this.DataDropValue == 'PPA_MSI_DEDUCTIONS' ||
            this.DataDropValue == 'PPA_GMIL_DWH' ||
            this.DataDropValue == 'PPA_EDI_DWH' ||
            this.DataDropValue == 'PPA_AP_HISTORY' ||
            this.DataDropValue == 'PPA_POS_ECOMMERCE' ||
            this.DataDropValue == 'PPA_MARKDOWN_DETAIL' ||
            this.DataDropValue == 'PPA_MARKDOWN_PRICE_HISTORY'
        ) {
            key = 'AUDIT_YEAR';
        } else if (this.DataDropValue === 'PPA_CL_COOPS_POS_T') {
            key = 'YearMM';
        } else {
            key = 'FILEYEAR';
        }
        return key;
    }

    loadInitialData() {
        this.exportBtnList = [];
        this.TotalRecords = [];
        this.showLoader = true;
        // this.gridApi = params.api; // To access the grids API
        // this.gridApi.hideOverlay();
        const showArray = this.showArray;
        const findData = showArray.indexOf(this.DataDropValue);
        this.auditYear = sessionStorage.getItem('auditYear') === null ? `${this.latestYear}` : sessionStorage.getItem('auditYear');
        if (this.auditYear == 'All' && this.DataDropValue != 'PPA_CL_NEW_STORE_LIST_M') {
            this.auditYear = `${this.latestYear}`;
        }
        if (findData > -1) {
            this.pageNumber++;
            this.numberOfPage++;
            if (this.DataDropValue === 'PPA_SCAN_REBATE_DEDUCTIONS') {
                this.keyName = 'YEAR_MONTH';
            } else if (this.DataDropValue == 'PPA_MARKDOWN_DETAIL' || this.DataDropValue == 'PPA_MARKDOWN_PRICE_HISTORY' ) {
                this.keyName = 'AUDIT_YEAR';
            }else {
                this.keyName = this.DataDropValue === 'PPA_CL_COOPS_POS_T' ? 'YearMM' : 'FILEYEAR';
            }
            this.keyName = this.setKeyNameForMX();
            this.apiservice.getPointOfSaleData(this.clCode, this.auditYear, this.monthValue, this.pageNumber, this.keyName, this.keyOrder, this.DataDropValue).subscribe(
                (result) => {
                    const dataValue = [];
                    _.each(result.data, (v) => {
                        delete v.PipeLineRunID;
                        delete v.LoadStartTime;
                        delete v.LoadEndTime;
                        delete v.HDR_ZUKRI;
                        delete v.HDR_EBELN;
                        delete v.HDR_NETWR;
                        delete v.HDR_WAERS;
                        delete v.HDR_IPTYP;
                        delete v.ITM_FILE_DATE;
                        //  delete v.YEAR_MONTH;
                        delete v.HDR_BUDAT;
                        delete v.PRSDT;
                        delete v.ZZVAR_WT_IND;
                        delete v.VSUM;
                        delete v.VDRSUM;

                        this.addingCommas(v);
                        dataValue.push(v);
                    });

                    this.rowData = dataValue;
                    console.log(this.rowData)
                    this.showLoader = false;
                    this.totalNumber = 0;
                    this.totalNumber = Math.ceil(result.count[0] / result.data.length);
                    this.TotalRecords = result.count[0];
                    this.totalNumberFilter = Math.floor(this.TotalRecords / 150000);
                    if (result.count[0] == result.data.length) {
                        this.nextDisabled = true;
                        this.inputDisabled = true;
                    }
                    // if (this.totalNumberFilter == 0) {
                    //     this.totalNumberFilter = 1
                    // }
                    for (let i = 0; i <= this.totalNumberFilter; i++) {
                        this.exportBtnList.push(i);
                    }
                    const columnDf = this.generateColumns(this.rowData);
                    columnDf[0] = {
                        ...columnDf[0],
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        checkboxSelection: true
                    };
                    this.columnDefs = columnDf;
                    this.showTable = true;
                    this.showMonthOption(this.DataDropValue);
                    this.showYearOption(this.DataDropValue);
                },
                (error) => { }
            );
        } else {
            this.pageNumber++;
            this.numberOfPage++;
            this.keyName = 'AUDITYEAR';

            this.apiservice.getDataTable(this.clCode, this.DataDropValue, this.pageNumber, this.auditYear, this.monthValue, this.keyName, this.keyOrder).subscribe(
                (result) => {
                    const dataValue = [];
                    // const convertToJson = JSON.parse(result);
                    const convertToJson = result;
                    _.each(result.data, (v) => {
                        delete v.PipeLineRunID;
                        delete v.LoadStartTime;
                        delete v.LoadEndTime;
                        delete v.FILEYEAR;
                        delete v.FILEDATE;
                        delete v.INCLUDE;
                        delete v.FILE_DATE;

                        delete v.HDR_ZUKRI;
                        delete v.HDR_EBELN;
                        delete v.HDR_NETWR;
                        delete v.HDR_WAERS;
                        delete v.HDR_IPTYP;
                        delete v.ITM_FILE_DATE;
                        // delete v.YEAR_MONTH;
                        delete v.HDR_BUDAT;
                        delete v.PRSDT;
                        delete v.ZZVAR_WT_IND;
                        delete v.VSUM;
                        delete v.VDRSUM;

                        this.addingCommas(v);
                        dataValue.push(v);
                    });
                    this.showLoader = false;
                    this.rowData = dataValue;
                    console.log(this.rowData)
                    this.totalNumber = Math.ceil(convertToJson.count[0] / convertToJson.data.length);

                    this.TotalRecords = convertToJson.count[0];
                    if (result.count[0] == result.data.length) {
                        this.nextDisabled = true;
                        this.inputDisabled = true;
                    }
                    this.totalNumberCountGrid = Math.floor(this.TotalRecords / 150000);
                    for (let i = 0; i <= this.totalNumberCountGrid; i++) {
                        this.exportBtnList.push(i);
                    }
                    const columnDf = this.generateColumns(this.rowData);
                    columnDf[0] = {
                        ...columnDf[0],
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        checkboxSelection: true
                    };
                    this.columnDefs = columnDf;
                    this.showTable = false;
                    this.showMonthOption(this.DataDropValue);
                    this.showYearOption(this.DataDropValue);
                },
                (error) => { }
            );
        }
    }

    printSortStateToConsole(data) {
        let isSortableCol = false;
        const getAllColumn = data.columnApi.getColumnState();
        const findSortData = _.find(getAllColumn, (v) => {
            return v.sort != null;
        });
        if (this.isAdvanceFilter) {
            this.keyName = findSortData.colId;
            this.keyOrder = this.keyOrder === 1 ? 0 : 1;
            this.afterFilterCallAPI();
        } else {
            if (findSortData) {
                this.keyName = findSortData.colId;
                this.keyOrder = this.keyOrder === 1 ? 0 : 1;
                if (this.DataDropValue === 'PPA_CL_COOPS_POS_T') {
                    const findIsSort = _.find(this.pointOfSaleData, (v) => {
                        return v === this.keyName;
                    });
                    isSortableCol = findIsSort ? true : false;
                } else if (this.DataDropValue === 'PPA_CL_COOPS_PURCHASE_ORDER_T') {
                    const findIsSort = _.find(this.perchesData, (v) => {
                        return v === this.keyName;
                    });
                    isSortableCol = findIsSort ? true : false;
                } else {
                    isSortableCol = true;
                }
            }
            if (isSortableCol) {
                this.rowData = [];
                this.showLoader = true;
                const showArray = this.showArray;
                const findData = showArray.indexOf(this.DataDropValue);

                if (findData > -1) {
                    this.pageNumber = 0;
                    this.numberOfPage = 1;
                    //  this.keyName = this.setKeyNameForMX();
                    this.apiservice.getPointOfSaleData(this.clCode, this.auditYear, this.monthValue, this.pageNumber, this.keyName, this.keyOrder, this.DataDropValue).subscribe(
                        (result) => {
                            const dataValue = [];
                            _.each(result.data, (v) => {
                                delete v.PipeLineRunID;
                                delete v.LoadStartTime;
                                delete v.LoadEndTime;
                                delete v.HDR_ZUKRI;
                                delete v.HDR_EBELN;
                                delete v.HDR_NETWR;
                                delete v.HDR_WAERS;
                                delete v.HDR_IPTYP;
                                delete v.ITM_FILE_DATE;
                                delete v.ZZVAR_WT_IND;
                                delete v.VSUM;
                                delete v.VDRSUM;
                                // delete v.YEAR_MONTH;
                                this.addingCommas(v);
                                dataValue.push(v);
                            });

                            this.rowData = dataValue;
                            this.showLoader = false;
                            this.totalNumber = 0;
                            this.totalNumber = Math.ceil(result.count[0] / result.data.length);
                            this.TotalRecords = result.count[0];
                            this.totalNumberFilter = Math.floor(result.count[0] / 150000);
                            if (result.count[0] == result.data.length) {
                                this.nextDisabled = true;
                                this.inputDisabled = true;
                            }
                            const columnDf = this.generateColumns(this.rowData);
                            columnDf[0] = {
                                ...columnDf[0],
                                headerCheckboxSelection: true,
                                headerCheckboxSelectionFilteredOnly: true,
                                checkboxSelection: true
                            };
                            this.columnDefs = columnDf;
                            this.showTable = true;
                        },
                        (error) => { }
                    );
                } else {
                    this.pageNumber = 0;
                    this.numberOfPage = 1;
                    this.apiservice.getDataTable(this.clCode, this.DataDropValue, this.pageNumber, this.auditYear, this.monthValue, this.keyName, this.keyOrder).subscribe(
                        (result) => {
                            const dataValue = [];
                            this.showLoader = false;
                            _.each(result.data, (v) => {
                                delete v.PipeLineRunID;
                                delete v.LoadStartTime;
                                delete v.LoadEndTime;
                                delete v.FILEYEAR;
                                delete v.FILEDATE;
                                delete v.INCLUDE;
                                delete v.FILE_DATE;

                                delete v.HDR_ZUKRI;
                                delete v.HDR_EBELN;
                                delete v.HDR_NETWR;
                                delete v.HDR_WAERS;
                                delete v.HDR_IPTYP;
                                delete v.ITM_FILE_DATE;
                                delete v.ZZVAR_WT_IND;
                                delete v.VSUM;
                                delete v.VDRSUM;
                                // delete v.YEAR_MONTH;

                                this.addingCommas(v);
                                dataValue.push(v);
                            });
                            this.rowData = dataValue;
                            
                            if (result.count[0] == result.data.length) {
                                this.nextDisabled = true;
                                this.inputDisabled = true;
                            }
                            const columnDf = this.generateColumns(this.rowData);
                            columnDf[0] = {
                                ...columnDf[0],
                                headerCheckboxSelection: true,
                                headerCheckboxSelectionFilteredOnly: true,
                                checkboxSelection: true
                            };
                            this.columnDefs = columnDf;

                            this.showTable = true;
                        },
                        (error) => { }
                    );
                }
            }
        }
    }
    generateColumn(rowData) {
        console.log(rowData)
        const columnDf = this.generateColumns(rowData);
        columnDf[0] = {
            ...columnDf[0],
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true
        };
        this.columnDefs = columnDf;
        this.columnDefs1 = columnDf;
    }

    changeInMonth() { }

    maxDataForAPiCall() {
        const showArray = this.showArray;
        const findData = showArray.indexOf(this.DataDropValue);

        if (findData > -1) {
            this.keyName = this.setKeyNameForMX();
            this.apiservice.getPointOfSaleData(this.clCode, this.auditYear, this.monthValue, this.pageNumber, this.keyName, this.keyOrder, this.DataDropValue).subscribe(
                (result) => {
                    this.showLoader = false;
                    const dataValue = [];
                    _.each(result.data, (v) => {
                        delete v.PipeLineRunID;
                        delete v.LoadStartTime;
                        delete v.LoadEndTime;
                        delete v.PipeLineRunID;
                        delete v.LoadStartTime;
                        delete v.LoadEndTime;
                        delete v.HDR_ZUKRI;
                        delete v.HDR_EBELN;
                        delete v.HDR_NETWR;
                        delete v.HDR_WAERS;
                        delete v.HDR_IPTYP;
                        delete v.ITM_FILE_DATE;
                        delete v.ZZVAR_WT_IND;
                        delete v.VSUM;
                        delete v.VDRSUM;
                        this.addingCommas(v);
                        dataValue.push(v);
                    });

                    this.rowData = dataValue;
                    this.totalNumber = 0;
                    this.totalNumber = Math.ceil(result.count[0] / result.data.length);
                    this.TotalRecords = result.count[0];
                    if (result.count[0] == result.data.length) {
                        this.nextDisabled = true;
                        this.inputDisabled = true;
                    }
                    this.totalNumberFilter = Math.floor(this.TotalRecords / 150000);
                    // if (this.totalNumberFilter == 0) {
                    //     this.totalNumberFilter = 1
                    // }
                    for (let i = 0; i <= this.totalNumberFilter; i++) {
                        this.exportBtnList.push(i);
                    }

                    const columnDf = this.generateColumns(this.rowData);
                    columnDf[0] = {
                        ...columnDf[0],
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        checkboxSelection: true
                    };
                    this.columnDefs = columnDf;
                    this.showTable = true;
                    this.showMonthOption(this.DataDropValue);
                    this.showYearOption(this.DataDropValue);
                },
                (error) => { }
            );
        } else {
            this.apiservice.getDataTable(this.clCode, this.DataDropValue, this.pageNumber, this.auditYear, this.monthValue, this.keyName, this.keyOrder).subscribe(
                (result) => {
                    const dataValue = [];
                    const convertToJson = JSON.parse(result);
                    _.each(convertToJson.data, (v) => {
                        delete v.PipeLineRunID;
                        delete v.LoadStartTime;
                        delete v.LoadEndTime;
                        delete v.FILEYEAR;
                        delete v.FILEDATE;
                        delete v.INCLUDE;
                        delete v.FILE_DATE;

                        delete v.HDR_ZUKRI;
                        delete v.HDR_EBELN;
                        delete v.HDR_NETWR;
                        delete v.HDR_WAERS;
                        delete v.HDR_IPTYP;
                        delete v.ITM_FILE_DATE;
                        delete v.ZZVAR_WT_IND;
                        delete v.VSUM;
                        delete v.VDRSUM;
                        // delete v.YEAR_MONTH;

                        this.addingCommas(v);
                        dataValue.push(v);
                    });
                    this.rowData = dataValue;
                    this.totalNumber = Math.ceil(convertToJson.count[0] / convertToJson.data.length);

                    this.TotalRecords = convertToJson.count[0];
                    this.totalNumberFilter = Math.floor(this.TotalRecords / 150000);
                    if (result.count[0] == result.data.length) {
                        this.nextDisabled = true;
                        this.inputDisabled = true;
                    }
                    for (let i = 0; i <= this.totalNumberCountGrid; i++) {
                        this.exportBtnList.push(i);
                    }
                    const columnDf = this.generateColumns(this.rowData);
                    columnDf[0] = {
                        ...columnDf[0],
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        checkboxSelection: true
                    };
                    this.columnDefs = columnDf;
                    this.showTable = false;
                    this.showMonthOption(this.DataDropValue);
                    this.showYearOption(this.DataDropValue);
                },
                (error) => { }
            );
        }
    }

    changAuditYear(data: any) { }

    goToPrv() {
        this.showLoader = true;
        this.pageNumber--;
        this.numberOfPage--;
        const rowData = [];
        if (this.numberOfPage > 1) {
            this.prvDisabled = false;
        } else {
            this.prvDisabled = true;
        }
        if (this.numberOfPage >= this.totalNumber) {
            this.nextDisabled = true;
        } else {
            this.nextDisabled = false;
        }
        if (this.filterPagination == true) {
            if (this.clCode == 'CL') {
                this.apiservice.dataPageFilters(this.filterPayload, this.DataDropValue, this.numberOfPage, this.keyName, this.keyOrder).subscribe(
                    (result) => {
                        _.each(result.data, (v) => {
                            delete v.PipeLineRunID;
                            delete v.LoadStartTime;
                            delete v.LoadEndTime;
                            delete v.PipeLineRunID;
                            delete v.LoadStartTime;
                            delete v.LoadEndTime;
                            delete v.HDR_ZUKRI;
                            delete v.HDR_EBELN;
                            delete v.HDR_NETWR;
                            delete v.HDR_WAERS;
                            delete v.HDR_IPTYP;
                            delete v.ITM_FILE_DATE;
                            delete v.ZZVAR_WT_IND;
                            delete v.VSUM;
                            delete v.VDRSUM;
                            this.addingCommas(v);
                            rowData.push(v);
                        });
                        if (result.count[0] == result.data.length) {
                            this.nextDisabled = true;
                            this.inputDisabled = true;
                        }
                        this.rowData = rowData;
                        this.generateColumn(rowData);
                        this.gridApi.setRowData(this.rowData);
                        this.gridApi.hideOverlay();
                        this.showLoader = false;
                    },
                    (error) => {
                        this.showLoader = false;
                    }
                );
            } else {
                this.apiservice.dataPageFiltersMX(this.filterPayload, this.DataDropValue, this.numberOfPage, this.keyName, this.keyOrder).subscribe(
                    (result) => {
                        _.each(result.data, (v) => {
                            delete v.PipeLineRunID;
                            delete v.LoadStartTime;
                            delete v.LoadEndTime;
                            delete v.HDR_ZUKRI;
                            delete v.HDR_EBELN;
                            delete v.HDR_NETWR;
                            delete v.HDR_WAERS;
                            delete v.HDR_IPTYP;
                            delete v.ITM_FILE_DATE;
                            delete v.ZZVAR_WT_IND;
                            delete v.VSUM;
                            delete v.VDRSUM;
                            this.addingCommas(v);
                            rowData.push(v);
                        });
                        if (result.count[0] == result.data.length) {
                            this.nextDisabled = true;
                            this.inputDisabled = true;
                        }
                        this.rowData = rowData;
                        this.generateColumn(rowData);
                        this.gridApi.setRowData(this.rowData);
                        this.gridApi.hideOverlay();
                        this.showLoader = false;
                    },
                    (error) => {
                        this.showLoader = false;
                    }
                );
            }
        } else {
            if (this.clCode === 'MX') {
                this.maxDataForAPiCall();
            } else {
                this.apiservice.getDataTable(this.clCode, this.DataDropValue, this.numberOfPage, this.auditYear, this.monthValue, this.keyName, this.keyOrder).subscribe(
                    (result) => {
                        _.each(result.data, (v) => {
                            delete v.PipeLineRunID;
                            delete v.LoadStartTime;
                            delete v.LoadEndTime;
                            delete v.FILEYEAR;
                            delete v.FILEDATE;
                            delete v.INCLUDE;
                            delete v.FILE_DATE;

                            delete v.HDR_ZUKRI;
                            delete v.HDR_EBELN;
                            delete v.HDR_NETWR;
                            delete v.HDR_WAERS;
                            delete v.HDR_IPTYP;
                            delete v.ITM_FILE_DATE;
                            delete v.ZZVAR_WT_IND;
                            delete v.VSUM;
                            delete v.VDRSUM;
                            //delete v.YEAR_MONTH;

                            this.addingCommas(v);
                            rowData.push(v);
                        });
                        if (result.count[0] == result.data.length) {
                            this.nextDisabled = true;
                            this.inputDisabled = true;
                        }
                        this.rowData = rowData;
                        this.generateColumn(rowData);
                        this.gridApi.setRowData(this.rowData);
                        this.gridApi.hideOverlay();
                        this.showLoader = false;
                    },
                    (error) => {
                        this.showLoader = false;
                    }
                );
            }
        }
    }

    goToNext() {
        this.showLoader = true;
        this.pageNumber++;
        this.numberOfPage++;
        const rowData = [];
        if (this.numberOfPage > 1) {
            this.prvDisabled = false;
        } else {
            this.prvDisabled = true;
        }
        if (this.numberOfPage >= this.totalNumber) {
            this.nextDisabled = true;
        } else {
            this.nextDisabled = false;
        }
        if (this.filterPagination == true) {
            if (this.clCode == 'CL') {
                this.apiservice.dataPageFilters(this.filterPayload, this.DataDropValue, this.pageNumber, this.keyName, this.keyOrder).subscribe(
                    (result) => {
                        _.each(result.data, (v) => {
                            delete v.PipeLineRunID;
                            delete v.LoadStartTime;
                            delete v.LoadEndTime;
                            delete v.PipeLineRunID;
                            delete v.LoadStartTime;
                            delete v.LoadEndTime;
                            delete v.HDR_ZUKRI;
                            delete v.HDR_EBELN;
                            delete v.HDR_NETWR;
                            delete v.HDR_WAERS;
                            delete v.HDR_IPTYP;
                            delete v.ITM_FILE_DATE;
                            delete v.ZZVAR_WT_IND;
                            delete v.VSUM;
                            delete v.VDRSUM;
                            this.addingCommas(v);
                            rowData.push(v);
                        });
                        this.rowData = rowData;
                        if (result.count[0] == result.data.length) {
                            this.nextDisabled = true;
                            this.inputDisabled = true;
                        }
                        this.generateColumn(rowData);
                        this.gridApi.setRowData(this.rowData);
                        this.gridApi.hideOverlay();
                        this.showLoader = false;
                    },
                    (error) => {
                        this.showLoader = false;
                    }
                );
            } else {
                this.apiservice.dataPageFiltersMX(this.filterPayload, this.DataDropValue, this.pageNumber, this.keyName, this.keyOrder).subscribe(
                    (result) => {
                        _.each(result.data, (v) => {
                            delete v.PipeLineRunID;
                            delete v.LoadStartTime;
                            delete v.LoadEndTime;
                            delete v.HDR_ZUKRI;
                            delete v.HDR_EBELN;
                            delete v.HDR_NETWR;
                            delete v.HDR_WAERS;
                            delete v.HDR_IPTYP;
                            delete v.ITM_FILE_DATE;
                            delete v.ZZVAR_WT_IND;
                            delete v.VSUM;
                            delete v.VDRSUM;
                            this.addingCommas(v);
                            rowData.push(v);
                        });
                        this.rowData = rowData;
                        if (result.count[0] == result.data.length) {
                            this.nextDisabled = true;
                            this.inputDisabled = true;
                        }
                        this.generateColumn(rowData);
                        this.gridApi.setRowData(this.rowData);
                        this.gridApi.hideOverlay();
                        this.showLoader = false;
                    },
                    (error) => {
                        this.showLoader = false;
                    }
                );
            }
        } else {
            if (this.clCode === 'MX') {
                this.maxDataForAPiCall();
            } else {
                this.apiservice.getDataTable(this.clCode, this.DataDropValue, this.pageNumber, this.auditYear, this.monthValue, this.keyName, this.keyOrder).subscribe(
                    (result) => {
                        _.each(result.data, (v) => {
                            delete v.PipeLineRunID;
                            delete v.LoadStartTime;
                            delete v.LoadEndTime;
                            delete v.FILEYEAR;
                            delete v.FILEDATE;
                            delete v.INCLUDE;
                            delete v.FILE_DATE;

                            delete v.HDR_ZUKRI;
                            delete v.HDR_EBELN;
                            delete v.HDR_NETWR;
                            delete v.HDR_WAERS;
                            delete v.HDR_IPTYP;
                            delete v.ITM_FILE_DATE;
                            delete v.ZZVAR_WT_IND;
                            delete v.VSUM;
                            delete v.VDRSUM;
                            // delete v.YEAR_MONTH;

                            this.addingCommas(v);
                            rowData.push(v);
                        });
                        if (result.count[0] == result.data.length) {
                            this.nextDisabled = true;
                            this.inputDisabled = true;
                        }
                        this.rowData = rowData;
                        this.generateColumn(rowData);
                        this.gridApi.setRowData(this.rowData);
                        this.gridApi.hideOverlay();
                        this.showLoader = false;
                    },
                    (error) => {
                        this.showLoader = false;
                    }
                );
            }
        }
    }

    onPageNumber($event) {
        let pageEntered = $event.target.value;
        if (pageEntered >= 1 && pageEntered <= this.totalNumber) {
            this.showLoader = true;
            setTimeout(() => {
                this.pageNumber;
                this.numberOfPage;
                const rowData = [];
                if (this.numberOfPage > 1) {
                    this.prvDisabled = false;
                } else {
                    this.prvDisabled = true;
                }
                if (this.numberOfPage >= this.totalNumber) {
                    this.nextDisabled = true;
                } else {
                    this.nextDisabled = false;
                }
                const pageNumber = Number(this.numberOfPage);
                if (this.filterPagination == true) {
                    if (this.clCode == 'CL') {
                        this.apiservice.dataPageFilters(this.filterPayload, this.DataDropValue, this.numberOfPage, this.keyName, this.keyOrder).subscribe(
                            (result) => {
                                _.each(result.data, (v) => {
                                    delete v.PipeLineRunID;
                                    delete v.LoadStartTime;
                                    delete v.LoadEndTime;
                                    delete v.PipeLineRunID;
                                    delete v.LoadStartTime;
                                    delete v.LoadEndTime;
                                    delete v.HDR_ZUKRI;
                                    delete v.HDR_EBELN;
                                    delete v.HDR_NETWR;
                                    delete v.HDR_WAERS;
                                    delete v.HDR_IPTYP;
                                    delete v.ITM_FILE_DATE;
                                    delete v.ZZVAR_WT_IND;
                                    delete v.VSUM;
                                    delete v.VDRSUM;
                                    this.addingCommas(v);
                                    rowData.push(v);
                                });
                                if (result.count[0] == result.data.length) {
                                    this.nextDisabled = true;
                                    this.inputDisabled = true;
                                }
                                this.rowData = rowData;
                                this.generateColumn(rowData);
                                this.gridApi.setRowData(this.rowData);
                                this.gridApi.hideOverlay();
                                this.showLoader = false;
                            },
                            (error) => {
                                this.showLoader = false;
                            }
                        );
                    } else {
                        this.apiservice.dataPageFiltersMX(this.filterPayload, this.DataDropValue, this.numberOfPage, this.keyName, this.keyOrder).subscribe(
                            (result) => {
                                _.each(result.data, (v) => {
                                    delete v.PipeLineRunID;
                                    delete v.LoadStartTime;
                                    delete v.LoadEndTime;
                                    delete v.HDR_ZUKRI;
                                    delete v.HDR_EBELN;
                                    delete v.HDR_NETWR;
                                    delete v.HDR_WAERS;
                                    delete v.HDR_IPTYP;
                                    delete v.ITM_FILE_DATE;
                                    delete v.ZZVAR_WT_IND;
                                    delete v.VSUM;
                                    delete v.VDRSUM;
                                    this.addingCommas(v);
                                    rowData.push(v);
                                });
                                if (result.count[0] == result.data.length) {
                                    this.nextDisabled = true;
                                    this.inputDisabled = true;
                                }
                                this.rowData = rowData;
                                this.generateColumn(rowData);
                                this.gridApi.setRowData(this.rowData);
                                this.gridApi.hideOverlay();
                                this.showLoader = false;
                            },
                            (error) => {
                                this.showLoader = false;
                            }
                        );
                    }
                } else {
                    if (this.clCode === 'MX') {
                        this.pageNumber = Number(this.numberOfPage - 1);
                        this.maxDataForAPiCall();
                    } else {
                        this.apiservice.getDataTable(this.clCode, this.DataDropValue, pageNumber, this.auditYear, this.monthValue, this.keyName, this.keyOrder).subscribe(
                            (result) => {
                                _.each(result.data, (v) => {
                                    delete v.PipeLineRunID;
                                    delete v.LoadStartTime;
                                    delete v.LoadEndTime;
                                    delete v.FILEYEAR;
                                    delete v.FILEDATE;
                                    delete v.INCLUDE;
                                    delete v.FILE_DATE;

                                    delete v.HDR_ZUKRI;
                                    delete v.HDR_EBELN;
                                    delete v.HDR_NETWR;
                                    delete v.HDR_WAERS;
                                    delete v.HDR_IPTYP;
                                    delete v.ITM_FILE_DATE;
                                    delete v.ZZVAR_WT_IND;
                                    delete v.VSUM;
                                    delete v.VDRSUM;
                                    // delete v.YEAR_MONTH;

                                    rowData.push(this.addingCommas(v));
                                });
                                if (result.count[0] == result.data.length) {
                                    this.nextDisabled = true;
                                    this.inputDisabled = true;
                                }
                                this.rowData = rowData;
                                this.generateColumn(rowData);
                                this.gridApi.setRowData(this.rowData);
                                this.gridApi.hideOverlay();
                                this.showLoader = false;
                            },
                            (error) => {
                                this.showLoader = false;
                            }
                        );
                    }
                }
            }, 1000);
        } else {
            $event.preventDefault();
            if (pageEntered < 1 || pageEntered > this.totalNumber) {
                this.openSnackBar('Please enter the correct page number', 'Ok');
            }
        }
    }

    getAllAuditYear(AuditType) {
        this.hideBulkupdateBtn(this.DataDropValue);
        this.auditYearList = [];
        let result;
        if (this.DataDropValue != 'PPA_CL_NEW_STORE_LIST_M') {
            result = [{ AUDIT_YEAR: 2018 }, { AUDIT_YEAR: 2019 }, { AUDIT_YEAR: 2020 }, { AUDIT_YEAR: 2021 }, { AUDIT_YEAR: 2022 }, { AUDIT_YEAR: 2023 }, { AUDIT_YEAR: 2024 }];
        } else {
            result = [{ AUDIT_YEAR: 2018 }, { AUDIT_YEAR: 2019 }, { AUDIT_YEAR: 2020 }, { AUDIT_YEAR: 2021 }, { AUDIT_YEAR: 2022 }, { AUDIT_YEAR: 2023 }, { AUDIT_YEAR: 'All' }];
        }
        this.auditYearList = _.map(result, (v) => {
            return {
                AUDITYEAR: v.AUDIT_YEAR
            };
        });
        this.auditYearList.sort().reverse();
        //  else {
        //     this.apiservice.getAuditYrData(AuditType, this.clCode).subscribe(
        //         (result) => {
        //             this.showLoader = false;
        //             this.auditYearList = result;
        //         },
        //         (error) => {
        //             this.showLoader = false;
        //         }
        //     );
        // }
        this.auditYear = sessionStorage.getItem('auditYear') === null ? `${this.latestYear}` : sessionStorage.getItem('auditYear');
        if (this.auditYear == 'All' && this.DataDropValue != 'PPA_CL_NEW_STORE_LIST_M') {
            this.auditYear = `${this.latestYear}`;
        }
    }


    onSelectOfPointOfSale() {
        this.pageNumber = -1;
        this.numberOfPage = 0;
        this.showLoader = true;
        this.pageNumber++;
        this.numberOfPage++;
        const rowData = [];
        if (this.numberOfPage > 1) {
            this.prvDisabled = false;
        } else {
            this.prvDisabled = true;
        }
        if (this.numberOfPage >= this.totalNumber) {
            this.nextDisabled = true;
        } else {
            this.nextDisabled = false;
        }
        const showArray = this.showArray;
        const findData = showArray.indexOf(this.DataDropValue);
        this.keyName = this.setKeyNameForMX();

        this.apiservice.getPointOfSaleData(this.clCode, this.auditYear, this.monthValue, this.pageNumber, this.keyName, this.keyOrder, this.DataDropValue).subscribe((result) => {
            _.each(result.data, (v) => {
                delete v.PipeLineRunID;
                delete v.LoadStartTime;
                delete v.LoadEndTime;
                delete v.HDR_ZUKRI;
                delete v.HDR_EBELN;
                delete v.HDR_NETWR;
                delete v.HDR_WAERS;
                delete v.HDR_IPTYP;
                delete v.ITM_FILE_DATE;
                delete v.ZZVAR_WT_IND;
                delete v.VSUM;
                delete v.VDRSUM;
                // delete v.YEAR_MONTH;
                rowData.push(this.addingCommas(v));
            });
            this.showLoader = false;
            this.rowData = rowData;
            this.generateColumn(rowData);

            this.TotalRecords = result.count[0];
            this.totalNumber = 0;
            this.totalNumber = Math.ceil(result.count[0] / result.data.length);

            this.totalNumberFilter = Math.floor(this.TotalRecords / 150000);
            if (result.count[0] == result.data.length) {
                this.nextDisabled = true;
                this.inputDisabled = true;
            }
            // if (this.totalNumberFilter == 0) {
            //     this.totalNumberFilter = 1
            // }
            for (let i = 0; i <= this.totalNumberFilter; i++) {
                this.exportBtnList.push(i);
            }

            this.gridApi.setRowData(this.rowData);
            this.gridApi.hideOverlay();
            this.showLoader = false;
            this.router.navigate(['data/' + this.clCode + '/' + this.selecteddata]);
        });
    }

    selectedValue(AuditType) {
        this.auditTypeID = AuditType;
        this.hideBulkupdateBtn(this.DataDropValue);
        this.ShowAddRowOption(AuditType);
        this.showEditRowOption(AuditType);
        this.APIExport = 'none';
        this.selectedRows = 'none';
        this.exportBtnList = [];
        this.TotalRecords = [];
        this.filterNameConditions(AuditType);
        this.hideExport(AuditType);
        this.DataDropValue = AuditType;
        this.getAllAuditYear(AuditType);
        this.showMonthOption(AuditType);
        this.showYearOption(this.DataDropValue);
        const showArray = this.showArray;
        const findData = showArray.indexOf(this.DataDropValue);
        if (findData > -1) {
            this.onSelectOfPointOfSale();
            this.updateYearAndAuditType();
        } else {
            this.updateYearAndAuditType();
            this.pageNumber = -1;
            this.numberOfPage = 0;
            this.showLoader = true;
            this.pageNumber++;
            this.numberOfPage++;
            const rowData = [];
            if (this.numberOfPage > 1) {
                this.prvDisabled = false;
            } else {
                this.prvDisabled = true;
            }
            if (this.numberOfPage >= this.totalNumber) {
                this.nextDisabled = true;
            } else {
                this.nextDisabled = false;
            }
            if (this.clCode === 'CL') {
                this.keyName = this.keyName = this.DataDropValue === 'PPA_CL_COOPS_POS_T' ? 'YearMM' : 'AUDITYEAR';
            } else {
                this.keyName = 'FILEYEAR';
            }

            this.apiservice.getDataTable(this.clCode, this.selecteddata, this.pageNumber, this.auditYear, this.monthValue, this.keyName, this.keyOrder).subscribe((result) => {
                this.auditYear = sessionStorage.getItem('auditYear') === null ? `${this.latestYear}` : sessionStorage.getItem('auditYear');
                if (this.auditYear == 'All' && this.DataDropValue != 'PPA_CL_NEW_STORE_LIST_M') {
                    this.auditYear = `${this.latestYear}`;
                }
                if (result.count[0] === undefined || result.count[0] === 0) {
                    alert(this.selecteddata + " don't have data for " + this.auditYear + '. Please select other audit year');
                    this.showLoader = false;
                } else {
                    this.showLoader = false;
                    _.each(result.data, (v) => {
                        delete v.PipeLineRunID;
                        delete v.LoadStartTime;
                        delete v.LoadEndTime;
                        delete v.FILEYEAR;
                        delete v.FILEDATE;
                        delete v.INCLUDE;
                        delete v.FILE_DATE;

                        delete v.HDR_ZUKRI;
                        delete v.HDR_EBELN;
                        delete v.HDR_NETWR;
                        delete v.HDR_WAERS;
                        delete v.HDR_IPTYP;
                        delete v.ITM_FILE_DATE;
                        // delete v.YEAR_MONTH;
                        delete v.HDR_BUDAT;
                        delete v.PRSDT;
                        delete v.ZZVAR_WT_IND;
                        delete v.VSUM;
                        delete v.VDRSUM;

                        this.addingCommas(v);
                        rowData.push(v);
                    });
                    this.rowData = rowData;
                    this.generateColumn(rowData);
                    this.totalNumber = 0;
                    this.totalNumber = Math.ceil(result.count[0] / result.data.length);

                    this.TotalRecords = result.count[0];
                    this.totalNumberFilter = Math.floor(this.TotalRecords / 150000);
                    if (result.count[0] == result.data.length) {
                        this.nextDisabled = true;
                        this.inputDisabled = true;
                    }
                    // if (this.totalNumberFilter == 0) {
                    //     this.totalNumberFilter = 1
                    // }
                    for (let i = 0; i <= this.totalNumberFilter; i++) {
                        this.exportBtnList.push(i);
                    }

                    this.gridApi.setRowData(this.rowData);
                    this.gridApi.hideOverlay();
                    this.router.navigate(['data/' + this.clCode + '/' + this.selecteddata]);
                }
            });
        }
    }

    selectedValueAuditYr(auditYear) {
        console.log(auditYear)
        this.APIExport = 'none';
        this.filterbulkUpdateBtn = 'none';
        this.selectedRows = 'none';
        if (auditYear == 'All') {
            this.auditYear = '2018,2019,2020,2021,2022,2023';
        } else {
            this.auditYear = auditYear;
        }
        
        sessionStorage.setItem('auditYear', auditYear);
        const showArray = this.showArray;
        const findData = showArray.indexOf(this.DataDropValue);
        if (findData > -1) {
            this.onSelectOfPointOfSale();
            this.updateYearAndAuditType();
        } else {
            this.updateYearAndAuditType();
            this.pageNumber = -1;
            this.numberOfPage = 0;
            this.showLoader = true;
            this.pageNumber++;
            this.numberOfPage++;
            const rowData = [];
            if (this.numberOfPage > 1) {
                this.prvDisabled = false;
            } else {
                this.prvDisabled = true;
            }
            if (this.numberOfPage >= this.totalNumber) {
                this.nextDisabled = true;
            } else {
                this.nextDisabled = false;
            }
            if (this.clCode === 'CL') {
                this.keyName = this.keyName = this.DataDropValue === 'PPA_CL_COOPS_POS_T' ? 'YearMM' : 'AUDITYEAR';
            } else {
                this.keyName = 'FILEYEAR';
            }
            this.apiservice.getDataTable(this.clCode, this.selecteddata, this.pageNumber, this.auditYear, this.monthValue, this.keyName, this.keyOrder).subscribe((result) => {
                if (result) {
                    this.showLoader = false;
                }
                _.each(result.data, (v) => {
                    delete v.PipeLineRunID;
                    delete v.LoadStartTime;
                    delete v.LoadEndTime;
                    delete v.FILEYEAR;
                    delete v.FILEDATE;
                    delete v.INCLUDE;
                    delete v.FILE_DATE;

                    delete v.HDR_ZUKRI;
                    delete v.HDR_EBELN;
                    delete v.HDR_NETWR;
                    delete v.HDR_WAERS;
                    delete v.HDR_IPTYP;
                    delete v.ITM_FILE_DATE;
                    // delete v.YEAR_MONTH;
                    delete v.HDR_BUDAT;
                    delete v.PRSDT;
                    delete v.ZZVAR_WT_IND;
                    delete v.VSUM;
                    delete v.VDRSUM;

                    this.addingCommas(v);
                    rowData.push(v);
                });
                this.rowData = rowData;
                this.generateColumn(rowData);
                this.totalNumber = 0;
                this.totalNumber = Math.ceil(result.count[0] / result.data.length);

                this.TotalRecords = result.count[0];
                this.totalNumberFilter = Math.floor(this.TotalRecords / 150000);
                if (result.count[0] == result.data.length) {
                    this.nextDisabled = true;
                    this.inputDisabled = true;
                }
                // if (this.totalNumberFilter == 0) {
                //     this.totalNumberFilter = 1
                // }
                for (let i = 0; i <= this.totalNumberFilter; i++) {
                    this.exportBtnList.push(i);
                }
                this.gridApi.setRowData(this.rowData);
                this.gridApi.hideOverlay();
                this.router.navigate(['data/' + this.clCode + '/' + this.selecteddata]);
            });
        }
    }

    pivotExport() {
        this.gridApi.exportDataAsCsv();
    }

    exportOnAPI(count) {
        this.showLoader = true;
        this.apiservice.exportFilter(this.filterPayload, this.DataDropValue, count, this.clCode).subscribe((response) => {
            let downloadFIle = response;
            this.downloadFile(downloadFIle, count);
            this.showLoader = false;
        });
    }

    onGridExport(pageNumber) {
        this.showLoader = true;
        if (this.clCode == 'CL') {
            this.apiservice.exportFilterNormal(this.DataDropValue, this.auditYear, pageNumber, this.clCode).subscribe((response) => {
                let downloadFIle = response;
                this.downloadFile(downloadFIle, pageNumber);
                this.showLoader = false;
            });
        } else {
            this.filterPayload = {
                AUDIT_YEAR: this.auditYear,
                VENDOR_NUMBER: null,
                AGREEMENT_NUMBER: null,
                UPC_NUMBER: null,
                LEGACY_ITEM_NUMBER: null,
                ITEM_NO: null,
                DEPT_NO: null,
                SalesDateStart: null,
                SalesDateEnd: null,
                SAP_NUMBER: null,
                STORE_NUM: null,
                SEQ_NO: null,
                FORMAT: null,
                PO_SOURCE: null,
                PROC_DATE_START: null,
                PROC_DATE_END: null,
                FINELINE_NBR: null,
                BANNER_CODE: null,
                CLUB_NBR: null,
                CAT: null,
                PO_NUM: null,
                INVOICE_NBR: null,
                ASSIGNMENT: null,
                RECV_DATE_START: null,
                RECV_DATE_END: null,
                PRICE_DATE_START: null,
                PRICE_DATE_END: null,
                MIN_DATE_START: null,
                MIN_DATE_END: null,
                INV_DATE_START: null,
                INV_DATE_END: null,
                RCVD_NBR: null,
                INCLUDE: 'Y',
                REF_NUM: null,
                PAID_AMOUNT_FROM: null,
                PAID_AMOUNT_TO: null,
                FYR: null,
                SAP_VND: null,
                DOC_NBR: null,
                SUPLR_NBR: null,
                INV_NUMBER: null,
                ASSIGN: null,
                PO_NUMBER: null,
                UUID: null,
                CATEGORY: null,
                DATE_START: null,
                DATE_END: null,
                VENDOR_NBR: null,
                DEPARTMENT: null,
                ITEM_NUM: null,
                GRE_DATE_START: null,
                GRE_DATE_END: null,
            };
            this.apiservice.exportFilter(this.filterPayload, this.DataDropValue, pageNumber, this.clCode).subscribe((response) => {
                let downloadFIle = response;
                this.downloadFile(downloadFIle, pageNumber);
                this.showLoader = false;
            });
        }
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 3000,
            verticalPosition: 'top'
        });
    }

    onSelectionChanged(params: GridReadyEvent) {
        this.selectedRows = 'inline-block';
        var selectedRows = this.gridApi.getSelectedRows();
        (document.querySelector('#selectedRows') as any).innerHTML = '<b>Rows selected-</b>' + selectedRows.length;
    }

    onFilterChanged() {
        this.TotalRecords = this.gridApi.getDisplayedRowCount();
    }

    dataDropDownValue(CLCode) {
        this.apiservice.getDataDropdown(CLCode).subscribe((response) => {
            this.dataDropDownValues = response;
            this.selecteddata = this.DataDropValue;
        });
    }

    onCellValueChanged($event) {
        this.payloadForCellEdit.push($event.data);
        this.apiservice.editData(this.payloadForCellEdit, this.auditTypeID).subscribe(
            (result) => {
                this.payloadForCellEdit = [];
                this.showLoader = false;
            },
            (error) => {
                this.payloadForCellEdit = [];
                this.showLoader = false;
                this.openSnackBar('Error Updting data', 'Ok');
            }
        );
    }

    advanceFilters() {
        this.btnCount = [];
        var dataTosend = {
            id: this.DataDropValue,
            countryCode: this.clCode,
            auditYear: this.auditYear
        };
        const dialogRef = this.dialog.open(DataFiltersComponent, {
            hasBackdrop: true,
            data: { requestId: dataTosend, filterPayload: this.filterPayload }
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.hideBulkupdateBtn(this.DataDropValue);
            this.advanceFilterPostBulkUpdateObj = {};
            this.advanceFilterPostBulkUpdateObj = result;
            var tableData = result.data.data;
            this.filterPayload = result.payload;
            const rowData = [];
            this.countOfFilterRecords = result.data.count[0].Count;
            if (result.data.count[0].Count == 0) {
                this.openSnackBar('No match found', 'Ok');
            } else {
                this.rowData = [];
                this.isAdvanceFilter = true;
                _.each(tableData, (v) => {
                    delete v.PipeLineRunID;
                    delete v.LoadStartTime;
                    delete v.LoadEndTime;
                    delete v.HDR_ZUKRI;
                    delete v.HDR_EBELN;
                    delete v.HDR_NETWR;
                    delete v.HDR_WAERS;
                    delete v.HDR_IPTYP;
                    delete v.ITM_FILE_DATE;
                    // delete v.YEAR_MONTH;
                    delete v.HDR_BUDAT;
                    delete v.PRSDT;
                    delete v.ZZVAR_WT_IND;
                    delete v.VSUM;
                    delete v.VDRSUM;
                    this.addingCommas(v);
                    rowData.push(v);
                });
                this.rowData = rowData;               
                this.totalNumber = Math.ceil(result.data.count[0].Count / result.data.data.length);
                this.gridApi.setRowData(rowData);
                this.gridApi.hideOverlay();
                this.showLoader = false;
                this.filterPagination = true;
                this.normalExport = 'none';
                this.isNormalExp = false;
                this.APIExport = 'inline-block';
                this.APIExportNormal = 'none';
                this.countOfFilterRecords;
                this.totalNumberFilter = Math.floor(this.countOfFilterRecords / 150000);
                for (let i = 0; i <= this.totalNumberFilter; i++) {
                    this.btnCount.push(i);
                }
            }
            this.hideBulkupdateBtn(this.DataDropValue);
        });
    }

    afterFilterCallAPI() {
        this.showLoader = true;
        this.rowData = [];
        if (this.clCode === 'CL') {
            this.apiservice.dataPageFilters(this.filterPayload, this.DataDropValue, 0, this.keyName, this.keyOrder).subscribe(
                (result) => {
                    const dataValue = [];
                    this.showLoader = false;
                    _.each(result.data, (v) => {
                        delete v.PipeLineRunID;
                        delete v.LoadStartTime;
                        delete v.LoadEndTime;

                        delete v.HDR_ZUKRI;
                        delete v.HDR_EBELN;
                        delete v.HDR_NETWR;
                        delete v.HDR_WAERS;
                        delete v.HDR_IPTYP;
                        delete v.ITM_FILE_DATE;
                        delete v.ZZVAR_WT_IND;
                        delete v.VSUM;
                        delete v.VDRSUM;
                        // delete v.YEAR_MONTH;
                        this.addingCommas(v);
                        dataValue.push(v);
                    });
                    this.rowData = dataValue;
                    this.totalNumber = Math.ceil(result.count[0].Count / result.data.length);
                    if (result.count[0] == result.data.length) {
                        this.nextDisabled = true;
                        this.inputDisabled = true;
                    }
                    const columnDf = this.generateColumns(this.rowData);
                    columnDf[0] = {
                        ...columnDf[0],
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        checkboxSelection: true
                    };
                    this.columnDefs = columnDf;
                    this.showTable = true;
                    this.showLoader = false;
                },
                (error) => {
                    this.showLoader = false;
                }
            );
        } else {
            this.apiservice.dataPageFiltersMX(this.filterPayload, this.DataDropValue, 0, this.keyName, this.keyOrder).subscribe(
                (result) => {
                    const dataValue = [];
                    this.showLoader = false;
                    _.each(result.data, (v) => {
                        delete v.PipeLineRunID;
                        delete v.LoadStartTime;
                        delete v.LoadEndTime;
                        delete v.HDR_ZUKRI;
                        delete v.HDR_EBELN;
                        delete v.HDR_NETWR;
                        delete v.HDR_WAERS;
                        delete v.HDR_IPTYP;
                        delete v.ITM_FILE_DATE;
                        delete v.ZZVAR_WT_IND;
                        delete v.VSUM;
                        delete v.VDRSUM;
                        // delete v.YEAR_MONTH;
                        this.addingCommas(v);
                        dataValue.push(v);
                    });
                    this.rowData = dataValue;
                    this.totalNumber = Math.ceil(result.count[0].Count / result.data.length);
                    if (result.count[0] == result.data.length) {
                        this.nextDisabled = true;
                        this.inputDisabled = true;
                    }
                    const columnDf = this.generateColumns(this.rowData);
                    columnDf[0] = {
                        ...columnDf[0],
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        checkboxSelection: true
                    };
                    this.columnDefs = columnDf;
                    this.showTable = true;
                    this.showLoader = false;
                },
                (error) => {
                    this.showLoader = false;
                }
            );
        }
    }

    pivot() {
        console.log('Pivot - advance filter ', this.filterPayload);
        const dialogRef = this.dialog.open(PivotTableComponent, {
            width: '200',
            hasBackdrop: true,
            data: {
                previousPayload: this.filterPayload,
                columnDets: this.columnNameandKey,
                auditYear: this.auditYear,
                id: this.auditTypeID,
                country: this.country_cd
            }
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result.from === 'submit') {
                this.processPivotData(result.data);
            }
        });
    }

    processPivotData(result) {
        console.log(result);
        var tableData = result;
        this.countOfFilterRecords = result.length;
        const columnDf = this.generatePivotColumns(tableData);
        columnDf[0] = {
            ...columnDf[0],
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true
        };
        this.columnDefs = columnDf;
        this.gridApi.setRowData(tableData);
        this.gridApi.hideOverlay();
        this.showTable = true;
        this.isNormalExp = false;
        this.showLoader = false;
        this.filterPagination = true;
        this.normalExport = 'none';
        this.APIExport = 'none';
        this.APIExportNormal = 'none';
        this.APIPivotExport = 'inline-block'
        this.hideBulkupdateBtn(this.DataDropValue);
    }

    generatePivotColumn(rowData) {
        const columnDf = this.generatePivotColumns(rowData);
        columnDf[0] = {
            ...columnDf[0],
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true
        };
        this.columnDefs = columnDf;
        this.columnDefs1 = columnDf;
    }

    generatePivotColumns(data: any[]) {
        if (this.clCode == 'MX') {
            if (data.length == 0) {
                alert('Data is not available in database');
            }
        }

        this.columnNameandKey = [];
        let columnDefinitions = [];
        let object = data[0];
        let colValue = [];
        Object.keys(object).map((key) => {
            const keyValue: any = key.toString();
            const valueD = keyValue.substr(keyValue.length - 4);
            const findIndex = colValue.indexOf(key);
            const isFind = this.commaSerrateArray.indexOf(key);
            colValue.push(key);

            let mappedColumn: any = {
                headerName: key,
                field: key,
                filter: true,
                sortable: false,
                width: '100%',
                resizable: true,
                editable: this.isCellEditable(key)
            };

            if (isFind > -1) {
                // if (this.clCode=='MX'){
                mappedColumn.valueFormatter = this.numberWithCommasWithTrunCat;
                // }
                // else{
                //     mappedColumn.valueFormatter = this.numberWithCommasWithTrunCatCL;
                // }
            }
            columnDefinitions.push(mappedColumn);
        });

        this.columnName = _.uniqBy(colValue);
        this.columnNameandKey = columnDefinitions;
        return columnDefinitions;
    }

    bulkUpload() {
        let selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes.length != 0) {
            let selectedData = selectedNodes.map((node) => node.data);
            var dataTosend = {
                data: selectedData,
                id: this.DataDropValue,
                countryCode: this.clCode,
                auditYear: this.auditYear
            };
            const dialogRef = this.dialog.open(BulkUPloadComponent, {
                hasBackdrop: true,
                data: { requestId: dataTosend }
            });
            dialogRef.afterClosed().subscribe((response) => {
                console.log(Object.keys(this.advanceFilterPostBulkUpdateObj).length === 0 && Object.getPrototypeOf(this.advanceFilterPostBulkUpdateObj) === Object.prototype, 'advanceFilterPostBulkUpdateObj');
                if (Object.keys(this.advanceFilterPostBulkUpdateObj).length === 0 && Object.getPrototypeOf(this.advanceFilterPostBulkUpdateObj) === Object.prototype) {
                    // this.loadInitialData();
                    this.apiservice.getPointOfSaleData(this.clCode, this.auditYear, this.monthValue, 0, this.keyName, this.keyOrder, this.DataDropValue).subscribe(
                        (result) => {
                            const dataValue = [];
                            _.each(result.data, (v) => {
                                delete v.PipeLineRunID;
                                delete v.LoadStartTime;
                                delete v.LoadEndTime;
                                delete v.HDR_ZUKRI;
                                delete v.HDR_EBELN;
                                delete v.HDR_NETWR;
                                delete v.HDR_WAERS;
                                delete v.HDR_IPTYP;
                                delete v.ITM_FILE_DATE;
                                //  delete v.YEAR_MONTH;
                                delete v.HDR_BUDAT;
                                delete v.PRSDT;
                                delete v.ZZVAR_WT_IND;
                                delete v.VSUM;
                                delete v.VDRSUM;

                                this.addingCommas(v);
                                dataValue.push(v);
                            });

                            this.rowData = dataValue;
                            this.showLoader = false;
                            this.totalNumber = 0;
                            this.totalNumber = Math.ceil(result.count[0] / result.data.length);
                            this.TotalRecords = result.count[0];
                            this.totalNumberFilter = Math.floor(this.TotalRecords / 150000);
                            if (result.count[0] == result.data.length) {
                                this.nextDisabled = true;
                                this.inputDisabled = true;
                            }
                            // if (this.totalNumberFilter == 0) {
                            //     this.totalNumberFilter = 1
                            // }
                            for (let i = 0; i <= this.totalNumberFilter; i++) {
                                this.exportBtnList.push(i);
                            }

                            const columnDf = this.generateColumns(this.rowData);
                            columnDf[0] = {
                                ...columnDf[0],
                                headerCheckboxSelection: true,
                                headerCheckboxSelectionFilteredOnly: true,
                                checkboxSelection: true
                            };
                            this.columnDefs = columnDf;
                            this.showTable = true;
                            this.showMonthOption(this.DataDropValue);
                            this.showYearOption(this.DataDropValue);
                        },
                        (error) => { }
                    );
                } else {
                    this.showLoader = true;
                    if (this.country_cd === 'CL') {
                        this.apiservice.dataPageFilters(this.advanceFilterPostBulkUpdateObj['payload'], this.advanceFilterPostBulkUpdateObj['tableName'], this.advanceFilterPostBulkUpdateObj['sort'], this.advanceFilterPostBulkUpdateObj['keyWord'], this.advanceFilterPostBulkUpdateObj['keyOrder']).subscribe(
                            (result) => {
                                this.updateDataTable(result);
                            },
                            (error) => {
                                this.showLoader = false;
                                this.openSnackBar('Error fetching data', 'Ok');
                            }
                        );
                    } else {
                        this.apiservice.dataPageFiltersMX(this.advanceFilterPostBulkUpdateObj['payload'], this.advanceFilterPostBulkUpdateObj['tableName'], this.advanceFilterPostBulkUpdateObj['sort'], this.advanceFilterPostBulkUpdateObj['keyWord'], this.advanceFilterPostBulkUpdateObj['keyOrder']).subscribe(
                            (result) => {
                                this.updateDataTable(result);
                            },
                            (error) => {
                                this.showLoader = false;
                                this.openSnackBar('Error fetching data', 'Ok');
                            }
                        );
                    }
                }
            });
        } else {
            alert('Please select the row');
        }
    }

    updateDataTable(result) {
        var tableData = result.data;
        const rowData = [];
        this.btnCount = [];
        this.countOfFilterRecords = result.count[0].Count;
        if (result.count[0].Count == 0) {
            this.openSnackBar('No match found', 'Ok');
        } else {
            this.isAdvanceFilter = true;
            _.each(tableData, (v) => {
                delete v.PipeLineRunID;
                delete v.LoadStartTime;
                delete v.LoadEndTime;
                delete v.HDR_ZUKRI;
                delete v.HDR_EBELN;
                delete v.HDR_NETWR;
                delete v.HDR_WAERS;
                delete v.HDR_IPTYP;
                delete v.ITM_FILE_DATE;
                // delete v.YEAR_MONTH;
                delete v.HDR_BUDAT;
                delete v.PRSDT;
                delete v.ZZVAR_WT_IND;
                delete v.VSUM;
                delete v.VDRSUM;
                this.addingCommas(v);
                rowData.push(v);
            });
            this.rowData = rowData;
            this.totalNumber = Math.ceil(result.count[0].Count / result.data.length);

            this.gridApi.setRowData(this.rowData);
            this.gridApi.hideOverlay();
            this.showLoader = false;
            this.filterPagination = true;
            this.normalExport = 'none';
            this.isNormalExp = false;
            this.APIExport = 'inline-block';
            this.APIExportNormal = 'none';
            this.totalNumberFilter = Math.floor(this.countOfFilterRecords / 150000);
            // if (this.totalNumberFilter == 0) {
            //     this.totalNumberFilter = 1
            // }
            this.hideBulkupdateBtn(this.DataDropValue);
            for (let i = 0; i <= this.totalNumberFilter; i++) {
                this.btnCount.push(i);
            }
        }
    }

    bulkUploadFilter() {
        if (confirm('Are you sure want to update all the ' + this.countOfFilterRecords + ' filtered records?')) {
            //  if (selectedNodes.length != 0) {

            var dataTosend = {
                id: this.DataDropValue,
                countryCode: this.clCode,
                auditYear: this.auditYear,
                filteredData: this.filterPayload
            };
            const dialogRef = this.dialog.open(FilterBulkUploadComponent, {
                hasBackdrop: true,
                data: { requestId: dataTosend }
            });
            dialogRef.afterClosed().subscribe((response) => {
                console.log(Object.keys(this.advanceFilterPostBulkUpdateObj).length === 0 && Object.getPrototypeOf(this.advanceFilterPostBulkUpdateObj) === Object.prototype, 'advanceFilterPostBulkUpdateObj');
                if (Object.keys(this.advanceFilterPostBulkUpdateObj).length === 0 && Object.getPrototypeOf(this.advanceFilterPostBulkUpdateObj) === Object.prototype) {
                    this.loadInitialData();
                } else {
                    this.showLoader = true;
                    if (this.country_cd === 'CL') {
                        this.apiservice.dataPageFilters(this.advanceFilterPostBulkUpdateObj['payload'], this.advanceFilterPostBulkUpdateObj['tableName'], this.advanceFilterPostBulkUpdateObj['sort'], this.advanceFilterPostBulkUpdateObj['keyWord'], this.advanceFilterPostBulkUpdateObj['keyOrder']).subscribe(
                            (result) => {
                                this.updateDataTable(result);
                            },
                            (error) => {
                                this.showLoader = false;
                                this.openSnackBar('Error fetching data', 'Ok');
                            }
                        );
                    } else {
                        this.apiservice.dataPageFiltersMX(this.advanceFilterPostBulkUpdateObj['payload'], this.advanceFilterPostBulkUpdateObj['tableName'], this.advanceFilterPostBulkUpdateObj['sort'], this.advanceFilterPostBulkUpdateObj['keyWord'], this.advanceFilterPostBulkUpdateObj['keyOrder']).subscribe(
                            (result) => {
                                this.updateDataTable(result);
                            },
                            (error) => {
                                this.showLoader = false;
                                this.openSnackBar('Error fetching data', 'Ok');
                            }
                        );
                    }
                }
            });
            // }
            // else {
            //   alert("Please select the row")
            // }
        }
    }

    numberWithCommasWithTrunCat(x) {
        if (x.value != null) {
            const numberValue = x.value;
            //  const convertNumber = Number(Number(numberValue).toFixed(2));
            const val = numberValue;
            return val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
        }
    }
    // numberWithCommasWithTrunCatCL(x) {

    //             if (x.value != null) {
    //                 const numberValue = x.value;
    //                 const convertNumber = Number(Number(numberValue).toFixed(0));
    //                 const val = Math.trunc(convertNumber);
    //                 return val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    //             }

    // }

    dateComparator(date1: string, date2: string) {
        const date1Number = date1 != undefined ? convertIntoDate(date1).getTime() : 0;
        const date2Number = date2 != undefined ? convertIntoDate(date2).getTime() : 0;
        if (date1Number == null && date2Number == null) {
            return 0;
        }
        if (date1Number == null) {
            return -1;
        }
        if (date2Number == null) {
            return 1;
        }
        return date1Number - date2Number;
    }

    generateColumns(data: any[]) {
        if (this.clCode == 'MX') {
            if (data.length == 0) {
                alert('Data is not available in database');
            }
        }
        this.columnNameandKey = [];
        let columnDefinitions = [];
        let object = data[0];
        let colValue = [];
        Object.keys(object).map((key) => {
            const keyValue: any = key.toString();
            const valueD = keyValue.substr(keyValue.length - 4);
            const findIndex = colValue.indexOf(key);
            const isFind = this.commaSerrateArray.indexOf(key);
            colValue.push(key);

            let mappedColumn: any = {
                headerName: this.getTranslation('langtableheaders.' + key),
                field: key,
                filter: true,
                sortable: true,
                width: '100%',
                resizable: true,
                editable: this.isCellEditable(key)
            };

            if (isFind > -1) {
                // if (this.clCode=='MX'){
                mappedColumn.valueFormatter = this.numberWithCommasWithTrunCat;
                // }
                // else{
                //     mappedColumn.valueFormatter = this.numberWithCommasWithTrunCatCL;
                // }
            }
            columnDefinitions.push(mappedColumn);
        });

        this.columnName = _.uniqBy(colValue);
        this.columnNameandKey = columnDefinitions;
        this.showPivot = true;
        return columnDefinitions;
    }

    isCellEditable(key) {
        if (this.auditTypeID === 'PPA_NEW_STORE_INVENTORY') {
            if (key === 'SUPERCTR_AMT' || key === 'SUPERAMA_AMT' || key === 'BODEGA_AMT' || key === 'MI_BODEGA_AMT' || key === 'BAE_AMT' || key === 'SAMS_AMT' || key === 'IVA_PCT' || key === 'IEPS_PCT') {
                return true;
            }
        }
        return false;
    }

    addNewRow() {
        // var dataTosend = { id: this.auditTypeID, countryCode: this.clCode };
        // const dialogRef = this.dialog.open(AddRowDataComponent, {
        //     width: '200',
        //     hasBackdrop: true,
        //     data: { requestId: dataTosend, rowData: this.columnName }
        // });

        let selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes.length != 0) {
            if (selectedNodes.length == 1) {
                let selectedData = selectedNodes.map((node) => node.data);

                var dataTosend = { data: selectedData, id: this.auditTypeID, countryCode: this.clCode };
                const dialogRef = this.dialog.open(AddRowDataComponent, {
                    hasBackdrop: true,
                    data: { requestId: dataTosend, rowData: this.columnName }
                });
                dialogRef.afterClosed().subscribe((result) => {
                    // if (result === 1) {
                    //     this.refreshDataValue();
                    // }
                });
            } else {
                alert('Please select only one row');
            }
        } else {
            alert('Please select the row');
        }
    }

    editRow(action) {
        console.log(action)
        let selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes.length != 0) {
        //    if ( (selectedNodes.length == 1 && action=='duplicate') || (action=='edit') )  {
                let selectedData = selectedNodes.map((node) => node.data);

                var dataTosend = { data: selectedData, id: this.auditTypeID, countryCode: this.clCode };
                const dialogRef = this.dialog.open(EditRowDataComponent, {
                    hasBackdrop: true,
                    data: { action: action, requestId: dataTosend, rowData: this.columnName }
                });
                dialogRef.afterClosed().subscribe((result) => {
                    // if (result === 1) {
                    //     this.refreshDataValue();
                    // }
                });
            // } else {
            //     alert('Please select only one row');
            // }
        } else {
            alert('Please select the row');
        }
    }

    getTranslation(str) {
        const currentLang = this.translate.currentLang;
        let returnValue;
        this.translate.get(str).subscribe((data) => {
            returnValue = data;
        });

        if (returnValue === undefined) {
            return this.translate.translations.en[str];
        } else {
            return returnValue;
        }
    }

    filterNameConditions(tableName) {
        if (tableName == 'PPA_CL_COOPS_CURRENCY_CONV_T' || tableName == 'PPA_CL_NEW_STORE_LIST_M' || tableName == 'PPA_NEW_STORE_LIST_M') {
            this.hideFilter = 'none';
        } else {
            this.hideFilter = 'inline-block';
        }
    }

    hideExport(tableName) {
        if (tableName == 'PPA_CL_COOPS_POS_T' || tableName == 'PPA_CL_COOPS_PURCHASE_ORDER_T') {
            // this.APIExportNormal = "none";
            //this.filterbulkUpdateBtn = "none";
            this.isNormalExp = false;
        } else {
            this.APIExportNormal = 'inline-block';
            this.filterbulkUpdateBtn = 'inline-block';
            this.isNormalExp = true;
        }
    }

    isNormalExp = true;

    downloadFile(data: any, count) {
        let title = 'my file';
        let blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
        });
        let filename = this.DataDropValue + '_' + count + '.xlsx';
        let filecontent = blob;
        fs.saveAs(blob, filename);
        this.showLoader = false;
        var url = window.URL.createObjectURL(fs);
        var pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert('Please disable your Pop-up blocker and try again.');
        }
    }

    // downloadFile(data: any, count) {
    //     let filename = this.DataDropValue + '_' + count + '.xlsx';
    //     let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //     saveAs(blob, filename);
    //     this.showLoader = false;
    // }

    clearFilters() {
        this.filterPayload = {};
        this.showLoader = true;
        this.normalExport = 'block';
        this.isNormalExp = true;
        this.APIExport = 'none';
        this.filterbulkUpdateBtn = 'none';
        this.APIExportNormal = 'inline-block';
        const showArray = this.showArray;
        const findData = showArray.indexOf(this.DataDropValue);
        if (findData > -1) {
            this.pageNumber = 0;
            this.keyName = this.setKeyNameForMX();

            this.apiservice.getPointOfSaleData(this.clCode, this.auditYear, this.monthValue, this.pageNumber, this.keyName, this.keyOrder, this.DataDropValue).subscribe(
                (result) => {
                    this.showLoader = false;
                    const dataValue = [];

                    _.each(result.data, (v) => {
                        delete v.PipeLineRunID;
                        delete v.LoadStartTime;
                        delete v.LoadEndTime;
                        delete v.HDR_ZUKRI;
                        delete v.HDR_EBELN;
                        delete v.HDR_NETWR;
                        delete v.HDR_WAERS;
                        delete v.HDR_IPTYP;
                        delete v.ITM_FILE_DATE;
                        //  delete v.YEAR_MONTH;
                        delete v.HDR_BUDAT;
                        delete v.PRSDT;
                        delete v.ZZVAR_WT_IND;
                        delete v.ZZ_SAMS_CATG;
                        delete v.ZZ_SAMS_SUBCATG;
                        delete v.VSUM;
                        delete v.VDRSUM;
                        this.addingCommas(v);
                        dataValue.push(v);
                    });

                    this.rowData = dataValue;
                    this.totalNumber = 0;
                    this.totalNumber = Math.ceil(result.count[0] / result.data.length);
                    if (result.count[0] == result.data.length) {
                        this.nextDisabled = true;
                        this.inputDisabled = true;
                    }
                    const columnDf = this.generateColumns(this.rowData);
                    columnDf[0] = {
                        ...columnDf[0],
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        checkboxSelection: true
                    };
                    this.columnDefs = columnDf;
                },
                (error) => {
                    this.showLoader = false;
                }
            );
        } else {
            this.pageNumber = 0;
            this.apiservice.getDataTable(this.clCode, this.DataDropValue, this.pageNumber, this.auditYear, this.monthValue, this.keyName, this.keyOrder).subscribe(
                (result) => {
                    const dataValue = [];
                    _.each(result.data, (v) => {
                        delete v.PipeLineRunID;
                        delete v.LoadStartTime;
                        delete v.LoadEndTime;
                        delete v.FILEYEAR;
                        delete v.FILEDATE;
                        delete v.INCLUDE;
                        delete v.FILE_DATE;

                        delete v.HDR_ZUKRI;
                        delete v.HDR_EBELN;
                        delete v.HDR_NETWR;
                        delete v.HDR_WAERS;
                        delete v.HDR_IPTYP;
                        delete v.ITM_FILE_DATE;
                        // delete v.YEAR_MONTH;
                        delete v.HDR_BUDAT;
                        delete v.PRSDT;
                        delete v.ZZVAR_WT_IND;
                        delete v.VSUM;
                        delete v.VDRSUM;

                        this.addingCommas(v);
                        dataValue.push(v);
                    });
                    this.showLoader = false;
                    this.rowData = dataValue;
                    this.totalNumber = 0;
                    this.totalNumber = Math.ceil(result.count[0] / result.data.length);
                    if (result.count[0] == result.data.length) {
                        this.nextDisabled = true;
                        this.inputDisabled = true;
                    }
                    const columnDf = this.generateColumns(this.rowData);
                    columnDf[0] = {
                        ...columnDf[0],
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        checkboxSelection: true
                    };
                    this.columnDefs = columnDf;
                },
                (error) => {
                    this.showLoader = false;
                }
            );
        }
        this.normalExport = 'block';
    }

    dataDate = ['GODate', 'LastUpdatedDate'];

    addingCommas(v) {
        let self = this;
        for (var key in v) {
            const value = v[key];
            const keyValue: any = key.toString();
            const valueD = keyValue.substr(keyValue.length - 4);
            if (valueD.toLowerCase() === 'date') {
                const dataValue = value;
                if (dataValue != undefined && dataValue != null && dataValue != 0) {
                    if (dataValue.search('-') <= -1) {
                        v[key] = dataValue;
                    } else {
                        const dateArray = dataValue.split('-');
                        const dateString = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
                        const dateObj = new Date(dateString);
                        v[key] = moment(dateObj).format('DD-MM-YYYY');
                    }
                } else {
                    v[key] = value;
                }
            }
            if (!isNaN(value)) {
                if (valueD == 'ID') {
                    v[key] = value;
                } else {
                    v[key] = value;
                }
            } else {
                v[key] = value;
            }
        }
        return v;
    }
}

var filterParams = {
    suppressAndOrCondition: false,
    filterOptions: ['equals', 'contains', 'startsWith', 'endsWith', 'notEqual', 'notContains', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
    buttons: ['reset', 'apply'],
    closeOnApply: true,
    defaultJoinOperator: 'OR',
    pagination: false
};

function convertIntoDate(dateValue) {
    if (dateValue != null && dateValue != undefined) {
        const dateArray = dateValue.split('-');
        const dateString = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
        const dateObj = new Date(dateString);
        return dateObj;
    } else {
        return null;
    }
}
