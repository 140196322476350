import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { apiService } from 'src/app/_services/app.service';
import { EditDetails } from '../../../_models/editDetails';
import { TranslateService } from '@ngx-translate/core';

import _ from 'lodash';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
    requestId: any;
}

@Component({
    selector: 'app-miscellaneous-pr',
    templateUrl: './miscellaneous-pr.component.html',
    styleUrls: ['./miscellaneous-pr.component.css']
})
export class MiscellaneousPRComponent implements OnInit {
    pageID: any;
    ccCode: any;
    AllDropdowns: any;
    auditTypeID: any;
    allowTypeDropDown: any;
    AuditorIdDropdown = [];
    releaseDropDown = [];
    privateLabelList = [];
    qr_statusList = [];
    accountDepartmentList = [];
    auditYearsData = [];
    formatList = [];
    auditorList: any;
    username: any;
    showLoader = false;
    currentDateTime;

    constructor(public dialogRef: MatDialogRef<MiscellaneousPRComponent>, @Inject(MAT_DIALOG_DATA) public datas: DialogData, public router: Router, public activatedRoute: ActivatedRoute, private datePipe: DatePipe, private formBuilder: FormBuilder, private apiservice: apiService, public translate: TranslateService, private _snackBar: MatSnackBar) {
        this.currentDateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }


    addRowData: EditDetails;
    @Output()
    addForm: FormGroup = new FormGroup({
        RELEASE_NUM: new FormControl(''),
        AUDIT_YEAR: new FormControl(2021),
        LEGACY_CATEGORY: new FormControl('', Validators.required),
        RUT: new FormControl('', Validators.required),
        DEPT: new FormControl('', Validators.required),
        FORMAT_TYPE: new FormControl('', Validators.required),
        AMOUNT_DUE: new FormControl(null, Validators.required),
        TOTAL_DEDUCTION: new FormControl(null, Validators.required),
        PENDING_DUE: new FormControl(null, Validators.required),
        IVA_DUE: new FormControl(null, Validators.required),
        IVA_PCT: new FormControl(null, Validators.required),
        IEPS_DUE: new FormControl(null, Validators.required),
        IEPS_PCT: new FormControl(null, Validators.required),
        TOTAL_PENDING: new FormControl(null, Validators.required),
        AUDITOR_ID: new FormControl('', Validators.required),
        REVISION_DATE: new FormControl('', Validators.required),
        AUDITOR_STATUS: new FormControl('', Validators.required),
        AGREEMENT_NUM: new FormControl('', Validators.required),
        NOTES: new FormControl('', Validators.required),
        SUPPLIER_NAME: new FormControl('', Validators.required),
        SUPPLIER_NUM: new FormControl('', Validators.required),
        NEW_CATEGORY: new FormControl('', Validators.required),
        CHANGE_USER: new FormControl('', Validators.required)
    });
    addFormMx: FormGroup = new FormGroup({


        CHANGE_USER: new FormControl('', Validators.required),
        RELEASE_NUM: new FormControl(''),
        SUPPLIER_NAME: new FormControl('', Validators.required),
        DEPT: new FormControl('', Validators.required),
        FORMAT_TYPE: new FormControl('', Validators.required),
        ALLOW_TYPE: new FormControl('', Validators.required),
        PENDING_DUE: new FormControl(null, Validators.required),
        TOTAL_PENDING: new FormControl(null, Validators.required),
        AUDITOR_ID: new FormControl('', Validators.required),
        AUDITOR_STATUS: new FormControl('', Validators.required),
        AGREEMENT_NUM: new FormControl('', Validators.required),
        NOTES: new FormControl('', Validators.required),
        IEPS_PCT: new FormControl(null, Validators.required),
        IEPS_DUE: new FormControl(null, Validators.required),
        IVA_PCT: new FormControl(null, Validators.required),
        IVA_DUE: new FormControl(null, Validators.required),
        AUDIT_YEAR: new FormControl(2021),
        VENDOR_NUM: new FormControl('', Validators.required),
        REVIEW_DATE: new FormControl('', Validators.required),
        SAP_NUM: new FormControl('', Validators.required),
        AMOUNT_DEDUCTED: new FormControl(null, Validators.required),
        EARNED: new FormControl(null, Validators.required),















    });

    ngOnInit(): void {
        this.pageID = this.datas.requestId.id;
        this.ccCode = this.datas.requestId.countryCode;
        const prompt = JSON.parse(localStorage.getItem('userinfo'));
        this.username = prompt.username;
        this.getAllDropdownValues(this.ccCode);
        this.getAllAuditYrData(this.pageID);
        this.getAuditorList();
    }

    getAllDropdownValues(CLCode) {
        this.apiservice.getClaimsDropdowns(CLCode, this.pageID).subscribe((response) => {
            this.AllDropdowns = response;

            for (var i = 0; i < this.AllDropdowns.length; i++) {
                // if (this.AllDropdowns[i].Lookup_Code == "AUDITOR_ID") {
                //   this.AuditorIdDropdown.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                // }
                if (this.AllDropdowns[i].Lookup_Code == 'REL') {
                    this.releaseDropDown.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'PRIVATE_LBL') {
                    this.privateLabelList.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'QR_STATUS') {
                    this.qr_statusList.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'CLAIM STATUS') {
                    this.qr_statusList.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }

                if (this.AllDropdowns[i].Lookup_Code == 'ACCOUNT_DEPARTMENT') {
                    this.accountDepartmentList.push(this.AllDropdowns[i].Lookup_Values);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'FORMAT') {
                    this.formatList.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
            }
        });
    }

    getAllAuditYrData(pageId) {
        this.apiservice.getAllAuditYr(pageId, this.ccCode).subscribe((result: any) => {
            this.auditYearsData = result;
            console.log("auditYearsData", this.auditYearsData)
        });
    }

    getAuditorList() {
        this.apiservice.getAuditorList(this.pageID, this.ccCode).subscribe((response) => {
            this.auditorList = response;
        });
    }

    openSnackBar(message: string, action: string) {
        // this._snackBar.open(message, action);
        this._snackBar.open(message, action, {
            duration: 3000,
            verticalPosition: 'top'
        });
    }

    onSubmit() {
        this.showLoader = true;


        if (this.ccCode == 'MX') {
            this.addFormMx.value.RECORD_ID = null;
            this.addFormMx.value.CREATED_DATE = this.currentDateTime;
            this.addFormMx.value.CREATED_BY = this.username;
            this.addFormMx.value.LAST_UPDATED_DATE = this.currentDateTime;
            this.addFormMx.value.LAST_UPDATED_BY = this.username;
            this.addFormMx.value.INSERTED = null;
            this.addFormMx.value.Claim_Number = null;
            this.addFormMx.value.VERSION = null;
            this.addFormMx.value.ALLOW_TYPE = this.pageID;
            this.apiservice.addNewRow(this.addFormMx.value, this.pageID, this.ccCode).subscribe((response) => {
                this.openSnackBar('New row added successfully', 'Ok');
                this.dialogRef.close();
                this.showLoader = false;
                this.router.navigate(['managePR/' + this.ccCode + '/' + this.pageID]);
                this.Refresh();
            });
        }
        else {
            this.addForm.value.ALLOW_TYPE = this.pageID;
            this.addForm.value.APP_CLAIM_NUMBER = null;
            this.addForm.value.CREATED_BY = this.username;
            this.addForm.value.INSERTED = null;
            this.apiservice.addNewRow(this.addForm.value, this.pageID, this.ccCode).subscribe((response) => {
                this.openSnackBar('New row added successfully', 'Ok');
                this.dialogRef.close();
                this.showLoader = false;
                this.router.navigate(['managePR/' + this.ccCode + '/' + this.pageID]);
                this.Refresh();
            });
        }


    }
    Refresh() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./managePR/' + this.ccCode + '/' + this.pageID], { relativeTo: this.activatedRoute });
    }

    resetMiscForm() {
        this.addForm.reset();
    }
    resetMiscFormMX() {
        this.addFormMx.reset();
    }
}
