import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError as observableThrowError, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { catchError } from 'rxjs/operators';
import { LoginUser, Role } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    domain = environment.ppa;
    private currentUserSubject: BehaviorSubject<LoginUser>;
    public currentUser: Observable<LoginUser>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<LoginUser>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    retrunval: string = '';
    public val: any[] = [];
    public get currentUserValue(): LoginUser {
        // console.log('i am here')
        // console.log(this.currentUserSubject.value)
        return this.currentUserSubject.value;
    }

    login(username: string, name) {
        return this.http.get<any>(this.domain + '/getUserRoleById/' + username).pipe(
            map((data) => {
                this.val.push(data);
                let user;
                if (data[0].ROLE_NAME) {
                    let r: Role;
                    if (data[0].ROLE_NAME.toLowerCase() == 'auditor') {
                        r = Role.Auditor;
                    }
                    if (data[0].ROLE_NAME.toLowerCase() == 'ppamanager') {
                        r = Role.Manager;
                    }
                    if (data[0].ROLE_NAME.toLowerCase() == 'applicationAdmin') {
                        r = Role.ApplicationAdmin;
                    }
                    if (data[0].ROLE_NAME.toLowerCase() == 'qaadmin') {
                        r = Role.QAAdmin;
                    }
                    if (data[0].ROLE_NAME.toLowerCase() == 'admin') {
                        r = Role.Admin;
                    }
                    if (data[0].ROLE_NAME.toLowerCase() == 'systemUser') {
                        r = Role.SystemUser;
                    }

                    user = {
                        id: parseInt(username),
                        username: username,
                        name: name,
                        role: r,
                        token: 'fake-jwt-token.' + username
                    };
                }

                // let user ={
                //     id: 5, username: '703130886', name: 'User', role: Role.QAAdmin,token: 'fake-jwt-token.1'
                // }
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }

                return data;
            })
            // catchError(this.errorHandler));
        );
    }

    // getrole(username){
    //     return this.http.get<any>('https://gchdwmteuswap02pythonapi.azurewebsites.net/getUserRoleById/'+username)
    //         .pipe(
    //         catchError(this.errorHandler));

    // }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    errorHandler(error: HttpErrorResponse) {
        return observableThrowError(error.message || 'server Error');
    }
}
