import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { apiService } from 'src/app/_services/app.service';
import { EditDetails } from '../../../_models/editDetails';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


export interface DialogData {
    requestId: any;
}
@Component({
    selector: 'app-edit-data',
    templateUrl: './edit-data.component.html',
    styleUrls: ['./edit-data.component.css']
})
export class EditDataComponent implements OnInit {
    // editPage: EditDetails;

    saveDisabled = false;
    editData = [];
    aucountdept: any;
    agreemntNO: any;
    alllowType: any;
    amountReceived: any;
    appClaimNo: any;
    auditorID: any;
    auditYr: any;
    category: any;
    changeUSer: any;
    comments: any;
    dateAudited: any;
    dealAgrementNo: any;
    debitedAMount: any;
    earned: any;
    endDAte: any;
    fixedAmontAgremnt: any;
    formatType: any;
    legacyCategory: any;
    Calculated: any;
    Audit_Year: any;
    privateLabel: any;
    qrStatus: any;
    recordID: any;
    releaseNum: any;
    rut: any;
    startDate: any;
    supplierNAme: any;
    supplierNO: any;
    volumn: any;
    yearvolumn: any;
    createdBy: any;
    created_Date: any;
    pageID: any;
    pageID2: any;
    ccCode: any;
    sendEditedData: any;
    incresedVersion: number;
    users: any;
    AllDropdowns: any;
    AllDropdownsMX: any;
    AuditorIdDropdown = [];
    releaseDropDown = [];
    privateLabelList = [];
    formatLabelList = [];
    qr_statusList = [];
    accountDepartmentList = [];
    rowDataObject: any;
    updatingRowValue = [];
    showLoader = false;
    valueEdited = false;
    showForm = true;
    singleAuditYear;
    isShowFormateType = true;
    currentValueObject;
    changeInputValue: Subject<any> = new Subject<any>();
    multipleEditDataArray = [];
    currentChangeItems = [];
    payloadToApi;
    test = 'IMPACT_MU/MD';
    //MX lists
    MXGROUPS = [];
    MXAUDITORS = [];
    MXSTATUS = [];
    MXDEP = [];
    MX_ALL_ITEMS = ['Yes', 'No'];
    MDStatusOptions = [
        'Pending Analysis',
        'Claim',
        'Well charged',
        'Client Unilateral',
        'No support found',
        'Difference in favor Supplier',
        'Manual Calculation',
        'Not relevant amount',
        'Claim other Firm'
      ];
    MXRootCauseOptions = [
        'Allowance charged minor than suppliers redemption',
        'Allowance charged major than suppliers redemption',
        'Total Omission',
        'Time period charged minor than event in POS',
        'Time period charged major than event in POS',
        'Agreement Rejected by support opportunity',
        'Provider not longer in business with WM',
        'Vendor has a mapping promotional Different',
        'OK Well Done'
        ];

    MXMarkdownTypeOptions = [
        'Rollback',
        'Price Protection (Clearance)',
        'Linksaves',
        'Coupon',
        'Free merchandise',
        'Promotions',
        'Others'
      ];
    FORMAT_SEQUENCE = [
        {
            FORMAT: 'WM SUPERCENTER',
            SEQ: 0
        },
        {
            FORMAT: 'SUPERAMA',
            SEQ: 4
        },
        {
            FORMAT: 'MI BODEGA',
            SEQ: 2
        },
        {
            FORMAT: 'BODEGA',
            SEQ: 2
        },
        {
            FORMAT: 'BODEGA AURRERA EXPRESS',
            SEQ: 2
        },
        {
            FORMAT: 'SAMS',
            SEQ: 0
        },
        {
            FORMAT: 'ECOMMERCE',
            SEQ: 6
        }
    ];

    SEQUENCE_FORMAT = [
        {
            FORMAT: 'WALMART',
            SEQ: 0
        },
        {
            FORMAT: 'WALMART',
            SEQ: 1
        },
        {
            FORMAT: 'BODEGA',
            SEQ: 2
        },
        {
            FORMAT: 'BODEGA',
            SEQ: 3
        },
        {
            FORMAT: 'SUPERAMA',
            SEQ: 4
        },
        {
            FORMAT: 'SUPERAMA',
            SEQ: 5
        },
        {
            FORMAT: 'ECOMMERCE',
            SEQ: 6
        },
        {
            FORMAT: 'ECOMMERCE',
            SEQ: 7
        },
        {
            FORMAT: 'BODEGA',
            SEQ: 8
        },
        {
            FORMAT: 'WM/BD/SU',
            SEQ: 9
        },
        {
            FORMAT: 'SAMS',
            SEQ: 18
        }
    ];
    saveLoader: boolean;
    currentChangedValue;
    constructor(public dialogRef: MatDialogRef<EditDataComponent>, @Inject(MAT_DIALOG_DATA) public datas: DialogData, public router: Router, public datepipe: DatePipe, public activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private apiservice: apiService) {
        this.changeInputValue.pipe(debounceTime(2000)).subscribe((obj) => {
            this.changeInModal(obj);
        });
    }
    addForm: FormGroup;
    editPage: EditDetails;
    allowTypeDropDown: any;
    rowData = [];
    editableRowData = [];
    auditorList = [];
    increasedVersion: number;
    finalPayload = [];
    numberArrya = [
        'FIXED_AMOUNT_AGREEMENT',
        'Calculated',
        'TOTAL_DUE',
        'AMT_DEDUCTED',
        'AMOUNT_DUE',
        'UFAMT',
        'STORE_NBR',
        'OTHER_DEDUCTION',
        'FIXED_AMT',
        'YEAR_VOLUME',
        'VOLUME',
        'EARNED',
        'AMOUNT_RECEIVED',
        'DEBITED_AMOUNT',
        'AMOUNT',
        'AMOUNT_FOR_THE_PERIOD',
        'AMT_FOR_THE_PERIOD',
        'AMOUNT_DEDUCTED',
        'AMOUNT_DUE',
        'TOTAL_DEDUCTION',
        'PENDING_DUE',
        'IVA_DUE',
        'TOTAL_PENDING',
        'TOTAL_SALES',
        'STORE',
        'VEN_NUM',
        'SEQ',
        'AUDIT_YEAR',
        'DEPARTMENT',
        'DPT_CAT',
        'SAP_NUMBER',
        'ALLOW_EARNED',
        'AMTDEDUCTED',
        'COOP_DEDUCTED',
        'AMTDUE',
        'IEPS',
        'IVA',
        'IEPS_PERCENT',
        'IVA_PERCENT',
        'SHIP_CHK',
        'TOTAL_SHIPS',
        'APP_CLM_NBR',
        'VERSION',
        'RECORD_ID',
        'REL',
        'COOP_NBR',
        'Supplier Number',
        'SAP_VENDOR_NUMBER',
        'COOP_TYPE',
        'Dapartment',
        'DEPT',
        'BASE_COOP_AMT',
        'Adjustments',
        'BASE_AMT_DUE',
        'APP_CLAIM_NUMBER',
        'POS_SALES_QTY',
        'ALL_BODEGA_POS_QTY',
        'AMS_RATE',
        'AMS_QTY',
        'AMS_SALES_AMT',
        'POS_SALES_AMT',
        'ALL_BODEGA_POS_AMT',
        'IEPS_PCT',
        'IVA_PCT',
        'AMT_DUE',
        'IEPS_DUE',
        'ACCRUAL_AMT',
        'GROUP',
        'VENDOR_NBR',
        'SAP_VENDOR',
        'ENTERED_AMT',
        'OTHER_DEDUCT',
        'IEPS_AMOUNT',
        'IVA_AMOUNT',
        'CLAIM_NUMBER',
        'NO_OF_DEDUCTION',
        'TOTAL_ORD_QTY',
        'TOTAL_RCV_QTY',
        'TOTAL_RCV_QTY',
        'FILL_RATE_CALC_PCT',
        'TOTAL_RETAIL_AMT_SHORT',
        'TOTAL_MARGIN_LOST',
        'FILL_RATE_PCT',
        'TOTAL_EARNED',
        'TOTAL_AMT_PAID',
        'TOTAL_PENDING',
        'INTERNAL_RATE'
    ];
    dataArrayMX = ['RELEASE_NUM', 'ALL_ITEMS', 'ASSIGNED_USERNAME', 'DEPT', 'ACCOUNT_DEPARTMENT', 'ALLOW_TYPE', 'PRIVATE_LABEL', 'QR_STATUS', 'AUDITOR_ID', 'FORMAT_TYPE', 'GROUPS', 'AUDITOR', 'Department', 'STATUS', 'StatusCode', 'STATUSCODE', 'AUDITOR_STATUS', 'MD_STATUS', 'MD_ROOTCAUSE', 'MARKDOWN_TYPE', 'AUDITOR_NAME'];
    dataArray = ['RELEASE_NUM', 'DEPT', 'ACCOUNT_DEPARTMENT', 'PRIVATE_LABEL', 'QR_STATUS', 'AUDITOR_ID', 'FORMAT_TYPE', 'ASSIGNED_USERNAME'];
    @Output()
    counter(i: number) {
        return new Array(99);
    }
    //createSchemaContact = new EventEmitter<EditDetails>();

    ngOnInit() {
        this.multipleEditDataArray = [];
        this.editData = [];
        this.saveDisabled = false;
        // this.pageID = this.activatedRoute.snapshot.params["ID"];
        this.pageID = this.datas.requestId.id;
        console.log(this.pageID)
        if (this.pageID == 'Scan Allowances' || this.pageID == 'Fill Rate' || this.pageID == 'MSI') {
            this.dataArrayMX.push('FORMAT');
        }
        if (this.pageID == 'Volume Allowances' || this.pageID == 'MSI') {
            this.dataArrayMX.push('SEQ_NO');
        }
        this.ccCode = this.datas.requestId.countryCode;
        this.editData = JSON.parse(JSON.stringify(this.datas.requestId.data));
        this.getAllowTypesDropDown();
        this.getAuditorList();

        // this.generatingOldData(this.editData[0]);
        this.getAllDropdownValues(this.ccCode, this.pageID);
        this.getAllDropdownValuesMX(this.pageID);
        const fistIndexValue = this.editData[0];
        this.singleAuditYear = fistIndexValue.AUDIT_YEAR;
        localStorage.setItem('initial_value', JSON.stringify(fistIndexValue));
        this.rowDataObject = fistIndexValue;
        this.isShowFormateType = this.rowDataObject.ALLOW_TYPE === 'REPO' ? true : false;

        this.rowData = this.generatedColumnObject([this.editData[0]]);
        // this.editableRowData = this.getEditableColumn([this.editData[0]]);
        const objectFrom = {};
        _.each(this.rowData, (obj) => {
            const v = obj.key;

            const getData = v.replaceAll('_', ' ');
            const getAry = getData.split(' ');
            const findDate = _.find(getAry, (v) => {
                return v.toLowerCase() === 'date';
            });
            const findNumber = _.find(this.numberArrya, (item) => item === v);
            if (findNumber) {
                const valueNum = this.convertStringToNumberValue(this.rowDataObject[v]);
                objectFrom[v] = [{ value: valueNum, disabled: this.getEditableColumn(v) }];
            } else if (findDate || v == 'START_DATE' || v === 'END_DATE' || v === 'GO_DATE' || v === 'INV_DATE' || v === 'CLEAR_DATE' || v == 'MIN_SALES' || v === 'MAX_SALES' || v == 'Valid_From' || v == 'Valid_To' || v == 'OPEN_DATE' || v == 'POSTING_DATE' || v == 'INITIAL_DATE' || v == 'FINAL_DATE' || v == 'DATE_AUDITED' || v == 'CREATION_DATE' || v == 'DATE_AUDITED' || v == 'COMPLETE_DATE' || v == 'COMPLETE_DT' || v == 'Complete_DT' || v == 'CLEARING_DT') {
                const dateValue = this.rowDataObject[v];
                if (dateValue != null) {
                    objectFrom[v] = [
                        {
                            value: dateValue,
                            disabled: this.getEditableColumn(v)
                        }
                    ];
                } else {
                    objectFrom[v] = [{ value: '', disabled: this.getEditableColumn(v) }];
                }
            } else {
                objectFrom[v] = [
                    {
                        value: this.rowDataObject[v],
                        disabled: this.getEditableColumn(v)
                    }
                ];
            }
        });
        this.addForm = this.formBuilder.group(objectFrom);
        this.currentValueObject = JSON.parse(localStorage.getItem('initial_value'));
    }

    isFloat(n) {
        return parseFloat(n.match(/^-?\d*(\.\d+)?$/)) > 0;
    }

    getDateFormateValue(dateValue) {
        if (dateValue != null && dateValue != '') {
            const convertInitialDate = new Date(dateValue);
            const getTime = convertInitialDate.getTime();
            if (Number.isInteger(getTime)) {
                return moment.utc(convertInitialDate).format('YYYY-MM-DD');
            } else {
                const dateArray = dateValue.split('-');
                const dateString = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
                const dateObj = new Date(dateString);
                return moment.utc(dateObj).format('YYYY-MM-DD');
            }
        } else {
            return null;
        }
    }

    convertDateValueInString(dateValue) {
        if (this.editData.length > 1) {
            if (dateValue != null) {
                const convertInitialDate = new Date(dateValue);
                const getTime = convertInitialDate.getTime();
                if (Number.isInteger(getTime)) {
                    return dateValue;
                } else {
                    const dateArray = dateValue.split('-');
                    const dateString = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
                    const dateObj = new Date(dateString);
                    return moment(dateObj).format('YYYY-MM-DD');
                }
            } else {
                return null;
            }
        } else {
            const convertInitialDate = new Date(dateValue);
            const getTime = convertInitialDate.getTime();
            if (Number.isInteger(getTime)) {
                return dateValue;
            } else {
                const dateArray = dateValue.split('-');
                const dateString = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
                const dateObj = new Date(dateString);
                return moment(dateObj).format('YYYY-MM-DD');
            }
        }
    }

    convertDateToSTring(dateValue) {
        if (dateValue != '' && dateValue != null) {
            const dateArray = dateValue.split('-');
            const dateString = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
            // const dateObj = new Date(dateString)
            const dateValString = moment(dateString).format('YYYY-MM-DD');
            return dateValString;
        } else {
            return null;
        }
    }

    convertStringtoDateValueForVolumeAllowance(date) {
        if (date != '' && date != null) {
            /* new Date('21-03-1998') returns an invalid date as it will work on ISO formats */
            const dateObj = new Date(date.toString().split('-'));
            return moment(dateObj).format('YYYY-MM-DD');
        } else {
            return null;
        }
    }

    saveUpdatedValue(v) {
        console.log(v)
        const findIndex = this.updatingRowValue.indexOf(v);
        if (findIndex > -1) {
            this.updatingRowValue.splice(findIndex, 1);
        } else {
            this.updatingRowValue.push(v);
        }
        console.log(this.updatingRowValue)
    }

    getEditableColumn(data) {
        let vColumn;
        vColumn = [];
        if (this.ccCode === 'MX') {
            if (this.pageID === 'New Stores') {
                vColumn = ['AUDIT_YR', 'STORE', 'DPT_CAT', 'DEPARTMENT', 'FORMAT', 'VENDOR_NBR', 'ALLOW_EARNED', 'AMTDEDUCTED', 'COOP_DEDUCTED', 'IEPS_PCT', 'IVA_PCT', 'DEDUCT_TYPE', 'COOP_NUMBER', 'DOC_NBR', 'INV_DATE', 'CLEARING_DOC', 'CLEAR_DATE', 'AUDITOR', 'STATUS', 'COMPLETE_DATE', 'AUDITOR_COMMENTS', 'CLAIM_NUMBER', 'TEXT'];
            } else if (this.pageID === 'Scan Allowances') {
                vColumn = ['FORMAT', 'AMS_RATE', 'AUDITOR', 'Adjustments', 'Agreement_Nbr', 'Agreement_Request_Status', 'Agreement_Request_Nbr', 'COOP_Number', 'Comments', 'Complete_DT', 'DEPARTMENT', 'GROUPS', 'Item_Nbr', 'StatusCode', 'VENDOR_NBR', 'Valid_From', 'Valid_To', 'IVA_PCT', 'IEPS_PCT'];
            } else if (this.pageID === 'Volume Allowances') {
                vColumn = ['GROUP', 'TIER', 'DEAL', 'ALL_ITEMS', 'START_DATE', 'END_DATE', 'PCT', 'DEPARTMENT', 'SEQ_NO', 'AUDITOR', 'AUDITOR_COMMENTS', 'COMPLETE_DATE', 'STATUS', 'OTHER_DED', 'IEPS_PCT', 'IVA_PCT'];
            } else if (this.pageID === 'UN-DEDUCTED COOPS') {
                vColumn = ['REL', 'SUPPLIER_NUM', 'Agreement_Request_Nbr', 'COOP_NBR', 'FORMAT', 'SEQ', 'ADJUSTMENTS', 'IVA_PCT', 'IEPS_PCT', 'AUDITOR', 'AUDITOR_COMMENT', 'COMPLETE_DT', 'STATUS', 'BASE_COOP_AMT'];
            } else if (this.pageID === 'Advertising') {
                vColumn = ['DEPT', 'GROUP', 'VENDOR_NBR', 'BRAND', 'DESCRIPTION_PAG', 'WEBCONTENT', 'DIV', 'NUM_PUBLICATION', 'TYPE_COLLECTION', 'ALLOW_EARNED', 'ENTERED_AMT', 'OTHER_DEDUCT', 'SAP_DOC_NBR', 'IEPS_PCT', 'IVA_PCT', 'COOP_NBR', 'AUDITOR', 'COMPLETE_DT', 'STATUS', 'NO_OF_DEDUCTION', 'AUDITOR_COMMENT'];
            } else if (this.pageID === 'Fill Rate') {
                vColumn = ['FORMAT', 'STATUS', 'GROUP', 'VENDOR_NBR', 'DEPARTMENT', 'PO_SEQ', 'FILL_RATE_PCT', 'TOTAL_AMT_PAID', 'COOP_NUMBER', 'IEPS_PCT', 'IVA_PCT', 'AUDITOR', 'COMPLETE_DT', 'COMMENTS', 'STATUSCODE'];
            } else if (this.pageID === 'MSI') {
                vColumn = ['OTHER_DEDUCTIONS', 'MSI_PCT', 'IVA_PCT', 'AUDITOR', 'COMPLETE_DT', 'STATUS', 'AUDITOR_COMMENTS', 'TOTAL_AMT_PAID'];
            } else if (this.pageID === 'Low Price') {
                vColumn = ['RELEASE', 'AUDITOR', 'COMPLETE_DT', 'STATUS', 'COMMENTS'];
            } else if (this.pageID === 'Shortages') {
                vColumn = ['RELEASE', 'AUDITOR', 'COMPLETE_DT', 'STATUS', 'COMMENTS'];
            } else if (this.pageID === 'MISC') {
                vColumn = ['RELEASE_NUM', 'VENDOR_NUM', 'DEPT', 'FORMAT_TYPE', 'ALLOW_TYPE', 'EARNED', 'AMOUNT_DEDUCTED', 'AUDITOR_ID', 'IVA_PCT', 'IEPS_PCT', 'REVIEW_DATE', 'AUDITOR_STATUS', 'AGREEMENT_NUM', 'AGREEMENT_NUM', 'NOTES', 'CHANGE_USER', 'INSERTED'];
            } else if (this.pageID === 'Markdown') {
                vColumn = ['VENDOR_NBR', 'VNDR_NAME','EFFECTIVE_DATE','END_DATE','DEPT','CAT','ITEM_NUM','ITEM1_DESC','BANNER_DESC','POS_SALES','POS_QTY','IMPACT_MU/MD','UNIT_RETAIL','AVG_UNIT_SALE_PRICE','UNIT_COST','COMMERCIAL_MARGE','REAL_MARGE','MARGE_DEVIATION','AMS_AGREEMENT','AGREEMENT_AMOUNT','DIFFERENCE','MD_STATUS','MD_ROOTCAUSE','MARKDOWN_TYPE','EMAIL_SUPPORT','AUDITOR_NAME','AUDITOR_COMMENTS','DUE_DATE','CLAIM_NUMBER','VAT','IEPS','EVENT_DESCRIPTION','RECORD_ID'];
            } else {
                vColumn = this.generatedColumn([this.editData[0]]);
            }
        } else if (this.ccCode === 'CL') {
            if (this.pageID === 'VOLUMES') {
                vColumn = ['RELEASE_NUM', 'SUPPLIER_NUM', 'ALLOW_TYPE', 'DEAL_AGREEMENT_NUM', 'FORMAT_TYPE', 'START_DATE', 'END_DATE', 'ACCOUNT_DEPARTMENT', 'CATEGORY', 'PRIVATE_LABEL', 'PERCENTAGE', 'FIXED_AMOUNT_AGREEMENT', 'AUDITOR_ID', 'DATE_AUDITED', 'QR_STATUS', 'AGREEMENT_NUMBER', 'COMMENTS', 'EARNED', 'AMOUNT_RECEIVED'];
            } else if (this.pageID === 'NEW-STORE') {
                vColumn = ['RELEASE_NUM', 'SUPPLIER_NUM', 'DEPT', 'CATEGORY', 'FIXED_AMT', 'UFAMT', 'OTHER_DEDUCTION', 'AUDITOR_ID', 'QR_STATUS', 'COMPLETED_DATE', 'COMMENTS'];
            } else if (this.pageID === 'UNDED-COOPS') {
                vColumn = ['DATE_AUDITED', 'QR_STATUS', 'NOTES', 'RELEASE_NUM', 'ASSIGNED_USERNAME'];
            } else {
                vColumn = this.generatedColumn([this.editData[0]]);
            }
        }

        const isFind = _.find(vColumn, (item) => item === data);
        if (isFind) {
            return false;
        } else {
            return true;
        }
    }

    changeModalData(obj) {
        this.changeInputValue.next(obj);
    }

    singleDataValue() {
        if (this.pageID === 'VOLUMES') {
            const fromData = this.addForm.getRawValue();
            const percent = fromData.PERCENTAGE;
            const volume = fromData.VOLUME;
            const amtRec = fromData.AMOUNT_RECEIVED;
            const earnedVal = this.percentageCalculated(Number(percent), Number(volume));
            this.addForm.get('EARNED').setValue(earnedVal);
            const fixedAmount = fromData.FIXED_AMOUNT_AGREEMENT;
            const debitedAmount = Number(earnedVal) - Number(amtRec) - Number(fixedAmount);
            this.addForm.get('DEBITED_AMOUNT').setValue(debitedAmount);
            this.addForm.get('CHANGE_USER').setValue('Y');
        } else if (this.pageID === 'NEW-STORE') {
            const fromValue = this.addForm.getRawValue();
            const oldTotalDue = fromValue.AMOUNT_DUE;
            const totalDue = Number(this.convertStringToNumberValue(fromValue.UFAMT)) * Number(fromValue.FIXED_AMT);
            const amountDue = Number(totalDue) + Number(fromValue.AMT_DEDUCTED) + Number(fromValue.OTHER_DEDUCTION);
            console.log(amountDue);
            this.addForm.get('FIXED_AMT').setValue(Number(fromValue.FIXED_AMT));
            this.addForm.get('TOTAL_DUE').setValue(totalDue);
            this.addForm.get('AMOUNT_DUE').setValue(amountDue);
            if (oldTotalDue !== amountDue) {
                this.addForm.get('CHANGE_USER').setValue('Y');
            }
        }
    }

    calculateValue(objectValue) {
        if (this.pageID === 'VOLUMES') {
            const percent = objectValue.PERCENTAGE;
            const volume = this.convertStringToNumberValue(objectValue.VOLUME);
            const amtRec = this.convertStringToNumberValue(objectValue.AMOUNT_RECEIVED);
            const earnedVal = this.percentageCalculated(Number(percent), Number(volume));
            objectValue.EARNED = earnedVal;

            const fixedAmount = objectValue.FIXED_AMOUNT_AGREEMENT;
            const debitedAmount = Number(earnedVal) - Number(amtRec) - Number(fixedAmount);
            if (debitedAmount == null || debitedAmount == undefined) {
                objectValue.DEBITED_AMOUNT = debitedAmount;
            } else {
                objectValue.DEBITED_AMOUNT = debitedAmount.toFixed(2);
            }

            return objectValue;
        } else if (this.pageID === 'NEW-STORE') {
            const totalDue = Number(this.convertStringToNumberValue(objectValue.UFAMT)) * Number(this.convertStringToNumberValue(objectValue.FIXED_AMT));
            const amountDue = Number(totalDue) - Math.abs(Number(this.convertStringToNumberValue(objectValue.AMT_DEDUCTED))) - Number(this.convertStringToNumberValue(objectValue.OTHER_DEDUCTION));
            console.log(amountDue);
            objectValue.TOTAL_DUE = totalDue;
            objectValue.AMOUNT_DUE = amountDue;
            return objectValue;
        }
    }

    updateDatainModal() {
        // console.log(obj)
        // const data = obj.key;
        // this.multipleEditDataArray.push(data);

        console.log("Updating the Modal", this.currentChangedValue)
        let fromValue = this.addForm.getRawValue();
        console.log(fromValue);
        if (this.pageID === 'Scan Allowances' && !this.valueEdited) {
            fromValue = this.addForm.getRawValue();
            this.apiservice.getVendorandSapDetails(fromValue.VENDOR_NBR).subscribe(
                (response1: any) => {
                    if (response1.length == 0) {
                        this.saveDisabled = true;
                        // Reverting to the original data
                        alert('Not a valid combination');

                        this.addForm.patchValue({
                            AUDITOR: this.rowDataObject.AUDITOR,
                            AUDITOR_COMMENTS: this.rowDataObject.AUDITOR_COMMENTS,
                            ALLOW_EARNED: this.rowDataObject.ALLOW_EARNED,
                            AMS_QTY: this.rowDataObject.AMS_QTY,
                            AMS_RATE: this.rowDataObject.AMS_RATE,
                            AMS_SALES_AMT: this.rowDataObject.AMS_SALES_AMT,
                            AMT_DUE: this.rowDataObject.AMT_DUE,
                            AUDIT_YEAR: this.rowDataObject.AUDIT_YEAR,
                            Accrual_AMT: this.rowDataObject.Accrual_AMT,
                            Adjustments: this.rowDataObject.Adjustments,
                            Agreement_Nbr: this.rowDataObject.Agreement_Nbr,
                            Agreement_Request_Nbr: this.rowDataObject.Agreement_Request_Nbr,
                            Agreement_Request_Status: this.rowDataObject.Agreement_Request_Status,
                            COOP_NUMBER: this.rowDataObject.COOP_Number,
                            Claim_Number: this.rowDataObject.Claim_Number,
                            Comments: this.rowDataObject.Comments,
                            Complete_DT: this.rowDataObject.Complete_DT,
                            DIVISION: this.rowDataObject.DIVISION,
                            DEPARTMENT: this.rowDataObject.DEPARTMENT,
                            FORMAT: this.rowDataObject.FORMAT,
                            GROUPS: this.rowDataObject.GROUPS,
                            ID: this.rowDataObject.ID,
                            INCLUDE: 'Y',
                            IEPS_DUE: this.rowDataObject.IEPS_DUE,
                            IEPS_PCT: this.rowDataObject.IEPS_PCT,
                            ITEM_DESC: this.rowDataObject.ITEM_DESC,
                            IVA_DUE: this.rowDataObject.IVA_DUE,
                            IVA_PCT: this.rowDataObject.IVA_PCT,
                            Item_Nbr: this.rowDataObject.Item_Nbr,
                            POS_BANNER_CODE: this.rowDataObject.POS_BANNER_CODE,
                            POS_SALES_AMT: this.rowDataObject.POS_SALES_AMT,
                            POS_SALES_QTY: this.rowDataObject.POS_SALES_QTY,
                            RECORD_ID: this.rowDataObject.RECORD_ID,
                            RFC: this.rowDataObject.RFC,
                            SAP_VENDOR: this.rowDataObject.SAP_VENDOR,
                            SEQ_NO: this.rowDataObject.SEQ_NO,
                            StatusCode: this.rowDataObject.StatusCode,
                            UPC_NBR: this.rowDataObject.UPC_NBR,
                            Valid_From: this.rowDataObject.Valid_From,
                            Valid_To: this.rowDataObject.Valid_To,
                            VENDOR_NAME: this.rowDataObject.VENDOR_NAME,
                            VENDOR_NBR: this.rowDataObject.VENDOR_NBR,
                            USR_CHANGE: this.rowDataObject.USR_CHANGE,
                            ALL_BODEGA_POS_QTY: this.rowDataObject.ALL_BODEGA_POS_QTY,
                            ALL_BODEGA_POS_AMT: this.rowDataObject.ALL_BODEGA_POS_AMT,
                            VERSION: this.rowDataObject.VERSION
                        });
                        this.saveDisabled = false;
                    } else {
                        this.addForm.patchValue({
                            SAP_VENDOR: response1[0].SAP_NUMBER,
                            VENDOR_NAME: response1[0].VENDOR_NAME
                        });

                        fromValue = this.addForm.getRawValue();
                        let finalPayload = [];
                        if (this.editData.length > 1) {
                            finalPayload = _.map(this.editData, (v, i) => {
                                let payload;
                                if (this.multipleEditDataArray.length > 0) {
                                    _.each(this.multipleEditDataArray, (item) => {
                                        payload = {
                                            VENDOR_NUMBER: item === 'VENDOR_NBR' ? fromValue.VENDOR_NBR : v.VENDOR_NBR,
                                            AMS_QTY: item === 'AMS_QTY' ? fromValue.AMS_QTY : parseFloat(v.AMS_QTY),
                                            AMS_SALES_AMT: item === 'AMS_SALES_AMT' ? fromValue.AMS_SALES_AMT : parseFloat(v.AMS_SALES_AMT),
                                            Accrual_AMT: item === 'Accrual_AMT' ? fromValue.Accrual_AMT : parseFloat(v.Accrual_AMT),
                                            Adjustments: item === 'Adjustments' ? fromValue.Adjustments : parseFloat(v.Adjustments),
                                            VALID_FROM: item === 'Valid_From' ? fromValue.Valid_From : v.Valid_From.toString(),
                                            VALID_TO: item === 'Valid_To' ? fromValue.Valid_To : v.Valid_To.toString(),
                                            DEPARTMENT: item === 'DEPARTMENT' ? fromValue.DEPARTMENT : v.DEPARTMENT,
                                            FORMAT: item === 'FORMAT' ? fromValue.FORMAT : v.FORMAT,
                                            SEQ_NO: item === 'SEQ_NO' ? fromValue.SEQ_NO : v.SEQ_NO,
                                            ITEM_NUMBER: item === 'Item_Nbr' ? fromValue.Item_Nbr : v.Item_Nbr,
                                            AGREEMENT_NUMBER: item === 'Agreement_Nbr' ? fromValue.Agreement_Nbr : v.Agreement_Nbr,
                                            RECORD_ID: item === 'RECORD_ID' ? fromValue.RECORD_ID : v.RECORD_ID
                                        };
                                    });
                                }
                                this.editData[i] = {
                                    ...v,
                                    ...payload
                                };
                                return payload;
                            });
                        } else {
                            const payload = {
                                VENDOR_NUMBER: fromValue.VENDOR_NBR,
                                AMS_QTY: parseFloat(fromValue.AMS_QTY),
                                AMS_SALES_AMT: parseFloat(fromValue.AMS_SALES_AMT),
                                Accrual_AMT: parseFloat(fromValue.Accrual_AMT),
                                Adjustments: parseFloat(fromValue.Adjustments),
                                VALID_FROM: fromValue.Valid_From.toString(),
                                VALID_TO: fromValue.Valid_To.toString(),
                                DEPARTMENT: fromValue.DEPARTMENT,
                                FORMAT: fromValue.FORMAT,
                                SEQ_NO: fromValue.SEQ_NO,
                                ITEM_NUMBER: fromValue.Item_Nbr,
                                AGREEMENT_NUMBER: fromValue.Agreement_Nbr,
                                RECORD_ID: fromValue.RECORD_ID
                            };

                            finalPayload.push(payload);
                        }
                        this.showLoader = true;
                        this.apiservice.getmxcalc(finalPayload).subscribe( (response2: any) => {
                            this.showLoader = false;
                            if (response2.length == 0) {
                                this.saveDisabled = true;
                                if (window.confirm("This is not a valid combination, Do you want keep the new values ?")) {
                                    console.log("leaving the values updated")
                                } else {
                                this.addForm.patchValue({
                                    AUDITOR: this.rowDataObject.AUDITOR,
                                    AUDITOR_COMMENTS: this.rowDataObject.AUDITOR_COMMENTS,
                                    ALLOW_EARNED: this.rowDataObject.ALLOW_EARNED,
                                    AMS_QTY: this.rowDataObject.AMS_QTY,
                                    AMS_RATE: this.rowDataObject.AMS_RATE,
                                    AMS_SALES_AMT: this.rowDataObject.AMS_SALES_AMT,
                                    AMT_DUE: this.rowDataObject.AMT_DUE,
                                    AUDIT_YEAR: this.rowDataObject.AUDIT_YEAR,
                                    Accrual_AMT: this.rowDataObject.Accrual_AMT,
                                    Adjustments: this.rowDataObject.Adjustments,
                                    Agreement_Nbr: this.rowDataObject.Agreement_Nbr,
                                    Agreement_Request_Nbr: this.rowDataObject.Agreement_Request_Nbr,
                                    Agreement_Request_Status: this.rowDataObject.Agreement_Request_Status,
                                    COOP_NUMBER: this.rowDataObject.COOP_Number,
                                    Claim_Number: this.rowDataObject.Claim_Number,
                                    Comments: this.rowDataObject.Comments,
                                    Complete_DT: this.rowDataObject.Complete_DT,
                                    DIVISION: this.rowDataObject.DIVISION,
                                    DEPARTMENT: this.rowDataObject.DEPARTMENT,
                                    FORMAT: this.rowDataObject.FORMAT,
                                    GROUPS: this.rowDataObject.GROUPS,
                                    ID: this.rowDataObject.ID,
                                    INCLUDE: 'Y',
                                    IEPS_DUE: this.rowDataObject.IEPS_DUE,
                                    IEPS_PCT: this.rowDataObject.IEPS_PCT,
                                    ITEM_DESC: this.rowDataObject.ITEM_DESC,
                                    IVA_DUE: this.rowDataObject.IVA_DUE,
                                    IVA_PCT: this.rowDataObject.IVA_PCT,
                                    Item_Nbr: this.rowDataObject.Item_Nbr,
                                    POS_BANNER_CODE: this.rowDataObject.POS_BANNER_CODE,
                                    POS_SALES_AMT: this.rowDataObject.POS_SALES_AMT,
                                    POS_SALES_QTY: this.rowDataObject.POS_SALES_QTY,
                                    RECORD_ID: this.rowDataObject.RECORD_ID,
                                    RFC: this.rowDataObject.RFC,
                                    SAP_VENDOR: this.rowDataObject.SAP_VENDOR,
                                    SEQ_NO: this.rowDataObject.SEQ_NO,
                                    StatusCode: this.rowDataObject.StatusCode,
                                    UPC_NBR: this.rowDataObject.UPC_NBR,
                                    Valid_From: this.rowDataObject.Valid_From,
                                    Valid_To: this.rowDataObject.Valid_To,
                                    VENDOR_NAME: this.rowDataObject.VENDOR_NAME,
                                    VENDOR_NBR: this.rowDataObject.VENDOR_NBR,
                                    USR_CHANGE: this.rowDataObject.USR_CHANGE,
                                    ALL_BODEGA_POS_QTY: this.rowDataObject.ALL_BODEGA_POS_QTY,
                                    ALL_BODEGA_POS_AMT: this.rowDataObject.ALL_BODEGA_POS_AMT,
                                    VERSION: this.rowDataObject.VERSION
                                });
                                }
                                
                                // Alert('');
                                // Reverting to the original data

                                // this.saveDisabled = false;
                            } else if (response2.length == 1) {
                                this.addForm.patchValue({
                                    POS_SALES_QTY: response2[0].POS_SALES_QTY,
                                    POS_SALES_AMT: response2[0].POS_SALES_AMT,
                                    Accrual_AMT: response2[0].Accrual_AMT,
                                    AMS_QTY: response2[0].AMS_QTY,
                                    AMS_SALES_AMT: response2[0].AMS_SALES_AMT,
                                    Adjustments: response2[0].Adjustments,
                                    FORMAT: response2[0].FORMAT,
                                    SEQ_NO: response2[0].SEQ_NO,
                                    USR_CHANGE: 'YES'
                                });

                                let val = this.convertStringToNumberValue(fromValue.AMS_RATE) * this.convertStringToNumberValue(response2[0].POS_SALES_QTY);
                                let calc_amountdue = val - response2[0].Accrual_AMT - this.convertStringToNumberValue(response2[0].Adjustments);
                                let calc_iepsdue = calc_amountdue * this.convertStringToNumberValue(fromValue.IEPS_PCT);
                                let calc_IVADUE = (calc_amountdue + calc_iepsdue) * this.convertStringToNumberValue(fromValue.IVA_PCT);
                                this.addForm.patchValue({
                                    ALLOW_EARNED: val.toFixed(2),
                                    AMT_DUE: calc_amountdue.toFixed(2),
                                    IEPS_DUE: calc_iepsdue.toFixed(2),
                                    IVA_DUE: calc_IVADUE.toFixed(2)
                                });
                            } else if (response2.length > 1) {
                                this.editData = _.each(this.editData, (v) => {
                                    response2.forEach((val) => {
                                        if (v.RECORD_ID == val.RECORD_ID) {
                                            (v.USR_CHANGE = 'YES'), (v.POS_SALES_QTY = val.POS_SALES_QTY), (v.POS_SALES_AMT = val.POS_SALES_AMT), (v.Accrual_AMT = val.Accrual_AMT), (v.AMS_QTY = val.AMS_QTY), (v.AMS_SALES_AMT = val.AMS_SALES_AMT), (v.FORMAT = val.FORMAT), (v.SEQ_NO = val.SEQ_NO);

                                            let valu = this.convertStringToNumberValue(v.AMS_RATE) * this.convertStringToNumberValue(val.POS_SALES_QTY);
                                            let calc_amountdue = valu - val.Accrual_AMT - this.convertStringToNumberValue(val.Adjustments);
                                            let calc_iepsdue = calc_amountdue * this.convertStringToNumberValue(v.IEPS_PCT);
                                            let calc_IVADUE = (calc_amountdue + calc_iepsdue) * this.convertStringToNumberValue(v.IVA_PCT);

                                            v['Adjustments'] = val.Adjustments;
                                            v['ALLOW_EARNED'] = valu.toFixed(2);
                                            v['AMT_DUE'] = calc_amountdue.toFixed(2);
                                            v['IEPS_DUE'] = calc_iepsdue.toFixed(2);
                                            v['IVA_DUE'] = calc_IVADUE.toFixed(2);

                                            if (fromValue.RECORD_ID == val.RECORD_ID) {
                                                this.addForm.patchValue({
                                                    POS_SALES_QTY: val.POS_SALES_QTY,
                                                    POS_SALES_AMT: val.POS_SALES_AMT,
                                                    Accrual_AMT: val.Accrual_AMT,
                                                    AMS_QTY: val.AMS_QTY,
                                                    AMS_SALES_AMT: val.AMS_SALES_AMT,
                                                    FORMAT: val.FORMAT,
                                                    SEQ_NO: val.SEQ_NO,
                                                    Adjustments: val.Adjustments,
                                                    ALLOW_EARNED: valu.toFixed(2),
                                                    AMT_DUE: calc_amountdue.toFixed(2),
                                                    IEPS_DUE: calc_iepsdue.toFixed(2),
                                                    IVA_DUE: calc_IVADUE.toFixed(2),
                                                    USR_CHANGE: 'YES'
                                                });
                                            }
                                        }
                                    });

                                    return v;
                                });
                            }
                        },(error) => {
                            this.showLoader = false;
                            alert(error);
                        });
                    }
                },
                (error) => {
                    this.showLoader = false;
                    alert(error);
                }
            );
        }


        else if (this.pageID === 'Markdown' && !this.valueEdited) {
            const apiCallCalculation = ['START_DATE','END_DATE'];
            const findApiCal = apiCallCalculation.indexOf(this.currentChangedValue);
			
			if (findApiCal > -1) {
                let finalPayload = [];
                if (this.editData.length > 1) {
                    finalPayload = _.map(this.editData, (v, i) => {
                        let payload;
                        if (this.multipleEditDataArray.length > 0) {
                            _.each(this.multipleEditDataArray, (item) => {
                                payload = {
                                   
                                    VENDOR_NBR: item === 'VENDOR_NBR' ? fromValue.VENDOR_NBR : parseFloat(v.VENDOR_NBR),
                                    START_DATE: item === 'EFFECTIVE_DATE' ? fromValue.EFFECTIVE_DATE : v.EFFECTIVE_DATE,
                                    END_DATE: item === 'END_DATE' ? fromValue.END_DATE : v.END_DATE,
                                    ITEM_NUM: item === 'ITEM_NUM' ? fromValue.ITEM_NUM : v.ITEM_NUM,
                                    UNIT_RETAIL: item === 'UNIT_RETAIL' ? fromValue.UNIT_RETAIL : parseFloat(v.UNIT_RETAIL),
                                    UNIT_COST: item === 'UNIT_COST' ? fromValue.UNIT_COST : v.UNIT_COST,
                                    RECORD_ID: item === 'RECORD_ID' ? fromValue.RECORD_ID : v.RECORD_ID
                                };
                            });
                        }
                       
                        this.editData[i] = {
                            ...v,
                            ...payload
                           
                        };
                        return payload;
                    });
                } else {
                    const payload = {
                       
                        VENDOR_NBR: parseFloat(fromValue.VENDOR_NBR),
                        START_DATE: fromValue.EFFECTIVE_DATE,
                        END_DATE: fromValue.END_DATE,
                        ITEM_NUM: fromValue.ITEM_NUM,
                        UNIT_RETAIL: parseFloat(fromValue.UNIT_RETAIL),
                        UNIT_COST: fromValue.UNIT_COST,
                        RECORD_ID: fromValue.RECORD_ID
                    };
    
                   
    
                    finalPayload.push(payload);
                   
                }
                this.showLoader = true;
                this.apiservice.getmxmarkdowncalc(finalPayload).subscribe(
                    (response2: any) => {
                        this.showLoader = false;
                        if (response2.length == 0) {
                            if (window.confirm("This is not a valid combination, Do you want keep the new values ?")) {
                                this.addForm.patchValue({POS_SALES: 0,POS_QTY: 0,['IMPACT_MU/MD']: 0,REAL_MARGE : 0,COMMERCIAL_MARGE: 0,MARGE_DEVIATION: 0, AVG_UNIT_SALE_PRICE: 0})
                                console.log("leaving the values updated")
                            } else {
                            // Reverting to the original data
                            this.addForm.patchValue({
                                VENDOR_NBR: this.rowDataObject.VENDOR_NBR,
                                VNDR_NAME: this.rowDataObject.VNDR_NAME,
                                START_DATE: fromValue.START_DATE,
                                EFFECTIVE_DATE: this.rowDataObject.EFFECTIVE_DATE,
                                END_DATE: fromValue.END_DATE,
                                DEPT: this.rowDataObject.DEPT,
                                CAT: this.rowDataObject.CAT,
                                ITEM_NUM: this.rowDataObject.ITEM_NUM,
                                ITEM1_DESC: this.rowDataObject.ITEM1_DESC,
                                POS_SALES: this.rowDataObject.POS_SALES,
                                POS_QTY: this.rowDataObject.POS_QTY,
                                IMPACT_MUMD: this.rowDataObject.IMPACT_MUMD,
                                UNIT_RETAIL: this.rowDataObject.UNIT_RETAIL,
                                AVG_UNIT_SALE_PRICE: this.rowDataObject.AVG_UNIT_SALE_PRICE,
                                UNIT_COST: this.rowDataObject.UNIT_COST,
                                COMMERCIAL_MARGE: this.rowDataObject.COMMERCIAL_MARGE,
                                REAL_MARGE: this.rowDataObject.REAL_MARGE,
                                MARGE_DEVIATION: this.rowDataObject.MARGE_DEVIATION,
                                AMS_AGREEMENT: this.rowDataObject.AMS_AGREEMENT,
                                ['IMPACT_MU/MD']: this.rowDataObject['IMPACT_MU/MD'], 
                                AGREEMENT_AMOUNT: this.rowDataObject.AGREEMENT_AMOUNT,
                                DIFFERENCE: this.rowDataObject.DIFFERENCE,
                                MD_STATUS: this.rowDataObject.MD_STATUS,
                                MD_ROOTCAUSE: this.rowDataObject.MD_ROOTCAUSE,
                                MARKDOWN_TYPE: this.rowDataObject.MARKDOWN_TYPE,
                                EMAIL_SUPPORT: this.rowDataObject.EMAIL_SUPPORT,
                                AUDITOR_NAME: this.rowDataObject.AUDITOR_NAME,
                                AUDITOR_COMMENTS: this.rowDataObject.AUDITOR_COMMENTS,
                                DUE_DATE: this.rowDataObject.DUE_DATE,
                                CLAIM_NUMBER: this.rowDataObject.CLAIM_NUMBER,
                                SELECT_ROW: this.rowDataObject.SELECT_ROW,
                                VAT: this.rowDataObject.VAT,
                                IEPS: this.rowDataObject.IEPS,
                                UNIT_ALLOWANCE: this.rowDataObject.UNIT_ALLOWANCE,
                                EVENT_DESCRIPTION: this.rowDataObject.EVENT_DESCRIPTION,
                                VERSION: this.rowDataObject.version,
                                RECORD_ID: this.rowDataObject.RECORD_ID,
                                INCLUDE: 'Y',
                                AUDIT_YEAR: this.rowDataObject.AUDIT_YEAR
                            });
                        }
                        } else if (response2.length == 1) {
                            this.addForm.patchValue({
                                POS_QTY:  response2[0].POS_QTY,
                                POS_SALES:  response2[0].POS_SALES,
                                ['IMPACT_MU/MD']:  response2[0]['IMPACT_MU/MD'],
                                REAL_MARGE:  response2[0].MARGE_REAL,
                                COMMERCIAL_MARGE:  response2[0].FIXED_MARGE,
                                MARGE_DEVIATION:  response2[0].MGR_DIF,
                                AVG_UNIT_SALE_PRICE: response2[0].AVG_SALE_PRICE 

                            });

                            let difference = this.convertStringToNumberValue(response2[0]['IMPACT_MU/MD']) - this.convertStringToNumberValue(fromValue.AGREEMENT_AMOUNT);
                            let calc_ieps = difference * this.convertStringToNumberValue(fromValue.IEPS);
                            let calc_iva = Math.abs((difference + calc_ieps) * this.convertStringToNumberValue(fromValue.VAT));
                            let due = difference + calc_ieps + calc_iva;

                            this.addForm.patchValue({
                                DIFFERENCE: difference.toFixed(2),
                                IEPS_DUE: calc_ieps.toFixed(2),
                                IVA_DUE: calc_iva.toFixed(2),
                                TOTAL_DUE: due.toFixed(2),
                            });
                            this.saveLoader = false;
                            
    
                        } else if (response2.length > 1) {
                            this.editData = _.each(this.editData, (v) => {
                                response2.forEach((val) => {

                                    let difference = this.convertStringToNumberValue(val['IMPACT_MU/MD']) - this.convertStringToNumberValue(val.AGREEMENT_AMOUNT);
                                    let calc_ieps = difference * this.convertStringToNumberValue(val.IEPS);
                                    let calc_iva = Math.abs((difference + calc_ieps) * this.convertStringToNumberValue(val.VAT));
                                    let due = difference + calc_ieps + calc_iva;

                                    if (v.RECORD_ID == val.RECORD_ID) {
                                        v.POS_QTY = val.POS_QTY,
                                        v.POS_SALES = val.POS_SALES,
                                        v.REAL_MARGE = val.MARGE_REAL,
                                        v.COMMERCIAL_MARGE = val.FIXED_MARGE,
                                        v.MARGE_DEVIATION = val.MGR_DIF,
                                        v.AVG_UNIT_SALE_PRICE= val.AVG_SALE_PRICE,
                                        v['IMPACT_MU/MD'] = val['IMPACT_MU/MD'],
                                        v['DIFFERENCE'] = difference.toFixed(2),
                                        v['IEPS_DUE'] = calc_ieps.toFixed(2),
                                        v['IVA_DUE'] = calc_iva.toFixed(2),
                                        v['TOTAL_DUE'] = due.toFixed(2)
                                    }

                                    if (fromValue.RECORD_ID == val.RECORD_ID) {
                                        this.addForm.patchValue({
                                            POS_QTY: val.POS_QTY,
                                            POS_SALES: val.POS_SALES,
                                            REAL_MARGE : val.MARGE_REAL,
                                            COMMERCIAL_MARGE : val.FIXED_MARGE,
                                            MARGE_DEVIATION : val.MGR_DIF,
                                            AVG_UNIT_SALE_PRICE : val.AVG_SALE_PRICE,
                                            ['IMPACT_MU/MD'] : val['IMPACT_MU/MD'],
                                            DIFFERENCE : difference.toFixed(2),
                                            IEPS_DUE : calc_ieps.toFixed(2),
                                            IVA_DUE : calc_iva.toFixed(2),
                                            TOTAL_DUE : due.toFixed(2)
                                        });
                                    }
                                });
                                return v;
                            });
                        }
                        this.showLoader = false;
                    },
                    (error) => {
                        this.showLoader = false;
                        alert(error);
                    }
                );
			}

        }
        
        
       


        else if (this.pageID === 'Volume Allowances' && !this.valueEdited) {
            let finalPayload = [];
            if (this.editData.length > 1) {
                finalPayload = _.map(this.editData, (v, i) => {
                    let payload;
                    if (this.multipleEditDataArray.length > 0) {
                        _.each(this.multipleEditDataArray, (item) => {
                            payload = {
                                AUDIT_YEAR: item === 'AUDIT_YEAR' ? fromValue.AUDIT_YEAR : parseFloat(v.AUDIT_YEAR),
                                VENDOR_NUMBER: item === 'VENDOR_NBR' ? fromValue.VENDOR_NBR : parseFloat(v.VENDOR_NBR),
                                START_DATE: item === 'START_DATE' ? fromValue.START_DATE : v.START_DATE.toString(),
                                END_DATE: item === 'END_DATE' ? fromValue.END_DATE : v.END_DATE.toString(),
                                DEPARTMENT: item === 'DEPARTMENT' ? fromValue.DEPARTMENT : v.DEPARTMENT,
                                SEQ_NO: item === 'SEQ_NO' ? fromValue.SEQ_NO : parseFloat(v.SEQ_NO),
                                DEAL: item === 'DEAL' ? (this.returnEmpty(fromValue.DEAL)) : (this.returnEmpty(v.DEAL)),
                                RECORD_ID: item === 'RECORD_ID' ? fromValue.RECORD_ID : v.RECORD_ID
                            };
                        });
                    }
                    this.editData[i] = {
                        ...v,
                        ...payload
                    };
                    return payload;
                });
            } else {
                const payload = {
                    AUDIT_YEAR: fromValue.AUDIT_YEAR,
                    VENDOR_NUMBER: parseFloat(fromValue.VENDOR_NBR),
                    START_DATE: fromValue.START_DATE.toString(),
                    END_DATE: fromValue.END_DATE.toString(),
                    DEPARTMENT: fromValue.DEPARTMENT,
                    SEQ_NO: parseFloat(fromValue.SEQ_NO),
                    DEAL: this.returnEmpty(fromValue.DEAL),
                    RECORD_ID: fromValue.RECORD_ID
                };

                finalPayload.push(payload);
            }
            this.apiservice.getmxVolcalc(finalPayload).subscribe(
                (response2: any) => {
                    if (response2.length == 0) {
                        if (window.confirm("This is not a valid combination, Do you want keep the new values ?")) {
                            console.log("leaving the values updated")
                        } else {
                        // Reverting to the original data
                        this.addForm.patchValue({
                            ADJUSTMENTS: this.rowDataObject.ADJUSTMENTS,
                            ALLOW_EARNED: this.rowDataObject.ALLOW_EARNED,
                            ALL_ITEMS: this.rowDataObject.ALL_ITEMS,
                            AMS_PURCHASE: this.rowDataObject.AMS_PURCHASE,
                            AMT_DUE: this.rowDataObject.AMT_DUE,
                            AUDITOR: this.rowDataObject.AUDITOR,
                            AUDITOR_COMMENTS: this.rowDataObject.AUDITOR_COMMENTS,
                            AUDIT_YEAR: this.rowDataObject.AUDIT_YEAR,
                            CATCH_RATE: this.rowDataObject.CATCH_RATE,
                            COMPLETE_DATE: this.rowDataObject.COMPLETE_DATE,
                            CLAIM_NUMBER: this.rowDataObject.Claim_Number,
                            DEAL: this.rowDataObject.DEAL,
                            DEPARTMENT: this.rowDataObject.DEPARTMENT,
                            END_DATE: this.rowDataObject.END_DATE,
                            ENTERED_AMT: this.rowDataObject.ENTERED_AMT,
                            FORMAT: this.rowDataObject.FORMAT,
                            GROUP: this.rowDataObject.GROUP,
                            IEPS: this.rowDataObject.IEPS,
                            IEPS_PCT: this.rowDataObject.IEPS_PCT,
                            INSERT_USER: this.rowDataObject.INSERT_USER,
                            INS_ROW: this.rowDataObject.INS_ROW,
                            IVA: this.rowDataObject.IVA,
                            IVA_PCT: this.rowDataObject.IVA_PCT,
                            OTHER_DED: this.rowDataObject.OTHER_DED,
                            PAST_CLAIM_NUMBERS: this.rowDataObject.PAST_CLAIM_NUMBERS,
                            PCT: this.rowDataObject.PCT,
                            PO_PURCHASES: this.rowDataObject.PO_PURCHASES,
                            PO_SOURCE: this.rowDataObject.PO_SOURCE,
                            PREVIOUS_CLAIMS: this.rowDataObject.PREVIOUS_CLAIMS,
                            RECORD_ID: this.rowDataObject.RECORD_ID,
                            RFC: this.rowDataObject.RFC,
                            SAP_VENDOR: this.rowDataObject.SAP_VENDOR,
                            SEQ_NO: this.rowDataObject.SEQ_NO,
                            START_DATE: this.rowDataObject.START_DATE,
                            STATUS: this.rowDataObject.STATUS,
                            TAX_CD: this.rowDataObject.TAX_CD,
                            TIER: this.rowDataObject.TIER,
                            TOTAL_DUE: this.rowDataObject.TOTAL_DUE,
                            USR_CHANGE: this.rowDataObject.USR_CHANGE,
                            VENDOR_NAME: this.rowDataObject.VENDOR_NAME,
                            VENDOR_NBR: this.rowDataObject.VENDOR_NBR,
                            Include: 'Y',
                            VERSION: this.rowDataObject.version
                        });
                    }
                    } else if (response2.length == 1) {
                        this.addForm.patchValue({
                            RECORD_ID: response2[0].RECORD_ID,
                            AUDIT_YEAR: response2[0].AUDIT_YEAR,
                            DEPARTMENT: response2[0].DEPARTMENT,
                            SEQ_NO: response2[0].SEQ_NO,
                            PO_PURCHASES: response2[0].PO_PURCHASES,
                            DEAL: response2[0].DEAL,
                            PCT: response2[0].PCT,
                            FORMAT: response2[0].FORMAT,
                            ADJUSTMENTS: response2[0].ADJUSTMENTS,
                            AMS_PURCHASE: response2[0].AMS_PURCHASE,
                            ALLOW_EARNED: response2[0].ALLOW_EARNED,
                            ENTERED_AMT: response2[0].ENTERED_AMT,
                            AMT_DUE: response2[0].AMT_DUE,
                            IEPS: response2[0].IEPS,
                            IVA: response2[0].IVA,
                            TOTAL_DUE: response2[0].TOTAL_DUE,
                            PREVIOUS_CLAIMS: response2[0].PREVIOUS_CLAIMS,
                            PAST_CLAIM_NUMBERS: response2[0].PAST_CLAIM_NUMBERS,
                            CATCH_RATE: response2[0].CATCH_RATE,
                            USR_CHANGE: 'YES'
                        });
                    } else if (response2.length > 1) {
                        this.editData = _.each(this.editData, (v) => {
                            response2.forEach((val) => {
                                if (v.RECORD_ID == val.RECORD_ID) {
                                    v.RECORD_ID = val.RECORD_ID,
                                    v.AUDIT_YEAR = val.AUDIT_YEAR,
                                    v.DEPARTMENT = val.DEPARTMENT,
                                    v.SEQ_NO = val.SEQ_NO,
                                    v.PO_PURCHASES = val.PO_PURCHASES,
                                    v.DEAL = val.DEAL,
                                    v.PCT = val.PCT,
                                    v.FORMAT = val.FORMAT,
                                    v.ADJUSTMENTS = val.ADJUSTMENTS,
                                    v.AMS_PURCHASE = val.AMS_PURCHASE,
                                    v.ALLOW_EARNED = val.ALLOW_EARNED,
                                    v.ENTERED_AMT = val.ENTERED_AMT,
                                    v.AMT_DUE = val.AMT_DUE,
                                    v.IEPS = val.IEPS,
                                    v.IVA = val.IVA,
                                    v.TOTAL_DUE = val.TOTAL_DUE,
                                    v.PREVIOUS_CLAIMS = val.PREVIOUS_CLAIMS,
                                    v.PAST_CLAIM_NUMBERS = val.PAST_CLAIM_NUMBERS,
                                    v.CATCH_RATE = val.CATCH_RATE
                                    v.USR_CHANGE = 'YES';
                                }
                                if (fromValue.RECORD_ID == val.RECORD_ID) {
                                    this.addForm.patchValue({
                                        RECORD_ID: val.RECORD_ID,
                                        AUDIT_YEAR: val.AUDIT_YEAR,
                                        DEPARTMENT: val.DEPARTMENT,
                                        SEQ_NO: val.SEQ_NO,
                                        PO_PURCHASES: val.PO_PURCHASES,
                                        DEAL: val.DEAL,
                                        PCT : val.PCT,
                                        FORMAT: val.FORMAT,
                                        ADJUSTMENTS: val.ADJUSTMENTS,
                                        AMS_PURCHASE: val.AMS_PURCHASE,
                                        ALLOW_EARNED: val.ALLOW_EARNED,
                                        ENTERED_AMT: val.ENTERED_AMT,
                                        AMT_DUE: val.AMT_DUE,
                                        IEPS: val.IEPS,
                                        IVA: val.IVA,
                                        TOTAL_DUE: val.TOTAL_DUE,
                                        PREVIOUS_CLAIMS: val.PREVIOUS_CLAIMS,
                                        PAST_CLAIM_NUMBERS: val.PAST_CLAIM_NUMBERS,
                                        CATCH_RATE: val.CATCH_RATE,
                                        USR_CHANGE: 'YES'
                                    });
                                }
                            });
                            return v;
                        });
                    }
                    this.showLoader = false;
                },
                (error) => {
                    this.showLoader = false;
                    alert(error);
                }
            );
        }
    }

    changeInModal(obj) {
        console.log(obj)
        this.currentChangedValue = '';
        const data = obj.key;
        this.currentChangedValue = obj.key;
        console.log(this.currentChangedValue)
        this.multipleEditDataArray.push(data);

        if (this.pageID === 'VOLUMES' && !this.valueEdited) {
            this.valueEdited = true;
            const fromDataValue = this.addForm.getRawValue();
            const apiCallCalculation = ['SUPPLIER_NUM', 'CATEGORY', 'DEAL_AGREEMENT_NUM', 'ALLOW_TYPE', 'PRIVATE_LABEL', 'FORMAT_TYPE', 'START_DATE', 'END_DATE', 'ACCOUNT_DEPARTMENT'];
            const editableCalculateRow = ['FIXED_AMOUNT_AGREEMENT', 'VOLUME', 'PERCENTAGE', 'DEBITED_AMOUNT', 'EARNED', 'AMOUNT_RECEIVED'];
            const findApiCal = apiCallCalculation.indexOf(data);
            const findData = editableCalculateRow.indexOf(data);
            if (findData > -1) {
                const fromData = this.addForm.getRawValue();
                this.isShowFormateType = fromData.ALLOW_TYPE === 'REPO' ? true : false;

                if (data === 'PERCENTAGE' || data === 'VOLUME' || data === 'FIXED_AMOUNT_AGREEMENT') {
                    this.editData = _.map(this.editData, (v) => {
                        const percent = data === 'PERCENTAGE' ? fromData.PERCENTAGE : v.PERCENTAGE;
                        const volume = data === 'VOLUME' ? fromData.VOLUME : this.convertStringToNumberValue(v.VOLUME);
                        const amtRec = data === 'AMOUNT_RECEIVED' ? fromData.AMOUNT_RECEIVED : this.convertStringToNumberValue(v.AMOUNT_RECEIVED);
                        const fixedAmount = data === 'FIXED_AMOUNT_AGREEMENT' ? fromData.FIXED_AMOUNT_AGREEMENT : this.convertStringToNumberValue(v.FIXED_AMOUNT_AGREEMENT);
                        const earnedVal = this.percentageCalculated(Number(percent), Number(volume));
                        const debitedAmount = Number(earnedVal) - Number(amtRec) - Number(fixedAmount);
                        v['PERCENTAGE'] = Number(percent);
                        v['VOLUME'] = Number(volume);
                        v['EARNED'] = Number(earnedVal);
                        v['DEBITED_AMOUNT'] = Number(debitedAmount);
                        v['CHANGE_USER'] = 'Y';
                        return v;
                    });
                    const percent = fromData.PERCENTAGE;
                    const volume = fromData.VOLUME;
                    const amtRec = fromData.AMOUNT_RECEIVED;
                    const fixedAmount = fromData.FIXED_AMOUNT_AGREEMENT;
                    const earnedVal = this.percentageCalculated(Number(percent), Number(volume));
                    this.addForm.get('EARNED').setValue(earnedVal);
                    const debitedAmount = Number(earnedVal) - Number(amtRec) - Number(fixedAmount);
                    this.addForm.get('DEBITED_AMOUNT').setValue(debitedAmount);
                    this.addForm.get('CHANGE_USER').setValue('Y');
                } else if (data === 'EARNED' || data === 'AMOUNT_RECEIVED') {
                    this.editData = _.map(this.editData, (v) => {
                        const percent = data === 'PERCENTAGE' ? fromData.PERCENTAGE : v.PERCENTAGE;
                        const volume = data === 'VOLUME' ? fromData.VOLUME : this.convertStringToNumberValue(v.VOLUME);
                        const fixedAmount = data === 'FIXED_AMOUNT_AGREEMENT' ? fromData.FIXED_AMOUNT_AGREEMENT : this.convertStringToNumberValue(v.FIXED_AMOUNT_AGREEMENT);
                        const earnedVal = this.percentageCalculated(Number(percent), Number(volume));
                        const amtRec = data === 'AMOUNT_RECEIVED' ? fromData.AMOUNT_RECEIVED : this.convertStringToNumberValue(v.AMOUNT_RECEIVED);
                        const debitedAmount = Number(earnedVal) - Number(amtRec) - Number(fixedAmount);
                        v['EARNED'] = Number(earnedVal);
                        v['DEBITED_AMOUNT'] = Number(debitedAmount);
                        v['CHANGE_USER'] = 'Y';
                        v['PERCENTAGE'] = percent;
                        return v;
                    });
                    const percent = fromData.PERCENTAGE;
                    const volume = fromData.VOLUME;
                    const earnedVal = this.percentageCalculated(Number(percent), Number(volume));
                    const amtRec = fromData.AMOUNT_RECEIVED;
                    const fixedAmount = fromData.FIXED_AMOUNT_AGREEMENT;
                    const debitedAmount = Number(earnedVal) - Number(amtRec) - Number(fixedAmount);
                    this.addForm.get('EARNED').setValue(earnedVal);
                    this.addForm.get('DEBITED_AMOUNT').setValue(debitedAmount);
                    this.addForm.get('CHANGE_USER').setValue('Y');
                    this.valueEdited = false;
                }
            } else if (findApiCal > -1) {
                this.showLoader = true;
                this.isShowFormateType = fromDataValue.ALLOW_TYPE === 'REPO' ? true : false;

                if (fromDataValue.PERCENTAGE == null) {
                    fromDataValue.PERCENTAGE = 0.0;
                }
                if (fromDataValue.DEAL_AGREEMENT_NUM == null) {
                    fromDataValue.DEAL_AGREEMENT_NUM = 0.0;
                }
                let finalPayload = [];
                if (this.editData.length > 1) {
                    finalPayload = _.map(this.editData, (v, i) => {
                        const dataV = {
                            RECORD_ID: v.RECORD_ID,
                            AUDIT_YEAR: data === 'AUDIT_YEAR' ? fromDataValue.AUDIT_YEAR : v.AUDIT_YEAR,
                            SUPPLIER_NUM: data === 'SUPPLIER_NUM' ? fromDataValue.SUPPLIER_NUM : v.SUPPLIER_NUM,
                            CATEGORY: data === 'CATEGORY' ? fromDataValue.CATEGORY : v.CATEGORY,
                            DEPT: data === 'ACCOUNT_DEPARTMENT' ? fromDataValue.ACCOUNT_DEPARTMENT : v.ACCOUNT_DEPARTMENT,
                            DEAL_AGREEMENT_NUM: data === 'DEAL_AGREEMENT_NUM' ? fromDataValue.DEAL_AGREEMENT_NUM : v.DEAL_AGREEMENT_NUM,
                            ALLOW_TYPE: data === 'ALLOW_TYPE' ? fromDataValue.ALLOW_TYPE : v.ALLOW_TYPE,
                            START_DATE: data === 'START_DATE' && fromDataValue.START_DATE != '' ? fromDataValue.START_DATE : v.START_DATE,
                            END_DATE: data === 'END_DATE' && fromDataValue.END_DATE != '' ? fromDataValue.END_DATE : v.END_DATE,
                            PRIVATE_LABEL: data === 'PRIVATE_LABEL' ? fromDataValue.PRIVATE_LABEL : v.PRIVATE_LABEL,
                            FORMAT_TYPE: ''
                        };
                        if (v.ALLOW_TYPE === 'REPO') {
                            dataV.FORMAT_TYPE = data === 'FORMAT_TYPE' && fromDataValue.FORMAT_TYPE != null && fromDataValue.FORMAT_TYPE != undefined ? fromDataValue.FORMAT_TYPE.toString() : v.FORMAT_TYPE;
                            dataV.DEAL_AGREEMENT_NUM = '';
                        } else {
                            dataV.FORMAT_TYPE = '';
                        }

                        this.editData[i] = {
                            ...v,
                            ACCOUNT_DEPARTMENT: dataV.DEPT,
                            ...dataV
                        };

                        return dataV;
                    });
                } else {
                    const vPayload = {
                        RECORD_ID: fromDataValue.RECORD_ID,
                        AUDIT_YEAR: fromDataValue.AUDIT_YEAR,
                        SUPPLIER_NUM: fromDataValue.SUPPLIER_NUM,
                        CATEGORY: fromDataValue.CATEGORY,
                        DEPT: fromDataValue.ACCOUNT_DEPARTMENT,
                        DEAL_AGREEMENT_NUM: fromDataValue.DEAL_AGREEMENT_NUM,
                        ALLOW_TYPE: fromDataValue.ALLOW_TYPE,
                        START_DATE: fromDataValue.START_DATE != '' ? fromDataValue.START_DATE : null,
                        END_DATE: fromDataValue.END_DATE != '' ? fromDataValue.END_DATE : null,
                        PRIVATE_LABEL: fromDataValue.PRIVATE_LABEL,
                        FORMAT_TYPE: ''
                    };

                    if (fromDataValue.ALLOW_TYPE === 'REPO') {
                        vPayload.FORMAT_TYPE = fromDataValue.FORMAT_TYPE != null && fromDataValue.FORMAT_TYPE != undefined ? fromDataValue.FORMAT_TYPE.toString() : '';
                    }
                    finalPayload.push(vPayload);
                }
                const findKeyBlank = _.find(finalPayload, (v) => {
                    const valueData = v;
                    if (v.ALLOW_TYPE === 'REPO') {
                        delete valueData.DEAL_AGREEMENT_NUM;
                    } else {
                        if (valueData.DEAL_AGREEMENT_NUM == null || valueData.DEAL_AGREEMENT_NUM == '') {
                            valueData.DEAL_AGREEMENT_NUM = 0.0;
                        }
                        delete valueData.FORMAT_TYPE;
                    }

                    let isFind = false;
                    for (const [key, value] of Object.entries(valueData)) {
                        if (value === null || value === '') {
                            isFind = true;
                            break;
                        }
                    }
                    if (isFind) {
                        return v;
                    }
                });
                if (findKeyBlank) {
                    alert('SUPPLIER_NUM,CATEGORY, DEPT, PRIVATE_LABEL, START_DATE, END_DATE provide all values to calculate!');
                    this.showLoader = false;
                    this.valueEdited = false;
                    return;
                } else {
                    finalPayload = _.map(finalPayload, (v) => {
                        return {
                            RECORD_ID: v.RECORD_ID,
                            AUDIT_YEAR: v.AUDIT_YEAR,
                            SUPPLIER_NUM: v.SUPPLIER_NUM,
                            CATEGORY: v.CATEGORY,
                            DEPT: v.DEPT,
                            DEAL_AGREEMENT_NUM: v.DEAL_AGREEMENT_NUM != null ? v.DEAL_AGREEMENT_NUM : 0,
                            ALLOW_TYPE: v.ALLOW_TYPE,
                            START_DATE: v.START_DATE,
                            END_DATE: v.END_DATE,
                            PRIVATE_LABEL: v.PRIVATE_LABEL,
                            FORMAT_TYPE: v.ALLOW_TYPE === 'REPO' ? v.FORMAT_TYPE : ''
                        };
                    });
                    this.apiservice.getCalculatedValueByVolume(finalPayload).subscribe((result: any) => {
                        this.saveDisabled = false;
                        if (result.length > 0) {
                            if (this.editData.length > 1 && result.length > 0) {
                                if (finalPayload.length != result.length) {
                                    this.showLoader = false;
                                    this.valueEdited = false;
                                    if (confirm('Some of the record id not have valid combination.!\nDo You want to proceed or cancel it') == true) {
                                        this.saveDisabled = true;
                                    } else {
                                        this.closeModal();
                                        this.saveDisabled = false;
                                    }
                                }
                                this.editData = _.map(this.editData, (v) => {
                                    const findValue = _.find(result, (item) => {
                                        return item.RECORD_ID === v.RECORD_ID;
                                    });
                                    const finalValue = {
                                        ...v,
                                        ACCOUNT_DEPARTMENT: findValue.DEPT_NBR,
                                        CHANGE_USER: 'Y',
                                        ...findValue
                                    };
                                    const finalObject = this.calculateValue(finalValue);
                                    return finalObject;
                                });
                            }
                            if (this.editData.length > 1) {
                                const fistIndexValue = this.createFileValue(this.editData[0]);
                                fistIndexValue['AUDIT_YEAR'] = [
                                    {
                                        value: fromDataValue.AUDIT_YEAR,
                                        disabled: this.getEditableColumn('AUDIT_YEAR')
                                    }
                                ];
                                this.addForm = this.formBuilder.group(fistIndexValue);
                                this.showForm = true;
                            } else {
                                const getFromData = this.addForm.getRawValue();
                                const resultData = result[0];
                                console.log(result[0])
                                const fistIndexValue = this.createFileValue({
                                    ...getFromData,
                                    ACCOUNT_DEPARTMENT: resultData.DEPT_NBR,
                                    AUDIT_YEAR: fromDataValue.AUDIT_YEAR,
                                    ...resultData
                                });
                                fistIndexValue['AUDIT_YEAR'] = [
                                    {
                                        value: fromDataValue.AUDIT_YEAR,
                                        disabled: this.getEditableColumn('AUDIT_YEAR')
                                    }
                                ];
                                fistIndexValue['CHANGE_USER'] = [
                                    {
                                        value: 'Y',
                                        disabled: this.getEditableColumn('AUDIT_YEAR')
                                    }
                                ];
                                this.singleAuditYear = fromDataValue.AUDIT_YEAR != undefined ? fromDataValue.AUDIT_YEAR : this.singleAuditYear;

                                this.addForm = this.formBuilder.group(fistIndexValue);
                                this.addForm.patchValue({
                                    YEAR_VOLUME: result[0].YEAR_VOLUME,
                                    RUT: result[0].RUT,
                                    SUPPLIER_NAME: result[0].SUPPLIER_NAME,
                                //    ALLOW_TYPE: result[0].ALLOW_TYPE,
                                    DEAL_ID: result[0].DEAL_ID,
                                    DEPT_NBR: result[0].DEPT_NBR,
                                    LEGACY_CATEGORY: result[0].LEGACY_CATEGORY,
                                    CATEGORY: result[0].CATEGORY,
                                    PRIVATE_LABEL: result[0].PRIVATE_LABEL,
                                    VOLUME: result[0].VOLUME,
                                    AMOUNT_RECEIVED: result[0].AMOUNT_RECEIVED,
                                });
                                this.singleDataValue();
                                this.showForm = true;
                            }

                            // this.addForm.patchValue(fistIndexValue)
                        } else {
                            alert('Not Valid Combination');
                        }

                        this.showLoader = false;
                        this.valueEdited = false;
                    });
                }
            } else {
                /* This condition is used to update the items that are not calculated or fetched from the API but selected 
                in the form for the multiple edit values like Status, Auditor etc */
                console.log("entered else")
                this.editData = _.each(this.editData, (v) => {
                    if (this.updatingRowValue.length > 0) {
                        _.each(this.updatingRowValue, (item) => {
                            if (item != 'FIXED_AMOUNT_AGREEMENT' && item != 'VOLUME' && item != 'PERCENTAGE' && item != 'DEBITED_AMOUNT' && item != 'EARNED' 
                            && item != 'AMOUNT_RECEIVED' && item != 'ACCOUNT_DEPARTMENT' && item != 'START_DATE' && item != 'END_DATE' && item != 'SUPPLIER_NUM' && item != 'CATEGORY'  && item != 'DEPT'  && item != 'DEAL_AGREEMENT_NUM' && item != 'ALLOW_TYPE') v[item] = fromDataValue[item];
                        });
                    }
                    return v;
                });
                this.valueEdited = false;
                console.log("updated the editdata ", this.editData)
            }
        } 

        else if (this.pageID === 'Fill Rate' && !this.valueEdited) {
            this.valueEdited = true;
            let fromValue = this.addForm.getRawValue();
            const editableCalculateRow = ['FILL_RATE_PCT', 'TOTAL_AMT_PAID', 'COOP_NUMBER', 'Adjustments', 'IEPS_PCT', 'IVA_PCT'];
            const apiCallCalculation = ['DEPARTMENT', 'FORMAT', 'VENDOR_NBR', 'PO_SEQ'];
            const findApiCal = apiCallCalculation.indexOf(data);
            const findData = editableCalculateRow.indexOf(data);

            if (findData > -1) {
                if (this.addForm.value.IEPS_PCT == null || this.addForm.value.IEPS_PCT == 'null') {
                    this.addForm.patchValue({
                        IEPS_PCT: 0
                    });
                }
                fromValue = this.addForm.getRawValue();

                let FILL_RATE_PCT, TOTAL_AMT_PAID, COOP_NUMBER;
                // Multipe
                this.editData = _.each(this.editData, (v) => {
                    if (data == 'FILL_RATE_PCT') {
                        FILL_RATE_PCT = fromValue.FILL_RATE_PCT;
                    } else {
                        FILL_RATE_PCT = v.FILL_RATE_PCT;
                    }

                    if (data == 'TOTAL_AMT_PAID') {
                        TOTAL_AMT_PAID = fromValue.TOTAL_AMT_PAID;
                    } else {
                        TOTAL_AMT_PAID = v.TOTAL_AMT_PAID;
                    }

                    if (data == 'COOP_NUMBER') {
                        COOP_NUMBER = fromValue.COOP_NUMBER;
                    } else {
                        COOP_NUMBER = v.COOP_NUMBER;
                    }
                    let fillRatePercent = (v.TOTAL_RCV_QTY / v.TOTAL_ORD_QTY)*100;
                    let val = this.convertStringToNumberValue(v.TOTAL_MARGIN_LOST) * this.convertStringToNumberValue(FILL_RATE_PCT);
                    let calc_amountdue = val - this.convertStringToNumberValue(TOTAL_AMT_PAID) - this.convertStringToNumberValue(COOP_NUMBER);
                    let calc_iepsdue = calc_amountdue * this.convertStringToNumberValue(fromValue.IEPS_PCT);
                    let calc_IVADUE = (calc_amountdue + calc_iepsdue) * this.convertStringToNumberValue(fromValue.IVA_PCT);
                    v['FILL_RATE_PCT'] = FILL_RATE_PCT;
                    v['TOTAL_AMT_PAID'] = TOTAL_AMT_PAID;
                    v['COOP_NUMBER'] = COOP_NUMBER;
                    v['FILL_RATE_CALC_PCT'] = fillRatePercent.toFixed(2);
                    v['TOTAL_EARNED'] = val.toFixed(2);
                    v['TOTAL_PENDING'] = calc_amountdue.toFixed(2);
                    v['IEPS_DUE'] = calc_iepsdue.toFixed(2);
                    v['IVA_DUE'] = calc_IVADUE.toFixed(2);
                    return v;
                });

                // Single
                let fillRatePercent = (fromValue.TOTAL_RCV_QTY  / fromValue.TOTAL_ORD_QTY) * 100;
                let val = this.convertStringToNumberValue(fromValue.TOTAL_MARGIN_LOST) * this.convertStringToNumberValue(fromValue.FILL_RATE_PCT);
                let calc_amountdue = val - this.convertStringToNumberValue(fromValue.TOTAL_AMT_PAID) - this.convertStringToNumberValue(fromValue.COOP_NUMBER);
                let calc_iepsdue = calc_amountdue * this.convertStringToNumberValue(fromValue.IEPS_PCT);
                let calc_IVADUE = (calc_amountdue + calc_iepsdue) * this.convertStringToNumberValue(fromValue.IVA_PCT);
                this.addForm.get('FILL_RATE_CALC_PCT').setValue(fillRatePercent.toFixed(2));
                this.addForm.get('TOTAL_EARNED').setValue(val.toFixed(2));
                this.addForm.get('TOTAL_PENDING').setValue(calc_amountdue.toFixed(2));
                this.addForm.get('IEPS_DUE').setValue(calc_iepsdue.toFixed(2));
                this.addForm.get('IVA_DUE').setValue(calc_IVADUE.toFixed(2));

                this.valueEdited = false;
            } else if (findApiCal > -1) {
                if (data == 'FORMAT') {
                    _.each(this.FORMAT_SEQUENCE, (item) => {
                        if (fromValue.FORMAT == item.FORMAT) {
                            this.addForm.patchValue({
                                PO_SEQ: item.SEQ
                            });
                        }
                    });
                }
                fromValue = this.addForm.getRawValue();
                this.showLoader = true;
                this.apiservice.getVendorandSapDetails(fromValue.VENDOR_NBR).subscribe((response1: any) => {
                    if (response1.length == 0) {
                        alert('Not a valid combination');
                        // Reverting to the original data
                        this.addForm.patchValue({
                            VENDOR_NBR: this.rowDataObject.VENDOR_NBR,
                            SAP_VENDOR: this.rowDataObject.SAP_VENDOR,
                            VENDOR_NAME: this.rowDataObject.VENDOR_NAME
                        });
                    } else {
                        this.addForm.patchValue({
                            SAP_VENDOR: response1[0].SAP_NUMBER,
                            VENDOR_NAME: response1[0].VENDOR_NAME
                        });

                        fromValue = this.addForm.getRawValue();
                        let finalPayload = [];
                        if (this.editData.length > 1) {
                            finalPayload = _.map(this.editData, (v, i) => {
                                let payload;
                                if (this.multipleEditDataArray.length > 0) {
                                    _.each(this.multipleEditDataArray, (item) => {
                                        payload = {
                                            VENDOR_NUMBER: item === 'VENDOR_NBR' ? fromValue.VENDOR_NBR : parseFloat(v.VENDOR_NBR),
                                            DEPARTMENT: item === 'DEPARTMENT' ? fromValue.DEPARTMENT : v.DEPARTMENT,
                                            FORMAT: item === 'FORMAT' ? fromValue.FORMAT : v.FORMAT,
                                            SEQ_NO: item === 'PO_SEQ' ? fromValue.PO_SEQ : parseFloat(v.PO_SEQ),
                                            RECORD_ID: item === 'RECORD_ID' ? fromValue.RECORD_ID : v.RECORD_ID
                                        };
                                    });
                                }
                                this.editData[i] = {
                                    ...v,
                                    ...payload
                                };
                                return payload;
                            });
                        } else {
                            const payload = {
                                VENDOR_NUMBER: parseFloat(fromValue.VENDOR_NBR),
                                DEPARTMENT: fromValue.DEPARTMENT,
                                FORMAT: fromValue.FORMAT,
                                SEQ_NO: parseFloat(fromValue.PO_SEQ),
                                RECORD_ID: fromValue.RECORD_ID
                            };

                            finalPayload.push(payload);
                        }
                        this.apiservice.getmxfillRatecalc(finalPayload).subscribe(
                            (response2: any) => {
                                if (response2.length == 0) {
                                    alert('Not a valid combination');
                                    // Reverting to the original data
                                    this.addForm.patchValue({
                                        VENDOR_NBR: this.rowDataObject.VENDOR_NBR,
                                        SAP_VENDOR: this.rowDataObject.SAP_VENDOR,
                                        VENDOR_NAME: this.rowDataObject.VENDOR_NAME,
                                        DEPARTMENT: this.rowDataObject.DEPARTMENT,
                                        FORMAT: this.rowDataObject.FORMAT,
                                        SEQ_NO: this.rowDataObject.PO_SEQ
                                    });
                                } else if (response2.length == 1) {
                                    this.addForm.patchValue({
                                        TOTAL_ORD_QTY: response2[0].TOTAL_ORD_QTY,
                                        TOTAL_RCV_QTY: response2[0].TOTAL_RCV_QTY,
                                        TOTAL_SHORT_QTY: response2[0].TOTAL_SHORT_QTY,
                                        TOTAL_RETAIL_AMT_SHORT: response2[0].TOTAL_RETAIL_AMT_SHORT,
                                        TOTAL_MARGIN_LOST: response2[0].TOTAL_MARGIN_LOST,
                                        PREVIOUS_CLAIMS: response2[0].PREVIOUS_CLAIMS,
                                        PAST_CLAIM_NUMBERS: response2[0].PAST_CLAIM_NUMBERS,
                                        FORMAT: response2[0].FORMAT,
                                        PO_SEQ: response2[0].SEQ_NO,
                                        VENDOR_NAME: response2[0].VENDOR_NAME,
                                        USR_CHANGE: 'YES'
                                    });

                                    let fillRatePercent = (response2[0].TOTAL_RCV_QTY / response2[0].TOTAL_ORD_QTY)*100;
                                    let val = Math.abs(response2[0].TOTAL_MARGIN_LOST * this.convertStringToNumberValue(fromValue.FILL_RATE_PCT));
                                    let calc_amountdue = val - this.convertStringToNumberValue(fromValue.TOTAL_AMT_PAID) - this.convertStringToNumberValue(fromValue.COOP_NUMBER);
                                    let calc_iepsdue = calc_amountdue * this.convertStringToNumberValue(fromValue.IEPS_PCT);
                                    let calc_IVADUE = (calc_amountdue + calc_iepsdue) * this.convertStringToNumberValue(fromValue.IVA_PCT);
                                    this.addForm.get('FILL_RATE_CALC_PCT').setValue(fillRatePercent.toFixed(2));
                                    this.addForm.get('TOTAL_EARNED').setValue(val.toFixed(2));
                                    this.addForm.get('TOTAL_PENDING').setValue(calc_amountdue.toFixed(2));
                                    this.addForm.get('IEPS_DUE').setValue(calc_iepsdue.toFixed(2));
                                    this.addForm.get('IVA_DUE').setValue(calc_IVADUE.toFixed(2));

                                    this.addForm.patchValue({
                                        FILL_RATE_CALC_PCT: fillRatePercent.toFixed(2),
                                        TOTAL_EARNED: val.toFixed(2),
                                        TOTAL_PENDING: calc_amountdue.toFixed(2),
                                        IEPS_DUE: calc_iepsdue.toFixed(2),
                                        IVA_DUE: calc_IVADUE.toFixed(2)
                                    });

                                    this.saveDisabled = false;
                                } else if (response2.length > 1) {
                                    this.editData = _.each(this.editData, (v) => {
                                        response2.forEach((val) => {
                                            if (v.RECORD_ID == val.RECORD_ID) {
                                                v.TOTAL_ORD_QTY = val.TOTAL_ORD_QTY;
                                                v.TOTAL_RCV_QTY = val.TOTAL_RCV_QTY;
                                                v.TOTAL_SHORT_QTY = val.TOTAL_SHORT_QTY;
                                                v.TOTAL_RETAIL_AMT_SHORT = val.TOTAL_RETAIL_AMT_SHORT;
                                                v.TOTAL_MARGIN_LOST = val.TOTAL_MARGIN_LOST;
                                                v.PREVIOUS_CLAIMS = val.PREVIOUS_CLAIMS;
                                                v.PAST_CLAIM_NUMBERS = val.PAST_CLAIM_NUMBERS;
                                                v.FORMAT = val.FORMAT;
                                                v.PO_SEQ = val.SEQ_NO;
                                                v.VENDOR_NAME = val.VENDOR_NAME;
                                                v.USR_CHANGE = 'YES';
                                            }

                                            let fillRatePercent = (val.TOTAL_RCV_QTY / val.TOTAL_ORD_QTY) * 100;
                                            let valu = this.convertStringToNumberValue(val.TOTAL_MARGIN_LOST) * this.convertStringToNumberValue(v.FILL_RATE_PCT);
                                            let calc_amountdue = valu - this.convertStringToNumberValue(v.TOTAL_AMT_PAID) - this.convertStringToNumberValue(v.COOP_NUMBER);
                                            let calc_iepsdue = calc_amountdue * this.convertStringToNumberValue(v.IEPS_PCT);
                                            let calc_IVADUE = (calc_amountdue + calc_iepsdue) * this.convertStringToNumberValue(v.IVA_PCT);

                                            if (fromValue.RECORD_ID == val.RECORD_ID) {
                                                this.addForm.patchValue({
                                                    TOTAL_ORD_QTY: val.TOTAL_ORD_QTY,
                                                    TOTAL_RCV_QTY: val.TOTAL_RCV_QTY,
                                                    TOTAL_SHORT_QTY: val.TOTAL_SHORT_QTY,
                                                    TOTAL_RETAIL_AMT_SHORT: val.TOTAL_RETAIL_AMT_SHORT,
                                                    TOTAL_MARGIN_LOST: val.TOTAL_MARGIN_LOST,
                                                    PREVIOUS_CLAIMS: val.PREVIOUS_CLAIMS,
                                                    PAST_CLAIM_NUMBERS: val.PAST_CLAIM_NUMBERS,
                                                    FORMAT: val.FORMAT,
                                                    PO_SEQ: val.SEQ_NO,
                                                    VENDOR_NAME: val.VENDOR_NAME,
                                                    FILL_RATE_CALC_PCT: fillRatePercent.toFixed(2),
                                                    TOTAL_EARNED: valu.toFixed(2),
                                                    TOTAL_PENDING: calc_amountdue.toFixed(2),
                                                    IEPS_DUE: calc_iepsdue.toFixed(2),
                                                    IVA_DUE: calc_IVADUE.toFixed(2),
                                                    USR_CHANGE: 'YES'
                                                });
                                            }
                                        });
                                        return v;
                                    });
                                }
                            },
                            (error) => {
                                this.showLoader = false;
                                alert(error);
                            }
                        );
                        this.showLoader = false;
                        this.valueEdited = false;
                    }
                    this.showLoader = false;
                    this.saveDisabled = false;
                });
            } else {
                this.editData = _.each(this.editData, (v) => {
                    if (this.updatingRowValue.length > 0) {
                        _.each(this.updatingRowValue, (item) => {
                            if (item != 'FILL_RATE_CALC_PCT' && item != 'TOTAL_ORD_QTY' && item != 'TOTAL_RCV_QTY' && item != 'TOTAL_MARGIN_LOST' && item != 'FILL_RATE_PCT' && item != 'TOTAL_AMT_PAID' && item != 'COOP_NUMBER' && item != 'TOTAL_PENDING' && item != 'TOTAL_EARNED' && item != 'IEPS_DUE' && item != 'IVA_DUE') v[item] = fromValue[item];
                        });
                    }
                    return v;
                });
                this.valueEdited = false;
            }
        }

       
        
        else if (this.pageID === 'NEW-STORE' && !this.valueEdited) {
            this.valueEdited = true;
            const fromValue = this.addForm.getRawValue();
            const editableCalculateRow = ['UFAMT', 'FIXED_AMT', 'TOTAL_DUE', 'AMT_DEDUCTED', 'OTHER_DEDUCTION'];
            const apiCallCalculation = ['SUPPLIER_NUM', 'CATEGORY', 'DEPT', 'STORE_NBR'];
            const findApiCal = apiCallCalculation.indexOf(data);
            const findData = editableCalculateRow.indexOf(data);
            if (findData > -1) {
                if (data === 'FIXED_AMT' || data === 'UFAMT') {
                    this.editData = _.each(this.editData, (v) => {
                        const oldTotalDue = this.convertStringToNumberValue(v.AMOUNT_DUE);
                        const UFAMT = data === 'UFAMT' ? fromValue.UFAMT : this.convertStringToNumberValue(v.UFAMT);
                        const FIXED_AMT = data === 'FIXED_AMT' ? fromValue.FIXED_AMT : this.convertStringToNumberValue(v.FIXED_AMT);
                        const totalDue = Number(this.convertStringToNumberValue(UFAMT)) * Number(FIXED_AMT);
                        const amountDue = Number(totalDue) + Number(this.convertStringToNumberValue(v.AMT_DEDUCTED)) + Number(this.convertStringToNumberValue(v.OTHER_DEDUCTION));
                        // const amountDue = Number(totalDue) + Number(fromValue.AMT_DEDUCTED) + Number(fromValue.OTHER_DEDUCTION);

                        v['UFAMT'] = UFAMT;
                        v['FIXED_AMT'] = FIXED_AMT;
                        v['TOTAL_DUE'] = totalDue;
                        v['AMOUNT_DUE'] = amountDue;
                        if (oldTotalDue !== amountDue) {
                            v['CHANGE_USER'] = 'Y';
                        }
                        return v;
                    });
                    const oldTotalDue = fromValue.AMOUNT_DUE;
                    const totalDue = Number(this.convertStringToNumberValue(fromValue.UFAMT)) * Number(fromValue.FIXED_AMT);
                    const amountDue = Number(totalDue) - Math.abs(Number(fromValue.AMT_DEDUCTED)) - Number(fromValue.OTHER_DEDUCTION);
                    this.addForm.get('TOTAL_DUE').setValue(totalDue);
                    this.addForm.get('AMOUNT_DUE').setValue(amountDue);
                    if (oldTotalDue !== amountDue) {
                        this.addForm.get('CHANGE_USER').setValue('Y');
                    }
                } else if (data === 'AMT_DEDUCTED' || data === 'OTHER_DEDUCTION') {
                    this.editData = _.each(this.editData, (v) => {
                        const totalDue = this.convertStringToNumberValue(v.TOTAL_DUE);
                        const AMT_DEDUCTED = data === 'AMT_DEDUCTED' ? fromValue.AMT_DEDUCTED : this.convertStringToNumberValue(v.AMT_DEDUCTED);
                        const OTHER_DEDUCTION = data === 'OTHER_DEDUCTION' ? fromValue.OTHER_DEDUCTION : this.convertStringToNumberValue(v.OTHER_DEDUCTION);
                        const amountDue = Number(totalDue) + Number(AMT_DEDUCTED) + Number(OTHER_DEDUCTION);
                        v['AMOUNT_DUE'] = amountDue;
                        v['AMT_DEDUCTED'] = AMT_DEDUCTED;
                        v['OTHER_DEDUCTION'] = OTHER_DEDUCTION;
                        return v;
                    });
                    const totalDue = fromValue.TOTAL_DUE;
                    const amountDue = Number(totalDue) + Number(fromValue.AMT_DEDUCTED) + Number(fromValue.OTHER_DEDUCTION);
                    this.addForm.get('AMOUNT_DUE').setValue(amountDue);
                }
                this.valueEdited = false;
            } else if (findApiCal > -1) {
                this.showLoader = true;
                let finalPayload = [];
                if (this.editData.length > 1) {
                    finalPayload = _.map(this.editData, (v, i) => {
                        const payload = {
                            RECORD_ID: v.RECORD_ID,
                            AUDIT_YEAR: data === 'AUDIT_YEAR' ? fromValue.AUDIT_YEAR : v.AUDIT_YEAR,
                            SUPPLIER_NUM: data === 'SUPPLIER_NUM' ? fromValue.SUPPLIER_NUM : v.SUPPLIER_NUM,
                            CATEGORY: data === 'CATEGORY' ? fromValue.CATEGORY : v.CATEGORY,
                            DEPT: data === 'DEPT' ? fromValue.DEPT : v.DEPT,
                            STORE_NBR: data === 'STORE_NBR' ? fromValue.STORE_NBR : v.STORE_NBR
                        };
                        this.editData[i] = {
                            ...v,
                            ...payload
                        };
                        return payload;
                    });
                } else {
                    const payload = {
                        RECORD_ID: fromValue.RECORD_ID,
                        AUDIT_YEAR: fromValue.AUDIT_YEAR,
                        SUPPLIER_NUM: fromValue.SUPPLIER_NUM,
                        CATEGORY: fromValue.CATEGORY,
                        DEPT: fromValue.DEPT,
                        STORE_NBR: fromValue.STORE_NBR
                    };
                    finalPayload.push(payload);
                }

                const findKeyBlankValue = _.find(finalPayload, (v) => {
                    let isFind = false;
                    for (const [key, value] of Object.entries(v)) {
                        if (v[key] === null && v[key] === '') {
                            isFind = true;
                            break;
                        }
                    }
                    if (isFind) {
                        return v;
                    }
                });
                if (findKeyBlankValue) {
                    this.showLoader = false;
                    this.valueEdited = false;
                    alert('SUPPLIER_NUM, CATEGORY,DEPT , STORE_NBR please provide all the file for calculation!');
                    return;
                } else {
                    this.apiservice.getCalculatedValue(finalPayload).subscribe(
                        (result: any) => {
                            this.saveDisabled = false;
                            if (result.length > 0) {
                                if (result.length != 0 && finalPayload.length != result.length) {
                                    this.showLoader = false;
                                    this.valueEdited = false;
                                    if (confirm('Some of the record id not have valid combination.!\nDo You want to proceed or cancel it') == true) {
                                        this.saveDisabled = true;
                                    } else {
                                        this.closeModal();
                                        this.saveDisabled = false;
                                    }
                                } else {
                                    this.editData = _.map(this.editData, (v) => {
                                        const findValue = _.find(result, (item) => {
                                            return item.RECORD_ID === v.RECORD_ID;
                                        });
                                        const finalValue = this.calculateValue({
                                            ...v,
                                            CHANGE_USER: 'Y',
                                            ...findValue
                                        });
                                        return finalValue;
                                    });
                                    if (this.editData.length > 1) {
                                        const fistIndexValue = this.createFileValue(this.editData[0]);
                                        fistIndexValue['AUDIT_YEAR'] = [
                                            {
                                                value: fromValue.AUDIT_YEAR,
                                                disabled: this.getEditableColumn('AUDIT_YEAR')
                                            }
                                        ];
                                        this.addForm = this.formBuilder.group(fistIndexValue);
                                        this.showForm = true;
                                    } else {
                                        const getFromData = this.addForm.getRawValue();
                                        const resultData = result[0];
                                        const fistIndexValue = this.createFileValue({
                                            ...getFromData,
                                            ...resultData
                                        });
                                        fistIndexValue['AUDIT_YEAR'] = [
                                            {
                                                value: fromValue.AUDIT_YEAR,
                                                disabled: this.getEditableColumn('AUDIT_YEAR')
                                            }
                                        ];
                                        this.singleAuditYear = fromValue.AUDIT_YEAR != undefined ? fromValue.AUDIT_YEAR : this.singleAuditYear;
                                        this.addForm = this.formBuilder.group(fistIndexValue);
                                        this.showForm = true;
                                        this.addForm.patchValue({
                                            RECORD_ID: result[0].RECORD_ID,
                                            RUT: result[0].RUT,
                                            SUPPLIER_NAME: result[0].SUPPLIER_NAME,
                                            SUPPLIER_NUM: result[0].SUPPLIER_NUM,
                                            PRIVATE_LABEL: result[0].PRIVATE_LABEL,
                                            DEPT: result[0].DEPT,
                                            LEGACY_CATEGORY: result[0].LEGACY_CATEGORY,
                                            CATEGORY: result[0].CATEGORY,
                                            STORE_NBR: result[0].STORE_NBR,
                                            MIN_SALES: result[0].MIN_SALES,
                                            MAX_SALES: result[0].MAX_SALES,
                                            TOTAL_SALES: result[0].TOTAL_SALES,
                                            TYPE: result[0].TYPE,
                                            OPEN_DATE: result[0].OPEN_DATE,
                                            FORMAT: result[0].FORMAT,
                                            UFAMT: result[0].UFAMT,
                                            AMT_DEDUCTED: result[0].AMT_DEDUCTED,
                                        });
                                        this.singleDataValue();
                                    }
                                }
                                this.showLoader = false;
                                this.valueEdited = false;
                            } else {
                                this.showLoader = false;
                                this.valueEdited = false;
                                alert('Not a Valid combination');
                            }

                            this.showLoader = false;
                            this.valueEdited = false;
                        },
                        (error) => {
                            this.showLoader = false;
                            this.valueEdited = false;
                        }
                    );
                }
            } else {
                /* This condition is used to update the items that are not calculated or fetched from the API but selected 
                in the form for the multiple edit values like Status, Auditor etc */
                this.editData = _.each(this.editData, (v) => {
                    if (this.updatingRowValue.length > 0) {
                        _.each(this.updatingRowValue, (item) => {
                            if (item != 'UFAMT' && item != 'FIXED_AMT' && item != 'TOTAL_DUE' && item != 'AMT_DEDUCTED' && item != 'OTHER_DEDUCTION' 
                            && item != 'SUPPLIER_NUM' && item != 'CATEGORY' && item != 'DEPT' && item != 'STORE_NBR') v[item] = fromValue[item];
                        });
                    }
                    return v;
                });
                this.valueEdited = false;
            }
        } else if (this.pageID === 'UNDED-COOPS'  && !this.valueEdited) {
                /* This condition is used to update the items that are not calculated or fetched from the API but selected 
                in the form for the multiple edit values like Status, Auditor etc */
                const fromValue = this.addForm.getRawValue();
                this.editData = _.each(this.editData, (v) => {
                    if (this.updatingRowValue.length > 0) {
                        _.each(this.updatingRowValue, (item) => {
                            v['DATE_AUDITED'] = fromValue['DATE_AUDITED'];
                            v['QR_STATUS'] = fromValue['QR_STATUS'];
                            v['NOTES'] = fromValue['NOTES'];
                            v['RELEASE_NUM'] = fromValue['RELEASE_NUM'];
                            v['ASSIGNED_USERNAME'] = fromValue['ASSIGNED_USERNAME'];
                        });
                    }
                    return v;
                });
                this.valueEdited = false;
        } else if (this.pageID === 'Scan Allowances' && !this.valueEdited) {
            this.valueEdited = true;
            let fromValue = this.addForm.getRawValue();
            const editableCalculateRow = ['AMS_RATE', 'POS_SALES_QTY', 'Accrual_AMT', 'Adjustments', 'IEPS_PCT', 'IVA_PCT'];
            const apiCallCalculation = ['ITEM_DESC', 'Valid_From', 'Valid_To', 'DEPARTMENT', 'FORMAT', 'Item_Nbr', 'Agreement_Nbr', 'VENDOR_NBR'];
            const findApiCal = apiCallCalculation.indexOf(data);
            const findData = editableCalculateRow.indexOf(data);

            if (findData > -1) {
                if (this.addForm.value.IEPS_PCT == null || this.addForm.value.IEPS_PCT == 'null') {
                    this.addForm.patchValue({
                        IEPS_PCT: 0
                    });
                }
                fromValue = this.addForm.getRawValue();
                let ams_rate, Adjustments;
                this.editData = _.each(this.editData, (v) => {
                    if (data == 'AMS_RATE') {
                        ams_rate = fromValue.AMS_RATE;
                    } else {
                        ams_rate = v.AMS_RATE;
                    }

                    if (data == 'Adjustments') {
                        Adjustments = fromValue.Adjustments;
                    } else {
                        Adjustments = v.Adjustments;
                    }
                    let val = this.convertStringToNumberValue(ams_rate) * this.convertStringToNumberValue(v.POS_SALES_QTY);
                    let calc_amountdue = val - v.Accrual_AMT - this.convertStringToNumberValue(Adjustments);
                    let calc_iepsdue = calc_amountdue * this.convertStringToNumberValue(fromValue.IEPS_PCT);
                    let calc_IVADUE = (calc_amountdue + calc_iepsdue) * this.convertStringToNumberValue(fromValue.IVA_PCT);
                    v['AMS_RATE'] = ams_rate;
                    v['Adjustments'] = Adjustments;
                    v['ALLOW_EARNED'] = val.toFixed(2);
                    v['AMT_DUE'] = calc_amountdue.toFixed(2);
                    v['IEPS_DUE'] = calc_iepsdue.toFixed(2);
                    v['IVA_DUE'] = calc_IVADUE.toFixed(2);
                    return v;
                });

                let val = this.convertStringToNumberValue(fromValue.AMS_RATE) * this.convertStringToNumberValue(fromValue.POS_SALES_QTY);
                let calc_amountdue = val - fromValue.Accrual_AMT - this.convertStringToNumberValue(fromValue.Adjustments);
                let calc_iepsdue = calc_amountdue * this.convertStringToNumberValue(fromValue.IEPS_PCT);
                let calc_IVADUE = (calc_amountdue + calc_iepsdue) * this.convertStringToNumberValue(fromValue.IVA_PCT);
                this.addForm.get('ALLOW_EARNED').setValue(val.toFixed(2));
                this.addForm.get('AMT_DUE').setValue(calc_amountdue.toFixed(2));
                this.addForm.get('IEPS_DUE').setValue(calc_iepsdue.toFixed(2));
                this.addForm.get('IVA_DUE').setValue(calc_IVADUE.toFixed(2));
                this.valueEdited = false;
            } else if (findApiCal > -1) {
                if (data == 'FORMAT') {
                    _.each(this.FORMAT_SEQUENCE, (item) => {
                        if (fromValue.FORMAT == item.FORMAT) {
                            this.addForm.patchValue({
                                SEQ_NO: item.SEQ
                            });
                            if (item.SEQ !== 2) {
                                this.addForm.patchValue({
                                    ALL_BODEGA_POS_AMT: 0.0,
                                    ALL_BODEGA_POS_QTY: 0.0
                                });
                            } else {
                                this.addForm.patchValue({
                                    ALL_BODEGA_POS_AMT: this.rowDataObject.ALL_BODEGA_POS_AMT,
                                    ALL_BODEGA_POS_QTY: this.rowDataObject.ALL_BODEGA_POS_QTY
                                });
                            }
                        }
                    });
                }
                this.valueEdited = false;
            } else {
                // To update other non calculation data
                this.editData = _.each(this.editData, (v) => {
                    if (this.updatingRowValue.length > 0) {
                        _.each(this.updatingRowValue, (item) => {
                            if (item != 'IVA_DUE' || (item != 'AMS_RATE' && item != 'POS_SALES_QTY' && item != 'Accrual_AMT' && item != 'Adjustments' && item != 'ALLOW_EARNED' && item != 'AMT_DUE' && item != 'IEPS_DUE' && item != 'IVA_DUE')) v[item] = fromValue[item];
                        });
                    }
                    return v;
                });
                this.valueEdited = false;
            }

           
        } else if (this.pageID === 'New Stores' && !this.valueEdited) {
            this.valueEdited = true;
            const fromValue = this.addForm.getRawValue();
            const editableCalculateRow = ['COOP_DEDUCTED', 'ALLOW_EARNED', 'AMTDEDUCTED', 'IEPS_PCT', 'IVA_PCT'];

            const findData = editableCalculateRow.indexOf(data);
            if (findData > -1) {
                let ALLOW_EARNED, AMTDEDUCTED, COOP_DEDUCTED;
                this.editData = _.each(this.editData, (v) => {
                    if (data == 'ALLOW_EARNED') {
                        ALLOW_EARNED = fromValue.ALLOW_EARNED;
                    } else {
                        ALLOW_EARNED = v.ALLOW_EARNED;
                    }

                    if (data == 'AMTDEDUCTED') {
                        AMTDEDUCTED = fromValue.AMTDEDUCTED;
                    } else {
                        AMTDEDUCTED = v.AMTDEDUCTED;
                    }

                    if (data == 'COOP_DEDUCTED') {
                        COOP_DEDUCTED = fromValue.COOP_DEDUCTED;
                    } else {
                        COOP_DEDUCTED = v.COOP_DEDUCTED;
                    }
                    let val = this.convertStringToNumberValue(ALLOW_EARNED) + this.convertStringToNumberValue(AMTDEDUCTED) + this.convertStringToNumberValue(COOP_DEDUCTED);
                    let calc_ieps = val * (this.convertStringToNumberValue(fromValue.IEPS_PCT) / 100);
                    let calc_iva = (val + calc_ieps) * (this.convertStringToNumberValue(fromValue.IVA_PCT) / 100);
                    v['ALLOW_EARNED'] = ALLOW_EARNED;
                    v['AMTDEDUCTED'] = AMTDEDUCTED;
                    v['COOP_DEDUCTED'] = COOP_DEDUCTED;
                    v['AMT_DUE'] = val.toFixed(2);
                    v['IEPS'] = calc_ieps.toFixed(2);
                    v['IVA'] = calc_iva.toFixed(2);
                    return v;
                });

                // Single
                let val = this.convertStringToNumberValue(fromValue.ALLOW_EARNED) + this.convertStringToNumberValue(fromValue.AMTDEDUCTED) + this.convertStringToNumberValue(fromValue.COOP_DEDUCTED);
                let calc_ieps = val * (this.convertStringToNumberValue(fromValue.IEPS_PCT) / 100);
                let calc_iva = (val + calc_ieps) * (this.convertStringToNumberValue(fromValue.IVA_PCT) / 100);
                this.addForm.get('AMT_DUE').setValue(val.toFixed(2));
                this.addForm.get('IEPS').setValue(calc_ieps.toFixed(2));
                this.addForm.get('IVA').setValue(calc_iva.toFixed(2));
                this.valueEdited = false;
            } else {
                this.editData = _.each(this.editData, (v) => {
                    if (this.updatingRowValue.length > 0) {
                        _.each(this.updatingRowValue, (item) => {
                            if (item != 'AMT_DUE' && item != 'COOP_DEDUCTED' && item != 'ALLOW_EARNED' && item != 'AMTDEDUCTED' && item != 'IEPS' && item != 'IVA') v[item] = fromValue[item];
                        });
                    }
                    return v;
                });
                this.valueEdited = false;
            }
        } else if (this.pageID === 'Volume Allowances' && !this.valueEdited) {
            this.valueEdited = true;
            let fromValue = this.addForm.getRawValue();
            const editableCalculateRow = ['PCT', 'OTHER_DED', 'IEPS_PCT', 'IVA_PCT'];
            const apiCallCalculation = ['VENDOR_NUMBER', 'START_DATE', 'END_DATE', 'DEPARTMENT', 'FORMAT', 'SEQ_NO', 'DEAL'];
            const findApiCal = apiCallCalculation.indexOf(data);
            const findData = editableCalculateRow.indexOf(data);
            let PCT, OTHER_DED;
            if (findData > -1) {
                // Multipe
                this.editData = _.each(this.editData, (v) => {
                    if (data == 'PCT') {
                        PCT = fromValue.PCT;
                    } else {
                        PCT = v.PCT;
                    }
                    if (data == 'OTHER_DED') {
                        OTHER_DED = fromValue.OTHER_DED;
                    } else {
                        OTHER_DED = v.OTHER_DED;
                    }
                    let AmtVal = this.convertStringToNumberValue(v.PO_PURCHASES) * this.convertStringToNumberValue(PCT);
                    let val = AmtVal - this.convertStringToNumberValue(v.ENTERED_AMT) - this.convertStringToNumberValue(OTHER_DED);
                    let calc_ieps = val * this.convertStringToNumberValue(fromValue.IEPS_PCT);
                    let calc_iva = (val + calc_ieps) * this.convertStringToNumberValue(fromValue.IVA_PCT);
                    let totalVal = val + calc_ieps + calc_iva;
                    v['PCT'] = PCT;
                    v['OTHER_DED'] = OTHER_DED;
                    v['ALLOW_EARNED'] = AmtVal.toFixed(2);
                    v['AMT_DUE'] = val.toFixed(2);
                    v['IEPS'] = calc_ieps.toFixed(2);
                    v['IVA'] = calc_iva.toFixed(2);
                    v['TOTAL_DUE'] = totalVal.toFixed(2);
                    return v;
                });

                // Single
                let AmtVal = this.convertStringToNumberValue(fromValue.PO_PURCHASES) * this.convertStringToNumberValue(fromValue.PCT);
                let val = AmtVal - this.convertStringToNumberValue(fromValue.ENTERED_AMT) - this.convertStringToNumberValue(fromValue.OTHER_DED);
                let calc_ieps = val * this.convertStringToNumberValue(fromValue.IEPS_PCT);
                let calc_iva = (val + calc_ieps) * this.convertStringToNumberValue(fromValue.IVA_PCT);
                let totalVal = val + calc_ieps + calc_iva;
                this.addForm.get('ALLOW_EARNED').setValue(AmtVal.toFixed(2));
                this.addForm.get('AMT_DUE').setValue(val.toFixed(2));
                this.addForm.get('IEPS').setValue(calc_ieps.toFixed(2));
                this.addForm.get('IVA').setValue(calc_iva.toFixed(2));
                this.addForm.get('TOTAL_DUE').setValue(totalVal.toFixed(2));
                this.valueEdited = false;
            } else if (findApiCal > -1) {
                if (data == 'SEQ_NO') {
                    _.each(this.SEQUENCE_FORMAT, (item) => {
                        if (fromValue.SEQ_NO == item.SEQ) {
                            this.addForm.patchValue({
                                FORMAT: item.FORMAT
                            });
                        }
                    });
                }
                this.valueEdited = false; 
            } else {
                this.editData = _.each(this.editData, (v) => {
                    if (this.updatingRowValue.length > 0) {
                        _.each(this.updatingRowValue, (item) => {
                            if (item != 'TOTAL_DUE' && item != 'PO_PURCHASES' && item != 'PCT' && item != 'ENTERED_AMT' && item != 'OTHER_DED' && item != 'ALLOW_EARNED' && item != 'AMT_DUE' && item != 'IEPS' && item != 'IVA') v[item] = fromValue[item];
                        });
                    }
                    return v;
                });
                this.valueEdited = false;
            }
        } else if (this.pageID === 'UN-DEDUCTED COOPS' && !this.valueEdited) {
            this.valueEdited = true;
            const fromValue = this.addForm.getRawValue();
            const editableCalculateRow = ['BASE_AMT_DUE', 'IVA_DUE', 'IEPS_DUE', 'ADJUSTMENTS', 'IVA_PCT', 'IEPS_PCT', 'AMT_DUE', 'BASE_AMT_DEDUCT', 'BASE_COOP_AMT'];
            const findData = editableCalculateRow.indexOf(data);
            let ADJUSTMENTS;
            if (findData > -1) {
                // Multipe
                this.editData = _.each(this.editData, (v) => {
                    if (data == 'ADJUSTMENTS') {
                        ADJUSTMENTS = fromValue.ADJUSTMENTS;
                    } else {
                        ADJUSTMENTS = v.ADJUSTMENTS;
                    }
                    let AmtVal = this.convertStringToNumberValue(v.BASE_AMT_DEDUCT) + this.convertStringToNumberValue(v.BASE_COOP_AMT) + this.convertStringToNumberValue(ADJUSTMENTS);
                    let calc_ieps = AmtVal * this.convertStringToNumberValue(fromValue.IEPS_PCT);
                    let calc_iva = (AmtVal + calc_ieps) * this.convertStringToNumberValue(fromValue.IVA_PCT);
                    let amt_deduct = (AmtVal + calc_iva + calc_ieps);
                    v['ADJUSTMENTS'] = ADJUSTMENTS;
                    v['BASE_AMT_DUE'] = AmtVal.toFixed(2);
                    v['IVA_DUE'] = calc_iva.toFixed(2);
                    v['IEPS_DUE'] = calc_ieps.toFixed(2);
                    v['AMT_DEDUCT_TAXES'] = amt_deduct.toFixed(2)
                    return v;
                });

                // Single
                let AmtVal = this.convertStringToNumberValue(fromValue.BASE_AMT_DEDUCT) + this.convertStringToNumberValue(fromValue.BASE_COOP_AMT) + this.convertStringToNumberValue(fromValue.ADJUSTMENTS);
                let calc_ieps = AmtVal * this.convertStringToNumberValue(fromValue.IEPS_PCT);
                let calc_iva = (AmtVal + calc_ieps) * this.convertStringToNumberValue(fromValue.IVA_PCT);
                let amt_deduct = (AmtVal + calc_iva + calc_ieps);
                console.log(amt_deduct)
                this.addForm.get('BASE_AMT_DUE').setValue(AmtVal.toFixed(2));
                this.addForm.get('IVA_DUE').setValue(calc_iva.toFixed(2));
                this.addForm.get('IEPS_DUE').setValue(calc_ieps.toFixed(2));
                this.addForm.get('AMT_DEDUCT_TAXES').setValue(amt_deduct.toFixed(2));
                this.valueEdited = false;
            } else {
                this.editData = _.each(this.editData, (v) => {
                    if (this.updatingRowValue.length > 0) {
                        _.each(this.updatingRowValue, (item) => {
                            if (item != 'AMT_DEDUCT_TAXES' && item != 'BASE_AMT_DEDUCT' && item != 'BASE_COOP_AMT' && item != 'ADJUSTMENTS' && item != 'BASE_AMT_DUE' && item != 'IEPS_DUE' && item != 'IVA_DUE') v[item] = fromValue[item];
                        });
                    }
                    return v;
                });
                this.valueEdited = false;
            }
        } else if (this.pageID === 'Markdown' && !this.valueEdited) {
            this.valueEdited = true;
            const editableCalculateRow = ['IMPACT_MU/MD','AGREEMENT_AMOUNT','IEPS','VAT'];
            const findData = editableCalculateRow.indexOf(data);
        
            
            if (findData > -1) {
                let fromValue = this.addForm.getRawValue(); 
                let IMPACT, AGREEMENT_AMOUNT, IEPS, VAT;
                this.editData = this.editData.map(v => {
                    if (data == 'IMPACT_MU/MD') {
                        IMPACT = fromValue['IMPACT_MU/MD'];
                    } else {
                        IMPACT = v['IMPACT_MU/MD'];
                    }

                    if (data == 'AGREEMENT_AMOUNT') {
                        AGREEMENT_AMOUNT = fromValue.AGREEMENT_AMOUNT;
                    } else {
                        AGREEMENT_AMOUNT = v.AGREEMENT_AMOUNT;
                    }

                    if (data == 'IEPS') {
                        IEPS = fromValue.IEPS;
                    } else {
                        IEPS = v.IEPS;
                    }
                    if (data == 'VAT') {
                        VAT = fromValue.VAT;
                    } else {
                        VAT = v.VAT;
                    }
                    let difference = this.convertStringToNumberValue(IMPACT) - this.convertStringToNumberValue(AGREEMENT_AMOUNT);
                    let calc_ieps = difference * this.convertStringToNumberValue(IEPS);
                    let calc_iva = Math.abs((difference + calc_ieps) * this.convertStringToNumberValue(VAT));
                    let due = difference + calc_ieps + calc_iva;
                    v['DIFFERENCE'] = difference.toFixed(2);
                    v['IEPS_DUE'] = calc_ieps.toFixed(2);
                    v['IVA_DUE'] = calc_iva.toFixed(2);
                    v['TOTAL_DUE'] = due.toFixed(2);
                    return v;
                });
        
                let difference = this.convertStringToNumberValue(fromValue['IMPACT_MU/MD']) - this.convertStringToNumberValue(fromValue.AGREEMENT_AMOUNT);
                let calc_ieps = difference * this.convertStringToNumberValue(fromValue.IEPS);
                let calc_iva = Math.abs((difference + calc_ieps) * this.convertStringToNumberValue(fromValue.VAT));
                let due = difference + calc_ieps + calc_iva;
				
                this.addForm.get('DIFFERENCE').setValue(difference.toFixed(2));
                this.addForm.get('IEPS_DUE').setValue(calc_ieps.toFixed(2));
                this.addForm.get('IVA_DUE').setValue(calc_iva.toFixed(2));
                this.addForm.get('TOTAL_DUE').setValue(due.toFixed(2));
            }
        
            this.valueEdited = false;
        }
        
        else if (this.pageID === 'Advertising' && !this.valueEdited) {
            this.valueEdited = true;
            const fromValue = this.addForm.getRawValue();
            const editableCalculateRow = ['OTHER_DEDUCT', 'ALLOW_EARNED', 'ENTERED_AMT', 'AMTDUE', 'IEPS_PCT', 'IVA_PCT'];
            const apiCallCalculation = ['VENDOR_NBR'];
            const findApiCal = apiCallCalculation.indexOf(data);
            const findData = editableCalculateRow.indexOf(data);

            if (findApiCal > -1) {
                this.showLoader = true;
                this.apiservice.getVendorandSapDetails(fromValue.VENDOR_NBR).subscribe((data: any) => {
                    if (data.length == 0) {
                        alert('Not a valid combination');
                        // Reverting to the original data
                        this.addForm.patchValue({
                            VENDOR_NBR: this.rowDataObject.VENDOR_NBR,
                            SAP_VENDOR: this.rowDataObject.SAP_VENDOR,
                            VENDOR_NAME: this.rowDataObject.VENDOR_NAME
                        });
                    } else {
                        this.addForm.patchValue({
                            SAP_VENDOR: data[0].SAP_NUMBER,
                            VENDOR_NAME: data[0].VENDOR_NAME
                        });
                    }
                    this.showLoader = false;
                    this.saveDisabled = false;
                });
            } else if (findData > -1) {
                let OTHER_DEDUCT, ALLOW_EARNED, ENTERED_AMT;
                // Multipe
                this.editData = _.each(this.editData, (v) => {
                    if (data == 'ALLOW_EARNED') {
                        ALLOW_EARNED = fromValue.ALLOW_EARNED;
                    } else {
                        ALLOW_EARNED = v.ALLOW_EARNED;
                    }

                    if (data == 'ENTERED_AMT') {
                        ENTERED_AMT = fromValue.ENTERED_AMT;
                    } else {
                        ENTERED_AMT = v.ENTERED_AMT;
                    }

                    if (data == 'OTHER_DEDUCT') {
                        OTHER_DEDUCT = fromValue.OTHER_DEDUCT;
                    } else {
                        OTHER_DEDUCT = v.OTHER_DEDUCT;
                    }
                    let val = this.convertStringToNumberValue(ALLOW_EARNED) - this.convertStringToNumberValue(ENTERED_AMT) - this.convertStringToNumberValue(v.OTHER_DEDUCT);
                    let calc_ieps = val * this.convertStringToNumberValue(fromValue.IEPS_PCT);
                    let calc_iva = Math.abs((val + calc_ieps) * this.convertStringToNumberValue(fromValue.IVA_PCT));
                    let due = val + calc_ieps + calc_iva;
                    v['ALLOW_EARNED'] = ALLOW_EARNED;
                    v['ENTERED_AMT'] = ENTERED_AMT;
                    v['OTHER_DEDUCT'] = OTHER_DEDUCT;
                    v['AMTDUE'] = val.toFixed(2);
                    v['IEPS_AMOUNT'] = calc_ieps.toFixed(2);
                    v['IVA_AMOUNT'] = calc_iva.toFixed(2);
                    v['TOTAL_DUE'] = due.toFixed(2);
                    return v;
                });

                // Single
                let val = this.convertStringToNumberValue(fromValue.ALLOW_EARNED) - this.convertStringToNumberValue(fromValue.ENTERED_AMT) - this.convertStringToNumberValue(fromValue.OTHER_DEDUCT);
                let calc_ieps = val * this.convertStringToNumberValue(fromValue.IEPS_PCT);
                let calc_iva = Math.abs((val + calc_ieps) * this.convertStringToNumberValue(fromValue.IVA_PCT));
                let due = val + calc_ieps + calc_iva;
                this.addForm.get('AMTDUE').setValue(val.toFixed(2));
                this.addForm.get('IEPS_AMOUNT').setValue(calc_ieps.toFixed(2));
                this.addForm.get('IVA_AMOUNT').setValue(calc_iva.toFixed(2));
                this.addForm.get('TOTAL_DUE').setValue(due.toFixed(2));
                this.valueEdited = false;
            } else {
                this.editData = _.each(this.editData, (v) => {
                    if (this.updatingRowValue.length > 0) {
                        _.each(this.updatingRowValue, (item) => {
                            if (item != 'TOTAL_DUE' && item != 'AMTDUE' && item != 'OTHER_DEDUCT' && item != 'ALLOW_EARNED' && item != 'ENTERED_AMT' && item != 'IEPS_AMOUNT' && item != 'IVA_AMOUNT') v[item] = fromValue[item];
                        });
                    }
                    return v;
                });
                this.valueEdited = false;
            }
        } 
        else if (this.pageID === 'MSI' && !this.valueEdited) {
            this.valueEdited = true;
            let fromValue = this.addForm.getRawValue();
            const editableCalculateRow = ['IVA_PCT', 'AMT_DUE', 'DEDUCTION', 'OTHER_DEDUCTIONS', 'MSI_PCT'];
            const findData = editableCalculateRow.indexOf(data);

            if (findData > -1) {
                fromValue = this.addForm.getRawValue();

                let OTHER_DEDUCTIONS, MSI_PCT;
                // Multipe
                this.editData = _.each(this.editData, (v) => {

                    if (data == 'OTHER_DEDUCTIONS') {
                        OTHER_DEDUCTIONS = fromValue.OTHER_DEDUCTIONS;
                    } else {
                        OTHER_DEDUCTIONS = v.OTHER_DEDUCTIONS;
                    }

                    if (data == 'MSI_PCT') {
                        MSI_PCT = fromValue.MSI_PCT;
                    } else {
                        MSI_PCT = v.MSI_PCT;
                    }

                    let calc_amountdue = (this.convertStringToNumberValue(v.SURCHARGE_SALES) * (this.convertStringToNumberValue(MSI_PCT) / 100)) - this.convertStringToNumberValue(v.DEDUCTION) - this.convertStringToNumberValue(OTHER_DEDUCTIONS);
                    let calc_IVADUE = calc_amountdue * this.convertStringToNumberValue(fromValue.IVA_PCT);
                    v['AMT_DUE'] = calc_amountdue.toFixed(2);
                    v['IVA'] = calc_IVADUE.toFixed(2);
                    v['OTHER_DEDUCTIONS'] = OTHER_DEDUCTIONS;
                    v['MSI_PCT'] = MSI_PCT;
                    v['IVA_PCT'] = fromValue.IVA_PCT;
                    return v;
                });

                // Single
                let calc_amountdue = (this.convertStringToNumberValue(fromValue.SURCHARGE_SALES)  * (this.convertStringToNumberValue(fromValue.MSI_PCT) / 100)) - this.convertStringToNumberValue(fromValue.DEDUCTION) - this.convertStringToNumberValue(fromValue.OTHER_DEDUCTIONS);
                let calc_IVADUE = calc_amountdue * this.convertStringToNumberValue(fromValue.IVA_PCT);
                this.addForm.get('AMT_DUE').setValue(calc_amountdue.toFixed(2));;
                this.addForm.get('IVA').setValue(calc_IVADUE.toFixed(2));
                this.valueEdited = false;
            } else {
                /* This condition is used to update the items that are not calculated or fetched from the API but selected 
                in the form for the multiple edit values like Status, Auditor etc */
                this.editData = _.each(this.editData, (v) => {
                    if (this.updatingRowValue.length > 0) {
                        _.each(this.updatingRowValue, (item) => {
                            if (item != 'AMT_DUE' && item != 'IVA' && item != 'OTHER_DEDUCTIONS' && item != 'MSI_PCT' && item != 'IVA_PCT' ) v[item] = fromValue[item];
                        });
                    }
                    return v;
                });
                this.valueEdited = false;
            }
        }  else if (this.pageID === 'Low Price'  && !this.valueEdited) {
            /* This condition is used to update the items that are not calculated or fetched from the API but selected 
            in the form for the multiple edit values like Status, Auditor etc */
            const fromValue = this.addForm.getRawValue();
            this.editData = _.each(this.editData, (v) => {
                if (this.updatingRowValue.length > 0) {
                    _.each(this.updatingRowValue, (item) => {
                        v['RELEASE'] = fromValue['RELEASE'];
                        v['AUDITOR'] = fromValue['AUDITOR'];
                        v['COMPLETE_DT'] = fromValue['COMPLETE_DT'];
                        v['STATUS'] = fromValue['STATUS'];
                        v['COMMENTS'] = fromValue['COMMENTS'];
                    });
                }
                return v;
            });
            this.valueEdited = false;
        }  else if (this.pageID === 'Shortages'  && !this.valueEdited) {
            /* This condition is used to update the items that are not calculated or fetched from the API but selected 
            in the form for the multiple edit values like Status, Auditor etc */
            const fromValue = this.addForm.getRawValue();
            this.editData = _.each(this.editData, (v) => {
                if (this.updatingRowValue.length > 0) {
                    _.each(this.updatingRowValue, (item) => {
                        v['RELEASE'] = fromValue['RELEASE'];
                        v['AUDITOR'] = fromValue['AUDITOR'];
                        v['COMPLETE_DT'] = fromValue['COMPLETE_DT'];
                        v['STATUS'] = fromValue['STATUS'];
                        v['COMMENTS'] = fromValue['COMMENTS'];
                    });
                }
                return v;
            });
            this.valueEdited = false;
        } else if (this.pageID === 'MISC' && !this.valueEdited) {
            this.valueEdited = true;
            const fromValue = this.addForm.getRawValue();
            const editableCalculateRow = ['AMOUNT_DEDUCTED', 'PENDING_DUE', 'EARNED', 'IEPS_PCT', 'IVA_PCT'];
            const apiCallCalculation = ['VENDOR_NUM'];
            const findApiCal = apiCallCalculation.indexOf(data);
            const findData = editableCalculateRow.indexOf(data);

            if (findApiCal > -1) {
                this.showLoader = true;
                this.apiservice.getVendorandSapDetails(fromValue.VENDOR_NUM).subscribe((data: any) => {
                    if (data.length == 0) {
                        alert('Not a valid combination');
                        // Reverting to the original data
                        this.addForm.patchValue({
                            VENDOR_NUM: this.rowDataObject.VENDOR_NUM,
                            SAP_NUM: this.rowDataObject.SAP_NUM,
                            SUPPLIER_NAME: this.rowDataObject.SUPPLIER_NAME
                        });
                    } else {
                        this.addForm.patchValue({
                            SAP_NUM: data[0].SAP_NUMBER,
                            SUPPLIER_NAME: data[0].VENDOR_NAME
                        });
                    }
                    this.showLoader = false;
                    this.saveDisabled = false;
                });
            } else if (findData > -1) {
                let AMOUNT_DEDUCTED, EARNED;
                // Multipe
                this.editData = _.each(this.editData, (v) => {
                    if (data == 'AMOUNT_DEDUCTED') {
                        AMOUNT_DEDUCTED = fromValue.AMOUNT_DEDUCTED;
                    } else {
                        AMOUNT_DEDUCTED = v.AMOUNT_DEDUCTED;
                    }

                    if (data == 'EARNED') {
                        EARNED = fromValue.EARNED;
                    } else {
                        EARNED = v.EARNED;
                    }
                    let val = this.convertStringToNumberValue(v.EARNED) - this.convertStringToNumberValue(AMOUNT_DEDUCTED);
                    let calc_ieps = val * this.convertStringToNumberValue(fromValue.IEPS_PCT);
                    let calc_iva = Math.abs((val + calc_ieps) * this.convertStringToNumberValue(fromValue.IVA_PCT));
                    let total = val + calc_ieps + calc_iva;
                    v['AMOUNT_DEDUCTED'] = AMOUNT_DEDUCTED;
                    v['EARNED'] = EARNED;
                    v['PENDING_DUE'] = val.toFixed(2);;
                    v['IEPS_DUE'] = calc_ieps.toFixed(2);
                    v['IVA_DUE'] = calc_iva.toFixed(2);
                    v['TOTAL_PENDING'] = total.toFixed(2);
                    return v;
                });

                // Single
                let val = this.convertStringToNumberValue(fromValue.EARNED) - this.convertStringToNumberValue(fromValue.AMOUNT_DEDUCTED);
                let calc_ieps = val * this.convertStringToNumberValue(fromValue.IEPS_PCT);
                let calc_iva = Math.abs((val + calc_ieps) * this.convertStringToNumberValue(fromValue.IVA_PCT));
                let total = val + calc_ieps + calc_iva;
                this.addForm.get('PENDING_DUE').setValue(val.toFixed(2));
                this.addForm.get('IEPS_DUE').setValue(calc_ieps.toFixed(2));
                this.addForm.get('IVA_DUE').setValue(calc_iva.toFixed(2));
                this.addForm.get('TOTAL_PENDING').setValue(total.toFixed(2));
                this.valueEdited = false;
            } else {
                this.editData = _.each(this.editData, (v) => {
                    if (this.updatingRowValue.length > 0) {
                        _.each(this.updatingRowValue, (item) => {
                            if (item != 'AMOUNT_DEDUCTED' && item != 'PENDING_DUE' && item != 'EARNED' && item != 'ALLOW_EARNED' && item != 'TOTAL_PENDING' && item != 'IEPS_DUE' && item != 'IVA_DUE') v[item] = fromValue[item];
                        });
                    }
                    return v;
                });
                this.valueEdited = false;
            }
        }
        this.valueEdited = false;
    }

    createFileValue(objectFrom) {
        this.isShowFormateType = objectFrom.ALLOW_TYPE === 'REPO' ? true : false;
        // const currentValueObject = this.currentValueObject;
        this.addForm = null;
        this.rowData = [];
        this.showForm = false;
        this.rowData = this.generatedColumnObject([objectFrom]);
        if (this.pageID === 'VOLUMES') {
            _.each(['DEAL_ID', 'DEPT_NBR', 'DEPT'], (v) => {
                const findIndex = this.rowData.indexOf(v);
                this.rowData.splice(findIndex, 1);
            });
        } else if (this.pageID === 'NEW-STORE') {
            if (objectFrom['UF_AMT'] != undefined) {
                objectFrom['UFAMT'] = objectFrom['UF_AMT'];
            }
            _.each(['AMOUNT_DEDUCTED', 'UF_AMT'], (v) => {
                const findIndex = this.rowData.indexOf(v);
                this.rowData.splice(findIndex, 1);
            });
        }
        let formObject: any = {};
        const findIndex = _.find(this.rowData, (v) => v.key === 'CHANGE_USER');
        if (findIndex) {
            this.rowData.push({ key: 'CHANGE_USER', isSelected: false });
        }
        _.each(this.rowData, (obj) => {
            const v = obj.key;

            const getData = v.replaceAll('_', ' ');
            const getAry = getData.split(' ');
            const findDate = _.find(getAry, (v) => {
                return v.toLowerCase() === 'date';
            });
            const findNumber = _.find(this.numberArrya, (item) => item === v);
            if (findNumber) {
                const valueNum = this.convertStringToNumberValue(objectFrom[v]);
                formObject[v] = [{ value: valueNum, disabled: this.getEditableColumn(v) }];
            } else if (
                findDate ||
                v == 'START_DATE' ||
                v === 'END_DATE' ||
                v === 'GO_DATE' ||
                v === 'INV_DATE' ||
                v === 'CLEAR_DATE' ||
                v == 'MIN_SALES' ||
                v === 'MAX_SALES' ||
                v == 'VALID_FROM' ||
                v == 'VALID_TO' ||
                v == 'Valid_From' ||
                v == 'Valid_To' ||
                v == 'OPEN_DATE' ||
                v == 'POSTING_DATE' ||
                v == 'INITIAL_DATE' ||
                v == 'FINAL_DATE' ||
                v == 'DATE_AUDITED' ||
                v == 'CREATION_DATE' ||
                v == 'DATE_AUDITED' ||
                v == 'COMPLETE_DATE' ||
                v == 'COMPLETE_DT' ||
                v == 'Complete_DT' ||
                v == 'CREATE_DTE' ||
                v == 'CLEARING_DT'
            ) {
                const dateValue = objectFrom[v];
                formObject[v] = [
                    {
                        value: this.getDateFormateValue(dateValue),
                        disabled: this.getEditableColumn(v)
                    }
                ];
            } else if (v == 'ACCOUNT_DEPARTMENT') {
                formObject[v] = [
                    {
                        value: objectFrom['DEPT_NBR'],
                        disabled: this.getEditableColumn(v)
                    }
                ];
            } else if (v == 'AMT_DEDUCTED') {
                formObject[v] = [
                    {
                        value: objectFrom['AMOUNT_DEDUCTED'],
                        disabled: this.getEditableColumn(v)
                    }
                ];
            } else if (v == 'UFAMT') {
                formObject[v] = [
                    {
                        value: objectFrom['UF_AMT'],
                        disabled: this.getEditableColumn(v)
                    }
                ];
            } else if (v == 'FORMAT_TYPE' && objectFrom['ALLOW_TYPE'] != 'REPO') {
                formObject[v] = [{ value: '', disabled: this.getEditableColumn(v) }];
            } else {
                formObject[v] = [
                    {
                        value: objectFrom[v],
                        disabled: this.getEditableColumn(v)
                    }
                ];
            }
        });

        return formObject;
    }

    changeValueToDate(v) {
        const dateArray = v.split('-');
        const dateString = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
        const dateObj = new Date(dateString);
        return dateObj;
    }
    percentageCalculated(percent, total) {
        if (percent === 0 || total === 0) {
            return 0;
        } else {
            return ((percent / 100) * total).toFixed(2);
        }
    }
    generatedColumn(data) {
        let column = [];
        data.map((object) => {
            Object.keys(object).map((key) => {
                if (key != 'CREATED_DATE' && key != 'AGREEMENT_NUMBER' && key != 'CREATED_BY' && key != 'LAST_UPDATED_BY' && key != 'LAST_UPDATED_DATE' && key != 'VERSION') {
                    column.push(key);
                }
            });
        });

        return column;
    }

    generatedColumnObject(data) {
        let column = [];
        data.map((object) => {
            Object.keys(object).map((key) => {
                if (key != 'CREATED_DATE' && key != 'CREATED_BY' && key != 'LAST_UPDATED_BY' && key != 'LAST_UPDATED_DATE' && key != 'VERSION') {
                    column.push({ key: key, isSelected: false });
                }
            });
        });

        return column;
    }

    removeUnderScore(v) {
        return v.replaceAll('_', ' ');
    }

    checkIsPresentAny(v) {
        let findIndex;

        if (this.ccCode === 'MX') {
            findIndex = this.dataArrayMX.indexOf(v);
        } else {
            findIndex = this.dataArray.indexOf(v);
        }
        if (findIndex === -1) {
            return true;
        } else {
            return false;
        }
    }

    getTextValue(data) {
        const getData = data.replaceAll('_', ' ');
        const getAry = getData.split(' ');
        const findDate = _.find(getAry, (v) => {
            return v.toLowerCase() === 'date';
        });
        const findNumber = _.find(this.numberArrya, (v) => v === data);
        if (
            findDate ||
            data == 'START_DATE' ||
            data === 'END_DATE' ||
            data === 'GO_DATE' ||
            data === 'INV_DATE' ||
            data === 'CLEAR_DATE' ||
            data == 'MIN_SALES' ||
            data === 'MAX_SALES' ||
            data == 'Valid_From' ||
            data == 'Valid_To' ||
            data == 'OPEN_DATE' ||
            data == 'POSTING_DATE' ||
            data == 'INITIAL_DATE' ||
            data == 'FINAL_DATE' ||
            data == 'DATE_AUDITED' ||
            data == 'CREATION_DATE' ||
            data == 'DATE_AUDITED' ||
            data == 'COMPLETE_DATE' ||
            data == 'COMPLETE_DT' ||
            data == 'Complete_DT' ||
            data == 'CREATE_DTE' ||
            data == 'CLEARING_DT'
        ) {
            return 'date';
        } else if (findNumber) {
            return 'number';
        } else {
            return 'text';
        }
    }

    getAllDropdownValues(CLCode, pageID) {
        this.apiservice.getClaimsDropdowns(CLCode, pageID).subscribe((response) => {
            this.AllDropdowns = response;

            for (var i = 0; i < this.AllDropdowns.length; i++) {
                if (this.AllDropdowns[i].Lookup_Code == 'AUDITOR_ID') {
                    this.AuditorIdDropdown.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'REL') {
                    this.releaseDropDown.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'PRIVATE_LBL') {
                    this.privateLabelList.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'QR_STATUS') {
                    this.qr_statusList.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'ACCOUNT_DEPARTMENT') {
                    this.accountDepartmentList.push(this.AllDropdowns[i].Lookup_Values);
                    this.MXDEP.push(this.AllDropdowns[i].Lookup_Values);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'FORMAT_TYPE') {
                    this.formatLabelList.push(this.AllDropdowns[i].Lookup_Values);
                }
                if (this.AllDropdowns[i].Lookup_Values == 'GROUP') {
                    this.MXGROUPS.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Values == 'AUDITORS') {
                    this.MXAUDITORS.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Values == 'STATUS') {
                    this.MXSTATUS.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'Department') {
                    this.MXDEP.push(this.AllDropdowns[i].Lookup_Values);
                }
            }
        });
    }

    getAllDropdownValuesMX(pageID) {
        this.apiservice.getMXDatadropdowns(pageID).subscribe((response) => {
            this.AllDropdownsMX = response;
            for (var i = 0; i < this.AllDropdownsMX.length; i++) {
                if (this.AllDropdownsMX[i].Lookup_Values == 'GROUP') {
                    this.MXGROUPS.push(this.AllDropdownsMX[i].Lookup_Value_Meaning);
                }
                // if (this.AllDropdownsMX[i].Lookup_Code == "Department") {
                //   this.MXDEP.push(this.AllDropdownsMX[i].Lookup_Values);
                // }
                if (this.AllDropdownsMX[i].Lookup_Values == 'STATUS') {
                    this.MXSTATUS.push(this.AllDropdownsMX[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdownsMX[i].Lookup_Values == 'MD_STATUS') {
                    this.MDStatusOptions.push(this.AllDropdownsMX[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdownsMX[i].Lookup_Values == 'MD_ROOTCAUSE') {
                    this.MXRootCauseOptions.push(this.AllDropdownsMX[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdownsMX[i].Lookup_Values == 'MARKDOWN_TYPE') {
                    this.MXMarkdownTypeOptions.push(this.AllDropdownsMX[i].Lookup_Value_Meaning);
                }
            }
        });
    }

    getAuditorList() {
        this.apiservice.getAuditorList(this.pageID, this.ccCode).subscribe((response) => {
            this.auditorList = response;
            for (var i = 0; i < this.auditorList.length; i++) {
                this.MXAUDITORS.push(this.auditorList[i].User_Name);
            }
        });
    }

    formatDateValue(date) {
        if (date != null && date != undefined) {
            const d = new Date(date);
            let month = '' + (d.getMonth() + 1);
            let day = '' + d.getDate();
            const year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [year, month, day].join('-');
        } else {
            return null;
        }
    }

    getAllowTypesDropDown() {
        this.apiservice.getAllowTypes().subscribe((response) => {
            this.allowTypeDropDown = response;
        });
    }

    convertStringToNumberValue(data) {
        if (data != '' && data != null && !isNaN(data)) {
            if (typeof data == 'number' ) {
                // check if it is integer
                if (Number.isInteger(data)) {
                    return data;
                } else {
                    return data;
                }
            } else {
                const v = parseFloat(data.replace(/,/g, ''));
                return Number(v);
            }
        } else {
            return 0;
        }
    }

    validatePayload() {
        const fromDataValue = this.addForm.getRawValue();
        console.log(fromDataValue)
        const userInfo = JSON.parse(localStorage.getItem('userinfo'));
        const finalPayload = [];
        const payload: any = {};
        if (this.editData.length > 1) {
        // MULTIPLE SELECTIONS
            if (this.updatingRowValue.length === 0) {
                alert('No Checkbox marked for update a data.');
                return;
            } else {
                console.log("this.editData", this.editData)
                _.each(this.editData, (v) => {
                    let payload: any = {};
                    // Chile Multiple
                    if (this.ccCode === 'CL') {
                        if (this.pageID == 'NEW-STORE') {
                            payload.RECORD_ID = v.RECORD_ID;
                            payload.CHANGE_USER = v.CHANGE_USER;
                            payload.AUDIT_YEAR = v.AUDIT_YEAR;
                            payload.RELEASE_NUM = v.RELEASE_NUM;
                            payload.RUT = v.RUT;
                            payload.SUPPLIER_NAME = v.SUPPLIER_NAME;
                            payload.SUPPLIER_NUM = v.SUPPLIER_NUM;
                            payload.DEPT = v.DEPT;
                            payload.LEGACY_CATEGORY = v.LEGACY_CATEGORY;
                            payload.CATEGORY = v.CATEGORY;
                            payload.MIN_SALES = v.MIN_SALES != '' ? v.MIN_SALES : null;
                            payload.MAX_SALES = v.MAX_SALES != '' ? v.MAX_SALES : null;
                            payload.STORE_NBR = v.STORE_NBR;
                            payload.TYPE = v.TYPE;
                            payload.OPEN_DATE = v.OPEN_DATE != '' ? v.OPEN_DATE : null;
                            payload.FORMAT = v.FORMAT;
                            payload.FIXED_AMT = this.convertStringToNumberValue(v.FIXED_AMT);
                            payload.UFAMT = this.convertStringToNumberValue(v.UFAMT);
                            payload.TOTAL_DUE = this.convertStringToNumberValue(v.TOTAL_DUE);
                            payload.AMT_DEDUCTED = this.convertStringToNumberValue(v.AMT_DEDUCTED);
                            payload.OTHER_DEDUCTION = this.convertStringToNumberValue(v.OTHER_DEDUCTION);
                            payload.AMOUNT_DUE = this.convertStringToNumberValue(v.AMOUNT_DUE);
                            payload.TOTAL_SALES = this.convertStringToNumberValue(v.TOTAL_SALES);
                            payload.AUDITOR_ID = v.AUDITOR_ID;
                            payload.QR_STATUS = v.QR_STATUS;
                            payload.COMPLETED_DATE = v.COMPLETED_DATE != '' && v.COMPLETED_DATE != 'Invalid date' ? v.COMPLETED_DATE : null;
                            payload.APP_CLAIM_NUMBER = v.APP_CLAIM_NUMBER;
                            payload.COMMENTS = v.COMMENTS;
                            payload.CITY = v.CITY;
                            payload.AREA = v.AREA;
                            payload.ADDRESS = v.ADDRESS;
                            payload.CREATED_BY = userInfo.username;
                            payload.CREATED_DATE = v.CREATED_DATE != '' && v.CREATED_DATE != 'Invalid date' ? v.CREATED_DATE : null;
                            payload.LAST_UPDATED_BY = this.rowDataObject.LAST_UPDATED_BY;
                            payload.VERSION = Number(v.VERSION) + 1;
                        } else if (this.pageID == 'UNDED-COOPS') {
                            payload.RECORD_ID = v.RECORD_ID;
                            payload.AUDIT_YEAR = v.AUDIT_YEAR;
                            payload.RELEASE_NUM = v.RELEASE_NUM;
                            payload.RUT = v.RUT;
                            payload.VENDOR_NBR = v.VENDOR_NBR;
                            payload.SUPPLIER_NAME = v.SUPPLIER_NAME;
                            payload.ALLOW_TYPE = v.ALLOW_TYPE;
                            payload.DEPT = v.DEPT;
                            payload.SEQUENCING = v.SEQUENCING;
                            payload.LEGACY_CATEGORY = v.LEGACY_CATEGORY;
                            payload.CATEGORY = v.CATEGORY;
                            payload.COMPANY = v.COMPANY;
                            payload.AGREEMENT_NUMBER = v.AGREEMENT_NUMBER;
                            payload.STATUS = v.STATUS;
                            payload.AMOUNT = this.convertStringToNumberValue(v.AMOUNT);
                            payload.AMT_FOR_THE_PERIOD = this.convertStringToNumberValue(v.AMT_FOR_THE_PERIOD);
                            payload.CREATION_DATE = v.CREATION_DATE != '' ? v.CREATION_DATE : null;
                            payload.POSTING_DATE = v.POSTING_DATE != '' ? v.POSTING_DATE : null;
                            payload.AMOUNT_DEDUCTED = this.convertStringToNumberValue(v.AMOUNT_DEDUCTED);
                            payload.INITIAL_DATE = v.INITIAL_DATE != '' ? v.INITIAL_DATE : null;
                            payload.FINAL_DATE = v.FINAL_DATE != '' ? v.FINAL_DATE : null;
                            payload.ASSIGNED_USERNAME = v.ASSIGNED_USERNAME;
                            payload.DATE_AUDITED = v.DATE_AUDITED != '' ? v.DATE_AUDITED : null;
                            payload.QR_STATUS = v.QR_STATUS;
                            payload.NOTES = v.NOTES;
                            payload.APP_CLAIM_NUMBER = v.APP_CLAIM_NUMBER;
                            payload.BUYER = v.BUYER;
                            payload.COMMENTS = v.COMMENTS;
                            payload.COMMENTS2 = v.COMMENTS2;
                            payload.CREATION_USER = v.CREATION_USER;
                            payload.CREATED_BY = userInfo.username;
                            payload.LAST_UPDATED_BY = v.LAST_UPDATED_BY;
                            payload.CREATED_DATE = v.CREATED_DATE != '' ? v.CREATED_DATE : null;
                            payload.VERSION = Number(v.VERSION) + 1;
                        } else if (this.pageID === 'VOLUMES') {
                            payload.RECORD_ID = v.RECORD_ID;
                            payload.CHANGE_USER = v.CHANGE_USER;
                            payload.AUDIT_YEAR = v.AUDIT_YEAR;
                            payload.RELEASE_NUM = fromDataValue.RELEASE_NUM;
                            payload.RUT = v.RUT;
                            payload.SUPPLIER_NAME = v.SUPPLIER_NAME;
                            payload.SUPPLIER_NUM = v.SUPPLIER_NUM;
                            payload.ALLOW_TYPE = v.ALLOW_TYPE;
                            payload.DEAL_AGREEMENT_NUM = v.DEAL_AGREEMENT_NUM;
                            payload.FORMAT_TYPE = _.isArray(v.FORMAT_TYPE) ? v.FORMAT_TYPE.toString() : v.FORMAT_TYPE;
                            payload.START_DATE = v.START_DATE != '' ? v.START_DATE : null;
                            payload.END_DATE = v.END_DATE != '' ? v.END_DATE : null;
                            payload.ACCOUNT_DEPARTMENT = v.ACCOUNT_DEPARTMENT;
                            payload.LEGACY_CATEGORY = v.LEGACY_CATEGORY;
                            payload.CATEGORY = v.CATEGORY;
                            payload.PRIVATE_LABEL = v.PRIVATE_LABEL;
                            payload.YEAR_VOLUME = this.convertStringToNumberValue(v.YEAR_VOLUME);
                            payload.PERCENTAGE = v.PERCENTAGE;
                            payload.VOLUME = this.convertStringToNumberValue(v.VOLUME);
                            payload.EARNED = this.convertStringToNumberValue(v.EARNED);
                            payload.AMOUNT_RECEIVED = this.convertStringToNumberValue(v.AMOUNT_RECEIVED);
                            payload.FIXED_AMOUNT_AGREEMENT = this.convertStringToNumberValue(v.FIXED_AMOUNT_AGREEMENT);
                            payload.DEBITED_AMOUNT = this.convertStringToNumberValue(v.DEBITED_AMOUNT);
                            payload.AUDITOR_ID = v.AUDITOR_ID;
                            payload.DATE_AUDITED = v.DATE_AUDITED != '' ? v.DATE_AUDITED : null;
                            payload.QR_STATUS = v.QR_STATUS;
                            payload.AGREEMENT_NUMBER = v.AGREEMENT_NUMBER;
                            payload.APP_CLAIM_NUMBER = v.APP_CLAIM_NUMBER;
                            payload.COMMENTS = v.COMMENTS;
                            payload.CREATED_BY = userInfo.username;
                            payload.CREATED_DATE = v.CREATED_DATE != '' ? v.CREATED_DATE : null;
                            payload.LAST_UPDATED_BY = v.LAST_UPDATED_BY;
                            payload.VERSION = Number(v.VERSION) + 1;
                        }else if (this.pageID === 'MISC') {
                            payload.RECORD_ID = v.RECORD_ID;
                            payload.CHANGE_USER = v.CHANGE_USER;
                            payload.RELEASE_NUM = v.RELEASE_NUM;
                            payload.AUDIT_YEAR = v.AUDIT_YEAR;
                            payload.LEGACY_CATEGORY = fromDataValue.LEGACY_CATEGORY;
                            payload.RUT = v.RUT;
                            payload.SUPPLIER_NAME = v.SUPPLIER_NAME;
                            payload.SUPPLIER_NUM = v.SUPPLIER_NUM;
                            payload.DEPT = v.DEPT;
                            payload.NEW_CATEGORY = v.NEW_CATEGORY;
                            payload.FORMAT_TYPE = v.FORMAT_TYPE;
                            payload.ALLOW_TYPE = v.ALLOW_TYPE;
                            payload.AMOUNT_DUE = this.convertStringToNumberValue(v.AMOUNT_DUE);
                            payload.TOTAL_DEDUCTION = this.convertStringToNumberValue(v.TOTAL_DEDUCTION);
                            payload.PENDING_DUE = this.convertStringToNumberValue(v.PENDING_DUE);
                            payload.IVA_DUE = this.convertStringToNumberValue(v.IVA_DUE);
                            payload.TOTAL_PENDING = this.convertStringToNumberValue(v.TOTAL_PENDING);
                            payload.AUDITOR_ID = v.AUDITOR_ID;
                            payload.REVISION_DATE = v.REVISION_DATE != '' ? v.REVISION_DATE : null;
                            payload.AUDITOR_STATUS = v.AUDITOR_STATUS;
                            payload.AGREEMENT_NUM = v.AGREEMENT_NUM;
                            payload.INSERTED = v.INSERTED;
                            payload.NOTES = v.NOTES;
                            payload.APP_CLAIM_NUMBER = v.APP_CLAIM_NUMBER;
                            payload.CREATED_BY = userInfo.username;
                            payload.CREATED_DATE = v.CREATED_DATE != '' ? v.CREATED_DATE : null;
                            payload.LAST_UPDATED_BY = v.LAST_UPDATED_BY;
                            payload.VERSION = Number(v.VERSION) + 1;
                            // if (this.ccCode === 'MX') {
                            //     payload.IEPS_PCT = this.convertStringToNumberValue(v.IEPS_PCT);
                            //     payload.IEPS_DUE = this.convertStringToNumberValue(v.IEPS_DUE);
                            //     payload.IVA_PCT = this.convertStringToNumberValue(v.IVA_PCT);
                            //     payload.LAST_UPDATED_DATE = v.CREATED_DATE != '' ? this.convertDateToSTring(v.CREATED_DATE) : null;
                            // }
                        }
                    } else {
                        // Mexico Multiple
                        if (this.pageID === 'Scan Allowances') {
                            payload.AUDITOR = v.AUDITOR;
                            payload.AUDITOR_COMMENTS = v.AUDITOR_COMMENTS;
                            payload.ALLOW_EARNED = v.ALLOW_EARNED;
                            payload.AMS_QTY = v.AMS_QTY;
                            payload.AMS_RATE = v.AMS_RATE;
                            payload.AMS_SALES_AMT = v.AMS_SALES_AMT;
                            payload.AMT_DUE = v.AMT_DUE;
                            payload.AUDIT_YEAR = v.AUDIT_YEAR;
                            payload.ACCRUAL_AMT = v.Accrual_AMT;
                            payload.ADJUSTMENTS = v.Adjustments;
                            payload.AGREEMENT_NBR = v.Agreement_Nbr != '' ? v.Agreement_Nbr : null;
                            payload.AGREEMENT_REQUEST_NBR = v.Agreement_Request_Nbr;
                            payload.AGREEMENT_REQUEST_STATUS = v.Agreement_Request_Status;
                            payload.COOP_NUMBER = v.COOP_Number;
                            payload.CLAIM_NUMBER = v.Claim_Number;
                            payload.COMMENTS = v.Comments;
                            payload.COMPLETE_DT = v.COMPLETE_DT != '' ? v.Complete_DT : null;
                            payload.DIVISION = v.DIVISION;
                            payload.DEPARTMENT = v.DEPARTMENT;
                            payload.FORMAT = v.FORMAT;
                            payload.GROUPS = v.GROUPS;
                            payload.ID = v.ID;
                            payload.INCLUDE = 'Y';
                            payload.IEPS_DUE = v.IEPS_DUE;
                            payload.IEPS_PCT = v.IEPS_PCT;
                            payload.ITEM_DESC = v.ITEM_DESC;
                            payload.IVA_DUE = v.IVA_DUE;
                            payload.IVA_PCT = v.IVA_PCT;
                            payload.ITEM_NBR = v.Item_Nbr;
                            payload.POS_BANNER_CODE = v.POS_BANNER_CODE;
                            payload.POS_SALES_AMT = v.POS_SALES_AMT;
                            payload.POS_SALES_QTY = v.POS_SALES_QTY;
                            payload.REASON_CODE = v.REASON_CODE;
                            payload.RECORD_ID = v.RECORD_ID;
                            payload.RFC = v.RFC;
                            payload.SAP_VENDOR = v.SAP_VENDOR;
                            payload.SEQ_NO = v.SEQ_NO;
                            payload.STATUSCODE = v.StatusCode;
                            payload.UPC_NBR = v.UPC_NBR;
                            payload.VALID_FROM = v.Valid_From != '' ? v.Valid_From : null;
                            payload.VALID_TO = v.Valid_To != '' ? v.Valid_To : null;
                            payload.VENDOR_NAME = this.returnItemAfterTrim(v.VENDOR_NAME);
                            payload.VENDOR_NBR = v.VENDOR_NBR;
                            payload.USR_CHANGE = v.USR_CHANGE;
                            payload.ALL_BODEGA_POS_QTY = v.ALL_BODEGA_POS_QTY;
                            payload.ALL_BODEGA_POS_AMT = v.ALL_BODEGA_POS_AMT;
                            payload.VERSION = Number(v.VERSION) + 1;
                        } else if (this.pageID === 'Markdown') {
                            payload.VENDOR_NBR = v.VENDOR_NBR;
                            payload.VNDR_NAME = v.VNDR_NAME;
                            payload.EFFECTIVE_DATE =  v.EFFECTIVE_DATE != '' ? v.EFFECTIVE_DATE : null;
                            payload.END_DATE =  v.END_DATE != '' ? v.END_DATE : null;
                            payload.DEPT = v.DEPT;
                            payload.CAT = v.CAT;
                            payload.ITEM_NUM = v.ITEM_NUM;
                            payload.ITEM1_DESC = v.ITEM1_DESC;
                            payload.BANNER_DESC = v.BANNER_DESC,
                            payload.POS_SALES = v.POS_SALES;
                            payload.POS_QTY = v.POS_QTY;
                            payload['IMPACT_MU/MD'] = v['IMPACT_MU/MD'];
                            payload.UNIT_RETAIL = this.convertStringToNumberValue(v.UNIT_RETAIL);
                            payload.AVG_UNIT_SALE_PRICE = v.AVG_UNIT_SALE_PRICE;
                            payload.UNIT_COST = this.convertStringToNumberValue(v.UNIT_COST);
                            payload.COMMERCIAL_MARGE = this.convertStringToNumberValue(v.COMMERCIAL_MARGE);
                            payload.REAL_MARGE = this.convertStringToNumberValue(v.REAL_MARGE);
                            payload.MARGE_DEVIATION = this.convertStringToNumberValue(v.MARGE_DEVIATION);
                            payload.AMS_AGREEMENT = v.AMS_AGREEMENT;
                            payload.AGREEMENT_AMOUNT = v.AGREEMENT_AMOUNT;
                            payload.DIFFERENCE = v.DIFFERENCE;
                            payload.MD_STATUS = v.MD_STATUS;
                            payload.MD_ROOTCAUSE = v.MD_ROOTCAUSE;
                            payload.MARKDOWN_TYPE = v.MARKDOWN_TYPE;
                            payload.EMAIL_SUPPORT = v.EMAIL_SUPPORT;
                            payload.AUDITOR_NAME = v.AUDITOR_NAME;
                            payload.AUDITOR_COMMENTS = v.AUDITOR_COMMENTS;
                            payload.DUE_DATE = v.DUE_DATE != '' ? v.DUE_DATE : null;
                            payload.CLAIM_NUMBER = v.CLAIM_NUMBER;
                            payload.SELECT_ROW = v.SELECT_ROW;
                            payload.VAT = v.VAT;
                            payload.IEPS = v.IEPS;
                            payload.IVA_DUE = v.IVA_DUE;
                            payload.IEPS_DUE = v.IEPS_DUE;
                            payload.TOTAL_DUE = v.TOTAL_DUE;
                            payload.BANNER_DESC = v.BANNER_DESC;
                            payload.UNIT_ALLOWANCE = v.UNIT_ALLOWANCE;
                            payload.EVENT_DESCRIPTION = v.EVENT_DESCRIPTION;
                            payload.VERSION = Number(this.rowDataObject.VERSION) + 1;
                            payload.RECORD_ID = v.RECORD_ID;
                            payload.Audit_Year = v.AUDIT_YEAR != undefined ? v.AUDIT_YEAR : this.singleAuditYear;;
                        } else if (this.pageID === 'New Stores') {
                            payload.ALLOW_EARNED = this.convertStringToNumberValue(v.ALLOW_EARNED);
                            payload.AMTDEDUCTED = this.convertStringToNumberValue(v.AMTDEDUCTED);
                            payload.AMT_DUE = this.convertStringToNumberValue(v.AMT_DUE);
                            payload.AUDITOR = v.AUDITOR;
                            payload.AUDITOR_COMMENTS = v.AUDITOR_COMMENTS;
                            payload.AUDIT_YEAR = v.AUDIT_YEAR;
                            payload.CLAIM_NUMBER = v.Claim_Number;
                            payload.CLEARING_DOC = v.CLEARING_DOC;
                            payload.CLEAR_DATE = v.CLEAR_DATE != '' ? v.CLEAR_DATE : null;
                            payload.COMPLETE_DATE = v.COMPLETE_DATE != '' ? v.COMPLETE_DATE : null;
                            payload.COOP_DEDUCTED = v.COOP_DEDUCTED != '' ? v.COOP_DEDUCTED : 0;
                            payload.COOP_NUMBER = v.COOP_NUMBER;
                            payload.DEDUCT_TYPE = v.DEDUCT_TYPE;
                            payload.DOC_NBR = v.DOC_NBR;
                            payload.DEPARTMENT = v.DEPARTMENT;
                            payload.FORMAT = v.FORMAT;
                            payload.GO_DATE = v.GO_DATE;
                            payload.IEPS = this.convertStringToNumberValue(v.IEPS);
                            payload.IEPS_PCT = this.convertStringToNumberValue(v.IEPS_PCT);
                            payload.INSERT_USER = v.INSERT_USER;
                            payload.INCLUDE = 'Y';
                            payload.INS_ROW = v.INS_ROW;
                            payload.INV_DATE = v.INV_DATE != '' ? v.INV_DATE : null;
                            payload.IVA = this.convertStringToNumberValue(v.IVA);
                            payload.IVA_PCT = this.convertStringToNumberValue(v.IVA_PCT);
                            payload.RECORD_ID = v.RECORD_ID;
                            payload.RFC = v.RFC;
                            payload.SAM_FLAG = v.SAM_FLAG;
                            payload.SAP_NUMBER = v.SAP_NUMBER;
                            payload.SEQ_NO = v.SEQ_NO;
                            payload.SHIP_CHK = v.SHIP_CHK;
                            payload.STATUS = v.STATUS;
                            payload.STORE = v.STORE;
                            payload.STORE_NAME = v.STORE_NAME;
                            payload.TEXT = v.TEXT;
                            payload.TOTAL_SHIPS = v.TOTAL_SHIPS;
                            payload.USRCHG = v.USRCHG;
                            payload.VENDOR_NAME = v.VENDOR_NAME;
                            payload.VENDOR_NBR = v.VENDOR_NBR;
                            payload.VERSION = Number(v.version) + 1;
                        } else if (this.pageID === 'Volume Allowances') {
                            payload.ADJUSTMENTS = v.ADJUSTMENTS;
                            payload.ALLOW_EARNED = this.convertStringToNumberValue(v.ALLOW_EARNED);
                            payload.ALL_ITEMS = v.ALL_ITEMS;
                            payload.AMS_PURCHASE = v.AMS_PURCHASE;
                            payload.AMT_DUE = this.convertStringToNumberValue(v.AMT_DUE);
                            payload.AUDITOR = v.AUDITOR;
                            payload.AUDITOR_COMMENTS = v.AUDITOR_COMMENTS;
                            payload.AUDIT_YEAR = v.AUDIT_YEAR;
                            payload.CATCH_RATE = v.CATCH_RATE;
                            payload.COMPLETE_DATE = v.COMPLETE_DATE != '' ? v.COMPLETE_DATE : null;
                            payload.CLAIM_NUMBER = v.Claim_Number;
                            payload.DEAL = v.DEAL;
                            payload.DEPARTMENT = this.convertStringToNumberValue(v.DEPARTMENT);
                            payload.END_DATE = v.END_DATE != '' ? v.END_DATE : null;
                            payload.ENTERED_AMT = v.ENTERED_AMT;
                            payload.FORMAT = v.FORMAT;
                            payload.GROUP = this.convertStringToNumberValue(v.GROUP);
                            payload.IEPS = v.IEPS;
                            payload.IEPS_PCT = v.IEPS_PCT;
                            payload.INSERT_USER = v.INSERT_USER;
                            payload.INS_ROW = v.INS_ROW;
                            payload.IVA = v.IVA;
                            payload.IVA_PCT = v.IVA_PCT;
                            payload.OTHER_DED = v.OTHER_DED;
                            payload.PAST_CLAIM_NUMBERS = v.PAST_CLAIM_NUMBERS;
                            payload.PCT = v.PCT;
                            payload.PO_PURCHASES = v.PO_PURCHASES;
                            payload.PO_SOURCE = v.PO_SOURCE;
                            payload.PREVIOUS_CLAIMS = this.convertStringToNumberValue(v.PREVIOUS_CLAIMS);
                            payload.RECORD_ID = this.convertStringToNumberValue(v.RECORD_ID);
                            payload.RFC = v.RFC;
                            payload.SAP_VENDOR = this.convertStringToNumberValue(v.SAP_VENDOR);
                            payload.SEQ_NO = v.SEQ_NO;
                            payload.START_DATE = v.START_DATE != '' ? v.START_DATE : null;
                            payload.STATUS = v.STATUS;
                            payload.TAX_CD = v.TAX_CD;
                            payload.TIER = this.convertStringToNumberValue(v.TIER);
                            payload.TOTAL_DUE = this.convertStringToNumberValue(v.TOTAL_DUE);
                            payload.USR_CHANGE = v.USR_CHANGE;
                            payload.VENDOR_NAME = v.VENDOR_NAME;
                            payload.VENDOR_NBR = this.convertStringToNumberValue(v.VENDOR_NBR);
                            payload.INCLUDE = 'Y';
                            payload.VERSION = Number(v.version) + 1;
                        } else if (this.pageID === 'UN-DEDUCTED COOPS') {
                            (payload.USRCHG = v.USRCHG),
                                (payload.REL = v.REL),
                                (payload.AUDIT_YEAR = v.AUDIT_YEAR),
                                (payload.ACTION = v.ACTION),
                                (payload.VENDOR_NBR = v.VENDOR_NBR),
                                (payload.SAP_VENDOR = v.SAP_VENDOR),
                                (payload.VENDOR_NAME = v.VENDOR_NAME),
                                (payload.AGREEMENT_REQUEST_NBR = v.Agreement_Request_Nbr),
                                (payload.COOP_NBR = v.COOP_NBR),
                                (payload.RSN_CDE = v.RSN_CDE),
                                (payload.COOP_TYPE = v.COOP_TYPE),
                                (payload.COOP_STATUS = v.COOP_STATUS),
                                (payload.SEQ_NO = v.SEQ_NO),
                                (payload.FORMAT = v.FORMAT),
                                (payload.EFFECT_DATE = v.EFFECT_DATE != '' ? v.EFFECT_DATE : null),
                                (payload.END_DATE = v.END_DATE != '' ? v.END_DATE : null),
                                (payload.DEPARTMENT = v.DEPARTMENT),
                                (payload.DIV = v.DIV),
                                (payload.BASE_COOP_AMT = this.convertStringToNumberValue(v.BASE_COOP_AMT)),
                                (payload.BASE_AMT_DEDUCT = this.convertStringToNumberValue(v.BASE_AMT_DEDUCT)),
                                (payload.ADJUSTMENTS = this.convertStringToNumberValue(v.ADJUSTMENTS)),
                                (payload.BASE_AMT_DUE = v.BASE_AMT_DUE),
                                (payload.IVA_DUE = v.IVA_DUE),
                                (payload.IEPS_DUE = v.IEPS_DUE),
                                (payload.AMT_DEDUCT_TAXES = v.AMT_DEDUCT_TAXES),
                                (payload.IVA_PCT = v.IVA_PCT),
                                (payload.IEPS_PCT = v.IEPS_PCT),
                                (payload.SAP_DOC_NBR = v.SAP_DOC_NBR),
                                (payload.CREATE_DTE = v.CREATE_DTE != '' ? v.CREATE_DTE : null),
                                (payload.CLEARING_DT = v.CLEARING_DT != '' ? v.CLEARING_DT : null),
                                (payload.REJECT_REASON = v.REJECT_REASON),
                                (payload.RFC = v.RFC),
                                (payload.TAX_CODE = v.TAX_CODE),
                                (payload.AUDITOR = v.AUDITOR),
                                (payload.STATUS = v.STATUS),
                                (payload.AUDITOR_COMMENT = v.AUDITOR_COMMENT),
                                (payload.ATTACH = v.ATTACH),
                                (payload.CLAIM_NUMBER = v.Claim_Number),
                                (payload.COMPLETE_DT = v.COMPLETE_DT != '' ? v.COMPLETE_DT : null),
                                (payload.VERSION = Number(v.version) + 1),
                                (payload.RECORD_ID = v.RECORD_ID);
                        } else if (this.pageID === 'MISC') {
                            payload.CHANGE_USER = v.CHANGE_USER;
                            payload.RELEASE_NUM = v.RELEASE_NUM;
                            payload.SUPPLIER_NAME = v.SUPPLIER_NAME;
                            payload.DEPT = v.DEPT;
                            payload.FORMAT_TYPE = v.FORMAT_TYPE;
                            payload.ALLOW_TYPE = v.ALLOW_TYPE;
                            payload.PENDING_DUE = this.convertStringToNumberValue(v.PENDING_DUE);
                            payload.TOTAL_PENDING = this.convertStringToNumberValue(v.TOTAL_PENDING);
                            payload.AUDITOR_ID = v.AUDITOR_ID;
                            payload.AUDITOR_STATUS = v.AUDITOR_STATUS;
                            payload.AGREEMENT_NUM = v.AGREEMENT_NUM;
                            payload.NOTES = v.NOTES;
                            payload.IEPS_PCT = this.convertStringToNumberValue(v.IEPS_PCT);
                            payload.IEPS_DUE = this.convertStringToNumberValue(v.IEPS_DUE);
                            payload.IVA_PCT = this.convertStringToNumberValue(v.IVA_PCT);
                            payload.IVA_DUE = this.convertStringToNumberValue(v.IVA_DUE);
                            payload.AUDIT_YEAR = v.AUDIT_YEAR;
                            payload.VENDOR_NUM = v.VENDOR_NUM;
                            payload.SAP_NUM = v.SAP_NUM;
                            payload.AMOUNT_DEDUCTED = this.convertStringToNumberValue(v.AMOUNT_DEDUCTED);
                            payload.EARNED = this.convertStringToNumberValue(v.EARNED);
                            payload.RECORD_ID = v.RECORD_ID;
                            payload.REVIEW_DATE = v.REVIEW_DATE != '' ? v.REVIEW_DATE : null;
                            payload.INSERTED = v.INSERTED;
                            payload.CLAIM_NUMBER = v.Claim_Number;
                            payload.CREATED_BY = userInfo.username;
                            payload.CREATED_DATE = v.CREATED_DATE != '' ? v.CREATED_DATE : null;
                            payload.LAST_UPDATED_BY = v.LAST_UPDATED_BY;
                            payload.LAST_UPDATED_DATE = v.CREATED_DATE != '' ? v.CREATED_DATE : null;
                            payload.VERSION = Number(v.VERSION) + 1;
                        } else if (this.pageID == 'Advertising') {
                            payload.USRCHG = v.USRCHG;
                            payload.AUDIT_YEAR = v.AUDIT_YEAR;
                            payload.GROUP = v.GROUP;
                            payload.VENDOR_NBR = v.VENDOR_NBR;
                            payload.SAP_VENDOR = v.SAP_VENDOR;
                            payload.VENDOR_NAME = this.returnItemAfterTrim(v.VENDOR_NAME);
                            payload.BRAND = v.BRAND;
                            payload.DEPT = v.DEPT;
                            payload.DESCRIPTION_PAG = v.DESCRIPTION_PAG;
                            payload.WEBCONTENT = v.WEBCONTENT;
                            payload.DIV = v.DIV;
                            payload.NUM_PUBLICATION = v.NUM_PUBLICATION;
                            payload.TYPE_COLLECTION = v.TYPE_COLLECTION;
                            payload.ALLOW_EARNED = this.convertStringToNumberValue(v.ALLOW_EARNED);
                            payload.ENTERED_AMT = this.convertStringToNumberValue(v.ENTERED_AMT);
                            payload.OTHER_DEDUCT = this.convertStringToNumberValue(v.OTHER_DEDUCT);
                            payload.AMTDUE = this.convertStringToNumberValue(v.AMTDUE);
                            payload.IEPS_AMOUNT = this.convertStringToNumberValue(v.IEPS_AMOUNT);
                            payload.IVA_AMOUNT = this.convertStringToNumberValue(v.IVA_AMOUNT);
                            payload.TOTAL_DUE = this.convertStringToNumberValue(v.TOTAL_DUE);
                            payload.SAP_DOC_NBR = v.SAP_DOC_NBR;
                            payload.IEPS_PCT = this.convertStringToNumberValue(v.IEPS_PCT);
                            payload.IVA_PCT = this.convertStringToNumberValue(v.IVA_PCT);
                            payload.COOP_NBR = this.convertStringToNumberValue(v.COOP_NBR);
                            payload.CLAIM_NUMBER = v.Claim_Number;
                            payload.AUDITOR = v.AUDITOR;
                            payload.COMPLETE_DT = v.COMPLETE_DT != '' ? v.COMPLETE_DT : null;
                            payload.STATUS = v.STATUS;
                            payload.NO_OF_DEDUCTION = v.NO_OF_DEDUCTION;
                            payload.AUDITOR_COMMENT = v.AUDITOR_COMMENT;
                            payload.INCLUDE_CHECK = v.INCLUDE_CHECK;
                            payload.INCLUDE = 'Y';
                            payload.VERSION = Number(v.VERSION) + 1;
                            payload.RECORD_ID = Number(v.RECORD_ID);
                        } else if (this.pageID == 'Fill Rate') {
                            (payload.USRCHG = v.USRCHG),
                                (payload.AUDIT_YEAR = v.AUDIT_YEAR),
                                (payload.GROUP = v.GROUP),
                                (payload.DIVISION = v.DIVISION),
                                (payload.VENDOR_NBR = v.VENDOR_NBR),
                                (payload.VENDOR_NAME = v.VENDOR_NAME),
                                (payload.SAP_VENDOR = v.SAP_VENDOR),
                                (payload.DEPARTMENT = v.DEPARTMENT),
                                (payload.PO_SEQ = v.PO_SEQ),
                                (payload.FORMAT = v.FORMAT),
                                (payload.TOTAL_ORD_QTY = v.TOTAL_ORD_QTY),
                                (payload.TOTAL_RCV_QTY = v.TOTAL_RCV_QTY),
                                (payload.TOTAL_SHORT_QTY = v.TOTAL_SHORT_QTY),
                                (payload.FILL_RATE_CALC_PCT = v.FILL_RATE_CALC_PCT),
                                (payload.TOTAL_RETAIL_AMT_SHORT = v.TOTAL_RETAIL_AMT_SHORT),
                                (payload.TOTAL_MARGIN_LOST = v.TOTAL_MARGIN_LOST),
                                (payload.FILL_RATE_PCT = v.FILL_RATE_PCT),
                                (payload.TOTAL_EARNED = v.TOTAL_EARNED),
                                (payload.TOTAL_AMT_PAID = v.TOTAL_AMT_PAID),
                                (payload.TOTAL_PENDING = v.TOTAL_PENDING),
                                (payload.IEPS_DUE = v.IEPS_DUE),
                                (payload.IVA_DUE = v.IVA_DUE),
                                (payload.COOP_NUMBER = v.COOP_NUMBER),
                                (payload.IEPS_PCT = v.IEPS_PCT),
                                (payload.IVA_PCT = v.IVA_PCT),
                                (payload.PREVIOUS_CLAIMS = v.PREVIOUS_CLAIMS),
                                (payload.PAST_CLAIM_NUMBERS = v.PAST_CLAIM_NUMBERS),
                                (payload.INTERNAL_RATE = v.INTERNAL_RATE),
                                (payload.RFC = v.RFC),
                                (payload.CLAIM_NUMBER = v.Claim_Number),
                                (payload.AUDITOR = v.AUDITOR),
                                (payload.COMPLETE_DT = v.COMPLETE_DT != '' ? v.COMPLETE_DT : null),
                                (payload.STATUSCODE = v.STATUSCODE),
                                (payload.COMMENTS = v.COMMENTS),
                                (payload.INCLUDE = 'Y');
                            payload.VERSION = Number(v.VERSION) + 1;
                            payload.RECORD_ID = Number(v.RECORD_ID);
                        } else if (this.pageID == 'MSI') {
                            (payload.USR_CHANGE = v.USR_CHANGE);
                            (payload.AUDIT_YEAR = v.AUDIT_YEAR);
                            (payload.VENDOR_NAME = v.VENDOR_NAME);
                            (payload.SAP_VENDOR = v.SAP_VENDOR);
                            (payload.VENDOR_NBR = v.VENDOR_NBR);
                            (payload.DEPARTMENT = v.DEPARTMENT);
                            (payload.SEQ_NO = v.SEQ_NO);
                            (payload.FORMAT = v.FORMAT);
                            (payload.UNITS_SOLD = this.convertStringToNumberValue(v.UNITS_SOLD));
                            (payload.TOTAL_DEFERRED = this.convertStringToNumberValue(v.TOTAL_DEFERRED));
                            (payload.SURCHARGE_SALES = this.convertStringToNumberValue(v.SURCHARGE_SALES));
                            (payload.AGREE = v.AGREE);
                            (payload.DEDUCTION = this.convertStringToNumberValue(v.DEDUCTION));
                            (payload.DEDUCTION_CIVA = this.convertStringToNumberValue(v.DEDUCTION_CIVA));
                            (payload.OTHER_DEDUCTIONS = this.convertStringToNumberValue(v.OTHER_DEDUCTIONS));
                            (payload.AMT_DUE = this.convertStringToNumberValue(v.AMT_DUE));
                            (payload.MSI_PCT = this.convertStringToNumberValue(v.MSI_PCT));
                            (payload.IVA = this.convertStringToNumberValue(v.IVA));
                            (payload.IVA_PCT = this.convertStringToNumberValue(v.IVA_PCT));
                            (payload.PREVIOUS_CLAIMS = this.convertStringToNumberValue(v.PREVIOUS_CLAIMS));
                            (payload.PAST_CLAIM_NUMBERS = this.convertStringToNumberValue(v.PAST_CLAIM_NUMBERS));
                            (payload.AUDITOR = v.AUDITOR);
                            (payload.COMPLETE_DT = v.COMPLETE_DT);
                            (payload.STATUS = v.STATUS);
                            (payload.IEPS_PCT = this.convertStringToNumberValue(v.IEPS_PCT));
                            (payload.AUDITOR_COMMENTS = v.AUDITOR_COMMENTS);
                            (payload.CLAIM_NUMBER = v.Claim_Number);
                            (payload.RFC = v.RFC);
                            (payload.INCLUDE = 'Y');
                            payload.VERSION = Number(v.VERSION) + 1;
                            payload.RECORD_ID = Number(v.RECORD_ID);
                        } else if (this.pageID === 'Low Price') {
                            payload.USRCHG = v.USRCHG;
                            payload.AUDIT_YEAR = v.AUDIT_YEAR;
                            payload.RELEASE = v.RELEASE;
                            payload.VENDOR_NBR = v.VENDOR_NBR;
                            payload.VENDOR_NAME = v.VENDOR_NAME;
                            payload.SAP_VENDOR = v.SAP_VENDOR;
                            payload.DEPT = v.DEPT;
                            payload.SEQ = v.SEQ;
                            payload.PO_TYPE = v.PO_TYPE;
                            payload.PO_EVENT = v.PO_EVENT;
                            payload.PO_NBR = v.PO_NBR;
                            payload.PO_DATE = v.PO_DATE;
                            payload.INV_NBR = v.INV_NBR;
                            payload.INV_DATE = v.INV_DATE;
                            payload.INV_TOTAL_COST = v.INV_TOTAL_COST;
                            payload.PO_TOTAL_COST = v.PO_TOTAL_COST;
                            payload.RCVD_TOTAL_COST = v.RCVD_TOTAL_COST;
                            payload.COST_PAID_TOTAL = v.COST_PAID_TOTAL;
                            payload.SB_TOTAL_COST = v.SB_TOTAL_COST;
                            payload.INV_COST_DUE = v.INV_COST_DUE;
                            payload.IEPS_COST_DUE = v.IEPS_COST_DUE;
                            payload.IVA_COST_DUE = v.IVA_COST_DUE;
                            payload.TOTAL_COST_DUE = v.TOTAL_COST_DUE;
                            payload.AP_TOT_AMT = v.AP_TOT_AMT;
                            payload.COST_PAID_TOT_AMT = v.COST_PAID_TOT_AMT;
                            payload.COST_PAID_TOT_DIFF = v.COST_PAID_TOT_DIFF;
                            payload.TOTAL_SHORT_DUE = v.TOTAL_SHORT_DUE;
                            payload.INV_TOTAL_AMT = v.INV_TOTAL_AMT;
                            payload.PO_TOT_AMT = v.PO_TOT_AMT;
                            payload.RCVD_TOT_AMT = v.RCVD_TOT_AMT;
                            payload.CLAIM_NUMBER = v.CLAIM_NUMBER;
                            payload.UUID = v.UUID;
                            payload.RFC = v.RFC;
                            payload.CLEARING_DT = v.CLEARING_DT;
                            payload.CHECK_NBR = v.CHECK_NBR;
                            payload.AUDITOR = v.AUDITOR;
                            payload.STATUS = v.STATUS;
                            payload.COMPLETE_DT = v.COMPLETE_DT != '' ? v.COMPLETE_DT : null;
                            payload.COMMENTS = v.COMMENTS;
                            payload.PREVIOUS_CLAIMS = v.PREVIOUS_CLAIMS;
                            payload.PAST_CLAIM_NUMBERS = v.PAST_CLAIM_NUMBERS;
                            payload.CATCH_RATE = v.CATCH_RATE;
                            payload.INCLUDE = 'Y';
                            payload.VERSION = Number(v.VERSION) + 1;
                            payload.RECORD_ID = Number(v.RECORD_ID);                        
                        } else if (this.pageID === 'Shortages') {
                            payload.USRCHG = v.USRCHG;
                            payload.AUDIT_YEAR = v.AUDIT_YEAR;
                            payload.RELEASE = v.RELEASE;
                            payload.VENDOR_NBR = v.VENDOR_NBR;
                            payload.VENDOR_NAME = v.VENDOR_NAME;
                            payload.SAP_VENDOR = v.SAP_VENDOR;
                            payload.DEPT = v.DEPT;
                            payload.SEQ = v.SEQ;
                            payload.PO_TYPE = v.PO_TYPE;
                            payload.PO_EVENT = v.PO_EVENT;
                            payload.PO_NBR = v.PO_NBR;
                            payload.PO_DATE = v.PO_DATE;
                            payload.INV_NBR = v.INV_NBR;
                            payload.INV_DATE = v.INV_DATE;
                            payload.EQUIV_EACH_INV = v.EQUIV_EACH_INV;
                            payload.ZERO_RCVD_CNT = v.ZERO_RCVD_CNT;
                            payload.EQUIVEACHRCVD = v.EQUIVEACHRCVD;
                            payload.QTY_SHORT = v.QTY_SHORT;
                            payload.SHORT_DUE = v.SHORT_DUE;
                            payload.IEPS_SHORT_DUE = v.IEPS_SHORT_DUE;
                            payload.IVA_SHORT_DUE = v.IVA_SHORT_DUE;
                            payload.TOTAL_SHORT_DUE = v.TOTAL_SHORT_DUE;
                            payload.COST_PAID_TOT_DIFF = v.COST_PAID_TOT_DIFF;
                            payload.NETSHORT = v.NETSHORT;
                            payload.AP_TOT_AMT = v.AP_TOT_AMT;
                            payload.TOT_COST_PAID_AMT = v.TOT_COST_PAID_AMT;
                            payload.CLAIM_NUMBER = v.CLAIM_NUMBER;
                            payload.UUID = v.UUID;
                            payload.RFC = v.RFC;
                            payload.CLEARING_DT = v.CLEARING_DT;
                            payload.CHECK_NBR = v.CHECK_NBR;
                            payload.AUDITOR = v.AUDITOR;
                            payload.STATUS = v.STATUS;
                            payload.COMPLETE_DT = v.COMPLETE_DT != '' ? v.COMPLETE_DT : null;
                            payload.COMMENTS = v.COMMENTS;
                            payload.PREVIOUS_CLAIMS = v.PREVIOUS_CLAIMS;
                            payload.PAST_CLAIM_NUMBERS = v.PAST_CLAIM_NUMBERS;
                            payload.CATCH_RATE = v.CATCH_RATE;
                            (payload.VERSION = Number(this.rowDataObject.VERSION) + 1);
                            payload.RECORD_ID = v.RECORD_ID;
                            payload.INCLUDE = 'Y'; 
                        }
                    }
                    finalPayload.push(payload);
                });
            }
        }  else {
            // SINGLE SELECTION
            // Chile Single
            if (this.ccCode === 'CL') {
                if (this.pageID == 'NEW-STORE') {
                    payload.RECORD_ID = fromDataValue.RECORD_ID;
                    payload.CHANGE_USER = fromDataValue.CHANGE_USER;
                    payload.AUDIT_YEAR = fromDataValue.AUDIT_YEAR;
                    payload.RELEASE_NUM = fromDataValue.RELEASE_NUM;
                    payload.RUT = fromDataValue.RUT;
                    payload.SUPPLIER_NAME = fromDataValue.SUPPLIER_NAME;
                    payload.SUPPLIER_NUM = fromDataValue.SUPPLIER_NUM;
                    payload.DEPT = fromDataValue.DEPT;
                    payload.LEGACY_CATEGORY = fromDataValue.LEGACY_CATEGORY;
                    payload.CATEGORY = fromDataValue.CATEGORY;
                    payload.MIN_SALES = fromDataValue.MIN_SALES != '' && fromDataValue.MIN_SALES != null ? moment(fromDataValue.MIN_SALES).format('YYYY-MM-DD') : null;
                    payload.MAX_SALES = fromDataValue.MAX_SALES != '' && fromDataValue.MAX_SALES != null ? moment(fromDataValue.MAX_SALES).format('YYYY-MM-DD') : null;
                    payload.STORE_NBR = fromDataValue.STORE_NBR;
                    payload.TYPE = fromDataValue.TYPE;
                    payload.OPEN_DATE = fromDataValue.OPEN_DATE != '' && fromDataValue.OPEN_DATE != null ? moment(fromDataValue.OPEN_DATE).format('YYYY-MM-DD') : null;
                    payload.FORMAT = fromDataValue.FORMAT;
                    payload.FIXED_AMT = this.convertStringToNumberValue(fromDataValue.FIXED_AMT);
                    payload.UFAMT = this.convertStringToNumberValue(fromDataValue.UFAMT);
                    payload.TOTAL_DUE = this.convertStringToNumberValue(fromDataValue.TOTAL_DUE);
                    payload.AMT_DEDUCTED = this.convertStringToNumberValue(fromDataValue.AMT_DEDUCTED);
                    payload.OTHER_DEDUCTION = this.convertStringToNumberValue(fromDataValue.OTHER_DEDUCTION);
                    payload.AMOUNT_DUE = this.convertStringToNumberValue(fromDataValue.AMOUNT_DUE);
                    payload.TOTAL_SALES = this.convertStringToNumberValue(fromDataValue.TOTAL_SALES);
                    payload.AUDITOR_ID = fromDataValue.AUDITOR_ID;
                    payload.QR_STATUS = fromDataValue.QR_STATUS;
                    payload.COMPLETED_DATE = fromDataValue.COMPLETED_DATE != '' && fromDataValue.COMPLETED_DATE != null ? moment(fromDataValue.COMPLETED_DATE).format('YYYY-MM-DD') : null;
                    payload.APP_CLAIM_NUMBER = fromDataValue.APP_CLAIM_NUMBER;
                    payload.COMMENTS = fromDataValue.COMMENTS;
                    payload.CITY = fromDataValue.CITY;
                    payload.AREA = fromDataValue.AREA;
                    payload.ADDRESS = fromDataValue.ADDRESS;
                    payload.CREATED_BY = userInfo.username;
                    payload.VERSION = Number(this.rowDataObject.VERSION) + 1;
                    payload.CREATED_DATE = this.rowDataObject.CREATED_DATE != '' && fromDataValue.COMPLETED_DATE != null ? this.rowDataObject.CREATED_DATE : null;
                    payload.LAST_UPDATED_BY = this.rowDataObject.LAST_UPDATED_BY;
                } else if (this.pageID == 'UNDED-COOPS') {
                    payload.RECORD_ID = fromDataValue.RECORD_ID;
                    payload.AUDIT_YEAR = this.singleAuditYear;
                    payload.RELEASE_NUM = fromDataValue.RELEASE_NUM;
                    payload.RUT = fromDataValue.RUT;
                    payload.VENDOR_NBR = fromDataValue.VENDOR_NBR;
                    payload.SUPPLIER_NAME = fromDataValue.SUPPLIER_NAME;
                    payload.ALLOW_TYPE = fromDataValue.ALLOW_TYPE;
                    payload.DEPT = fromDataValue.DEPT;
                    payload.SEQUENCING = fromDataValue.SEQUENCING;
                    payload.LEGACY_CATEGORY = fromDataValue.LEGACY_CATEGORY;
                    payload.CATEGORY = fromDataValue.CATEGORY;
                    payload.COMPANY = fromDataValue.COMPANY;
                    payload.AGREEMENT_NUMBER = fromDataValue.AGREEMENT_NUMBER;
                    payload.STATUS = fromDataValue.STATUS;
                    payload.AMOUNT = this.convertStringToNumberValue(fromDataValue.AMOUNT);
                    payload.AMT_FOR_THE_PERIOD = this.convertStringToNumberValue(fromDataValue.AMT_FOR_THE_PERIOD);
                    payload.CREATION_DATE = fromDataValue.CREATION_DATE != '' ? moment(fromDataValue.CREATION_DATE).format('YYYY-MM-DD') : null;
                    payload.POSTING_DATE = fromDataValue.POSTING_DATE != '' ? moment(fromDataValue.POSTING_DATE).format('YYYY-MM-DD') : null;
                    payload.AMOUNT_DEDUCTED = this.convertStringToNumberValue(fromDataValue.AMOUNT_DEDUCTED);
                    payload.INITIAL_DATE = fromDataValue.INITIAL_DATE != '' ? moment(fromDataValue.INITIAL_DATE).format('YYYY-MM-DD') : null;
                    payload.FINAL_DATE = fromDataValue.FINAL_DATE != '' ? moment(fromDataValue.FINAL_DATE).format('YYYY-MM-DD') : null;
                    payload.ASSIGNED_USERNAME = fromDataValue.ASSIGNED_USERNAME;
                    payload.DATE_AUDITED = fromDataValue.DATE_AUDITED != '' ? moment(fromDataValue.DATE_AUDITED).format('YYYY-MM-DD') : null;
                    payload.QR_STATUS = fromDataValue.QR_STATUS;
                    payload.NOTES = fromDataValue.NOTES;
                    payload.APP_CLAIM_NUMBER = fromDataValue.APP_CLAIM_NUMBER;
                    payload.BUYER = fromDataValue.BUYER;
                    payload.COMMENTS = fromDataValue.COMMENTS;
                    payload.COMMENTS2 = fromDataValue.COMMENTS2;
                    payload.CREATION_USER = fromDataValue.CREATION_USER;
                    payload.CREATED_BY = userInfo.username;
                    payload.VERSION = Number(this.rowDataObject.VERSION) + 1;
                    payload.CREATED_DATE = this.rowDataObject.CREATED_DATE != '' ? this.rowDataObject.CREATED_DATE : null;
                    payload.LAST_UPDATED_BY = this.rowDataObject.LAST_UPDATED_BY;
                } else if (this.pageID === 'VOLUMES') {
                    payload.RECORD_ID = fromDataValue.RECORD_ID;
                    payload.CHANGE_USER = fromDataValue.CHANGE_USER;
                    payload.AUDIT_YEAR = this.singleAuditYear;
                    payload.RELEASE_NUM = fromDataValue.RELEASE_NUM;
                    payload.RUT = fromDataValue.RUT;
                    payload.SUPPLIER_NAME = fromDataValue.SUPPLIER_NAME;
                    payload.SUPPLIER_NUM = fromDataValue.SUPPLIER_NUM;
                    payload.ALLOW_TYPE = fromDataValue.ALLOW_TYPE;
                    payload.DEAL_AGREEMENT_NUM = fromDataValue.DEAL_AGREEMENT_NUM;
                    payload.FORMAT_TYPE = _.isArray(fromDataValue.FORMAT_TYPE) ? fromDataValue.FORMAT_TYPE.toString() : fromDataValue.FORMAT_TYPE;
                    payload.START_DATE = fromDataValue.START_DATE != '' ? fromDataValue.START_DATE : null;
                    payload.END_DATE = fromDataValue.END_DATE != '' ? fromDataValue.END_DATE : null;
                    payload.ACCOUNT_DEPARTMENT = fromDataValue.ACCOUNT_DEPARTMENT;
                    payload.LEGACY_CATEGORY = fromDataValue.LEGACY_CATEGORY;
                    payload.CATEGORY = fromDataValue.CATEGORY;
                    payload.PRIVATE_LABEL = fromDataValue.PRIVATE_LABEL;
                    payload.YEAR_VOLUME = this.convertStringToNumberValue(fromDataValue.YEAR_VOLUME);
                    payload.PERCENTAGE = fromDataValue.PERCENTAGE;
                    payload.VOLUME = this.convertStringToNumberValue(fromDataValue.VOLUME);
                    payload.EARNED = this.convertStringToNumberValue(fromDataValue.EARNED);
                    payload.AMOUNT_RECEIVED = this.convertStringToNumberValue(fromDataValue.AMOUNT_RECEIVED);
                    payload.FIXED_AMOUNT_AGREEMENT = this.convertStringToNumberValue(fromDataValue.FIXED_AMOUNT_AGREEMENT);
                    payload.DEBITED_AMOUNT = this.convertStringToNumberValue(fromDataValue.DEBITED_AMOUNT);
                    payload.AUDITOR_ID = fromDataValue.AUDITOR_ID;
                    payload.DATE_AUDITED = fromDataValue.DATE_AUDITED != '' ? fromDataValue.DATE_AUDITED : null;
                    payload.QR_STATUS = fromDataValue.QR_STATUS;
                    payload.AGREEMENT_NUMBER = fromDataValue.AGREEMENT_NUMBER;
                    payload.APP_CLAIM_NUMBER = fromDataValue.APP_CLAIM_NUMBER;
                    payload.COMMENTS = fromDataValue.COMMENTS;
                    payload.CREATED_BY = userInfo.username;
                    payload.VERSION = Number(this.rowDataObject.VERSION) + 1;
                    payload.CREATED_DATE = this.rowDataObject.CREATED_DATE != '' ? this.rowDataObject.CREATED_DATE : null;
                    payload.LAST_UPDATED_BY = this.rowDataObject.LAST_UPDATED_BY;
                } else if (this.pageID === 'MISC') {
                    payload.RECORD_ID = fromDataValue.RECORD_ID;
                    payload.CHANGE_USER = fromDataValue.CHANGE_USER;
                    payload.RELEASE_NUM = fromDataValue.RELEASE_NUM;
                    payload.AUDIT_YEAR = this.singleAuditYear;
                    payload.LEGACY_CATEGORY = fromDataValue.LEGACY_CATEGORY;
                    payload.RUT = fromDataValue.RUT;
                    payload.SUPPLIER_NAME = fromDataValue.SUPPLIER_NAME;
                    payload.SUPPLIER_NUM = fromDataValue.SUPPLIER_NUM;
                    payload.DEPT = fromDataValue.DEPT;
                    payload.NEW_CATEGORY = fromDataValue.NEW_CATEGORY;
                    payload.FORMAT_TYPE = fromDataValue.FORMAT_TYPE;
                    payload.ALLOW_TYPE = fromDataValue.ALLOW_TYPE;
                    payload.AMOUNT_DUE = this.convertStringToNumberValue(fromDataValue.AMOUNT_DUE);
                    payload.TOTAL_DEDUCTION = this.convertStringToNumberValue(fromDataValue.TOTAL_DEDUCTION);
                    payload.PENDING_DUE = this.convertStringToNumberValue(fromDataValue.PENDING_DUE);
                    payload.IVA_DUE = this.convertStringToNumberValue(fromDataValue.IVA_DUE);
                    payload.TOTAL_PENDING = this.convertStringToNumberValue(fromDataValue.TOTAL_PENDING);
                    payload.AUDITOR_ID = fromDataValue.AUDITOR_ID;
                    payload.REVISION_DATE = fromDataValue.REVISION_DATE != '' ? moment(fromDataValue.REVISION_DATE).format('YYYY-MM-DD') : null;
                    payload.AUDITOR_STATUS = fromDataValue.AUDITOR_STATUS;
                    payload.AGREEMENT_NUM = fromDataValue.AGREEMENT_NUM;
                    payload.INSERTED = fromDataValue.INSERTED;
                    payload.NOTES = fromDataValue.NOTES;
                    payload.APP_CLAIM_NUMBER = fromDataValue.APP_CLAIM_NUMBER;
                    payload.CREATED_BY = userInfo.username;
                    payload.VERSION = Number(this.rowDataObject.VERSION) + 1;
                    payload.CREATED_DATE = this.rowDataObject.CREATED_DATE != '' ? this.rowDataObject.CREATED_DATE : null;
                    payload.LAST_UPDATED_BY = this.rowDataObject.LAST_UPDATED_BY;
                    if (this.ccCode === 'MX') {
                        payload.IEPS_PCT = this.convertStringToNumberValue(fromDataValue.IEPS_PCT);
                        payload.IEPS_DUE = this.convertStringToNumberValue(fromDataValue.IEPS_DUE);
                        payload.IVA_PCT = this.convertStringToNumberValue(fromDataValue.IVA_PCT);
                        payload.LAST_UPDATED_DATE = this.rowDataObject.CREATED_DATE != '' ? this.rowDataObject.CREATED_DATE : null;
                    }
                }
            } else {
                // Mexico Single
                if (this.pageID === 'Scan Allowances') {
                    payload.AUDITOR = fromDataValue.AUDITOR;
                    payload.AUDITOR_COMMENTS = fromDataValue.AUDITOR_COMMENTS;
                    payload.ALLOW_EARNED = fromDataValue.ALLOW_EARNED;
                    payload.AMS_QTY = fromDataValue.AMS_QTY;
                    payload.AMS_RATE = fromDataValue.AMS_RATE;
                    payload.AMS_SALES_AMT = fromDataValue.AMS_SALES_AMT;
                    payload.AMT_DUE = fromDataValue.AMT_DUE;
                    payload.AUDIT_YEAR = fromDataValue.AUDIT_YEAR;
                    payload.ACCRUAL_AMT = fromDataValue.Accrual_AMT;
                    payload.ADJUSTMENTS = fromDataValue.Adjustments;
                    payload.AGREEMENT_NBR = fromDataValue.Agreement_Nbr != '' ? fromDataValue.Agreement_Nbr : null;
                    payload.AGREEMENT_REQUEST_NBR = fromDataValue.Agreement_Request_Nbr;
                    payload.AGREEMENT_REQUEST_STATUS = fromDataValue.Agreement_Request_Status;
                    payload.COOP_NUMBER = fromDataValue.COOP_Number;
                    payload.CLAIM_NUMBER = fromDataValue.Claim_Number;
                    payload.COMMENTS = fromDataValue.Comments;
                    payload.COMPLETE_DT = fromDataValue.Complete_DT != '' ? fromDataValue.Complete_DT : null;
                    payload.DIVISION = fromDataValue.DIVISION;
                    payload.DEPARTMENT = fromDataValue.DEPARTMENT;
                    payload.FORMAT = fromDataValue.FORMAT;
                    payload.GROUPS = fromDataValue.GROUPS;
                    payload.ID = fromDataValue.ID;
                    payload.INCLUDE = 'Y';
                    payload.IEPS_DUE = fromDataValue.IEPS_DUE;
                    payload.IEPS_PCT = fromDataValue.IEPS_PCT;
                    payload.ITEM_DESC = fromDataValue.ITEM_DESC;
                    payload.IVA_DUE = fromDataValue.IVA_DUE;
                    payload.IVA_PCT = fromDataValue.IVA_PCT;
                    payload.ITEM_NBR = fromDataValue.Item_Nbr;
                    payload.POS_BANNER_CODE = fromDataValue.POS_BANNER_CODE;
                    payload.POS_SALES_AMT = fromDataValue.POS_SALES_AMT;
                    payload.POS_SALES_QTY = fromDataValue.POS_SALES_QTY;
                    payload.REASON_CODE = fromDataValue.REASON_CODE;
                    payload.RECORD_ID = fromDataValue.RECORD_ID;
                    payload.RFC = fromDataValue.RFC;
                    payload.SAP_VENDOR = fromDataValue.SAP_VENDOR;
                    payload.SEQ_NO = fromDataValue.SEQ_NO;
                    payload.STATUSCODE = fromDataValue.StatusCode;
                    payload.UPC_NBR = fromDataValue.UPC_NBR;
                    payload.VALID_FROM = fromDataValue.Valid_From != '' ? fromDataValue.Valid_From : null;
                    payload.VALID_TO = fromDataValue.Valid_To != '' ? fromDataValue.Valid_To : null;
                    payload.VENDOR_NAME = this.returnItemAfterTrim(fromDataValue.VENDOR_NAME);
                    payload.VENDOR_NBR = fromDataValue.VENDOR_NBR;
                    payload.USR_CHANGE = fromDataValue.USR_CHANGE;
                    payload.ALL_BODEGA_POS_QTY = fromDataValue.ALL_BODEGA_POS_QTY;
                    payload.ALL_BODEGA_POS_AMT = fromDataValue.ALL_BODEGA_POS_AMT;
                    payload.VERSION = Number(this.rowDataObject.VERSION) + 1;
                } else if (this.pageID === 'New Stores') {
                    payload.ALLOW_EARNED = this.convertStringToNumberValue(fromDataValue.ALLOW_EARNED);
                    payload.AMTDEDUCTED = this.convertStringToNumberValue(fromDataValue.AMTDEDUCTED);
                    payload.AMT_DUE = this.convertStringToNumberValue(fromDataValue.AMT_DUE);
                    payload.AUDITOR = fromDataValue.AUDITOR;
                    payload.AUDITOR_COMMENTS = fromDataValue.AUDITOR_COMMENTS;
                    payload.AUDIT_YEAR = fromDataValue.AUDIT_YEAR;
                    payload.CLAIM_NUMBER = fromDataValue.Claim_Number;
                    payload.CLEARING_DOC = fromDataValue.CLEARING_DOC;
                    payload.CLEAR_DATE = fromDataValue.CLEAR_DATE != '' ? fromDataValue.CLEAR_DATE : null;
                    payload.COMPLETE_DATE = fromDataValue.COMPLETE_DATE != '' ? fromDataValue.COMPLETE_DATE : null;
                    payload.COOP_DEDUCTED = fromDataValue.COOP_DEDUCTED != '' ? fromDataValue.COOP_DEDUCTED : 0;
                    payload.COOP_NUMBER = fromDataValue.COOP_NUMBER;
                    payload.DEDUCT_TYPE = fromDataValue.DEDUCT_TYPE;
                    payload.DOC_NBR = fromDataValue.DOC_NBR;
                    payload.DEPARTMENT = fromDataValue.DEPARTMENT;
                    payload.FORMAT = fromDataValue.FORMAT;
                    payload.GO_DATE = fromDataValue.GO_DATE != '' ? fromDataValue.GO_DATE : null;
                    payload.IEPS = this.convertStringToNumberValue(fromDataValue.IEPS);
                    payload.IEPS_PCT = this.convertStringToNumberValue(fromDataValue.IEPS_PCT);
                    payload.INSERT_USER = fromDataValue.INSERT_USER;
                    payload.INCLUDE = 'Y';
                    payload.INS_ROW = fromDataValue.INS_ROW;
                    payload.INV_DATE = fromDataValue.INV_DATE != '' ? fromDataValue.INV_DATE : null;
                    payload.IVA = this.convertStringToNumberValue(fromDataValue.IVA);
                    payload.IVA_PCT = this.convertStringToNumberValue(fromDataValue.IVA_PCT);
                    payload.RECORD_ID = fromDataValue.RECORD_ID;
                    payload.RFC = fromDataValue.RFC;
                    payload.SAM_FLAG = fromDataValue.SAM_FLAG;
                    payload.SAP_NUMBER = fromDataValue.SAP_NUMBER;
                    payload.SEQ_NO = fromDataValue.SEQ_NO;
                    payload.SHIP_CHK = fromDataValue.SHIP_CHK;
                    payload.STATUS = fromDataValue.STATUS;
                    payload.STORE = fromDataValue.STORE;
                    payload.STORE_NAME = fromDataValue.STORE_NAME;
                    payload.TEXT = fromDataValue.TEXT;
                    payload.TOTAL_SHIPS = fromDataValue.TOTAL_SHIPS;
                    payload.USRCHG = fromDataValue.USRCHG;
                    payload.VENDOR_NAME = fromDataValue.VENDOR_NAME;
                    payload.VENDOR_NBR = fromDataValue.VENDOR_NBR;
                    payload.VERSION = Number(this.rowDataObject.version) + 1;
                } else if (this.pageID == 'Volume Allowances') {
                    payload.ADJUSTMENTS = fromDataValue.ADJUSTMENTS;
                    payload.ALLOW_EARNED = this.convertStringToNumberValue(fromDataValue.ALLOW_EARNED);
                    payload.ALL_ITEMS = fromDataValue.ALL_ITEMS;
                    payload.AMS_PURCHASE = fromDataValue.AMS_PURCHASE;
                    payload.AMT_DUE = this.convertStringToNumberValue(fromDataValue.AMT_DUE);
                    payload.AUDITOR = fromDataValue.AUDITOR;
                    payload.AUDITOR_COMMENTS = fromDataValue.AUDITOR_COMMENTS;
                    payload.AUDIT_YEAR = fromDataValue.AUDIT_YEAR;
                    payload.CATCH_RATE = fromDataValue.CATCH_RATE;
                    payload.COMPLETE_DATE = fromDataValue.COMPLETE_DATE != '' ? fromDataValue.COMPLETE_DATE : null;
                    payload.CLAIM_NUMBER = fromDataValue.Claim_Number;
                    payload.DEAL = fromDataValue.DEAL;
                    payload.DEPARTMENT = this.convertStringToNumberValue(fromDataValue.DEPARTMENT);
                    payload.END_DATE = fromDataValue.END_DATE != '' ? fromDataValue.END_DATE : null;
                    payload.ENTERED_AMT = fromDataValue.ENTERED_AMT;
                    payload.FORMAT = fromDataValue.FORMAT;
                    payload.GROUP = this.convertStringToNumberValue(fromDataValue.GROUP);
                    payload.IEPS = fromDataValue.IEPS;
                    payload.IEPS_PCT = fromDataValue.IEPS_PCT;
                    payload.INSERT_USER = fromDataValue.INSERT_USER;
                    payload.INS_ROW = fromDataValue.INS_ROW;
                    payload.IVA = fromDataValue.IVA;
                    payload.IVA_PCT = fromDataValue.IVA_PCT;
                    payload.OTHER_DED = fromDataValue.OTHER_DED;
                    payload.PAST_CLAIM_NUMBERS = fromDataValue.PAST_CLAIM_NUMBERS;
                    payload.PCT = fromDataValue.PCT;
                    payload.PO_PURCHASES = fromDataValue.PO_PURCHASES;
                    payload.PO_SOURCE = fromDataValue.PO_SOURCE;
                    payload.PREVIOUS_CLAIMS = this.convertStringToNumberValue(fromDataValue.PREVIOUS_CLAIMS);
                    payload.RECORD_ID = this.convertStringToNumberValue(fromDataValue.RECORD_ID);
                    payload.RFC = fromDataValue.RFC;
                    payload.SAP_VENDOR = this.convertStringToNumberValue(fromDataValue.SAP_VENDOR);
                    payload.SEQ_NO = fromDataValue.SEQ_NO;
                    payload.START_DATE = fromDataValue.START_DATE != '' ? fromDataValue.START_DATE : null;
                    payload.STATUS = fromDataValue.STATUS;
                    payload.TAX_CD = fromDataValue.TAX_CD;
                    payload.TIER = this.convertStringToNumberValue(fromDataValue.TIER);
                    payload.TOTAL_DUE = this.convertStringToNumberValue(fromDataValue.TOTAL_DUE);
                    payload.USR_CHANGE = fromDataValue.USR_CHANGE;
                    payload.VENDOR_NAME = fromDataValue.VENDOR_NAME;
                    payload.VENDOR_NBR = this.convertStringToNumberValue(fromDataValue.VENDOR_NBR);
                    payload.INCLUDE = 'Y';
                    payload.VERSION = Number(this.rowDataObject.version) + 1;
                }  else if (this.pageID === 'UN-DEDUCTED COOPS') {
                    (payload.USRCHG = fromDataValue.USRCHG),
                        (payload.REL = fromDataValue.REL),
                        (payload.AUDIT_YEAR = fromDataValue.AUDIT_YEAR),
                        (payload.ACTION = fromDataValue.ACTION),
                        (payload.VENDOR_NBR = fromDataValue.VENDOR_NBR),
                        (payload.SAP_VENDOR = fromDataValue.SAP_VENDOR),
                        (payload.VENDOR_NAME = fromDataValue.VENDOR_NAME),
                        (payload.AGREEMENT_REQUEST_NBR = fromDataValue.Agreement_Request_Nbr),
                        (payload.COOP_NBR = fromDataValue.COOP_NBR),
                        (payload.RSN_CDE = fromDataValue.RSN_CDE),
                        (payload.COOP_TYPE = fromDataValue.COOP_TYPE),
                        (payload.COOP_STATUS = fromDataValue.COOP_STATUS),
                        (payload.SEQ_NO = fromDataValue.SEQ_NO),
                        (payload.FORMAT = fromDataValue.FORMAT),
                        (payload.EFFECT_DATE = fromDataValue.EFFECT_DATE  != '' ? fromDataValue.EFFECT_DATE : null),
                        (payload.END_DATE = fromDataValue.END_DATE != '' ? fromDataValue.END_DATE : null),
                        (payload.DEPARTMENT = fromDataValue.DEPARTMENT),
                        (payload.DIV = fromDataValue.DIV),
                        (payload.BASE_COOP_AMT = this.convertStringToNumberValue(fromDataValue.BASE_COOP_AMT)),
                        (payload.BASE_AMT_DEDUCT = this.convertStringToNumberValue(fromDataValue.BASE_AMT_DEDUCT)),
                        (payload.ADJUSTMENTS = this.convertStringToNumberValue(fromDataValue.ADJUSTMENTS)),
                        (payload.BASE_AMT_DUE = fromDataValue.BASE_AMT_DUE),
                        (payload.IVA_DUE = fromDataValue.IVA_DUE),
                        (payload.IEPS_DUE = fromDataValue.IEPS_DUE),
                        (payload.AMT_DEDUCT_TAXES = fromDataValue.AMT_DEDUCT_TAXES),
                        (payload.IVA_PCT = fromDataValue.IVA_PCT),
                        (payload.IEPS_PCT = fromDataValue.IEPS_PCT),
                        (payload.SAP_DOC_NBR = fromDataValue.SAP_DOC_NBR),
                        (payload.CREATE_DTE = fromDataValue.CREATE_DTE  != '' ? fromDataValue.CREATE_DTE : null),
                        (payload.CLEARING_DT = fromDataValue.CLEARING_DT != '' ? fromDataValue.CLEARING_DT : null),
                        (payload.REJECT_REASON = fromDataValue.REJECT_REASON),
                        (payload.RFC = fromDataValue.RFC),
                        (payload.TAX_CODE = fromDataValue.TAX_CODE),
                        (payload.AUDITOR = fromDataValue.AUDITOR),
                        (payload.STATUS = fromDataValue.STATUS),
                        (payload.AUDITOR_COMMENT = fromDataValue.AUDITOR_COMMENT),
                        (payload.ATTACH = fromDataValue.ATTACH),
                        (payload.CLAIM_NUMBER = fromDataValue.Claim_Number),
                        (payload.COMPLETE_DT = fromDataValue.COMPLETE_DT != '' ? fromDataValue.COMPLETE_DT : null),
                        (payload.VERSION = Number(this.rowDataObject.version) + 1),
                        (payload.RECORD_ID = fromDataValue.RECORD_ID)
                } else if (this.pageID === 'MISC') {
                    payload.CHANGE_USER = fromDataValue.CHANGE_USER;
                    payload.RELEASE_NUM = fromDataValue.RELEASE_NUM;
                    payload.SUPPLIER_NAME = fromDataValue.SUPPLIER_NAME;
                    payload.DEPT = fromDataValue.DEPT;
                    payload.FORMAT_TYPE = fromDataValue.FORMAT_TYPE;
                    payload.ALLOW_TYPE = fromDataValue.ALLOW_TYPE;
                    payload.PENDING_DUE = this.convertStringToNumberValue(fromDataValue.PENDING_DUE);
                    payload.TOTAL_PENDING = this.convertStringToNumberValue(fromDataValue.TOTAL_PENDING);
                    payload.AUDITOR_ID = fromDataValue.AUDITOR_ID;
                    payload.AUDITOR_STATUS = fromDataValue.AUDITOR_STATUS;
                    payload.AGREEMENT_NUM = fromDataValue.AGREEMENT_NUM;
                    payload.NOTES = fromDataValue.NOTES;
                    payload.IEPS_PCT = this.convertStringToNumberValue(fromDataValue.IEPS_PCT);
                    payload.IEPS_DUE = this.convertStringToNumberValue(fromDataValue.IEPS_DUE);
                    payload.IVA_PCT = this.convertStringToNumberValue(fromDataValue.IVA_PCT);
                    payload.IVA_DUE = this.convertStringToNumberValue(fromDataValue.IVA_DUE);
                    payload.AUDIT_YEAR = fromDataValue.AUDIT_YEAR;
                    payload.VENDOR_NUM = fromDataValue.VENDOR_NUM;
                    payload.REVIEW_DATE = fromDataValue.REVIEW_DATE != '' ? fromDataValue.REVIEW_DATE : null;
                    payload.SAP_NUM = fromDataValue.SAP_NUM;
                    payload.AMOUNT_DEDUCTED = this.convertStringToNumberValue(fromDataValue.AMOUNT_DEDUCTED);
                    payload.EARNED = this.convertStringToNumberValue(fromDataValue.EARNED);
                    payload.RECORD_ID = fromDataValue.RECORD_ID;
                    payload.INSERTED = fromDataValue.INSERTED;
                    payload.CLAIM_NUMBER = fromDataValue.Claim_Number;
                    payload.CREATED_BY = userInfo.username;
                    payload.CREATED_DATE = null;
                    payload.LAST_UPDATED_BY = userInfo.username;
                    payload.LAST_UPDATED_DATE = null;
                    payload.VERSION = Number(this.rowDataObject.VERSION) + 1;
                } else if (this.pageID == 'Advertising') {
                    payload.USRCHG = fromDataValue.USRCHG;
                    payload.AUDIT_YEAR = fromDataValue.AUDIT_YEAR;
                    payload.GROUP = fromDataValue.GROUP;
                    payload.VENDOR_NBR = fromDataValue.VENDOR_NBR;
                    payload.SAP_VENDOR = fromDataValue.SAP_VENDOR;
                    payload.VENDOR_NAME = this.returnItemAfterTrim(fromDataValue.VENDOR_NAME);
                    payload.BRAND = fromDataValue.BRAND;
                    payload.DEPT = fromDataValue.DEPT;
                    payload.DESCRIPTION_PAG = fromDataValue.DESCRIPTION_PAG;
                    payload.WEBCONTENT = fromDataValue.WEBCONTENT;
                    payload.DIV = fromDataValue.DIV;
                    payload.NUM_PUBLICATION = fromDataValue.NUM_PUBLICATION;
                    payload.TYPE_COLLECTION = fromDataValue.TYPE_COLLECTION;
                    payload.ALLOW_EARNED = this.convertStringToNumberValue(fromDataValue.ALLOW_EARNED);
                    payload.ENTERED_AMT = this.convertStringToNumberValue(fromDataValue.ENTERED_AMT);
                    payload.OTHER_DEDUCT = this.convertStringToNumberValue(fromDataValue.OTHER_DEDUCT);
                    payload.AMTDUE = this.convertStringToNumberValue(fromDataValue.AMTDUE);
                    payload.IEPS_AMOUNT = this.convertStringToNumberValue(fromDataValue.IEPS_AMOUNT);
                    payload.IVA_AMOUNT = this.convertStringToNumberValue(fromDataValue.IVA_AMOUNT);
                    payload.TOTAL_DUE = this.convertStringToNumberValue(fromDataValue.TOTAL_DUE);
                    payload.SAP_DOC_NBR = fromDataValue.SAP_DOC_NBR;
                    payload.IEPS_PCT = this.convertStringToNumberValue(fromDataValue.IEPS_PCT);
                    payload.IVA_PCT = this.convertStringToNumberValue(fromDataValue.IVA_PCT);
                    payload.COOP_NBR = this.convertStringToNumberValue(fromDataValue.COOP_NBR);
                    payload.CLAIM_NUMBER = fromDataValue.Claim_Number;
                    payload.AUDITOR = fromDataValue.AUDITOR;
                    payload.COMPLETE_DT = fromDataValue.COMPLETE_DT != '' ? fromDataValue.COMPLETE_DT : null;
                    payload.STATUS = fromDataValue.STATUS;
                    payload.NO_OF_DEDUCTION = fromDataValue.NO_OF_DEDUCTION;
                    payload.AUDITOR_COMMENT = fromDataValue.AUDITOR_COMMENT;
                    payload.INCLUDE_CHECK = fromDataValue.INCLUDE_CHECK;
                    payload.INCLUDE = 'Y';
                    payload.VERSION = Number(this.rowDataObject.VERSION) + 1;
                    payload.RECORD_ID = Number(this.rowDataObject.RECORD_ID);
                } else if (this.pageID == 'Fill Rate') {
                    (payload.USRCHG = fromDataValue.USRCHG),
                        (payload.AUDIT_YEAR = fromDataValue.AUDIT_YEAR),
                        (payload.GROUP = fromDataValue.GROUP),
                        (payload.DIVISION = fromDataValue.DIVISION),
                        (payload.VENDOR_NBR = fromDataValue.VENDOR_NBR),
                        (payload.VENDOR_NAME = fromDataValue.VENDOR_NAME),
                        (payload.SAP_VENDOR = fromDataValue.SAP_VENDOR),
                        (payload.DEPARTMENT = fromDataValue.DEPARTMENT),
                        (payload.PO_SEQ = fromDataValue.PO_SEQ),
                        (payload.FORMAT = fromDataValue.FORMAT),
                        (payload.TOTAL_ORD_QTY = this.convertStringToNumberValue(fromDataValue.TOTAL_ORD_QTY)),
                        (payload.TOTAL_RCV_QTY = this.convertStringToNumberValue(fromDataValue.TOTAL_RCV_QTY)),
                        (payload.TOTAL_SHORT_QTY = this.convertStringToNumberValue(fromDataValue.TOTAL_SHORT_QTY)),
                        (payload.FILL_RATE_CALC_PCT = this.convertStringToNumberValue(fromDataValue.FILL_RATE_CALC_PCT)),
                        (payload.TOTAL_RETAIL_AMT_SHORT = this.convertStringToNumberValue(fromDataValue.TOTAL_RETAIL_AMT_SHORT)),
                        (payload.TOTAL_MARGIN_LOST = this.convertStringToNumberValue(fromDataValue.TOTAL_MARGIN_LOST)),
                        (payload.FILL_RATE_PCT = this.convertStringToNumberValue(fromDataValue.FILL_RATE_PCT)),
                        (payload.TOTAL_EARNED = this.convertStringToNumberValue(fromDataValue.TOTAL_EARNED)),
                        (payload.TOTAL_AMT_PAID = this.convertStringToNumberValue(fromDataValue.TOTAL_AMT_PAID)),
                        (payload.TOTAL_PENDING = this.convertStringToNumberValue(fromDataValue.TOTAL_PENDING)),
                        (payload.IEPS_DUE = this.convertStringToNumberValue(fromDataValue.IEPS_DUE)),
                        (payload.IVA_DUE = this.convertStringToNumberValue(fromDataValue.IVA_DUE)),
                        (payload.COOP_NUMBER = this.convertStringToNumberValue(fromDataValue.COOP_NUMBER)),
                        (payload.IEPS_PCT = this.convertStringToNumberValue(fromDataValue.IEPS_PCT)),
                        (payload.IVA_PCT = this.convertStringToNumberValue(fromDataValue.IVA_PCT)),
                        (payload.PREVIOUS_CLAIMS = this.convertStringToNumberValue(fromDataValue.PREVIOUS_CLAIMS)),
                        (payload.PAST_CLAIM_NUMBERS = this.convertStringToNumberValue(fromDataValue.PAST_CLAIM_NUMBERS)),
                        (payload.INTERNAL_RATE = this.convertStringToNumberValue(fromDataValue.INTERNAL_RATE)),
                        (payload.RFC = fromDataValue.RFC),
                        (payload.CLAIM_NUMBER = fromDataValue.Claim_Number),
                        (payload.AUDITOR = fromDataValue.AUDITOR),
                        (payload.COMPLETE_DT = fromDataValue.COMPLETE_DT != '' ? fromDataValue.COMPLETE_DT : null),
                        (payload.STATUSCODE = fromDataValue.STATUSCODE),
                        (payload.COMMENTS = fromDataValue.COMMENTS),
                        (payload.VERSION = Number(this.rowDataObject.VERSION) + 1),
                        (payload.RECORD_ID = fromDataValue.RECORD_ID),
                        (payload.INCLUDE = 'Y');
                } else if (this.pageID == 'MSI') {
                    (payload.USR_CHANGE = fromDataValue.USR_CHANGE),
                    (payload.AUDIT_YEAR = fromDataValue.AUDIT_YEAR),
                    (payload.VENDOR_NAME = fromDataValue.VENDOR_NAME);
                    (payload.SAP_VENDOR = fromDataValue.SAP_VENDOR);
                    (payload.VENDOR_NBR = fromDataValue.VENDOR_NBR);
                    (payload.DEPARTMENT = fromDataValue.DEPARTMENT);
                    (payload.SEQ_NO = fromDataValue.SEQ_NO);
                    (payload.FORMAT = fromDataValue.FORMAT);
                    (payload.UNITS_SOLD = this.convertStringToNumberValue(fromDataValue.UNITS_SOLD));
                    (payload.TOTAL_DEFERRED = this.convertStringToNumberValue(fromDataValue.TOTAL_DEFERRED));
                    (payload.SURCHARGE_SALES = this.convertStringToNumberValue(fromDataValue.SURCHARGE_SALES));
                    (payload.AGREE = fromDataValue.AGREE);
                    (payload.DEDUCTION = this.convertStringToNumberValue(fromDataValue.DEDUCTION));
                    (payload.DEDUCTION_CIVA = this.convertStringToNumberValue(fromDataValue.DEDUCTION_CIVA));
                    (payload.OTHER_DEDUCTIONS = this.convertStringToNumberValue(fromDataValue.OTHER_DEDUCTIONS));
                    (payload.AMT_DUE = this.convertStringToNumberValue(fromDataValue.AMT_DUE));
                    (payload.MSI_PCT = this.convertStringToNumberValue(fromDataValue.MSI_PCT));
                    (payload.IVA = this.convertStringToNumberValue(fromDataValue.IVA));
                    (payload.IVA_PCT = this.convertStringToNumberValue(fromDataValue.IVA_PCT));
                    (payload.PREVIOUS_CLAIMS = this.convertStringToNumberValue(fromDataValue.PREVIOUS_CLAIMS));
                    (payload.PAST_CLAIM_NUMBERS = this.convertStringToNumberValue(fromDataValue.PAST_CLAIM_NUMBERS));
                    (payload.AUDITOR = fromDataValue.AUDITOR);
                    (payload.COMPLETE_DT = fromDataValue.COMPLETE_DT != '' ? fromDataValue.COMPLETE_DT : null);
                    (payload.STATUS = fromDataValue.STATUS);
                    (payload.IEPS_PCT = this.convertStringToNumberValue(fromDataValue.IEPS_PCT));
                    (payload.AUDITOR_COMMENTS = fromDataValue.AUDITOR_COMMENTS);
                    (payload.CLAIM_NUMBER = fromDataValue.Claim_Number);
                    (payload.RFC = fromDataValue.RFC);
                    (payload.VERSION = Number(this.rowDataObject.VERSION) + 1),
                    (payload.RECORD_ID = fromDataValue.RECORD_ID),
                    (payload.INCLUDE = 'Y');
                } else if (this.pageID === 'Low Price') {
                    payload.USRCHG = fromDataValue.USRCHG;
                    payload.AUDIT_YEAR = fromDataValue.AUDIT_YEAR;
                    payload.RELEASE = fromDataValue.RELEASE;
                    payload.VENDOR_NBR = fromDataValue.VENDOR_NBR;
                    payload.VENDOR_NAME = fromDataValue.VENDOR_NAME;
                    payload.SAP_VENDOR = fromDataValue.SAP_VENDOR;
                    payload.DEPT = fromDataValue.DEPT;
                    payload.SEQ = fromDataValue.SEQ;
                    payload.PO_TYPE = fromDataValue.PO_TYPE;
                    payload.PO_EVENT = fromDataValue.PO_EVENT;
                    payload.PO_NBR = fromDataValue.PO_NBR;
                    payload.PO_DATE = fromDataValue.PO_DATE;
                    payload.INV_NBR = fromDataValue.INV_NBR;
                    payload.INV_DATE = fromDataValue.INV_DATE;
                    payload.INV_TOTAL_COST = fromDataValue.INV_TOTAL_COST;
                    payload.PO_TOTAL_COST = fromDataValue.PO_TOTAL_COST;
                    payload.RCVD_TOTAL_COST = fromDataValue.RCVD_TOTAL_COST;
                    payload.COST_PAID_TOTAL = fromDataValue.COST_PAID_TOTAL;
                    payload.SB_TOTAL_COST = fromDataValue.SB_TOTAL_COST;
                    payload.INV_COST_DUE = fromDataValue.INV_COST_DUE;
                    payload.IEPS_COST_DUE = fromDataValue.IEPS_COST_DUE;
                    payload.IVA_COST_DUE = fromDataValue.IVA_COST_DUE;
                    payload.TOTAL_COST_DUE = fromDataValue.TOTAL_COST_DUE;
                    payload.AP_TOT_AMT = fromDataValue.AP_TOT_AMT;
                    payload.COST_PAID_TOT_AMT = fromDataValue.COST_PAID_TOT_AMT;
                    payload.COST_PAID_TOT_DIFF = fromDataValue.COST_PAID_TOT_DIFF;
                    payload.TOTAL_SHORT_DUE = fromDataValue.TOTAL_SHORT_DUE;
                    payload.INV_TOTAL_AMT = fromDataValue.INV_TOTAL_AMT;
                    payload.PO_TOT_AMT = fromDataValue.PO_TOT_AMT;
                    payload.RCVD_TOT_AMT = fromDataValue.RCVD_TOT_AMT;
                    payload.CLAIM_NUMBER = fromDataValue.CLAIM_NUMBER;
                    payload.UUID = fromDataValue.UUID;
                    payload.RFC = fromDataValue.RFC;
                    payload.CLEARING_DT = fromDataValue.CLEARING_DT;
                    payload.CHECK_NBR = fromDataValue.CHECK_NBR;
                    payload.AUDITOR = fromDataValue.AUDITOR;
                    payload.STATUS = fromDataValue.STATUS;
                    payload.COMPLETE_DT = fromDataValue.COMPLETE_DT != '' ? fromDataValue.COMPLETE_DT : null;
                    payload.COMMENTS = fromDataValue.COMMENTS;
                    payload.PREVIOUS_CLAIMS = fromDataValue.PREVIOUS_CLAIMS;
                    payload.PAST_CLAIM_NUMBERS = fromDataValue.PAST_CLAIM_NUMBERS;
                    payload.CATCH_RATE = fromDataValue.CATCH_RATE;
                    (payload.VERSION = Number(this.rowDataObject.VERSION) + 1);
                    (payload.RECORD_ID = fromDataValue.RECORD_ID);
                    (payload.INCLUDE = 'Y');                
                }  else if (this.pageID === 'Shortages') {
                    payload.USRCHG = fromDataValue.USRCHG;
                    payload.AUDIT_YEAR = fromDataValue.AUDIT_YEAR;
                    payload.RELEASE = fromDataValue.RELEASE;
                    payload.VENDOR_NBR = fromDataValue.VENDOR_NBR;
                    payload.VENDOR_NAME = fromDataValue.VENDOR_NAME;
                    payload.SAP_VENDOR = fromDataValue.SAP_VENDOR;
                    payload.DEPT = fromDataValue.DEPT;
                    payload.SEQ = fromDataValue.SEQ;
                    payload.PO_TYPE = fromDataValue.PO_TYPE;
                    payload.PO_EVENT = fromDataValue.PO_EVENT;
                    payload.PO_NBR = fromDataValue.PO_NBR;
                    payload.PO_DATE = fromDataValue.PO_DATE;
                    payload.INV_NBR = fromDataValue.INV_NBR;
                    payload.INV_DATE = fromDataValue.INV_DATE;
                    payload.EQUIV_EACH_INV = fromDataValue.EQUIV_EACH_INV;
                    payload.ZERO_RCVD_CNT = fromDataValue.ZERO_RCVD_CNT;
                    payload.EQUIVEACHRCVD = fromDataValue.EQUIVEACHRCVD;
                    payload.QTY_SHORT = fromDataValue.QTY_SHORT;
                    payload.SHORT_DUE = fromDataValue.SHORT_DUE;
                    payload.IEPS_SHORT_DUE = fromDataValue.IEPS_SHORT_DUE;
                    payload.IVA_SHORT_DUE = fromDataValue.IVA_SHORT_DUE;
                    payload.TOTAL_SHORT_DUE = fromDataValue.TOTAL_SHORT_DUE;
                    payload.COST_PAID_TOT_DIFF = fromDataValue.COST_PAID_TOT_DIFF;
                    payload.NETSHORT = fromDataValue.NETSHORT;
                    payload.AP_TOT_AMT = fromDataValue.AP_TOT_AMT;
                    payload.TOT_COST_PAID_AMT = fromDataValue.TOT_COST_PAID_AMT;
                    payload.CLAIM_NUMBER = fromDataValue.CLAIM_NUMBER;
                    payload.UUID = fromDataValue.UUID;
                    payload.RFC = fromDataValue.RFC;
                    payload.CLEARING_DT = fromDataValue.CLEARING_DT;
                    payload.CHECK_NBR = fromDataValue.CHECK_NBR;
                    payload.AUDITOR = fromDataValue.AUDITOR;
                    payload.STATUS = fromDataValue.STATUS;
                    payload.COMPLETE_DT = fromDataValue.COMPLETE_DT != '' ? fromDataValue.COMPLETE_DT : null;
                    payload.COMMENTS = fromDataValue.COMMENTS;
                    payload.PREVIOUS_CLAIMS = fromDataValue.PREVIOUS_CLAIMS;
                    payload.PAST_CLAIM_NUMBERS = fromDataValue.PAST_CLAIM_NUMBERS;
                    payload.CATCH_RATE = fromDataValue.CATCH_RATE;
                    (payload.VERSION = Number(this.rowDataObject.VERSION) + 1);
                    (payload.RECORD_ID = fromDataValue.RECORD_ID);
                    (payload.INCLUDE = 'Y');  
                } else if (this.pageID === 'Markdown') {
                    payload.VENDOR_NBR = fromDataValue.VENDOR_NBR;
                    payload.VNDR_NAME = fromDataValue.VNDR_NAME;
                    payload.EFFECTIVE_DATE =  fromDataValue.EFFECTIVE_DATE != '' ? fromDataValue.EFFECTIVE_DATE : null;
                    payload.END_DATE =  fromDataValue.END_DATE != '' ? fromDataValue.END_DATE : null;
                    payload.DEPT = fromDataValue.DEPT;
                    payload.CAT = fromDataValue.CAT;
                    payload.ITEM_NUM = fromDataValue.ITEM_NUM;
                    payload.ITEM1_DESC = fromDataValue.ITEM1_DESC;
					payload.BANNER_DESC = fromDataValue.BANNER_DESC,
                    payload.POS_SALES = fromDataValue.POS_SALES;
                    payload.POS_QTY = fromDataValue.POS_QTY;
                    payload['IMPACT_MU/MD'] = fromDataValue['IMPACT_MU/MD'];
                    payload.UNIT_RETAIL = this.convertStringToNumberValue(fromDataValue.UNIT_RETAIL);
                    payload.AVG_UNIT_SALE_PRICE = fromDataValue.AVG_UNIT_SALE_PRICE;
                    payload.UNIT_COST = this.convertStringToNumberValue(fromDataValue.UNIT_COST);
                    payload.COMMERCIAL_MARGE = this.convertStringToNumberValue(fromDataValue.COMMERCIAL_MARGE);
                    payload.REAL_MARGE = this.convertStringToNumberValue(fromDataValue.REAL_MARGE);
                    payload.MARGE_DEVIATION = this.convertStringToNumberValue(fromDataValue.MARGE_DEVIATION);
                    payload.AMS_AGREEMENT = fromDataValue.AMS_AGREEMENT;
                    payload.AGREEMENT_AMOUNT = fromDataValue.AGREEMENT_AMOUNT;
                    payload.DIFFERENCE = fromDataValue.DIFFERENCE;
                    payload.MD_STATUS = fromDataValue.MD_STATUS;
                    payload.MD_ROOTCAUSE = fromDataValue.MD_ROOTCAUSE;
                    payload.MARKDOWN_TYPE = fromDataValue.MARKDOWN_TYPE;
                    payload.EMAIL_SUPPORT = fromDataValue.EMAIL_SUPPORT;
                    payload.AUDITOR_NAME = fromDataValue.AUDITOR_NAME;
                    payload.AUDITOR_COMMENTS = fromDataValue.AUDITOR_COMMENTS;
                    payload.DUE_DATE = fromDataValue.DUE_DATE != '' ? fromDataValue.DUE_DATE : null;
                    payload.CLAIM_NUMBER = fromDataValue.CLAIM_NUMBER;
                    payload.SELECT_ROW = fromDataValue.SELECT_ROW;
                    payload.VAT = fromDataValue.VAT;
                    payload.IEPS = fromDataValue.IEPS;
                    payload.IVA_DUE = fromDataValue.IVA_DUE;
                    payload.IEPS_DUE = fromDataValue.IEPS_DUE;
                    payload.TOTAL_DUE = fromDataValue.TOTAL_DUE;
                    payload.UNIT_ALLOWANCE = fromDataValue.UNIT_ALLOWANCE;
                    payload.EVENT_DESCRIPTION = fromDataValue.EVENT_DESCRIPTION;
                    payload.VERSION = Number(this.rowDataObject.VERSION) + 1;
                    payload.RECORD_ID = fromDataValue.RECORD_ID;
                    payload.Audit_Year = fromDataValue.AUDIT_YEAR != undefined ? fromDataValue.AUDIT_YEAR : 2022;                                                                                 
                }
            }
            finalPayload.push(payload);
        }
        console.log(finalPayload)
        this.payloadToApi = finalPayload;
    }

    onSubmit() {
        this.validatePayload();
        console.log('this.payloadToApi', this.payloadToApi);
        this.showLoader = true;
        this.apiservice.editDataManagePR(this.payloadToApi, this.pageID, this.ccCode).subscribe(
            (response) => {
                this.showLoader = false;
                this.dialogRef.close('save');
               this.router.navigate(['managePR/' + this.ccCode + '/' + this.pageID]);
               this.Refresh();
            },
            (error) => {
                this.showLoader = false;
                alert(error);
            }
        );
    }

    returnItemAfterTrim(item) {
        if (item !== null && item !== undefined) {
           return item.trim() != '' ? item.trim() : null;
        } else {
            return null;
        }
    }

    returnEmpty(item) {
        return item.trim();
      }

    data(arg0: string, data: any) {
        throw new Error('Method not implemented.');
    }
    closeModal() {
        this.dialogRef.close('cancel');
    }

    Refresh() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./managePR/' + this.ccCode + '/' + this.pageID], {
            relativeTo: this.activatedRoute
        });
    }
}
