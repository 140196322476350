import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
import { apiService } from 'src/app/_services/app.service';
import { EditDetails } from '../../../_models/editDetails';
import _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface DialogData {
  action: any;
  requestId: any;
}

@Component({
  selector: 'app-edit-row-data',
  templateUrl: './edit-row-data.component.html',
  styleUrls: ['./edit-row-data.component.css']
})
export class EditRowDataComponent implements OnInit {

  editData = [];
  pageID: any;
  ccCode: any;
  allowTypeDropDown: any;
  AllDropdowns: any;
  rowDataObject: any;
  rowData = [];
  addForm: FormGroup;
  auditorList = [];
  singleAuditYear;
  showLoader = false;
  username: any;
  auditYearList = [];
  formatList = [];
  MXDEP = [];
  action: any;
  numberArrya = ['VEN_NUM', 'SAP_VENDOR', 'DPT_CAT', 'SUPERCTR_AMT', 'SUPERAMA_AMT', 'BODEGA_AMT', 'MI_BODEGA_AMT',
    'BAE_AMT', 'SAMS_AMT', 'IEPS_PCT', 'IVA_PCT', 'SC_PCT', 'BOD_PCT', 'SPRAMA_PCT', 'MI_BOD_PCT', 'BAE_PCT',
    'SAMS_PCT', 'ID'];

  currentValueObject;
  currentDateTime;
  changeInputValue: Subject<any> = new Subject<any>();
  updatingRowValue : any = [];
  multipleEditDataArray = [];
  public responsedata: any = [];
  constructor(private datePipe: DatePipe, public dialogRef: MatDialogRef<EditRowDataComponent>, @Inject(MAT_DIALOG_DATA) public datas: DialogData, public datepipe: DatePipe, public router: Router, public activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private apiservice: apiService) {
    this.changeInputValue.pipe(debounceTime(2000)).subscribe((obj) => {
      this.changeInModal(obj);
    });
    this.currentDateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  ngOnInit() { 
    console.log("datas ", this.datas)
    this.editData = [];
    this.multipleEditDataArray = [];
    //Audit year dropdown values
    let result = [{ AUDIT_YEAR: 2018 }, { AUDIT_YEAR: 2019 }, { AUDIT_YEAR: 2020 }, { AUDIT_YEAR: 2021 }, { AUDIT_YEAR: 2022 }, { AUDIT_YEAR: 2023 },
       { AUDIT_YEAR: 2024 }, { AUDIT_YEAR: 2025 }];
    this.auditYearList = _.map(result, (v) => {
      return {
        AUDITYEAR: v.AUDIT_YEAR
      };
    });
    this.auditYearList.sort().reverse();
    const prompt = JSON.parse(localStorage.getItem('userinfo'));
    this.username = prompt.username;
    console.log(this.datas)
    this.editData = this.datas.requestId.data;
    this.action = this.datas.action;
    this.pageID = this.datas.requestId.id;
    this.ccCode = this.datas.requestId.countryCode;
    this.getAllDropdownValues(this.ccCode, this.pageID);
    this.formatDropDownValue()
    this.rowDataObject = this.editData[0];
  //  localStorage.setItem('initial_value', JSON.stringify(this.editData[0]));
    this.rowData = this.generatedColumn([this.editData[0]]);

    const objectFrom = {};
    _.each(this.rowData, (v) => {
      const getData = v.replaceAll('_', ' ');
      const getAry = getData.split(' ');
      const findDate = _.find(getAry, (v) => {
        return v.toLowerCase() === 'date';
      });
      const findNumber = _.find(this.numberArrya, (item) => item === v);
      if (findNumber) {
        const valueNum = this.convertStringToNumberValue(this.rowDataObject[v]);
        objectFrom[v] = [valueNum];
      }

      else {
        objectFrom[v] = [
          {
            value: this.rowDataObject[v],
            disabled: this.getEditableColumn(v)
          }
        ];
      }
    });
    
    this.addForm = this.formBuilder.group(objectFrom);
   // this.currentValueObject = JSON.parse(localStorage.getItem('initial_value'));
    this.currentValueObject = this.editData[0]
    if (this.pageID != 'PPA_VOLUME_ALLOWANCE_DETAIL') {
        this.addForm.get('INCLUDE').setValue("Y");
      }
    }

    getEditableColumn(data) {
      let vColumn;
  
      vColumn = this.generatedColumn([this.editData[0]]);
      const isFind = _.find(vColumn, (item) => item === data);
      if (isFind) {
        return false;
      } else {
        return true;
      }
    }
    valueEdited = false;
  
    generatedColumn(data) {
      let column = [];
      data.map((object) => {
        Object.keys(object).map((key) => {
          if (key != 'ID') {
            column.push(key);
          }
        });
      });
      return column;
    }
  
     dataArray = ['AUDIT_YEAR', 'DPT_CAT', 'LastUpdatedBy', 'LastUpdatedDate', 'ID', 'SOURCE', 'FORMAT', 'INCLUDE', 'INS_ROW'];
  
    checkIsPresentAny(v) {
      let findIndex;
      findIndex = this.dataArray.indexOf(v);
      if (findIndex === -1) {
        return true;
      } else {
        return false;
      }
    }
  
    getTextValue(data) {
      const getData = data.replaceAll('_', ' ');
      const getAry = getData.split(' ');
      const findDate = _.find(getAry, (v) => {
        return v.toLowerCase() === 'date';
      });
      const findNumber = _.find(this.numberArrya, (v) => v === data);
      if (findDate || data == 'EFF_DATE' || data === 'EXPIRED_DATE' || data == 'GO_DATE' || data == 'START_DATE' || data == 'END_DATE') {
        return 'date';
      } else if (findNumber) {
        return 'number';
      } else {
        return 'text';
      }
    }
  
    getAllDropdownValues(CLCodee, pageID) {
      this.apiservice.getClaimsDropdowns(CLCodee, pageID).subscribe((response) => {
        this.AllDropdowns = response;
        for (var i = 0; i < this.AllDropdowns.length; i++) {
          if (this.AllDropdowns[i].Lookup_Code == 'ACCOUNT_DEPARTMENT') {
            this.MXDEP.push(this.AllDropdowns[i].Lookup_Values);
          }
        }
      });
    }
   
     convertStringToNumberValue(data) {
      if (data != '' && data != null && !isNaN(data)) {
        if (typeof data == 'number' ) {
          // check if it is integer
          if (Number.isInteger(data)) {
            return data;
          } else {
            return 0;
          }
        } else {
          const v = parseFloat(data.replace(/,/g, ''));
          return Number(v);
        }
      } else {
        return 0;
      }
    }

    formatDropDownValue() {
      this.showLoader = true;
      this.apiservice.getFormatsForDWH('PPA_NEW_STORE_LIST_M').subscribe((response) => {
        this.showLoader = false;
        this.formatList = response;
      });
    }

    returnEmpty(item) {
      return item.trim();
    }

    returnItemAfterTrim(item) {
      if (item !== null && item !== undefined) {
         return item.trim() != '' ? item.trim() : null;
      } else {
          return null;
      }
    }

    returnZero(data) {
      if (data != '' && data != null) {
        console.log("entered if data -", data )
        return data.trim() != '' ? data.trim() : 0;
      }
      return 0;
    }

    saveUpdatedValue(v) {
      const findIndex = this.updatingRowValue.indexOf(v);
      if (findIndex > -1) {
          this.updatingRowValue.splice(findIndex, 1);
      } else {
          this.updatingRowValue.push(v);
      }
      this.updatingRowValue = _.uniq(this.updatingRowValue);
  }

    changeModalData(obj) {
      this.changeInputValue.next(obj);
    }

    changeInModal(obj) {
      this.multipleEditDataArray.push(obj);
      // The calculations performed when there is a change on the modal directly
       const fromValue = this.addForm.getRawValue();
      // const data = obj;
      // const editableCalculateRow = ['PCT', 'PO_PURCHASES'];
      // const findData = editableCalculateRow.indexOf(data);
      // let PCT, PO_PURCHASES;
      // if (findData > -1) {
      //   // Multiple
      //   if (this.editData.length > 1) {
      //     this.editData = _.each(this.editData, (v) => {

      //         if (data == 'PCT') {
      //           PCT = fromValue.PCT;
      //         } else {
      //           PCT = v.PCT;
      //         }
      //         if (data == 'PO_PURCHASES') {
      //           PO_PURCHASES = fromValue.PO_PURCHASES;
      //         } else {
      //           PO_PURCHASES = v.PO_PURCHASES;
      //         }

      //       let val = this.convertStringToNumberValue(v.PCT) * this.convertStringToNumberValue(v.PO_PURCHASES);
      //       v['ALLOW_EARNED'] = val;
      //       v['PCT'] = PCT;
      //       v['PO_PURCHASES'] = PO_PURCHASES;
      //     });
      //   } else {
      //   // Single
      //   let val = this.convertStringToNumberValue(fromValue.PCT) * this.convertStringToNumberValue(fromValue.PO_PURCHASES);
      //   this.addForm.get('ALLOW_EARNED').setValue(val.toFixed(2));
      //   }
      // } 
        // To update other non calculation data
        this.editData = _.each(this.editData, (v) => {
            if (this.updatingRowValue.length > 0) {
                _.each(this.updatingRowValue, (item) => {
                    if (item != 'ALLOW_EARNED' && item != 'PO_PURCHASES') v[item] = fromValue[item];
                });
            }
            return v;
        });
    }

    updateDatainModal() {
      // The calculations performed when the user clicks on the update button
      let fromValue = this.addForm.getRawValue();
      this.showLoader = true;
      this.apiservice.getvendornameinData(fromValue.VENDOR_NBR).subscribe((response1) => {
        if (response1.length == 0) {
            // Reverting to the original data
          alert('Not a valid combination for Vendor Number');
          this.addForm.patchValue({
            VENDOR_NBR: this.rowDataObject.VENDOR_NBR,
          });
        } else {
          this.addForm.get('SAP_VENDOR').setValue(response1[0].SAP_NUMBER);
          this.addForm.get('VENDOR_NAME').setValue(response1[0].VENDOR_NAME);
        }
      });

      let finalPayload1 = [];
      if (this.editData.length > 1) {
        
        finalPayload1 = _.map(this.editData, (v, i) => {
          let payload;
          if (this.updatingRowValue.length > 0) {
            _.each(this.updatingRowValue, (item) => {
              console.log(this.returnZero(v.SEQ_NO))
              payload = {
                AUDIT_YEAR: item === 'AUDIT_YEAR' ? fromValue.AUDIT_YEAR : parseFloat(v.AUDIT_YEAR),
                VENDOR_NUMBER: item === 'VENDOR_NBR' ? fromValue.VENDOR_NBR : v.VENDOR_NBR,
                START_DATE: item === 'START_DATE' ? fromValue.START_DATE : v.START_DATE.toString(),
                END_DATE: item === 'END_DATE' ? fromValue.END_DATE : v.END_DATE.toString(),
                DEPARTMENT: item === 'DEPARTMENT' ? fromValue.DEPARTMENT : v.DEPARTMENT,
                SEQ_NO: item === 'SEQ_NO' ? this.returnZero(fromValue.SEQ_NO) : this.returnZero(v.SEQ_NO),
                DEAL: item === 'DEAL' ? (this.returnEmpty(fromValue.DEAL)) : (this.returnEmpty(v.DEAL)),
                PO_SOURCE: item === 'PO_SOURCE' ? this.returnEmpty(fromValue.PO_SOURCE) : this.returnEmpty(v.PO_SOURCE),
                ITEM_NBR: item === 'ITEM_NBR' ? this.convertStringToNumberValue(fromValue.ITEM_NBR) : this.convertStringToNumberValue(v.ITEM_NBR),
                PCT: item === 'PCT' ? this.returnZero(fromValue.PCT) : this.returnZero(v.PCT),
                RECORD_ID: item === 'RECORD_ID' ? fromValue.RECORD_ID : v.RECORD_ID
              }
            });
          }
          return payload;
        });
      } else {
        const payload1 = {
          AUDIT_YEAR: fromValue.AUDIT_YEAR,
          VENDOR_NUMBER: this.convertStringToNumberValue(fromValue.VENDOR_NBR),
          START_DATE: fromValue.START_DATE.toString(),
          END_DATE: fromValue.END_DATE.toString(),
          DEPARTMENT: this.convertStringToNumberValue(fromValue.DEPARTMENT),
          SEQ_NO: this.returnZero(fromValue.SEQ_NO),
          DEAL: this.returnEmpty(fromValue.DEAL),
          PO_SOURCE: this.returnEmpty(fromValue.PO_SOURCE),
          ITEM_NBR: this.convertStringToNumberValue(fromValue.ITEM_NBR),
          PCT: this.returnZero(fromValue.PCT),
          RECORD_ID: fromValue.RECORD_ID
        };
        finalPayload1.push(payload1);
      }
      this.showLoader = true;
        this.apiservice.getVolumeAllowanceDetailForDWH(finalPayload1).subscribe(
          (response2: any) => {
            console.log(response2)
              if (response2.length == 0) {
                  if (window.confirm("This is not a valid combination, Do you want keep the new values ?")) {
                  } else {
                  // Reverting to the original data
                  this.addForm.patchValue({
                      AUDIT_YEAR: this.rowDataObject.AUDIT_YEAR,
                      SAP_VENDOR: this.rowDataObject.SAP_VENDOR,
                      VENDOR_NAME: this.rowDataObject.VENDOR_NAME,
                      VENDOR_NBR: this.rowDataObject.VENDOR_NBR,
                      DEAL: this.rowDataObject.DEAL,
                      START_DATE: this.rowDataObject.START_DATE,
                      END_DATE: this.rowDataObject.END_DATE,
                      PCT: this.rowDataObject.PCT,
                      DEPARTMENT: this.rowDataObject.DEPARTMENT,
                      SEQ_NO: this.rowDataObject.SEQ_NO,
                      FORMAT: this.rowDataObject.FORMAT,
                      ASST: this.rowDataObject.ASST,
                      ITEM_NBR: this.rowDataObject.ITEM_NBR,
                      ITEM_DESC_1: this.rowDataObject.ITEM_DESC_1,
                      ITEM_DESC_2: this.rowDataObject.ITEM_DESC_2,
                      PO_PURCHASES: this.rowDataObject.PO_PURCHASES,
                      PO_SOURCE: this.rowDataObject.PO_SOURCE,
                      ALLOW_EARNED: this.rowDataObject.ALLOW_EARNED,
                      VDR_SUM: this.rowDataObject.VDR_SUM,
                      ITEM_SPEED: this.rowDataObject.ITEM_SPEED,
                      INSERT_USER: this.rowDataObject.INSERT_USER,
                      INS_ITM: this.rowDataObject.INS_ITM,
                      LastUpdatedDate: this.rowDataObject.LastUpdatedDate,
                      LastUpdatedBy: this.rowDataObject.LastUpdatedBy
                  });
                }
              } else if (response2.length == 1) {
                  let val = this.convertStringToNumberValue(response2[0].PCT) * this.convertStringToNumberValue(response2[0].PO_PURCHASES);
                  this.addForm.patchValue({
                    AUDIT_YEAR: response2[0].AUDIT_YEAR,
                    VENDOR_NBR: response2[0].VENDOR_NBR,
                    DEAL: response2[0].DEAL,
                    DEPARTMENT: response2[0].DEPARTMENT,
                    SEQ_NO: response2[0].SEQ_NO,
                    PO_PURCHASES: response2[0].PO_PURCHASES,
                    ALLOW_EARNED: val.toFixed(2),
                });
              }  else if (response2.length > 1) {
                this.editData = _.each(this.editData, (v) => {
                  response2.forEach((val) => {
                    let valu = this.convertStringToNumberValue(val.PCT) * this.convertStringToNumberValue(val.PO_PURCHASES);
                    if (v.RECORD_ID == val.RECORD_ID) {
                      v.AUDIT_YEAR = val.AUDIT_YEAR,
                      v.VENDOR_NBR = val.VENDOR_NBR,
                      v.DEAL = val.DEAL,
                      v.DEPARTMENT = val.DEPARTMENT,
                      v.SEQ_NO = val.SEQ_NO,
                      v.PO_PURCHASES = val.PO_PURCHASES,
                      v.ALLOW_EARNED= valu.toFixed(2)
                    }
                    if (fromValue.RECORD_ID == val.RECORD_ID) {
                      this.addForm.patchValue({
                        AUDIT_YEAR: val.AUDIT_YEAR,
                        VENDOR_NBR: val.VENDOR_NBR,
                        DEAL: val.DEAL,
                        DEPARTMENT: val.DEPARTMENT,
                        SEQ_NO: val.SEQ_NO,
                        PO_PURCHASES: val.PO_PURCHASES,
                        ALLOW_EARNED: valu.toFixed(2),
                      })
                    }
                  });
                });
              }
              this.showLoader = false;
          },
          (error) => {
              alert(error);
          }
      );

      let finalPayload2 = [];
      if (this.editData.length > 1) {
        finalPayload2 = _.map(this.editData, (v, i) => {
          let payload;
          if (this.updatingRowValue.length > 0) {
            _.each(this.updatingRowValue, (item) => {
              payload = {
                ITEM_NBR: item === 'ITEM_NBR' ? this.convertStringToNumberValue(fromValue.ITEM_NBR) : this.convertStringToNumberValue(v.ITEM_NBR),
                PO_SOURCE: item === 'PO_SOURCE' ? this.returnItemAfterTrim(fromValue.PO_SOURCE) : this.returnItemAfterTrim(v.PO_SOURCE),
                RECORD_ID: item === 'RECORD_ID' ? fromValue.RECORD_ID : v.RECORD_ID
              }
            });
          }
          return payload;
        });
      } else {
        const payload2 = {
          ITEM_NBR: this.convertStringToNumberValue(fromValue.ITEM_NBR),
          PO_SOURCE: this.returnItemAfterTrim(fromValue.PO_SOURCE),
          RECORD_ID: fromValue.RECORD_ID
        };
        finalPayload2.push(payload2);
      }

      this.apiservice.getMXItemForWDH(finalPayload2).subscribe((response3) => {
        if (response3.length == 0) {
            // Reverting to the original data
          alert('Not a valid combination fo Item Number and PO Source');
          this.addForm.patchValue({
            ITEM_NBR: this.rowDataObject.ITEM_NBR,
            PO_SOURCE: this.rowDataObject.PO_SOURCE,
          });
        } else if (response3.length == 1) {
          this.addForm.get('ITEM_DESC_1').setValue(response3[0].ITEM1_DESC);
          this.addForm.get('ITEM_DESC_2').setValue(response3[0].ITEM2_DESC);
        }   else if (response3.length > 1) {
        //  this.addForm.get('ITEM_NBR').setValue(response3[0].ITEM_NBR);
        this.editData = _.each(this.editData, (v) => {
          response3.forEach((val) => {
            if (v.RECORD_ID == val.RECORD_ID) {
              v.ITEM_DESC_1 = val.ITEM1_DESC,
              v.ITEM2_DESC = val.ITEM2_DESC
            }
            if (fromValue.RECORD_ID == val.RECORD_ID) {
              this.addForm.patchValue({
                ITEM_DESC_1: val.ITEM1_DESC,
                ITEM2_DESC: val.ITEM2_DESC,
              })
            }
          });
        });
        }
      });
    }

    onSubmit() {
      let finalPayload = [];
      let payload: any = {};
      let fromDataValue = this.addForm.getRawValue();
      if (this.editData.length > 1) {
        _.each(this.editData, (v) => {
          payload = {};
          payload.AUDIT_YEAR= v.AUDIT_YEAR,
          payload.SAP_VENDOR= v.SAP_VENDOR,
          payload.VENDOR_NAME= this.returnItemAfterTrim(v.VENDOR_NAME),
          payload.VENDOR_NBR= v.VENDOR_NBR,
          payload.DEAL= v.DEAL,
          payload.START_DATE= v.START_DATE,
          payload.END_DATE= v.END_DATE,
          payload.PCT= this.convertStringToNumberValue(v.PCT),
          payload.DEPARTMENT= v.DEPARTMENT,
          payload.SEQ_NO= v.SEQ_NO,
          payload.FORMAT= v.FORMAT,
          payload.ASST= v.ASST,
          payload.DEPT = v.DEPT,
          payload.CAT = v.CAT,
          payload.ITEM_NBR= v.ITEM_NBR,
          payload.ITEM_DESC_1= this.returnItemAfterTrim(v.ITEM_DESC_1),
          payload.ITEM_DESC_2= this.returnItemAfterTrim(v.ITEM_DESC_2),
          payload.PO_PURCHASES= this.convertStringToNumberValue(v.PO_PURCHASES),
          payload.PO_SOURCE= v.PO_SOURCE,
          payload.ALLOW_EARNED= this.convertStringToNumberValue(v.ALLOW_EARNED),
          payload.VDR_SUM= v.VDR_SUM,
          payload.ITEM_SPEED= v.ITEM_SPEED,
          payload.INSERT_USER= v.INSERT_USER,
          payload.INS_ITM= v.INS_ITM,
          payload.INCLUDE= "Y",
          payload.LastUpdatedBy = this.username;
          if (this.action == 'edit') {
            payload.RECORD_ID= v.RECORD_ID;
          } else {
            payload.RECORD_ID= '';
          }
          finalPayload.push(payload)
        });
      } else {
        payload.AUDIT_YEAR= fromDataValue.AUDIT_YEAR,
        payload.SAP_VENDOR= fromDataValue.SAP_VENDOR,
        payload.VENDOR_NAME= this.returnItemAfterTrim(fromDataValue.VENDOR_NAME),
        payload.VENDOR_NBR= fromDataValue.VENDOR_NBR,
        payload.DEAL= fromDataValue.DEAL,
        payload.START_DATE= fromDataValue.START_DATE,
        payload.END_DATE= fromDataValue.END_DATE,
        payload.PCT= this.convertStringToNumberValue(fromDataValue.PCT),
        payload.DEPARTMENT= fromDataValue.DEPARTMENT,
        payload.SEQ_NO= fromDataValue.SEQ_NO,
        payload.FORMAT= fromDataValue.FORMAT,
        payload.ASST= fromDataValue.ASST,
        payload.ITEM_NBR= fromDataValue.ITEM_NBR,
        payload.ITEM_DESC_1= this.returnItemAfterTrim(fromDataValue.ITEM_DESC_1),
        payload.ITEM_DESC_2= this.returnItemAfterTrim(fromDataValue.ITEM_DESC_2),
        payload.PO_PURCHASES= this.convertStringToNumberValue(fromDataValue.PO_PURCHASES),
        payload.PO_SOURCE= fromDataValue.PO_SOURCE,
        payload.ALLOW_EARNED= this.convertStringToNumberValue(fromDataValue.ALLOW_EARNED),
        payload.VDR_SUM= fromDataValue.VDR_SUM,
        payload.DEPT = fromDataValue.DEPT,
        payload.CAT = fromDataValue.CAT,
        payload.ITEM_SPEED= fromDataValue.ITEM_SPEED,
        payload.INSERT_USER= fromDataValue.INSERT_USER,
        payload.INS_ITM= fromDataValue.INS_ITM,
        payload.INCLUDE= "Y",
        payload.LastUpdatedBy = this.username;
        if (this.action == 'edit') {
          payload.RECORD_ID= fromDataValue.RECORD_ID;
        } else {
          payload.RECORD_ID= '';
        }
        finalPayload.push(payload);
      }
      console.log("final payload ",finalPayload)
      this.callToAPI(finalPayload)
    }

    callToAPI(payload) {
      this.showLoader = true;
      if (this.action == 'edit') {
        this.apiservice.editData(payload, this.pageID).subscribe((response) => {
          if (response.response == 'OK') {
            this.dialogRef.close();
            this.showLoader = false
            alert('data added sucessfully');
            this.router.navigate(['data/' + this.ccCode + '/' + this.pageID]);
            this.Refresh();
          }
          else {
            alert('Cannot add data, not a valid combination');
            this.showLoader = false
          }
    
        }, (error) => {
          this.showLoader = false;
          alert(error);
      });
      } else {
        this.apiservice.addNewRowData(payload, this.pageID, this.ccCode).subscribe((response) => {
          if (response.response == 'OK') {
            this.dialogRef.close();
            this.showLoader = false
            alert('data added sucessfully');
            this.router.navigate(['data/' + this.ccCode + '/' + this.pageID]);
            this.Refresh();
          }
          else {
            alert('Cannot add data, not a valid combination');
            this.showLoader = false
          }
    
        }, (error) => {
          this.showLoader = false;
          alert(error);
      });
      }
    }

    Refresh() {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['./data/' + this.ccCode + '/' + this.pageID], { relativeTo: this.activatedRoute });
    }
}
