import { Component, OnInit, Output, Input, EventEmitter, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { User } from '../../_models/user';
import { apiService } from 'src/app/_services/app.service';

import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../_services';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { Observable } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';

//import { ToastrService } from 'ngx-toastr';

//import { AuthenticationService } from '../_services';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    public isAuthenticated$!: Observable<boolean>;

    public name$!: Observable<string>;
    users: User;
    @Output()
    createUsercontact = new EventEmitter<User>();
    countryCodes: any;

    //new Login
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    //new Login

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private apiservice: apiService,
        private authenticationService: AuthenticationService,
        private _oktaStateService: OktaAuthStateService,
        @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth // private toastr: ToastrService
    ) {
        //   this.users = new User();
        //   this.users.username = "";
        //   this.users.password = "";
        //  this.users.country = "CL";

        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    //   addForm: FormGroup = new FormGroup({
    //     username: new FormControl(null, Validators.required),
    //     password: new FormControl(null, Validators.required),
    //  //   country: new FormControl(null, Validators.required)
    //   });

    ngOnInit() {
        localStorage.clear();
        this.getContryCode();
        this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
            filter((s: AuthState) => !!s),
            map((s: AuthState) => s.isAuthenticated ?? false)
        );

        // this.addForm = this.formBuilder.group({
        //   username: [''],
        //   password: [''],
        //   country: [''],
        // });

        this.loginForm = this.formBuilder.group({
            // username: ['', Validators.required],
            // password: ['', Validators.required],
            country: ['CL', Validators.required]
        });

        // get return url from route parameters or default to '/'
        // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    getContryCode() {
        this.apiservice.getCountryCode().subscribe((response) => {
            this.countryCodes = response;
        });
    }

    //auditType: any;

    // onSubmit() {
    //   this.apiservice.setSession('auditType', this.auditType);
    //   if (this.users.username == 'admin@genpact.com' && this.users.password == "123") {
    //     this.router.navigate(['/pages/dashboard/' + this.users.country]);
    //   }
    //   else {
    //     alert("Invalid credentials")
    //   }

    // }
    get f() {
        return this.loginForm.controls;
    }

    public async signIn(): Promise<void> {
        this.submitted = true;
        this.loading = true;
        localStorage.setItem('country', this.f.country.value);
        await this._oktaAuth.signInWithRedirect().then((_) => {
            this.router.navigate(['/profile']);
        });
    }

    public async signOut(): Promise<void> {
        await this._oktaAuth.signOut();
    }
}
