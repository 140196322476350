import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-table-modal',
    templateUrl: './table-modal.component.html',
    styleUrls: ['./table-modal.component.css']
})
export class TableModalComponent implements OnInit {
    displayedColumns = [];
    dataSource = [];
    tableColumn = [];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<TableModalComponent>) {}

    ngOnInit(): void {
        for (let [key, value] of Object.entries(this.data[0])) {
            const keyValue = key;
            const col = keyValue.replace('_', ' ');
            this.tableColumn.push({ name: col, key, value });
        }
        // if (this.data.length > 0) {

        //   for (let [key, value] of Object.entries(this.data[0])) {
        //     const keyValue = key;
        //     const col = keyValue.replace("_", " ");
        //     this.tableColumn.push({ name: col, key, value });
        //   }

        // }
    }
    replaceString(keyValue) {
        const col = keyValue.replace('_', '');
        return col;
    }
    closeModal() {
        this.dialogRef.close();
    }
}
