import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, RowSelectedEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';

import { ActivatedRoute, Router } from '@angular/router';
//import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AssignAuditorComponent } from '../popup/assign-auditor/assign-auditor.component';
import { MatDialog } from '@angular/material/dialog';
import { AddRowComponent } from '../popup/add-row/add-row.component';
import { GridHeaderSelectComponent } from '.././grid-header-select.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import _ from 'lodash';
import { AuditServiceService } from 'src/app/_services/audit-service.service';
import { apiService } from 'src/app/_services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { CommaFilterComponent } from '../popup/comma-filter/comma-filter.component';
import * as fs from 'file-saver';
import * as moment from 'moment';

@Component({
    selector: 'app-assign',
    templateUrl: './assign.component.html',
    styleUrls: ['./assign.component.css']
})
export class AssignComponent implements OnInit {
    // @ViewChild('agGrid',true) agGrid!: AgGridAngular;

    //rowData: Observable<any[]>;

    auditTableType: any;
    auditTableData: any;
    auditTypeID: any;
    ID: any;
    auditDropDown: any;
    selecteddata: any;
    clCode: any;
    public rowSelection = 'multiple';
    key = 'AUDIT_YEAR';
    private gridApi!: GridApi;
    private gridApi1!: GridApi;
    dummyData: any;
    rowData = [];
    pageNumber = -1;
    nextDisabled = false;
    inputDisabled = false;
    prvDisabled = true;
    showLoader = false;
    numberOfPage = 0;
    totalNumber = 0;
    TotalRecords: any;
    advanceFilterPayload = {};
    advanceFilterEnabled = false;
    finalData = [];
    columnDefs;
    columnName = [];
    currentYear = new Date().getFullYear();
    auditYear = new Date().getFullYear()-1;
    auditYearsData = [
        {
            "AuditYear": 2021
        },
        {
            "AuditYear": 2022
        },
        {
            "AuditYear": 2023
        },
        {
            "AuditYear": 2024
        }
    ];

    defaultColDef: any;

    fileteredResult = [];
    suppressRowClickSelection = true;
    pagination = true;
    paginationPageSize = 10;

    rowData1 = [];
    columnDefs1;
    rowSelection1 = 'multiple';
    addRow = 'inline-block';
    btnCount = [];
    keyOrder = 0;
    country = '';
    flag = '';
    country_cd = '';
    totalNumberFilter = 0;
    exportBtnList = [];
    showPagination = true;

    constructor(private apiservice: apiService, private http: HttpClient, private auditService: AuditServiceService, public activatedRoute: ActivatedRoute, public dialog: MatDialog, public router: Router, private _snackBar: MatSnackBar, private translate: TranslateService) {
        this.auditTypeID = this.activatedRoute.snapshot.params['ID'];
        this.defaultColDef = {
            width: 100,
            checkboxSelection: isFirstColumn,
            resizable: true
        };
    }

    nullHandler(params) {
        return params.value === null ? '-' : params.value;
    }

    ngOnInit() {
        this.TotalRecords = [];
        this.country_cd = localStorage.getItem('country');

        this.countryfields();

        this.clCode = this.country_cd;
        this.auditTypeID = this.activatedRoute.snapshot.params['ID'];
        this.loadAuditTypeValues(this.clCode);
        if (this.auditTypeID == 'MISC') {
            this.addRow = 'none';
        } else {
            this.addRow = 'inline-block';
        }
    }

    countryfields() {
        if (this.country_cd === 'CL') {
            this.country = 'Chile';
            this.flag = '../assets/img/chile.png';
        } else if (this.country_cd === 'MX') {
            this.country = 'Mexico';
            this.flag = '../assets/img/mexico.png';
        } else {
            this.country = '';
            this.flag = '';
        }
    }

    // getAllAuditYrData() {
    //     this.apiservice.getAllAuditYr(this.auditTypeID, this.country_cd).subscribe((result: any) => {
    //         if (this.country_cd === 'MX') {
    //             let temp;
    //             for (let i = 0; i < result.length; i++) {
    //                 result = _.sortBy(result, (itm) => {
    //                     return itm.AUDIT_YEAR;
    //                 });

    //                 temp = {
    //                     AuditYear: result[i].AUDIT_YEAR
    //                 };
    //                 this.auditYearsData[i] = temp;
    //             }
    //             this.auditYearsData.sort().reverse();
    //         } else {
    //             this.auditYearsData = result;
    //         }
    //     });
    // }

    numberWithCommas(x) {
        if (x.value != null) {
            const numberValue = x.value;
            const convertNumber = Number(numberValue);
            return convertNumber.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
        }
    }

    numberWithCommasMX(x) {
        if (x.value != null) {
            const numberValue = x.value;
            if (x.colDef.field == 'POS_SALES_QTY' || x.colDef.field == 'AMS_QTY') {
                return numberValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            } else {
                const convertNumber = Number(numberValue);
                return convertNumber.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            }
        }
    }

    allNumberColume = [
        'RELEASE_NUM',
        'SUPPLIER_NUM',
        'DEAL_AGREEMENT_NUM',
        'ACCOUNT_DEPARTMENT',
        'LEGACY_CATEGORY',
        'CATEGORY',
        'YEAR_VOLUME',
        'PERCENTAGE',
        'VOLUME',
        'EARNED',
        'AMOUNT_RECEIVED',
        'FIXED_AMOUNT_AGREEMENT',
        'DEBITED_AMOUNT',
        'AGREEMENT_NUMBER',
        'APP_CLAIM_NUMBER',
        'RECORD_ID',
        'AUDIT_YEAR',
        'DEPT',
        'TOTAL_SALES',
        'STORE_NBR',
        'FIXED_AMT',
        'UFAMT',
        'AMT_DEDUCTED',
        'OTHER_DEDUCTION',
        'AMOUNT_DUE',
        'VENDOR_NBR',
        'SEQUENCING',
        'AMOUNT',
        'AMT_FOR_THE_PERIOD',
        'AMOUNT_DEDUCTED',
        'TOTAL_DEDUCTION',
        'PENDING_DUE',
        'IVA_DUE',
        'TOTAL_PENDING',
        'AGREEMENT_NUM',
        'AMT_DUE',
        'AMS_RATE',
        'ALLOW_EARNED',
        'Accrual_AMT',
        'AMS_QTY',
        'AMS_SALES_AMT',
        'POS_SALES_AMT',
        'IVA_DUE',
        'IEPS_DUE',
        'POS_SALES_QTY',
        'Adjustments',
        'GROUP',
        'SAP_VENDOR',
        'IEPS_PCT',
        'IVA_PCT',
        'CLAIM_NUMBER',
        'NO_OF_DEDUCTION',
        'ENTERED_AMT',
        'AMTDUE',
        'IEPS_AMOUNT',
        'IVA_AMOUNT',
        'TOTAL_DUE',
        'OTHER_DEDUCT',
        'TOTAL_RETAIL_AMT_SHORT',
        'TOTAL_MARGIN_LOST',
        'TOTAL_EARNED',
        'AMOUNT_DEDUCTED',
        'PENDING_DUE',
        'COOP_DEDUCTED',
        'AMT_DEDUCTED',
        'ALL_BODEGA_POS_QTY',
        'ALL_BODEGA_POS_AMT',
        'BASE_COOP_AMT',
        'BASE_AMT_DEDUCT',
        'ADJUSTMENTS',
        'BASE_AMT_DUE',
        'AMT_DEDUCT_TAXES',
        'AMS_PURCHASE',
        'PO_PURCHASES',
        'OTHER_DED',
        'IEPS_AMOUNT',
        'IVA_AMOUNT',
        'PREVIOUS_CLAIMS',
        'IEPS',
        'IVA',
        'AMTDEDUCTED',
        'TOTAL_AMT_PAID',
        'TOTAL_ORD_QTY',
        'TOTAL_RCV_QTY',
        'TOTAL_SHORT_QTY',
        'TOTAL_SHIPS',
        'UNITS_SOLD',
        'TOTAL_DEFERRED',
        'SURCHARGE_SALES',
        'OTHER_DEDUCTIONS',
        'DEDUCTION',
        'DEDUCTION_CIVA'
    ];
    allNumberWIthComma = [
        'YEAR_VOLUME',
        'VOLUME',
        'TOTAL_DEDUCTION',
        'IVA_DUE',
        'TOTAL_PENDING',
        'FIXED_AMT',
        'TOTAL_SALES',
        'UFAMT',
        'TOTAL_DUE',
        'AMT_DEDUCTED',
        'OTHER_DEDUCTION',
        'AMOUNT_DUE',
        'EARNED',
        'AMOUNT_RECEIVED',
        'FIXED_AMOUNT_AGREEMENT',
        'DEBITED_AMOUNT',
        'AMOUNT',
        'AMT_FOR_THE_PERIOD',
        'AMT_DUE',
        'POS_SALES_QTY',
        'AMS_RATE',
        'ALLOW_EARNED',
        'Accrual_AMT',
        'Adjustments',
        'AMS_QTY',
        'AMS_SALES_AMT',
        'POS_SALES_AMT',
        'IVA_DUE',
        'IEPS_DUE',
        'OTHER_DEDUCT',
        'ENTERED_AMT',
        'AMTDUE',
        'IEPS_AMOUNT',
        'IVA_AMOUNT',
        'TOTAL_RETAIL_AMT_SHORT',
        'TOTAL_MARGIN_LOST',
        'TOTAL_EARNED',
        'AMOUNT_DEDUCTED',
        'PENDING_DUE',
        'COOP_DEDUCTED',
        'AMT_DEDUCTED',
        'ALL_BODEGA_POS_QTY',
        'ALL_BODEGA_POS_AMT',
        'BASE_COOP_AMT',
        'BASE_AMT_DEDUCT',
        'ADJUSTMENTS',
        'BASE_AMT_DUE',
        'AMT_DEDUCT_TAXES',
        'AMS_PURCHASE',
        'PO_PURCHASES',
        'OTHER_DED',
        'IEPS_AMOUNT',
        'IVA_AMOUNT',
        'PREVIOUS_CLAIMS',
        'IEPS',
        'IVA',
        'AMTDEDUCTED',
        'TOTAL_AMT_PAID',
        'TOTAL_ORD_QTY',
        'TOTAL_RCV_QTY',
        'TOTAL_SHORT_QTY',
        'TOTAL_SHIPS',
        'UNITS_SOLD',
        'TOTAL_DEFERRED',
        'SURCHARGE_SALES',
        'OTHER_DEDUCTIONS',
        'DEDUCTION',
        'DEDUCTION_CIVA'
    ];

    convertObject(v) {
        for (const [key, value] of Object.entries(v)) {
            const findIndex = this.allNumberColume.indexOf(key);
            if (findIndex > -1) {
                if (v[key] != undefined) {
                    v[key] = Number(v[key]);
                }
            }
        }
        delete v.DEPARTMENT_NAME;
        return v;
    }

    onSelectionChanged(params: GridReadyEvent) {
        var selectedRows = this.gridApi.getSelectedRows();
        (document.querySelector('#selectedRows') as any).innerHTML = '<b>Rows selected-</b>' + selectedRows.length;
        if (this.gridApi) {
            this.gridApi.refreshHeader();
        }
    }

    onFilterChanged() {
        this.TotalRecords = this.gridApi.getDisplayedRowCount();
    }

    onPaginationChanged(params: GridReadyEvent) {
        if (this.gridApi) {
            this.gridApi.refreshHeader();
        }
    }

    homeIcon() {
        this.router.navigate(['dashboard/' + '/' + this.clCode]);
    }

    loadAuditTypeValues(CLCode) {
        this.apiservice.getAuditType(CLCode).subscribe((response) => {
            this.auditDropDown = response;
            this.selecteddata = this.auditTypeID;
        });
    }

    selectedValue(auditTypeId: any) {
        this.auditTypeID = auditTypeId;
        this.showLoader = true;
        this.pageNumber = -1;
        this.numberOfPage = 0;

        this.pageNumber++;
        this.numberOfPage++;
        const rowData = [];
        if (this.numberOfPage > 1) {
            this.prvDisabled = false;
        }
        this.updateDataTable(this.auditTypeID, this.auditYear, this.country_cd, this.pageNumber);
        this.router.navigate(['managePR/' + this.clCode + '/' + auditTypeId]);
        this.Refresh(auditTypeId);
    }

    Refresh(auditTypeId) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['assign/' + this.clCode + '/' + auditTypeId]);
    }

    selectedValueAuditYear(auditYear: any) {
        this.showLoader = true;
        this.pageNumber = -1;
        this.numberOfPage = 0;

        this.pageNumber++;
        this.numberOfPage++;
        const rowData = [];
        if (this.numberOfPage > 1) {
            this.prvDisabled = false;
        }
        this.updateDataTable(this.auditTypeID, auditYear, this.country_cd, this.pageNumber);
        this.gridApi.hideOverlay();
        this.gridApi.setRowData(this.rowData);
    }

    generateCol(data) {
        const columnDf = this.generateColumns(data);

        columnDf[0] = {
            ...columnDf[0],

            headerCheckboxSelection: true,

            headerCheckboxSelectionFilteredOnly: true,

            checkboxSelection: true
        };

        this.columnDefs = columnDf;
    }

    onGridReady(params: GridReadyEvent) {
        this.showLoader = true;
        this.gridApi = params.api; // To access the grids API
        this.gridApi.hideOverlay();
        this.pageNumber++;

        this.numberOfPage++;
        this.updateDataTable(this.auditTypeID, this.auditYear, this.country_cd, this.pageNumber);
    }

    updateDataTable(auditTypeID, auditYear, country_cd, pageNumber) {
        if (this.advanceFilterEnabled) {
            this.apiservice.qrPageFiltersMX(this.country, this.advanceFilterPayload, this.auditTypeID, this.auditYear, this.pageNumber, this.key, this.keyOrder).subscribe(
                (result) => {
                    this.processTable(result);
                },
                (error) => {
                    this.showLoader = false;
                    this.openSnackBar('There was an error ' + error, 'Ok');
                }
            );
        } else {
            this.apiservice.getAuditTableDataByYear(auditTypeID, auditYear, country_cd, pageNumber).subscribe(
                (result: any) => {
                    this.processTable(result);
                },
                (error) => {
                    this.showLoader = false;
                    this.openSnackBar('There was an error ' + error, 'Ok');
                }
            );
        }
    }

    processTable(result) {
        this.exportBtnList = [];
        this.TotalRecords = result.count[0];
        this.totalNumber = 0;
        this.totalNumber = Math.ceil(result.count[0] / result.data.length);
        if (isNaN(this.totalNumber)) {
            this.totalNumber = 0;
            this.showPagination = false;
        } else {
            this.showPagination = true;
        }
        this.totalNumberFilter = Math.floor(this.TotalRecords / 150000);
        for (let i = 0; i <= this.totalNumberFilter; i++) {
            this.exportBtnList.push(i);
        }
        if (this.totalNumber <= 1) {
            this.nextDisabled = true;
            this.inputDisabled = true;
        } else if (this.totalNumber > 1) {
            this.nextDisabled = false;
            this.inputDisabled = false;
        }
        const dataValue = [];
        _.each(result.data, (v) => {
            dataValue.push(this.convertObject(v));
        });
        this.showLoader = false;
        this.rowData = dataValue;

        //  this.totalNumber =result.count[0].Count;

        const columnDf = this.generateColumns(this.rowData);

        columnDf[0] = {
            ...columnDf[0],

            headerCheckboxSelection: true,

            headerCheckboxSelectionFilteredOnly: true,

            checkboxSelection: true
        };

        this.columnDefs = columnDf;
    }

    goToPrv() {
        this.showLoader = true;
        this.pageNumber--;
        this.numberOfPage--;
        if (this.numberOfPage > 1) {
            this.prvDisabled = false;
        } else {
            this.prvDisabled = true;
        }
        if (this.numberOfPage >= this.totalNumber) {
            this.nextDisabled = true;
        } else {
            this.nextDisabled = false;
        }
        this.updateDataTable(this.auditTypeID, this.auditYear, this.country_cd, this.pageNumber);
    }

    goToNext() {
        this.showLoader = true;
        this.pageNumber++;
        this.numberOfPage++;
        if (this.numberOfPage > 1) {
            this.prvDisabled = false;
        } else {
            this.prvDisabled = true;
        }
        if (this.numberOfPage >= this.totalNumber) {
            this.nextDisabled = true;
        } else {
            this.nextDisabled = false;
        }
        this.updateDataTable(this.auditTypeID, this.auditYear, this.country_cd, this.pageNumber);
    }

    onPageNumber($event) {
        let pageEntered = $event.target.value;
        if (pageEntered >= 1 && pageEntered <= this.totalNumber) {
            this.showLoader = true;
            if (this.numberOfPage > 1) {
                this.prvDisabled = false;
            } else {
                this.prvDisabled = true;
            }
            if (this.numberOfPage >= this.totalNumber) {
                this.nextDisabled = true;
            } else {
                this.nextDisabled = false;
            }
            this.pageNumber = pageEntered;
            this.updateDataTable(this.auditTypeID, this.auditYear, this.country_cd, this.pageNumber);
        } else {
            $event.preventDefault();
            if (pageEntered < 1 || pageEntered > this.totalNumber) {
                this.openSnackBar('Please enter the correct page number', 'Ok');
            }
        }
    }

    advanceFilters() {
        const dialogRef = this.dialog.open(CommaFilterComponent, {
            width: '200',
            hasBackdrop: true,
            data: {
                previousPayload: this.advanceFilterPayload,
                columnDets: this.columnDefs,
                auditYear: this.auditYear,
                id: this.auditTypeID,
                country: this.country_cd
            }
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result.from === 'submit') {
                this.advanceFilterPayload = result.payload;
                this.advanceFilterEnabled = true;
                this.processTable(result.data);
            } else {
                this.advanceFilterEnabled = false;
            }
        });
    }

    clearFilters() {
        this.Refresh(this.auditTypeID);
    }

    printSortStateToConsole($event) {
        if (this.advanceFilterEnabled) {
            this.keyOrder = this.keyOrder === 1 ? 0 : 1;
            this.apiservice.qrPageFiltersMX(this.country, this.advanceFilterPayload, this.auditTypeID, this.auditYear, this.pageNumber, this.key, this.keyOrder).subscribe(
                (result) => {
                    this.processTable(result);
                },
                (error) => {
                    this.showLoader = false;
                    this.openSnackBar('There was an error ' + error, 'Ok');
                }
            );
        }
    }

    dateFilterParams = {
        comparator: function (filterLocalDateAtMidnight: Date, cellValue: string) {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var dateParts = dateAsString.split('-');
            var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
        }
        // suppressAndOrCondition: false,
        // closeOnApply: true,
        // filterOptions: ['equals', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
        // browserDatePicker: true,
        // buttons: ['reset', 'apply'],
    };

    numberFilterOption = {
        //   suppressAndOrCondition: false,
        //   closeOnApply: true,
        //   filterOptions: ['equals', 'contains', 'startsWith', 'endsWith', 'notEqual', 'notContains', 'lessThan', 'lessThanOrEqual',
        //   'greaterThan', 'greaterThanOrEqual'],
        //   buttons: ['reset', 'apply'],
    };

    dateComparator(date1: string, date2: string) {
        const date1Number = date1 != undefined ? convertIntoDate(date1).getTime() : 0;
        const date2Number = date2 != undefined ? convertIntoDate(date2).getTime() : 0;
        if (date1Number == null && date2Number == null) {
            return 0;
        }
        if (date1Number == null) {
            return -1;
        }
        if (date2Number == null) {
            return 1;
        }
        return date1Number - date2Number;
    }

    generateColumns(data: any) {
        let columnDefinitions = [];
        let colValue = [];
        for (const [key, value] of Object.entries(data[0])) {
            const v = value;
            const findNumber = _.find(this.allNumberColume, (item) => item === key);
            const keyValue: any = key.toString();
            const convertNumber = Number(v);
            const getData = keyValue.replaceAll('_', ' ');
            const getAry = getData.split(' ');
            const findDate = _.find(getAry, (v) => {
                return v.toLowerCase() === 'date';
            });
            let keyInLowerCase = key.toLowerCase();
            const findIndex = colValue.indexOf(key);
            if (findIndex === -1) {
                if (key != 'CREATED_DATE' && key != 'LAST_UPDATED_DATE' && key != 'LAST_UPDATED_BY' && key != 'VERSION') {
                    colValue.push(key);
                }
            }
            let mappedColumn: any = {
                headerName: this.getTranslation('langtableheaders.' + key),
                field: key,
                filter: this.shouldEnableFilter(),
                sortable: true,
                width: '100%',
                resizable: true
            };
            if (findDate) {
                //  mappedColumn.filter = 'agDateColumnFilter';
                //  mappedColumn.filterParams = this.dateFilterParams;
                mappedColumn.comparator = this.dateComparator;
            } else if (findNumber) {
                //  if (this.clCode == "CL") {
                const findCommaFilter = this.allNumberWIthComma.indexOf(key);
                if (findCommaFilter > -1) {
                    //  mappedColumn.filter = 'agNumberColumnFilter';
                    //  mappedColumn.filterParams = this.numberFilterOption;

                    // if (this.clCode == 'CL') {
                    mappedColumn.valueFormatter = this.numberWithCommas;
                    // }
                    // if (this.clCode == 'MX') {
                    //     mappedColumn.valueFormatter = this.numberWithCommasMX;
                    // }
                } else {
                    //  mappedColumn.filter = 'agNumberColumnFilter';
                    //   mappedColumn.filterParams = this.numberFilterOption;
                }
                //}
                // if (this.clCode == "MX") {
                //   const findCommaFilter = this.allNumberWIthCommaMX.indexOf(key);
                //   if (findCommaFilter > -1) {
                //     mappedColumn.filter = 'agNumberColumnFilter';
                //     mappedColumn.filterParams = this.numberFilterOption;
                //     mappedColumn.valueFormatter = this.numberWithCommasMX;
                //   } else {
                //     mappedColumn.filter = 'agNumberColumnFilter';
                //     mappedColumn.filterParams = this.numberFilterOption;
                //   }
                // }
            } else {
                //  mappedColumn.filter = true;
                //  mappedColumn.filterParams = filterParams;
            }
            if (key == 'MIN_SALES' || key === 'MAX_SALES' || key === 'Valid_From' || key === 'Valid_To') {
                //  mappedColumn.filter = 'agDateColumnFilter';
                //  mappedColumn.filterParams = this.dateFilterParams;
                mappedColumn.comparator = this.dateComparator;
            }
            if (key != 'LAST_UPDATED_BY' && key != 'LAST_UPDATED_DATE' && key != 'VERSION' && key != 'CREATED_DATE') {
                columnDefinitions.push(mappedColumn);
            }
        }
        //Remove duplicate columns
        columnDefinitions = columnDefinitions.filter((column, index, self) => index === self.findIndex((colAtIndex) => colAtIndex.field === column.field));

        this.columnName = _.uniqBy(colValue);
        return columnDefinitions;
        // return datdef;
    }

    shouldEnableFilter() {
        if (this.auditTypeID === 'New Stores') {
            return false;
        }
        return true;
    }

    onBtnExport(count?) {
        if (this.auditTypeID === 'New Stores' || this.auditTypeID === 'Low Price' || this.auditTypeID === 'Shortages' || this.auditTypeID ===  'UN-DEDUCTED COOPS') {
            let num;
            let payload;
            if (this.advanceFilterEnabled) {
                payload = this.advanceFilterPayload;
            } else {
                payload = {
                    result: []
                };
            }
            if (count == null || count == '' || count == undefined) {
                num = this.pageNumber + 1;
            } else {
                num = count + 1;
            }

            let pageNo = num - 1;
            this.showLoader = true;
            let key = "RECORD_ID";
            this.apiservice.qrPageExport(this.auditTypeID, this.auditYear, pageNo, this.country_cd, payload, this.translate.currentLang, key, this.keyOrder).subscribe((response) => {
                let downloadFIle = response;
                this.downloadFile(downloadFIle, num);
                this.showLoader = false;
            });
        } else {
            this.gridApi.exportDataAsCsv();
        }
    }

    downloadFile(data: any, count) {
        let title = 'my file';
        let blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
        });
        let filename = this.auditTypeID + '_' + count + '.xlsx';
        let filecontent = blob;
        fs.saveAs(blob, filename);
        this.showLoader = false;
        var url = window.URL.createObjectURL(fs);
        var pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert('Please disable your Pop-up blocker and try again.');
        }
    }

    assignAuditor() {
        let selectedNodes = this.gridApi.getSelectedNodes();

        if (selectedNodes.length != 0) {
            let selectedData = selectedNodes.map((node) => node.data);
            var dataTosend = {
                data: selectedData,
                id: this.auditTypeID,
                countryCode: this.clCode
            };
            const dialogRef = this.dialog.open(AssignAuditorComponent, {
                hasBackdrop: true,
                data: { requestId: dataTosend }
            });
            dialogRef.afterClosed().subscribe((result) => {});
        } else {
            alert('Please select the row');
        }
    }

    addNewRow() {
        var dataTosend = { id: this.auditTypeID, countryCode: this.clCode };
        const dialogRef = this.dialog.open(AddRowComponent, {
            width: '200',
            hasBackdrop: true,
            data: { requestId: dataTosend, rowData: this.columnName }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result === 1) {
                this.refreshDataValue();
            }
        });
    }

    checkHistory() {
        let selectedNodes = this.gridApi.getSelectedNodes();

        if (selectedNodes.length != 0) {
            if (selectedNodes.length == 1) {
                let recordID = selectedNodes[0].data.RECORD_ID;
                this.router.navigate(['/pages/assign-history/' + this.clCode + '/' + this.auditTypeID + '/' + recordID]);
            } else {
                alert('Please select only one row');
            }
        } else {
            alert('Please select the row');
        }
    }

    refreshDataValue() {
        this.showLoader = true;
        const rowData = [];
        if (this.numberOfPage > 1) {
            this.prvDisabled = false;
        }
        this.apiservice.getAuditTableDataByYear(this.auditTypeID, this.auditYear, this.country_cd, this.pageNumber).subscribe((result: any) => {
            this.TotalRecords = result.count[0];
            this.totalNumber = 0;
            this.totalNumber = Math.round(result.count[0] / result.data.length);
            if (result.count[0] == result.data.length) {
                this.nextDisabled = true;
                this.inputDisabled = true;
            }
            _.each(result.data, (v) => {
                if (this.clCode == 'CL') {
                    if (v.VOLUME != undefined) {
                        v.VOLUME = this.numberWithCommas(v.VOLUME);
                    }
                    if (v.EARNED != undefined) {
                        v.EARNED = this.numberWithCommas(v.EARNED);
                    }
                    if (v.AMOUNT_RECEIVED != undefined) {
                        v.AMOUNT_RECEIVED = this.numberWithCommas(v.AMOUNT_RECEIVED);
                    }
                    if (v.FIXED_AMOUNT_AGREEMENT != undefined) {
                        v.FIXED_AMOUNT_AGREEMENT = this.numberWithCommas(v.FIXED_AMOUNT_AGREEMENT);
                    }
                    if (v.DEBITED_AMOUNT != undefined) {
                        v.DEBITED_AMOUNT = this.numberWithCommas(v.DEBITED_AMOUNT);
                    }

                    if (v.TOTAL_SALES != undefined) {
                        v.TOTAL_SALES = this.numberWithCommas(v.TOTAL_SALES);
                    }

                    if (v.FIXED_AMT != undefined) {
                        v.FIXED_AMT = this.numberWithCommas(v.FIXED_AMT);
                    }
                    if (v.AMT_DEDUCTED != undefined) {
                        v.AMT_DEDUCTED = this.numberWithCommas(v.AMT_DEDUCTED);
                    }
                    if (v.OTHER_DEDUCTION != undefined) {
                        v.OTHER_DEDUCTION = this.numberWithCommas(v.OTHER_DEDUCTION);
                    }
                    if (v.AMOUNT_DUE != undefined) {
                        v.AMOUNT_DUE = this.numberWithCommas(v.AMOUNT_DUE);
                    }
                    if (v.TOTAL_DUE != undefined) {
                        v.TOTAL_DUE = this.numberWithCommas(v.TOTAL_DUE);
                    }
                    if (v.AMOUNT != undefined) {
                        v.AMOUNT = this.numberWithCommas(v.AMOUNT);
                    }

                    if (v.AMT_FOR_THE_PERIOD != undefined) {
                        v.AMT_FOR_THE_PERIOD = this.numberWithCommas(v.AMT_FOR_THE_PERIOD);
                    }
                    if (v.UFAMT != undefined) {
                        v.UFAMT = this.numberWithCommas(v.UFAMT);
                    }
                    if (v.YEAR_VOLUME != undefined) {
                        v.YEAR_VOLUME = this.numberWithCommas(v.YEAR_VOLUME);
                    }
                    if (v.TOTAL_DEDUCTION != undefined) {
                        v.TOTAL_DEDUCTION = this.numberWithCommas(v.TOTAL_DEDUCTION);
                    }
                    if (v.PENDING_DUE != undefined) {
                        v.PENDING_DUE = this.numberWithCommas(v.PENDING_DUE);
                    }
                    if (v.IVA_DUE != undefined) {
                        v.IVA_DUE = this.numberWithCommas(v.IVA_DUE);
                    }
                    if (v.TOTAL_PENDING != undefined) {
                        v.TOTAL_PENDING = this.numberWithCommas(v.TOTAL_PENDING);
                    }
                    if (v.SEQUENCING != undefined) {
                        v.SEQUENCING = this.numberWithCommas(Math.trunc(v.SEQUENCING));
                    }
                }

                if (this.clCode == 'MX') {
                    delete v.DEPARTMENT_NAME;
                    if (v.IEPS_DUE != undefined) {
                        v.IEPS_DUE = this.numberWithCommas(Math.trunc(v.IEPS_DUE));
                    }
                    if (v.IVA_DUE != undefined) {
                        v.IVA_DUE = this.numberWithCommas(Math.trunc(v.IVA_DUE));
                    }
                    if (v.POS_SALES_AMT != undefined) {
                        v.POS_SALES_AMT = this.numberWithCommas(Math.trunc(v.POS_SALES_AMT));
                    }
                    if (v.AMS_SALES_AMT != undefined) {
                        v.AMS_SALES_AMT = this.numberWithCommas(Math.trunc(v.AMS_SALES_AMT));
                    }
                    if (v.AMS_QTY != undefined) {
                        v.AMS_QTY = this.numberWithCommas(Math.trunc(v.AMS_QTY));
                    }
                    if (v.Adjustments != undefined) {
                        v.Adjustments = this.numberWithCommas(Math.trunc(v.Adjustments));
                    }
                    if (v.Accrual_AMT != undefined) {
                        v.Accrual_AMT = this.numberWithCommas(Math.trunc(v.Accrual_AMT));
                    }
                    if (v.ALLOW_EARNED != undefined) {
                        v.ALLOW_EARNED = this.numberWithCommas(Math.trunc(v.ALLOW_EARNED));
                    }
                    if (v.AMS_RATE != undefined) {
                        v.AMS_RATE = this.numberWithCommas(Math.trunc(v.AMS_RATE));
                    }
                    if (v.POS_SALES_QTY != undefined) {
                        v.POS_SALES_QTY = this.numberWithCommas(Math.trunc(v.POS_SALES_QTY));
                    }
                    if (v.AMT_DUE != undefined) {
                        v.AMT_DUE = this.numberWithCommas(Math.trunc(v.AMT_DUE));
                    }
                }

                rowData.push(v);
            });
            this.showLoader = false;
            this.rowData = rowData;
            this.generateCol(rowData);
            this.gridApi.hideOverlay();
            this.gridApi.setRowData(this.rowData);
            this.showLoader = false;
        });
    }
    getTranslation(str) {
        const currentLang = this.translate.currentLang;
        // const returnValue = this.translate.translations[currentLang][str];
        let returnValue;
        this.translate.get(str).subscribe((data) => {
            returnValue = data;
        });

        if (returnValue === undefined) {
            return this.translate.translations.en[str];
        } else {
            return returnValue;
        }
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 3000,
            verticalPosition: 'top'
        });
    }
}

// var filterParams = {
//   suppressAndOrCondition: false,
//   filterOptions: ['equals', 'contains', 'startsWith', 'endsWith', 'notEqual', 'notContains', 'lessThan', 'lessThanOrEqual',
//     'greaterThan', 'greaterThanOrEqual'],
//   buttons: ['reset', 'apply'],
//   closeOnApply: true,
//   defaultJoinOperator: 'OR'
// };

function convertIntoDate(dateValue) {
    if (dateValue != null && dateValue != undefined) {
        const dateArray = dateValue.split('-');
        const dateString = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
        const dateObj = new Date(dateString);
        return dateObj;
    } else {
        return null;
    }
}

function isFirstColumn(params) {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
}
