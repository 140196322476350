import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(@Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(this.addAuthHeaderToAllowedOrigins(request));
    }

    private addAuthHeaderToAllowedOrigins(request: HttpRequest<unknown>): HttpRequest<unknown> {
        let req = request;

        const allowedOrigins = ['https://gch-fd-dev-wmt-001.azurefd.net/'];
        //  const allowedOrigins = ['https://localhost:4200/'];
        if (!!allowedOrigins.find((origin) => request.url.includes(origin))) {
            debugger
            const authToken = this._oktaAuth.getAccessToken();
            localStorage.setItem('accesstoken', authToken);
            req = request.clone({
                setHeaders: { Authorization: `Bearer ${authToken}` }
            });
        }

        return req;
    }
}
