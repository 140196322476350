import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { UserService, AuthenticationService } from '../../_services';
import { LoginUser } from '../../_models';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    loading = false;
    currentUser: LoginUser;
    userFromApi: LoginUser;
    loggedInUser: any;
    username: any;

    constructor(private router: Router, private userService: UserService, private authenticationService: AuthenticationService, public translate: TranslateService, @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit() {
        const prompt = JSON.parse(localStorage.getItem('userinfo'));
        this.username = prompt.username;
        this.SetLang();
        this.onPageLoad();
    }

    public async logout(): Promise<void> {
        if (confirm('Are you sure want to Logout?')) {
            this._oktaAuth.tokenManager.clear();
            await this._oktaAuth.signOut().then((_) => {
                this.router.navigate(['/login']);
            });

            localStorage.removeItem('userinfo');
            localStorage.removeItem('report_lookup');
            localStorage.removeItem('okta-shared-transaction-storage');
            localStorage.removeItem('okta-token-storage');
            localStorage.removeItem('okta-original-uri-storage');
            localStorage.removeItem('currentUser');
            localStorage.removeItem('okta-cache-storage');
            localStorage.removeItem('country');
            // this.router.navigate(['/login']);
        }
    }

    onPageLoad() {
        this.loading = true;
        this.userService
            .getById(this.currentUser.id)
            .pipe(first())
            .subscribe((user) => {
                this.loading = false;
                this.userFromApi = user;
                // this.loggedInUser = this.currentUser.username
                this.loggedInUser = this.username;
            });
    }

    SetLang() {
        let lang = localStorage.getItem('lang');
        if (lang === null) {
            this.translate.setDefaultLang('en');
        } else {
            this.translate.use(lang);
        }
    }
}
