import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatTreeModule } from '@angular/material/tree';
// import { CloseComponent } from './modules/close/close/close.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [],
    imports: [MatCardModule, MatTreeModule, MatGridListModule, MatMenuModule, MatIconModule, MatButtonModule, MatChipsModule, LayoutModule, MatToolbarModule, MatSidenavModule, MatDividerModule, MatListModule, MatTooltipModule, MatTabsModule, MatSelectModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatDialogModule],
    exports: [MatCardModule, MatTreeModule, MatGridListModule, MatMenuModule, MatIconModule, MatButtonModule, MatChipsModule, LayoutModule, MatToolbarModule, MatSidenavModule, MatDividerModule, MatListModule, MatTooltipModule, MatTabsModule, MatSelectModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatDialogModule]
})
export class MaterialModule {}
