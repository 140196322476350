import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { apiService } from 'src/app/_services/app.service';
import _ from 'lodash';
import { PdfViewComponent } from '../pdf-view/pdf-view.component';
import { DatePipe } from '@angular/common';
import { EditDetails } from 'src/app/_models/editDetails';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as JSZip from 'jszip';

@Component({
    selector: 'app-create-claim-package',
    templateUrl: './create-claim-package.component.html',
    styleUrls: ['./create-claim-package.component.css'],
    providers: [DatePipe]
})
export class CreateClaimPackageComponent implements OnInit {
    allRowValue = [];
    claimRowAllValues: any = [];
    userSelectedValue: any = [];
    leftRowSelectedValue: any;
    rightRowSelectedValue: any;
    claimInfo: any;
    fileType = 1;
    viewType = 0;
    currentDateTime;
    createdDateBtn = 'none';
    checked = false;
    selectedDate: any;
    claimPackage: any;
    showLoader = false;
    fileFormat = [
        { name: 'Excel', isChecked: false },
        { name: 'PDF', isChecked: false }
    ];
    IsValuesChecked = false;
    claimCreationDate: any;
    packageDate: any;
    claim_number: any;
    ccCode: any;

    constructor(public translate: TranslateService, public dialog: MatDialog, private apiservice: apiService, private datePipe: DatePipe, public dialogRef: MatDialogRef<CreateClaimPackageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.claimRowAllValues = [];
        // this.currentDate=new Date();
        //let latest_date =this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
        this.currentDateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        this.claimPackage = new EditDetails();

        this.claimPackage.claimDate = '';
        this.claimPackage.fileType = '';
    }

    //fileTypeList = { "PDF", "EXCEL" };

    fileTypeList = [{ fileType: 'PDF' }, { fileType: 'EXCEL' }];
    addForm: FormGroup = new FormGroup({
        claimDate: new FormControl('', Validators.required),
        fileType: new FormControl('', Validators.required)
    });

    ngOnInit(): void {
        this.claim_number = this.data.claim_number;
        this.claimInfo = this.data.claim_data;
        this.claimCreationDate = this.data.creationDate;
        this.ccCode = this.data.country;
         this.SetLang();
        this.claimRowAllValues = _.map(this.data.fileDetail, (item, i) => {
            return {
                id: i,
                name: item.File_Name.replace(/\.[^/.]+$/, ''),
                file_name: item.File_Name,
                file: item.Attachment_object,
                file_id: item.Id
            };
        });
        this.allRowValue = this.claimRowAllValues;
    }

    convertToDateFormat(date) {
        if (this.ccCode == 'CL') {
            return date.split('-').reverse().join('-');
        } else {
            return date;
        }
    }


    changeDate(event) {
        console.log("event", event.value)
        this.packageDate = event.value;
    }

    viewFile() {
        if (this.userSelectedValue.length > 0) {
            let dialogRef = this.dialog.open(PdfViewComponent, {
                data: _.map(this.userSelectedValue, (v) => {
                    return {
                        file_name: v.name,
                        file: v.file,
                        file_id: v.file_id
                    };
                })
            });
        } else {
            this.viewType = 0;
            alert('Please select atlest one value ');
        }
    }

    SetLang() {
        let lang = localStorage.getItem('lang');
        if (lang === null) {
            this.translate.setDefaultLang('en');
        } else {
            this.translate.use(lang);
        }
    }

    leftRightClick(type: number) {
        switch (type) {
            case 0:
                this.allRowValue.forEach((item, index) => {
                    const findValue = this.leftRowSelectedValue.find((element: any) => {
                        return element === item.id;
                    });
                    if (findValue != undefined) {
                        this.userSelectedValue.push(item);
                        this.claimRowAllValues = this.claimRowAllValues.filter((v: any) => v.id !== findValue);
                    }
                });
                this.leftRowSelectedValue = null;
                break;
            case 1:
                this.userSelectedValue = this.claimRowAllValues;
                this.claimRowAllValues = [];
                break;
            case 2:
                this.allRowValue.forEach((item, index) => {
                    const findValue = this.rightRowSelectedValue.find((element: any) => {
                        return element === item.id;
                    });
                    if (findValue != undefined) {
                        this.claimRowAllValues.push(item);
                        this.userSelectedValue = this.userSelectedValue.filter((v: any) => v.id !== findValue);
                    }
                });
                this.rightRowSelectedValue = null;
                break;
            case 3:
                this.claimRowAllValues = this.userSelectedValue;
                this.userSelectedValue = [];
                break;
            default:
                break;
        }
    }

    /**
     * Function to set/update the value of this.claimPackage.fileType on calling event change
     */
    onFileTypeSelection = () => {
        let fileType = this.fileFormat.filter((val) => val.isChecked === true);
        this.claimPackage.fileType = fileType.length === 2 ? 'BOTH' : fileType[0]?.name;
    };

    onSubmit() {
        this.showLoader = true;
        const payload = _.map(this.userSelectedValue, (v) => {
            return {
                Claim_Number: this.claim_number,
                File_Name: v.file_name,
                File_ID: v.file_id,
                File_URL: v.file
            };
        });

        // if (this.claimPackage.claimDate == "" || this.claimPackage.claimDate == null || this.claimPackage.claimDate == undefined) {
        //   this.IsValuesChecked = false
        // }
        // else {
        //   this.IsValuesChecked = true
        // }

        const sendPayload = {
            package_date: this.packageDate,
            package_type: this.claimPackage.fileType, //('Excel','PDF','BOTH'),
            Claim_Number: this.claim_number,
            package_file: payload
        };
        if (this.claimPackage.fileType == '' || this.claimPackage.fileType == null || (this.claimPackage.fileType == undefined && this.claimPackage.claimDate == '') || this.claimPackage.claimDate == null || this.claimPackage.claimDate == undefined) {
            this.IsValuesChecked = false;
        } else {
            this.IsValuesChecked = true;
        }

        if (this.IsValuesChecked == true) {
            if (sendPayload.package_file.length == 0) {
                alert('Please select Files ');
                this.showLoader = false;
            } else {
                this.apiservice.updateClaimPageDate(sendPayload, this.data.country).subscribe(
                    (result: any) => {
                        if ((result.response = 'OK')) {
                            let downloadFIle = result;

                            this.showLoader = false;
                            alert('Claim Package Created Successfully');
                            this.dialogRef.close();
                            this.downloadFile(downloadFIle);
                        } else {
                            alert('Claim Package Creation failed');
                            this.dialogRef.close();
                        }
                    },
                    (error) => { }
                );
            }
        } else {
            alert('Please select all the fileds, Date, FileType ');
            this.showLoader = false;
        }
    }
    title;
    uploadFiles;
    downloadFile(data: any) {
        let title = 'my file';
        let blob = new Blob([data], { type: 'application/zip' });
        let filename = this.claimInfo.APP_CLAIM_NUMBER;
        let filecontent = blob;
        fs.saveAs(blob, filename);
        //  var url = window.URL.createObjectURL(fs);
        //  var pwa = window.open(url);
        //  if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        //    alert('Please disable your Pop-up blocker and try again.');
        //  }
    }
}
