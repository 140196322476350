import { Role } from './role';

export class LoginUser {
    id: number;
    username: string;
    name: string;
    // password: string;
    // firstName: string;
    // lastName: string;
    role: Role;
    token?: string;
}
