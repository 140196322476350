import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { apiService } from 'src/app/_services/app.service';
import { Component, OnInit, Output, Input, EventEmitter, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { User } from '../../../_models/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

export interface DialogData {
    requestId: any;
}

@Component({
    selector: 'app-assign-auditor',
    templateUrl: './assign-auditor.component.html',
    styleUrls: ['./assign-auditor.component.css']
})
export class AssignAuditorComponent implements OnInit {
    users: User;
    auditorList: any = [];
    seletedRow: any;
    pageID: any;
    ccCode: any;
    recordID: any;
    sendEditedData: any = {};
    data: any;
    increasedVersion: number;
    username: any;
    showLoader = false;
    multiDataSend = [];

    constructor(private apiservice: apiService, public dialogRef: MatDialogRef<AssignAuditorComponent>, @Inject(MAT_DIALOG_DATA) public datas: DialogData, private formBuilder: FormBuilder, public datepipe: DatePipe, public router: Router, public activatedRoute: ActivatedRoute, private _snackBar: MatSnackBar, public translate: TranslateService) {
        this.users = new User();
        this.users.AUDITOR_ID = '';
        this.users.COMMENTS = '';
    }

    addForm: FormGroup = new FormGroup({
        AUDITOR_ID: new FormControl('', Validators.required),
        COMMENTS: new FormControl('', Validators.required)
    });

    openSnackBar(message: string, action: string) {
        // this._snackBar.open(message, action);
        this._snackBar.open(message, action, {
            duration: 3000,
            verticalPosition: 'top'
        });
    }

    ngOnInit() {
        const prompt = JSON.parse(localStorage.getItem('userinfo'));
        this.username = prompt.username;
        this.SetLang();

        this.seletedRow = this.datas.requestId.data;
        //  this.recordID = this.seletedRow[0].RECORD_ID
        this.pageID = this.datas.requestId.id;
        this.ccCode = this.datas.requestId.countryCode;
        if (this.ccCode === 'MX') {
            this.recordID = this.seletedRow[0].ID;
        } else {
            this.recordID = this.seletedRow[0].RECORD_ID;
        }
        this.getAuditorList();
        this.data = this.seletedRow[0];

        this.addForm = this.formBuilder.group({
            AUDITOR_ID: [''],
            COMMENTS: ['']
        });
    }

    SetLang() {
        let lang = localStorage.getItem('lang');
        if (lang === null) {
            this.translate.setDefaultLang('en');
        } else {
            this.translate.use(lang);
        }
    }

    getAuditorList() {
        this.apiservice.getAuditorList(this.pageID, this.ccCode).subscribe((response) => {
            this.auditorList = response;
            if (this.ccCode === 'MX') {
                let temp;
                for (let i = 0; i < response.length; i++) {
                    temp = {
                        User_Name: response[i].Auditor
                    };
                    this.auditorList[i] = temp;
                }
                this.auditorList.sort();
            } else {
                this.auditorList = response;
            }
        });
    }

    convertStringToNumberValue(data) {
        if (data != '' && data != null && !isNaN(data)) {
            if (typeof data == 'number' ) {
                // check if it is integer
                if (Number.isInteger(data)) {
                    return data;
                } else {
                    return data;
                }
            } else {
                const v = parseFloat(data.replace(/,/g, ''));
                return Number(v);
            }
        } else {
            return null;
        }
    }

    convertStringtoDateValue(dateValue) {
        if (dateValue != '' && dateValue != null) {
            const dateArray = dateValue.split('-');
            const dateString = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
            // const dateObj = new Date(dateString)
            const dateValString = moment(dateString).format('YYYY-MM-DD');
            return dateValString;
        } else {
            return null;
        }
    }

    convertStringtoDateValueForVolumeAllowance(date) {
        if (date != '' && date != null) {
            /* new Date('21-03-1998') returns an invalid date as it will work on ISO formats */
            const dateObj = new Date(date.toString().split('-'));
            return moment(dateObj).format('YYYY-MM-DD');
        } else {
            return null;
        }
    }

    // removeTheComma(x) {
    //   if (x != null) {
    //     if (x.indexOf(',') > -1) {

    //       return x.replace(',', '');
    //     }
    //     else{
    //       return x
    //     }
    //   }
    //   else {
    //     return x=0
    //   }
    // }

    convertDateToSTring(dateValue) {
        if (dateValue != null) {
            const dateArray = dateValue.toString().split('-');
            const dateString = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
            // const dateObj = new Date(dateString)
            const dateValString = moment(dateString).format('YYYY-MM-DD');

            return dateValString;
        } else {
            return null;
        }
    }

    dataToSEnd() {
        for (let i = 0; i < this.seletedRow.length; i++) {
            let temp = {};
            this.data = this.seletedRow[i];

            let c: any = {};
            c = this.data;
            this.increasedVersion = Number(this.data.VERSION);
            if (this.ccCode === 'CL') {
                if (this.pageID == 'VOLUMES') {
                    this.sendEditedData = {
                        ACCOUNT_DEPARTMENT: this.data.ACCOUNT_DEPARTMENT,
                        AGREEMENT_NUMBER: this.data.AGREEMENT_NUMBER,
                        ALLOW_TYPE: this.data.ALLOW_TYPE,
                        AUDITOR_ID: this.users.AUDITOR_ID,
                        CATEGORY: this.data.CATEGORY,
                        COMMENTS: this.users.COMMENTS,
                        DATE_AUDITED: this.data.DATE_AUDITED,
                        DEAL_AGREEMENT_NUM: this.data.DEAL_AGREEMENT_NUM,
                        END_DATE: this.data.END_DATE,
                        FIXED_AMOUNT_AGREEMENT: this.convertStringToNumberValue(this.data.FIXED_AMOUNT_AGREEMENT),
                        LEGACY_CATEGORY: this.data.LEGACY_CATEGORY,
                        PERCENTAGE: this.data.PERCENTAGE,
                        PRIVATE_LABEL: this.data.PRIVATE_LABEL,
                        QR_STATUS: this.data.QR_STATUS,
                        RELEASE_NUM: this.data.RELEASE_NUM,
                        RUT: this.data.RUT,
                        START_DATE: this.data.START_DATE,
                        SUPPLIER_NAME: this.data.SUPPLIER_NAME,
                        SUPPLIER_NUM: this.data.SUPPLIER_NUM,
                        RECORD_ID: this.data.RECORD_ID,
                        AMOUNT_RECEIVED: this.convertStringToNumberValue(this.data.AMOUNT_RECEIVED),
                        APP_CLAIM_NUMBER: this.data.APP_CLAIM_NUMBER,
                        AUDIT_YEAR: this.data.AUDIT_YEAR,
                        CHANGE_USER: this.data.CHANGE_USER,
                        DEBITED_AMOUNT: this.convertStringToNumberValue(this.data.DEBITED_AMOUNT),
                        EARNED: this.convertStringToNumberValue(this.data.EARNED),
                        FORMAT_TYPE: this.data.FORMAT_TYPE,
                        VOLUME: this.convertStringToNumberValue(this.data.VOLUME),
                        YEAR_VOLUME: this.convertStringToNumberValue(this.data.YEAR_VOLUME),
                        // "VERSION": this.increasedVersion + 1,
                        CREATED_BY: this.data.CREATED_BY,
                        CREATED_DATE: this.data.CREATED_DATE,
                        LAST_UPDATED_BY: this.username
                    };
                }
                if (this.pageID == 'NEW-STORE') {
                    this.sendEditedData = {
                        RECORD_ID: this.data.RECORD_ID,
                        RELEASE_NUM: this.data.RELEASE_NUM,
                        CHANGE_USER: this.data.CHANGE_USER,
                        AUDIT_YEAR: this.data.AUDIT_YEAR,
                        RELEASES: this.data.RELEASES,
                        RUT: this.data.RUT,
                        SUPPLIER_NAME: this.data.SUPPLIER_NAME,
                        SUPPLIER_NUM: this.data.SUPPLIER_NUM,
                        DEPT: this.data.DEPT,
                        LEGACY_CATEGORY: this.data.LEGACY_CATEGORY,
                        CATEGORY: this.data.CATEGORY,
                        MIN_SALES: this.data.MIN_SALES,
                        MAX_SALES: this.data.MAX_SALES,
                        TOTAL_SALES: this.convertStringToNumberValue(this.data.TOTAL_SALES),
                        STORE_NBR: this.data.STORE_NBR,
                        TYPE: this.data.TYPE,
                        OPEN_DATE: this.data.OPEN_DATE,
                        FORMAT: this.data.FORMAT,
                        FIXED_AMT: this.convertStringToNumberValue(this.data.FIXED_AMT),
                        UFAMT: this.convertStringToNumberValue(this.data.UFAMT),
                        TOTAL_DUE: this.convertStringToNumberValue(this.data.TOTAL_DUE),
                        AMT_DEDUCTED: this.convertStringToNumberValue(this.data.AMT_DEDUCTED),
                        OTHER_DEDUCTION: this.convertStringToNumberValue(this.data.OTHER_DEDUCTION),
                        AMOUNT_DUE: this.convertStringToNumberValue(this.data.AMOUNT_DUE),
                        AUDITOR_ID: this.users.AUDITOR_ID,
                        QR_STATUS: this.data.QR_STATUS,
                        COMPLETED_DATE: this.data.COMPLETED_DATE,
                        APP_CLAIM_NUMBER: this.data.APP_CLAIM_NUMBER,
                        COMMENTS: this.users.COMMENTS,
                        CITY: this.data.CITY,
                        AREA: this.data.AREA,
                        ADDRESS: this.data.ADDRESS,
                        CREATED_BY: this.data.CREATED_BY,
                        // "VERSION": VERSION + 1,
                        CREATED_DATE: this.data.CREATED_DATE,
                        LAST_UPDATED_BY: this.username
                    };
                }

                if (this.pageID == 'UNDED-COOPS') {
                    this.sendEditedData = {
                        RECORD_ID: this.data.RECORD_ID,
                        RELEASE_NUM: this.data.RELEASE_NUM,
                        AUDIT_YEAR: this.data.AUDIT_YEAR,
                        RELEASES: this.data.RELEASES,
                        RUT: this.data.RUT,
                        VENDOR_NBR: this.data.VENDOR_NBR, //
                        SUPPLIER_NAME: this.data.SUPPLIER_NAME,
                        ALLOW_TYPE: this.data.ALLOW_TYPE, //
                        DEPT: this.data.DEPT,
                        SEQUENCING: this.data.SEQUENCING,
                        LEGACY_CATEGORY: this.data.LEGACY_CATEGORY,
                        CATEGORY: this.data.CATEGORY,
                        COMPANY: this.data.COMPANY,
                        AGREEMENT_NUMBER: this.data.AGREEMENT_NUMBER,
                        STATUS: this.data.STATUS,

                        AMOUNT: this.convertStringToNumberValue(this.data.AMOUNT),
                        AMT_FOR_THE_PERIOD: this.convertStringToNumberValue(this.data.AMT_FOR_THE_PERIO),
                        CREATION_DATE: this.data.CREATION_DATE,
                        POSTING_DATE: this.data.POSTING_DATE,
                        AMOUNT_DEDUCTED: this.convertStringToNumberValue(this.data.AMOUNT_DEDUCTED),
                        INITIAL_DATE: this.data.INITIAL_DATE,
                        FINAL_DATE: this.data.FINAL_DATE,
                        ASSIGNED_USERNAME: this.users.AUDITOR_ID,
                        DATE_AUDITED: this.data.CREATED_DATE,
                        QR_STATUS: this.data.QR_STATUS,
                        NOTES: this.data.NOTES,
                        APP_CLAIM_NUMBER: this.data.APP_CLAIM_NUMBER,
                        BUYER: this.data.BUYER,
                        COMMENTS: this.users.COMMENTS,
                        COMMENTS2: this.users.COMMENTS,
                        CREATION_USER: this.data.CREATION_USER,
                        // "VERSION": this.increasedVersion + 1,
                        CREATED_DATE: this.data.CREATED_DATE,
                        CREATED_BY: this.data.CREATED_BY,
                        LAST_UPDATED_BY: this.username
                        //  "AUDITOR_ID": this.users.AUDITOR_ID,
                    };
                }
                if (this.pageID == 'MISC') {
                    this.sendEditedData = {
                        RECORD_ID: this.data.RECORD_ID,
                        CHANGE_USER: this.data.CHANGE_USER,
                        RELEASE_NUM: this.data.RELEASE_NUM,
                        AUDIT_YEAR: this.data.AUDIT_YEAR,
                        LEGACY_CATEGORY: this.data.LEGACY_CATEGORY,
                        RUT: this.data.RUT,
                        SUPPLIER_NUM: this.data.SUPPLIER_NUM,
                        SUPPLIER_NAME: this.data.SUPPLIER_NAME,
                        DEPT: this.data.DEPT,
                        NEW_CATEGORY: this.data.NEW_CATEGORY,
                        FORMAT_TYPE: this.data.FORMAT_TYPE,
                        ALLOW_TYPE: this.data.ALLOW_TYPE,
                        AMOUNT_DUE: this.data.AMOUNT_DUE,
                        TOTAL_DEDUCTION: this.convertStringToNumberValue(this.data.TOTAL_DEDUCTION),
                        PENDING_DUE: this.convertStringToNumberValue(this.data.PENDING_DUE),
                        IVA_DUE: this.convertStringToNumberValue(this.data.IVA_DUE),
                        TOTAL_PENDING: this.convertStringToNumberValue(this.data.TOTAL_PENDING),
                        AUDITOR_ID: this.users.AUDITOR_ID,

                        AUDITOR_STATUS: this.data.AUDITOR_STATUS,
                        AGREEMENT_NUM: this.data.AGREEMENT_NUM,
                        INSERTED: this.data.INSERTED,
                        NOTES: this.users.COMMENTS,
                        APP_CLAIM_NUMBER: this.data.APP_CLAIM_NUMBER,

                        CREATED_BY: this.data.CREATED_BY,
                        LAST_UPDATED_BY: this.username
                    };
                    if (this.ccCode === 'MX') {
                        (this.sendEditedData.REVISION_DATE = this.data.REVISION_DATE), (this.sendEditedData.CREATED_DATE = this.data.CREATED_DATE), (this.sendEditedData.IEPS_PCT = this.data.IEPS_PCT);
                        this.sendEditedData.IEPS_DUE = this.data.IEPS_DUE;
                        this.sendEditedData.IVA_PCT = this.data.IVA_PCT;
                        this.sendEditedData.LAST_UPDATED_DATE = this.data.CREATED_DATE;
                    } else {
                        (this.sendEditedData.REVISION_DATE = this.data.REVISION_DATE), (this.sendEditedData.CREATED_DATE = this.data.CREATED_DATE);
                        this.sendEditedData.LAST_UPDATED_DATE = null;
                    }
                }
            } else {
                if (this.pageID === 'Scan Allowances') {
                    this.sendEditedData = {
                        AUDITOR: this.users.AUDITOR_ID,
                        ALLOW_EARNED: this.data.ALLOW_EARNED,
                        AMS_QTY: this.data.AMS_QTY,
                        AMS_RATE: this.data.AMS_RATE,
                        AMS_SALES_AMT: this.data.AMS_SALES_AMT,
                        AMT_DUE: this.data.AMT_DUE,
                        AUDIT_YEAR: this.data.AUDIT_YEAR,
                        ACCRUAL_AMT: this.data.Accrual_AMT,
                        ADJUSTMENTS: this.data.Adjustments,
                        AGREEMENT_NBR: this.data.Agreement_Nbr,
                        AGREEMENT_REQUEST_NBR: this.data.Agreement_Request_Nbr,
                        AGREEMENT_REQUEST_STATUS: this.data.Agreement_Request_Status,
                        COOP_NUMBER: this.data.COOP_Number,
                        CLAIM_NUMBER: this.data.Claim_Number,
                        COMMENTS: this.users.COMMENTS,
                        COMPLETE_DT: this.data.Complete_DT,
                        DIVISION: this.data.DIVISION,
                        DEPARTMENT: this.data.DEPARTMENT,
                        FORMAT: this.data.FORMAT,
                        GROUPS: this.data.GROUPS,
                        ID: this.data.ID,
                        INCLUDE: 'Y',
                        IEPS_DUE: this.data.IEPS_DUE,
                        IEPS_PCT: this.data.IEPS_PCT,
                        ITEM_DESC: this.data.ITEM_DESC,
                        IVA_DUE: this.data.IVA_DUE,
                        IVA_PCT: this.data.IVA_PCT,
                        ITEM_NBR: this.data.Item_Nbr,
                        POS_BANNER_CODE: this.data.POS_BANNER_CODE,
                        POS_SALES_AMT: this.data.POS_SALES_AMT,
                        POS_SALES_QTY: this.data.POS_SALES_QTY,
                        REASON_CODE : this.data.REASON_CODE,
                        RECORD_ID: this.data.RECORD_ID,
                        RFC: this.data.RFC,
                        SAP_VENDOR: this.data.SAP_VENDOR,
                        SEQ_NO: this.data.SEQ_NO,
                        STATUSCODE: this.data.StatusCode,
                        UPC_NBR: this.data.UPC_NBR,
                        VALID_FROM: this.data.Valid_From,
                        VALID_TO: this.data.Valid_To,
                        VENDOR_NAME: this.data.VENDOR_NAME,
                        VENDOR_NBR: this.data.VENDOR_NBR,
                        USR_CHANGE: this.data.USR_CHANGE,
                        ALL_BODEGA_POS_QTY: this.data.ALL_BODEGA_POS_QTY,
                        ALL_BODEGA_POS_AMT: this.data.ALL_BODEGA_POS_AMT,
                        VERSION: Number(this.data.VERSION) + 1
                    };
                }
                if (this.pageID === 'Markdown') {
                    this.sendEditedData = {
                        VENDOR_NBR: this.data.VENDOR_NBR,
                        VNDR_NAME: this.data.VNDR_NAME,
                        EFFECTIVE_DATE: this.data.EFFECTIVE_DATE,
                        END_DATE: this.data.END_DATE,
                        DEPT: this.data.DEPT,
                        CAT: this.data.CAT,
                        ITEM_NUM: this.data.ITEM_NUM,
                        ITEM1_DESC: this.data.ITEM1_DESC,
                        BANNER_DESC: this.data.BANNER_DESC,
                        POS_SALES: this.data.POS_SALES,
                        POS_QTY: this.data.POS_QTY,
                        IMPACT_MU_MD: this.data.IMPACT_MU_MD,
                        UNIT_RETAIL: this.data.UNIT_RETAIL,
                        AVG_UNIT_SALE_PRICE: this.data.AVG_UNIT_SALE_PRICE,
                        UNIT_COST: this.data.UNIT_COST,
                        COMMERCIAL_MARGE: this.data.COMMERCIAL_MARGE,
                        REAL_MARGE: this.data.REAL_MARGE,
                        MARGE_DEVIATION: this.data.MARGE_DEVIATION,
                        AMS_AGREEMENT: this.data.AMS_AGREEMENT,
                        AGREEMENT_AMOUNT: this.data.AGREEMENT_AMOUNT,
                        DIFFERENCE: this.data.DIFFERENCE,
                        MD_STATUS: this.data.MD_STATUS,
                        MD_ROOTCAUSE: this.data.MD_ROOTCAUSE,
                        MARKDOWN_TYPE: this.data.MARKDOWN_TYPE,
                        EMAIL_SUPPORT: this.data.EMAIL_SUPPORT,
                        AUDITOR_NAME: this.data.AUDITOR_NAME,
                        AUDITOR_COMMENTS: this.data.AUDITOR_COMMENTS,
                        DUE_DATE: this.data.DUE_DATE,
                        CLAIM_NUMBER: this.data.CLAIM_NUMBER,
                        SELECT_ROW: this.data.SELECT_ROW,
                        VAT: this.data.VAT,
                        IEPS: this.data.IEPS,
                        IVA_DUE : this.data.IVA_DUE,
                        IEPS_DUE : this.data.IEPS_DUE,
                        TOTAL_DUE : this.data.TOTAL_DUE,
                        UNIT_ALLOWANCE: this.data.UNIT_ALLOWANCE,
                        EVENT_DESCRIPTION: this.data.EVENT_DESCRIPTION,
                        RECORD_ID: this.data.RECORD_ID,
                        INCLUDE: 'Y',
                        VERSION: Number(this.data.VERSION) + 1
                        
                    };
                }
                if (this.pageID === 'New Stores') {
                    this.sendEditedData = {
                        ALLOW_EARNED: this.convertStringToNumberValue(this.data.ALLOW_EARNED),
                        AMTDEDUCTED: this.convertStringToNumberValue(this.data.AMTDEDUCTED),
                        AMT_DUE: this.convertStringToNumberValue(this.data.AMT_DUE),
                        AUDITOR: this.users.AUDITOR_ID,
                        AUDITOR_COMMENTS: this.users.COMMENTS,
                        AUDIT_YEAR: this.data.AUDIT_YEAR,
                        CLAIM_NUMBER: this.data.Claim_Number,
                        CLEARING_DOC: this.data.CLEARING_DOC,
                        CLEAR_DATE: this.data.CLEAR_DATE,
                        COMPLETE_DATE: this.data.COMPLETE_DATE,
                        COOP_DEDUCTED: this.data.COOP_DEDUCTED,
                        COOP_NUMBER: this.data.COOP_NUMBER,
                        DEDUCT_TYPE: this.data.DEDUCT_TYPE,
                        DOC_NBR: this.data.DOC_NBR,
                        DEPARTMENT: this.data.DEPARTMENT,
                        FORMAT: this.data.FORMAT,
                        GO_DATE: this.data.GO_DATE,
                        IEPS: this.convertStringToNumberValue(this.data.IEPS),
                        IEPS_PCT: this.convertStringToNumberValue(this.data.IEPS_PCT),
                        INCLUDE: 'Y',
                        INSERT_USER: this.data.INSERT_USER,
                        INS_ROW: this.data.INS_ROW,
                        INV_DATE: this.data.INV_DATE,
                        IVA: this.convertStringToNumberValue(this.data.IVA),
                        IVA_PCT: this.convertStringToNumberValue(this.data.IVA_PCT),
                        RECORD_ID: this.data.RECORD_ID,
                        RFC: this.data.RFC,
                        SAM_FLAG: this.data.SAM_FLAG,
                        SAP_NUMBER: this.data.SAP_NUMBER,
                        SEQ_NO: this.data.SEQ_NO,
                        SHIP_CHK: this.data.SHIP_CHK,
                        STATUS: this.data.STATUS,
                        STORE: this.data.STORE,
                        STORE_NAME: this.data.STORE_NAME,
                        TEXT: this.data.TEXT,
                        TOTAL_SHIPS: this.data.TOTAL_SHIPS,
                        USRCHG: this.data.USRCHG,
                        VENDOR_NAME: this.data.VENDOR_NAME,
                        VENDOR_NBR: this.data.VENDOR_NBR,
                        VERSION: Number(this.data.version) + 1
                    };
                }

                if (this.pageID === 'Volume Allowances') {
                    this.sendEditedData = {
                        ADJUSTMENTS: this.data.ADJUSTMENTS,
                        ALLOW_EARNED: this.convertStringToNumberValue(this.data.ALLOW_EARNED),
                        ALL_ITEMS: this.data.ALL_ITEMS,
                        AMS_PURCHASE: this.data.AMS_PURCHASE,
                        AMT_DUE: this.convertStringToNumberValue(this.data.AMT_DUE),
                        AUDITOR: this.users.AUDITOR_ID,
                        AUDITOR_COMMENTS: this.users.COMMENTS,
                        AUDIT_YEAR: this.data.AUDIT_YEAR,
                        CATCH_RATE: this.data.CATCH_RATE,
                        COMPLETE_DATE: this.data.COMPLETE_DATE,
                        CLAIM_NUMBER: this.data.Claim_Number,
                        DEAL: this.data.DEAL,
                        DEPARTMENT: this.convertStringToNumberValue(this.data.DEPARTMENT),
                        END_DATE: this.data.END_DATE,
                        ENTERED_AMT: this.data.ENTERED_AMT,
                        FORMAT: this.data.FORMAT,
                        GROUP: this.convertStringToNumberValue(this.data.GROUP),
                        IEPS: this.data.IEPS,
                        IEPS_PCT: this.data.IEPS_PCT,
                        INSERT_USER: this.data.INSERT_USER,
                        INS_ROW: this.data.INS_ROW,
                        IVA: this.data.IVA,
                        IVA_PCT: this.data.IVA_PCT,
                        OTHER_DED: this.data.OTHER_DED,
                        PAST_CLAIM_NUMBERS: this.data.PAST_CLAIM_NUMBERS,
                        PCT: this.data.PCT,
                        PO_PURCHASES: this.data.PO_PURCHASES,
                        PO_SOURCE: this.data.PO_SOURCE,
                        PREVIOUS_CLAIMS: this.data.PREVIOUS_CLAIMS,
                        RECORD_ID: this.convertStringToNumberValue(this.data.RECORD_ID),
                        RFC: this.data.RFC,
                        SAP_VENDOR: this.convertStringToNumberValue(this.data.SAP_VENDOR),
                        SEQ_NO: this.data.SEQ_NO,
                        START_DATE: this.data.START_DATE,
                        STATUS: this.data.STATUS,
                        TAX_CD: this.data.TAX_CD,
                        TIER: this.convertStringToNumberValue(this.data.TIER),
                        TOTAL_DUE: this.convertStringToNumberValue(this.data.TOTAL_DUE),
                        USR_CHANGE: this.data.USR_CHANGE,
                        VENDOR_NAME: this.data.VENDOR_NAME,
                        VENDOR_NBR: this.convertStringToNumberValue(this.data.VENDOR_NBR),
                        INCLUDE: 'Y',
                        VERSION: Number(this.data.version) + 1
                    };
                }
                if (this.pageID === 'UN-DEDUCTED COOPS') {
                    this.sendEditedData = {
                        USRCHG: this.data.USRCHG,
                        REL: this.data.REL,
                        AUDIT_YEAR: this.data.AUDIT_YEAR,
                        ACTION: this.data.ACTION,
                        VENDOR_NBR: this.data.VENDOR_NBR,
                        SAP_VENDOR: this.data.SAP_VENDOR,
                        VENDOR_NAME: this.data.VENDOR_NAME,
                        AGREEMENT_REQUEST_NBR: this.data.Agreement_Request_Nbr,
                        COOP_NBR: this.data.COOP_NBR,
                        RSN_CDE: this.data.RSN_CDE,
                        COOP_TYPE: this.data.COOP_TYPE,
                        COOP_STATUS: this.data.COOP_STATUS,
                        SEQ_NO: this.data.SEQ_NO,
                        FORMAT: this.data.FORMAT,
                        EFFECT_DATE: this.data.EFFECT_DATE,
                        END_DATE: this.data.END_DATE,
                        DEPARTMENT: this.data.DEPARTMENT,
                        DIV: this.data.DIV,
                        BASE_COOP_AMT: this.data.BASE_COOP_AMT,
                        BASE_AMT_DEDUCT: this.data.BASE_AMT_DEDUCT,
                        ADJUSTMENTS: this.data.ADJUSTMENTS,
                        BASE_AMT_DUE: this.data.BASE_AMT_DUE,
                        IVA_DUE: this.data.IVA_DUE,
                        IEPS_DUE: this.data.IEPS_DUE,
                        AMT_DEDUCT_TAXES: this.data.AMT_DEDUCT_TAXES,
                        IVA_PCT: this.data.IVA_PCT,
                        IEPS_PCT: this.data.IEPS_PCT,
                        SAP_DOC_NBR: this.data.SAP_DOC_NBR,
                        CREATE_DTE: this.data.CREATE_DTE,
                        CLEARING_DT: this.data.CLEARING_DT,
                        REJECT_REASON: this.data.REJECT_REASON,
                        RFC: this.data.RFC,
                        TAX_CODE: this.data.TAX_CODE,
                        AUDITOR: this.users.AUDITOR_ID,
                        AUDITOR_COMMENT: this.users.COMMENTS,
                        STATUS: this.data.STATUS,
                        ATTACH: this.data.ATTACH,
                        CLAIM_NUMBER: this.data.Claim_Number,
                        COMPLETE_DT: this.data.COMPLETE_DT,
                        version: this.data.version,
                        RECORD_ID: this.data.RECORD_ID,
                        VERSION: Number(this.data.version) + 1
                    };
                }
                if (this.pageID === 'MISC') {
                    this.sendEditedData = {
                        CHANGE_USER: this.data.CHANGE_USER,
                        RELEASE_NUM: this.data.RELEASE_NUM,
                        SUPPLIER_NAME: this.data.SUPPLIER_NAME,
                        DEPT: this.data.DEPT,
                        FORMAT_TYPE: this.data.FORMAT_TYPE,
                        ALLOW_TYPE: this.data.ALLOW_TYPE,
                        PENDING_DUE: this.convertStringToNumberValue(this.data.PENDING_DUE),
                        TOTAL_PENDING: this.convertStringToNumberValue(this.data.TOTAL_PENDING),
                        AUDITOR_ID: this.data.AUDITOR_ID,
                        AUDITOR_STATUS: this.users.QR_STATUS,
                        AGREEMENT_NUM: this.data.AGREEMENT_NUM,
                        NOTES: this.users.COMMENTS,
                        IEPS_PCT: this.convertStringToNumberValue(this.data.IEPS_PCT),
                        IEPS_DUE: this.convertStringToNumberValue(this.data.IEPS_DUE),
                        IVA_PCT: this.convertStringToNumberValue(this.data.IVA_PCT),
                        IVA_DUE: this.convertStringToNumberValue(this.data.IVA_DUE),
                        AUDIT_YEAR: this.data.AUDIT_YEAR,
                        VENDOR_NUM: this.data.VENDOR_NUM,
                        REVIEW_DATE: this.data.REVIEW_DATE,
                        SAP_NUM: this.data.SAP_NUM,
                        AMOUNT_DEDUCTED: this.convertStringToNumberValue(this.data.AMOUNT_DEDUCTED),
                        EARNED: this.convertStringToNumberValue(this.data.EARNED),
                        RECORD_ID: this.data.RECORD_ID,
                        REVISION_DATE: this.data.REVISION_DATE,
                        INSERTED: this.data.INSERTED,
                        CLAIM_NUMBER: this.data.Claim_Number,
                        CREATED_BY: this.data.CREATED_BY,
                        CREATED_DATE: this.data.CREATED_DATE != '' ? this.data.CREATED_DATE : null,
                        LAST_UPDATED_BY: this.username,
                        LAST_UPDATED_DATE: this.data.CREATED_DATE != '' ? this.data.CREATED_DATE : null,
                        VERSION: Number(this.data.VERSION) + 1
                    };
                }
                if (this.pageID === 'Advertising') {
                    this.sendEditedData = {
                        USRCHG: this.data.USRCHG,
                        AUDIT_YEAR: this.data.AUDIT_YEAR,
                        GROUP: this.data.GROUP,
                        VENDOR_NBR: this.data.VENDOR_NBR,
                        SAP_VENDOR: this.data.SAP_VENDOR,
                        VENDOR_NAME: this.data.VENDOR_NAME,
                        BRAND: this.data.BRAND,
                        DEPT: this.data.DEPT,
                        DESCRIPTION_PAG: this.data.DESCRIPTION_PAG,
                        WEBCONTENT: this.data.WEBCONTENT,
                        DIV: this.data.DIV,
                        NUM_PUBLICATION: this.data.NUM_PUBLICATION,
                        TYPE_COLLECTION: this.data.TYPE_COLLECTION,
                        ALLOW_EARNED: this.convertStringToNumberValue(this.data.ALLOW_EARNED),
                        ENTERED_AMT: this.convertStringToNumberValue(this.data.ENTERED_AMT),
                        OTHER_DEDUCT: this.convertStringToNumberValue(this.data.OTHER_DEDUCT),
                        AMTDUE: this.convertStringToNumberValue(this.data.AMTDUE),
                        IEPS_AMOUNT: this.convertStringToNumberValue(this.data.IEPS_AMOUNT),
                        IVA_AMOUNT: this.convertStringToNumberValue(this.data.IVA_AMOUNT),
                        TOTAL_DUE: this.convertStringToNumberValue(this.data.TOTAL_DUE),
                        SAP_DOC_NBR: this.data.SAP_DOC_NBR,
                        IEPS_PCT: this.convertStringToNumberValue(this.data.IEPS_PCT),
                        IVA_PCT: this.convertStringToNumberValue(this.data.IVA_PCT),
                        COOP_NBR: this.convertStringToNumberValue(this.data.COOP_NBR),
                        CLAIM_NUMBER: this.data.Claim_Number,
                        AUDITOR: this.users.AUDITOR_ID,
                        AUDITOR_COMMENT: this.users.COMMENTS,
                        COMPLETE_DT: this.data.COMPLETE_DT,
                        STATUS: this.data.STATUS,
                        NO_OF_DEDUCTION: this.data.NO_OF_DEDUCTION,
                        INCLUDE_CHECK: this.data.INCLUDE_CHECK,
                        INCLUDE: 'Y',
                        VERSION: Number(this.data.VERSION) + 1,
                        RECORD_ID: this.data.RECORD_ID
                    };
                }
                if (this.pageID == 'Fill Rate') {
                    this.sendEditedData = {
                        USRCHG: this.data.USRCHG,
                        AUDIT_YEAR: this.data.AUDIT_YEAR,
                        GROUP: this.data.GROUP,
                        DIVISION: this.data.DIVISION,
                        VENDOR_NBR: this.data.VENDOR_NBR,
                        VENDOR_NAME: this.data.VENDOR_NAME,
                        SAP_VENDOR: this.data.SAP_VENDOR,
                        DEPARTMENT: this.data.DEPARTMENT,
                        PO_SEQ: this.data.PO_SEQ,
                        FORMAT: this.data.FORMAT,
                        TOTAL_ORD_QTY: this.convertStringToNumberValue(this.data.TOTAL_ORD_QTY),
                        TOTAL_RCV_QTY: this.convertStringToNumberValue(this.data.TOTAL_RCV_QTY),
                        TOTAL_SHORT_QTY: this.convertStringToNumberValue(this.data.TOTAL_SHORT_QTY),
                        FILL_RATE_CALC_PCT: this.convertStringToNumberValue(this.data.FILL_RATE_CALC_PCT),
                        TOTAL_RETAIL_AMT_SHORT: this.convertStringToNumberValue(this.data.TOTAL_RETAIL_AMT_SHORT),
                        TOTAL_MARGIN_LOST: this.convertStringToNumberValue(this.data.TOTAL_MARGIN_LOST),
                        FILL_RATE_PCT: this.convertStringToNumberValue(this.data.FILL_RATE_PCT),
                        TOTAL_EARNED: this.convertStringToNumberValue(this.data.TOTAL_EARNED),
                        TOTAL_AMT_PAID: this.convertStringToNumberValue(this.data.TOTAL_AMT_PAID),
                        TOTAL_PENDING: this.convertStringToNumberValue(this.data.TOTAL_PENDING),
                        IEPS_DUE: this.convertStringToNumberValue(this.data.IEPS_DUE),
                        IVA_DUE: this.convertStringToNumberValue(this.data.IVA_DUE),
                        COOP_NUMBER: this.convertStringToNumberValue(this.data.COOP_NUMBER),
                        IEPS_PCT: this.convertStringToNumberValue(this.data.IEPS_PCT),
                        IVA_PCT: this.convertStringToNumberValue(this.data.IVA_PCT),
                        PREVIOUS_CLAIMS: this.convertStringToNumberValue(this.data.PREVIOUS_CLAIMS),
                        PAST_CLAIM_NUMBERS: this.convertStringToNumberValue(this.data.PAST_CLAIM_NUMBERS),
                        INTERNAL_RATE: this.convertStringToNumberValue(this.data.INTERNAL_RATE),
                        RFC: this.data.RFC,
                        CLAIM_NUMBER: this.data.Claim_Number,
                        AUDITOR: this.users.AUDITOR_ID,
                        COMMENTS: this.users.COMMENTS,
                        COMPLETE_DT: this.data.COMPLETE_DT,
                        STATUSCODE: this.data.STATUSCODE,
                        VERSION: Number(this.data.VERSION) + 1,
                        RECORD_ID: this.data.RECORD_ID,
                        INCLUDE: 'Y'
                    };
                }
                if (this.pageID == 'MSI') {
                    this.sendEditedData = {
                        USR_CHANGE: this.data.USR_CHANGE,
                        AUDIT_YEAR: this.data.AUDIT_YEAR,
                        VENDOR_NAME: this.data.VENDOR_NAME,
                        SAP_VENDOR: this.data.SAP_VENDOR,
                        VENDOR_NBR: this.data.VENDOR_NBR,
                        DEPARTMENT: this.data.DEPARTMENT,
                        SEQ_NO: this.data.SEQ_NO,
                        FORMAT: this.data.FORMAT,
                        UNITS_SOLD: this.convertStringToNumberValue(this.data.UNITS_SOLD),
                        TOTAL_DEFERRED: this.convertStringToNumberValue(this.data.TOTAL_DEFERRED),
                        SURCHARGE_SALES: this.convertStringToNumberValue(this.data.SURCHARGE_SALES),
                        AGREE: this.data.AGREE,
                        DEDUCTION: this.convertStringToNumberValue(this.data.DEDUCTION),
                        DEDUCTION_CIVA: this.convertStringToNumberValue(this.data.DEDUCTION_CIVA),
                        OTHER_DEDUCTIONS: this.convertStringToNumberValue(this.data.OTHER_DEDUCTIONS),
                        AMT_DUE: this.convertStringToNumberValue(this.data.AMT_DUE),
                        MSI_PCT: this.convertStringToNumberValue(this.data.MSI_PCT),
                        IVA: this.convertStringToNumberValue(this.data.IVA),
                        IVA_PCT: this.convertStringToNumberValue(this.data.IVA_PCT),
                        PREVIOUS_CLAIMS: this.convertStringToNumberValue(this.data.PREVIOUS_CLAIMS),
                        PAST_CLAIM_NUMBERS: this.convertStringToNumberValue(this.data.PAST_CLAIM_NUMBERS),
                        AUDITOR: this.users.AUDITOR_ID,
                        COMPLETE_DT: this.data.COMPLETE_DT,
                        STATUS: this.data.STATUS,
                        IEPS_PCT: this.convertStringToNumberValue(this.data.IEPS_PCT),
                        AUDITOR_COMMENTS: this.users.COMMENTS,
                        CLAIM_NUMBER: this.data.Claim_Number,
                        RFC: this.data.RFC,
                        VERSION: Number(this.data.VERSION) + 1,
                        RECORD_ID: this.data.RECORD_ID,
                        INCLUDE: 'Y'
                    };
                }
                if (this.pageID == 'Low Price') {
                    this.sendEditedData = {
                        USRCHG : this.data.USRCHG,
                        AUDIT_YEAR : this.data.AUDIT_YEAR,
                        RELEASE : this.data.RELEASE,
                        VENDOR_NBR : this.data.VENDOR_NBR,
                        VENDOR_NAME : this.data.VENDOR_NAME,
                        SAP_VENDOR : this.data.SAP_VENDOR,
                        DEPT : this.data.DEPT,
                        SEQ : this.data.SEQ,
                        PO_TYPE : this.data.PO_TYPE,
                        PO_EVENT : this.data.PO_EVENT,
                        PO_NBR : this.data.PO_NBR,
                        PO_DATE : this.data.PO_DATE,
                        INV_NBR : this.data.INV_NBR,
                        INV_DATE : this.data.INV_DATE,
                        INV_TOTAL_COST : this.data.INV_TOTAL_COST,
                        PO_TOTAL_COST : this.data.PO_TOTAL_COST,
                        RCVD_TOTAL_COST : this.data.RCVD_TOTAL_COST,
                        COST_PAID_TOTAL : this.data.COST_PAID_TOTAL,
                        SB_TOTAL_COST : this.data.SB_TOTAL_COST,
                        INV_COST_DUE : this.data.INV_COST_DUE,
                        IEPS_COST_DUE : this.data.IEPS_COST_DUE,
                        IVA_COST_DUE : this.data.IVA_COST_DUE,
                        TOTAL_COST_DUE : this.data.TOTAL_COST_DUE,
                        AP_TOT_AMT : this.data.AP_TOT_AMT,
                        COST_PAID_TOT_AMT : this.data.COST_PAID_TOT_AMT,
                        COST_PAID_TOT_DIFF : this.data.COST_PAID_TOT_DIFF,
                        TOTAL_SHORT_DUE : this.data.TOTAL_SHORT_DUE,
                        INV_TOTAL_AMT : this.data.INV_TOTAL_AMT,
                        PO_TOT_AMT : this.data.PO_TOT_AMT,
                        RCVD_TOT_AMT : this.data.RCVD_TOT_AMT,
                        CLAIM_NUMBER : this.data.CLAIM_NUMBER,
                        UUID : this.data.UUID,
                        RFC : this.data.RFC,
                        CLEARING_DT : this.data.CLEARING_DT,
                        CHECK_NBR : this.data.CHECK_NBR,
                        AUDITOR : this.users.AUDITOR_ID,
                        COMPLETE_DT : this.data.COMPLETE_DT,
                        STATUS : this.data.STATUS,
                        COMMENTS : this.users.COMMENTS,
                        PREVIOUS_CLAIMS : this.data.PREVIOUS_CLAIMS,
                        PAST_CLAIM_NUMBERS : this.data.PAST_CLAIM_NUMBERS,
                        CATCH_RATE : this.data.CATCH_RATE,
                        VERSION: Number(this.data.VERSION) + 1,
                        RECORD_ID: this.data.RECORD_ID,
                        INCLUDE: 'Y'                    
                    }
                }  else if (this.pageID === 'Shortages') {
                    this.sendEditedData = {
                        USRCHG : this.data.USRCHG,
                        AUDIT_YEAR : this.data.AUDIT_YEAR,
                        RELEASE : this.data.RELEASE,
                        VENDOR_NBR : this.data.VENDOR_NBR,
                        VENDOR_NAME : this.data.VENDOR_NAME,
                        SAP_VENDOR : this.data.SAP_VENDOR,
                        DEPT : this.data.DEPT,
                        SEQ : this.data.SEQ,
                        PO_TYPE : this.data.PO_TYPE,
                        PO_EVENT : this.data.PO_EVENT,
                        PO_NBR : this.data.PO_NBR,
                        PO_DATE : this.data.PO_DATE,
                        INV_NBR : this.data.INV_NBR,
                        INV_DATE : this.data.INV_DATE,
                        EQUIV_EACH_INV : this.data.EQUIV_EACH_INV,
                        ZERO_RCVD_CNT : this.data.ZERO_RCVD_CNT,
                        EQUIVEACHRCVD : this.data.EQUIVEACHRCVD,
                        QTY_SHORT : this.data.QTY_SHORT,
                        SHORT_DUE : this.data.SHORT_DUE,
                        IEPS_SHORT_DUE : this.data.IEPS_SHORT_DUE,
                        IVA_SHORT_DUE : this.data.IVA_SHORT_DUE,
                        TOTAL_SHORT_DUE : this.data.TOTAL_SHORT_DUE,
                        COST_PAID_TOT_DIFF : this.data.COST_PAID_TOT_DIFF,
                        NETSHORT : this.data.NETSHORT,
                        AP_TOT_AMT : this.data.AP_TOT_AMT,
                        TOT_COST_PAID_AMT : this.data.TOT_COST_PAID_AMT,
                        CLAIM_NUMBER : this.data.CLAIM_NUMBER,
                        UUID : this.data.UUID,
                        RFC : this.data.RFC,
                        CLEARING_DT : this.data.CLEARING_DT,
                        CHECK_NBR : this.data.CHECK_NBR,
                        AUDITOR : this.users.AUDITOR_ID,
                        STATUS : this.data.STATUS,
                        COMMENTS : this.users.COMMENTS,
                        COMPLETE_DT : this.data.COMPLETE_DT,
                        PREVIOUS_CLAIMS : this.data.PREVIOUS_CLAIMS,
                        PAST_CLAIM_NUMBERS : this.data.PAST_CLAIM_NUMBERS,
                        CATCH_RATE : this.data.CATCH_RATE,
                        VERSION : Number(this.data.VERSION) + 1,
                        RECORD_ID : this.data.RECORD_ID,
                        INCLUDE : 'Y', 
                    }
                }
            }

            //  this.multiDataSend.push(this.sendEditedData);
            this.multiDataSend[i] = this.sendEditedData;
        }
    }

    onSubmit() {
        let myDate = new Date();

        let someDateVar = this.datepipe.transform(myDate, 'yyyy-mm-dd');
        this.increasedVersion = parseInt(this.data.VERSION);
        this.dataToSEnd();
        if (confirm('Are you sure you want to assign Auditor for selected claims?')) {
            this.showLoader = true;
            if (this.ccCode == 'CL') {
                this.apiservice.bulkDataUpload(this.multiDataSend, this.pageID, this.ccCode).subscribe(
                    (response) => {
                        this.auditorList = response;
                        this.openSnackBar('Assigned Auditor successfully', 'Ok');
                        this.dialogRef.close();
                        this.router.navigate(['assign/' + this.ccCode + '/' + this.pageID]);
                        this.Refresh();
                    },
                    (error) => {
                        this.showLoader = false;
                        this.openSnackBar('There was an error ' + error, 'Ok');
                    }
                );
            }
            // else {
            //   this.apiservice.bulkDataUpload(this.multiDataSend, this.pageID, this.ccCode).subscribe(response => {
            //     this.auditorList = response;
            //     this.openSnackBar("Chnaged the status successfully", "Ok")
            //     this.dialogRef.close();
            //     this.router.navigate(['managePR/' + this.ccCode + "/" + this.pageID]);
            //     this.Refresh();
            //   })
            // }
            else {
                // this.multiDataSend.forEach(function(item){
                //   let valid_from = item["VALID_FROM"].split("-").reverse().join("-");
                //   let valid_to = item["VALID_TO"].split("-").reverse().join("-");
                //   item["VALID_FROM"] = valid_from;
                //   item["VALID_TO"] = valid_to;
                // })

                this.apiservice.bulkDataUpload(this.multiDataSend, this.pageID, this.ccCode).subscribe(
                    (response) => {
                        this.auditorList = response;
                        this.openSnackBar('Assigned Auditor successfully', 'Ok');
                        this.showLoader = false;
                        this.dialogRef.close();
                        this.router.navigate(['assign/' + this.ccCode + '/' + this.pageID]);
                        this.Refresh();
                    },
                    (error) => {
                        this.showLoader = false;
                        this.openSnackBar('There was an error ' + error, 'Ok');
                    }
                );
            }
        }
    }

    Refresh() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./assign/' + this.ccCode + '/' + this.pageID], {
            relativeTo: this.activatedRoute
        });
    }
}
