export class User {
    username: string;
    password: string;
    country: string;
    AUDITOR_ID: any;
    COMMENTS: any;
    QR_STATUS: any;
}
export interface IBreadCrums {
    label: string;
    url: string;
}
export interface IUrlTitle {
    url: string;
    title: string;
    breadCrums?: IBreadCrums[];
}
