import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import _ from 'lodash';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-pdf-view',
    templateUrl: './pdf-view.component.html',
    styleUrls: ['./pdf-view.component.scss']
})
export class PdfViewComponent implements OnInit {
    domain = environment.ppa;
    fileUrl: any;
    fileDetails: any;
    selectedFile: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _sanitizationService: DomSanitizer) { }

    ngOnInit(): void {
        if (this.data != undefined && this.data.length > 0) {
            this.selectedFile = this.data[0];
            console.log('this.selectedFile', this.selectedFile.file);
            this.fileUrl = this._sanitizationService.bypassSecurityTrustResourceUrl(this.domain + '/FilePreview/' + this.selectedFile.file_id);
        }
    }

    selectedValue() {
        this.fileUrl = this._sanitizationService.bypassSecurityTrustResourceUrl(this.domain + '/FilePreview/' + this.selectedFile.file_id);
    }
}
