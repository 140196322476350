import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { filter, distinctUntilChanged, map, subscribeOn } from 'rxjs/operators';

@Component({
    selector: 'app-breadcrums',
    templateUrl: './breadcrums.component.html',
    styleUrls: ['./breadcrums.component.css']
})
export class BreadcrumsComponent implements OnInit {
    breadCrumbLabel = [];
    country;

    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.breadCrumbLabel = this.route.snapshot.data['breadcrumb'];
        this.country = this.route.snapshot.params['CL'];
    }
    backToHome() {
        this.router.navigate(['/dashboard/' + this.country]);
    }
}
