import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EditDetails } from 'src/app/_models/editDetails';
import { apiService } from 'src/app/_services/app.service';

export interface DialogData {
    requestId: any;
}

@Component({
    selector: 'app-bulk-upload',
    templateUrl: './bulk-upload.component.html',
    styleUrls: ['./bulk-upload.component.css']
})
export class BulkUPloadComponent implements OnInit {
    auditorList: any;
    uploadeData: any;
    bulkUpload: any;
    pageID: any;
    ccCode: any;
    currentValue: any;
    data: any;
    multiDataSend = [];
    auditYear = '';
    username: any;
    showLoader = false;

    constructor(public dialogRef: MatDialogRef<BulkUPloadComponent>, @Inject(MAT_DIALOG_DATA) public datas: DialogData, public router: Router, public activatedRoute: ActivatedRoute, public translate: TranslateService, private apiservice: apiService) {
        this.bulkUpload = new EditDetails();

        this.bulkUpload.Include = '';
    }

    addForm: FormGroup = new FormGroup({
        Include: new FormControl('', Validators.required)
    });

    ngOnInit() {
        const prompt = JSON.parse(localStorage.getItem('userinfo'));
        this.username = prompt.username;
        this.uploadeData = this.datas.requestId.data;
        this.pageID = this.datas.requestId.id;
        this.ccCode = this.datas.requestId.countryCode;
        this.auditYear = this.datas.requestId.auditYear;
        this.currentValue = this.uploadeData[0].Include;

        this.SetLang();
    }

    sendEditedData: any;

    onSubmit() {
        this.showLoader = true;
        this.bulkUpload.id = this.uploadeData[0].ID;
        // this.bulkUpload.id = '0xF09F9C0001000000'
        console.log("uploadeData", this.uploadeData)
        for (let i = 0; i < this.uploadeData.length; i++) {
            this.data = this.uploadeData[i];
            if (this.pageID == 'PPA_CL_COOPS_POS_T') {
                this.sendEditedData = {
                    Include: this.bulkUpload.Include,
                    ID: this.uploadeData[i].ID,
                    LastUpdatedBy: this.username,
                    YearMM: this.uploadeData[i].YearMM
                };
            } else if (this.pageID == 'PPA_SCAN_REBATE_DEDUCTIONS') {
                this.sendEditedData = {
                    Include: this.bulkUpload.Include,
                    ID: this.uploadeData[i].ID,
                    LastUpdatedBy: this.username,
                    YEAR_MONTH: this.uploadeData[i].YEAR_MONTH
                };
            } else {
                this.sendEditedData = {
                    Include: this.bulkUpload.Include,
                    ID: this.uploadeData[i].ID,
                    LastUpdatedBy: this.username
                };
            }

            this.multiDataSend.push(this.sendEditedData);
        }

        if (confirm(' Do you want to Submit the changes?')) {
            this.apiservice.sendBulkUpdate(this.multiDataSend, this.pageID, this.auditYear, this.ccCode).subscribe(
                (result: any) => {
                    alert('data updated sucessfully');
                    this.showLoader = false;
                    this.dialogRef.close();
                    //   this.router.navigate(['/pages/data/' + this.ccCode + '/' + this.pageID]);
                    //    this.Refresh();
                },
                (error) => { }
            );
        } else {
            this.showLoader = false;
        }
    }

    Refresh() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/pages/data/' + this.ccCode + '/' + this.pageID], { relativeTo: this.activatedRoute });
    }

    SetLang() {
        let lang = localStorage.getItem('lang');
        if (lang === null) {
            this.translate.setDefaultLang('en');
        } else {
            this.translate.use(lang);
        }
    }
}
